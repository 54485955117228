import { DEFAULT_OPTION } from "../constants/option.js";
import { List, Model } from "./base.js";

export class Option extends Model {
  isEnabled(data) {
    return this.filter(data);
  }

  get defaults() {
    return DEFAULT_OPTION;
  }
}

export class OptionsList extends List {
  getEnabledOptions(data) {
    return this.models.filter((option) => option.isEnabled(data));
  }

  getOptions() {
    return this.models;
  }

  get model() {
    return Option;
  }
}
