import React, { createContext, useState, useCallback, useEffect } from "react";
import Engage from "../services/engage";

const AvailableNumbersContext = createContext({});

export function AvailableNumbersProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableNumbers, setAvailableNumbers] = useState([]);

  const getAvailableNumbers = useCallback(
    async (areaCode, countryCode) => {
      setLoading(true);
      setError(null);
      const res = await Engage.getAvailableNumbers({ areaCode, countryCode });
      if (res.error) {
        setError(res.message);
      } else {
        setAvailableNumbers(res.response);
      }
      setLoading(false);
    },
    [availableNumbers, setAvailableNumbers, setLoading, setError],
  );

  // useEffect(() => {
  //   if (!loading) {
  //     getAvailableNumbers();
  //   }
  // }, [areaCode]);

  return (
    <AvailableNumbersContext.Provider
      value={{ loading, error, availableNumbers, getAvailableNumbers }}
    >
      {children}
    </AvailableNumbersContext.Provider>
  );
}

export default AvailableNumbersContext;
