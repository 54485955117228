import { loadAppFromCacheAsync } from "~/helpers/cache.native";
import FieldPulse from "../services/fieldpulse";
import Engage from "../services/engage";

export const loadApplicationAsync = async () => {
  console.log("[Loading App From Storage]");
  const appCache = await loadAppFromCacheAsync();
  const { apiToken, engageToken } = appCache;
  // Set FieldPulse Token
  if (apiToken) FieldPulse.token(apiToken);
  if (engageToken) Engage.token(engageToken);
  return appCache;
};

export function getOffset(el) {
  if (!el) return { left: 0, top: 0 };
  const rect = el.getBoundingClientRect();
  return rect;
}
