import { List } from "./base";
import { PhoneNumber } from "./PhoneNumber";

export default class PhoneNumbers extends List {
  getPhoneNumbersForCallRouting(
    currentPhoneNumberId = "",
    serializeForList = true,
  ) {
    const phoneNumbersWithoutCallRouting = this.models.filter(
      (phoneNumber) =>
        !phoneNumber.hasCallRouting && phoneNumber.id !== currentPhoneNumberId,
    );

    if (serializeForList) {
      return phoneNumbersWithoutCallRouting.map((phoneNumber) =>
        phoneNumber.serializeForList(),
      );
    }

    return phoneNumbersWithoutCallRouting;
  }

  orderAlphabetically() {
    return this.models.sort((a, b) =>
      a.friendlyName.localeCompare(b.friendlyName),
    );
  }

  get phoneNumbers() {
    return this.models;
  }

  get model() {
    return PhoneNumber;
  }
}
