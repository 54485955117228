import { useState, useCallback, useEffect } from "react";
import { firebaseMessaging, getFCMToken }   from "../services/firebase";
import { onMessage }                        from "firebase/messaging";

const Notification = typeof window === 'undefined' ? null : window.Notification;

export default function useWebPush() {

  if(typeof window === 'undefined'){
    throw new Error("useWebPush can only be used in supported Web Browsers.")
  }

  const [notification, setNotification] = useState(null);
  const [token,setToken] = useState(localStorage.getItem("FCM_TOKEN"))
  const [granted, setGranted] = useState(Notification?.permission === "granted");

  const requestPermissions = useCallback( async () => {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      setGranted(false)
      return;
    }
    else if (Notification.permission === "granted" && token) {
      console.log('Web Push Permissions have been granted!')
      setGranted(true)
      return token
    }
    // Otherwise, we need to ask the user for permission
    else if (
      Notification.permission !== "denied" ||
      Notification.permission === "default" || 
      !token
    ) {
      localStorage.removeItem("FCM_TOKEN")
      const fcmToken = await getFCMToken();
      if(fcmToken && typeof fcmToken === 'string'){
        localStorage.setItem("FCM_TOKEN",fcmToken)
        setToken(fcmToken)
        return fcmToken
      }
    }
  }, [granted]);

  useEffect(()=>{

    if (granted) {
      onMessage(firebaseMessaging, (payload) => {
        console.log("Message received in foreground. ", payload);
          setNotification({ ...payload.notification });
      });
    }
  },[granted])

  return {
    requestPermissions,
    notification,
    granted,
    pushToken: token
  };
}
