import React from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import palette from "../../styles/palette";

const LoadingIndicator = ({ title }) => {
  return (
    <View style={styles.loading_indicator}>
      <ActivityIndicator size="large" />
      <Text style={[styles.loader_list__item_text, { marginTop: 20 }]}>
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  loading_indicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    width: 200,
    height: 200,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: `1px 2px 20px 4px rgba(0,0,0,0.3)`,
  },
  loader_list__item_text: {
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
});

export default LoadingIndicator;
