import { useCallback, useEffect } from "react";
import { APP_ENV, BASE_URI } from "~/constants/app";
import { EngageApi } from "@flicent/field-pulse-js-sdk";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Engage = new EngageApi({ mode: APP_ENV, baseURL: BASE_URI });

export default function useEngage() {
  const loadToken = useCallback(async () => {
    const token = await AsyncStorage.getItem("access_token");
    if (token) {
      Engage.token(token);
    }
  }, [Engage]);

  useEffect(() => {
    loadToken();
  }, [false]);

  return Engage;
}
