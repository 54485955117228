import { useState, useEffect, useRef, useCallback } from "react";
import FieldPulse from "~/services/fieldpulse";
import Engage from "~/services/engage";
import { getAttributesForParticipant } from "~/helpers/participants";

export default function useParticipant(initial = {}) {
  let isMounted = useRef(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loadingCustomer, setLoadingCustomer] = useState(null);

  const [participant, setParticipant] = useState(
    getAttributesForParticipant(initial?.participant, initial?.customer),
  );

  const [customer, setCustomer] = useState({
    id: participant?.remote_id,
    ...initial?.customer,
  });

  const updateParticipant = useCallback(
    (data = {}) => {
      setParticipant(
        getAttributesForParticipant(
          {
            ...participant,
            ...data,
          },
          customer,
        ),
      );
    },
    [participant, customer, setParticipant],
  );

  const getCustomer = useCallback(
    async (id) => {
      const customer_id = id || customer?.id;
      if (!customer_id || loadingCustomer) return;
      if (isMounted.current) setLoadingCustomer(true);
      const res = await FieldPulse.getCustomer(
        customer_id,
        "files,jobs,invoices",
      );
      if (res.error) {
        if (isMounted.current) setError("No customer data available.");
      } else {
        if (isMounted.current) {
          setCustomer(res.response);
        }
      }
      if (isMounted.current) setLoadingCustomer(false);
      return res;
    },
    [customer, loadingCustomer, setCustomer, setLoadingCustomer],
  );

  const getParticipant = useCallback(
    async (id) => {
      const participant_id = id || participant?.id;
      if (!participant_id || loading) return;
      if (isMounted.current) setLoading(true);
      const res = await Engage.getParticipant(participant_id);
      if (res.error) {
        if (isMounted.current) setError(res.message);
      } else {
        if (isMounted.current) {
          updateParticipant(res.response);
        }
      }
      if (isMounted.current) setLoading(false);
      return res;
    },
    [loading, participant, setParticipant, setError, setLoading],
  );

  useEffect(() => {
    if (initial?.participant?.id !== participant?.id) {
      setParticipant(initial?.participant);
    }
  }, [initial]);

  useEffect(() => {
    isMounted.current = true;
    if (participant?.id) {
      getParticipant();
    }

    return () => {
      isMounted.current = false;
    };
  }, [participant?.id, customer?.id]);

  return {
    error,
    loading,
    loadingCustomer,
    participant,
    customer,
    getCustomer,
    getParticipant,
  };
}
