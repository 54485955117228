import React, { useState } from "react";
import { Pressable, StyleSheet, TextInput, View } from "react-native";
import Icon from "react-native-vector-icons/Feather";
import palette from "../../styles/palette";

export default function StyledInput({
  style,
  icon,
  center,
  innerRef,
  containerStyle,
  lessPadding = false,
  hoverable = true,
  bold = false,
  border = false,
  autoGrow = false,
  ...props
}) {
  const [state, setState] = useState(null);
  const [height, setHeight] = useState("auto");

  const updateHeight = (e) => {
    setHeight(e?.nativeEvent?.contentSize?.height || "auto");
  };

  return (
    <Pressable
      onHoverIn={() =>
        hoverable &&
        setState((state) => (state === "focused" ? "focused" : "hovered"))
      }
      onHoverOut={() =>
        hoverable &&
        setState((state) => (state === "focused" ? "focused" : null))
      }
    >
      <View
        style={[
          {
            backgroundColor:
              hoverable && state === "focused"
                ? palette.lightest_grey
                : state === "hovered"
                ? palette.lighter_grey
                : palette.white,
            borderRadius: 4,
          },
          border && { borderWidth: 1, borderColor: palette.light_grey },
          containerStyle,
        ]}
      >
        <TextInput
          ref={innerRef}
          placeholderTextColor={palette.light_grey}
          style={[
            styles.styled_input,
            style,
            icon && { paddingRight: 36 },
            center && { textAlign: "center" },
            lessPadding && {
              paddingVertical: 4,
              paddingHorizontal: 8,
              fontSize: 14,
            },
            bold && {
              fontWeight: "600",
              fontFamily: "OpenSans_600SemiBold",
            },
            autoGrow && { height, maxHeight: 200 },
          ]}
          {...props}
          onFocus={() => {
            setState("focused");
            if (props.onFocus) props?.onFocus();
          }}
          onBlur={() => {
            setState(null);
            if (props.onBlur) props?.onBlur();
          }}
          onContentSizeChange={(e) => {
            if (autoGrow) updateHeight(e);
            if (props?.onContentSizeChange) props?.onContentSizeChange(e);
          }}
        />
        {icon && (
          <Icon
            name={icon}
            size={18}
            color={palette.grey}
            style={[styles.styled_input__icon]}
          />
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  styled_input: {
    borderColor: palette.light_grey,
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    fontWeight: "400",
    fontFamily: "OpenSans_400Regular",
    outlineColor: palette.transparent,
    outlineOffset: 3,
    outlineWidth: 0,
    borderRadius: 2,
  },
  styled_input__icon: {
    position: "absolute",
    right: 16,
    top: "calc(50% - 10px)",
  },
});
