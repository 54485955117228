import { ScrollView, View, ActivityIndicator, StyleSheet } from "react-native";
import {
  BusinessAddressForm,
  BusinessInformationForm,
  BusinessRepresentativeForm,
} from "../../../modals/BusinessInformationModal/index.web";
import { useContext } from "react";
import UserContext from "../../../contexts/UserContext";
import Row from "../../../layouts/Row";
import SolidButton from "../../../components/Buttons/SolidButton";
import palette from "../../../styles/palette";
import { Business } from "../../../models/BusinessInformation";
import { Text } from "react-native";
import Heading from "../../../components/Heading/index.web";
import { CAMPAIGN_STATUS_NOT_SUBMITTED } from "../../../constants/businessInformation";
import { useEffect } from "react";
import BusinessContext from "../../../contexts/BusinessContext";
import ResubmitCampaignScreen from "./ResubmitCampaignScreen/index.web";
import { Strings } from "../../../constants/strings";
import InfoIconButton from "../../../components/InfoIconButton/index.web";

export default function BusinessInformationScreen() {
  const {
    fetchingCampaignStatus,
    campaignStatus,
    hasFlicentCampaign,
    setBusinessModelVisible,
  } = useContext(UserContext);
  const { loadingCampaignData, getCampaignData, brandStatus } =
    useContext(BusinessContext);

  useEffect(() => {
    if (
      !fetchingCampaignStatus &&
      campaignStatus !== false &&
      campaignStatus !== CAMPAIGN_STATUS_NOT_SUBMITTED &&
      brandStatus === false
    ) {
      getCampaignData();
    }
  }, [campaignStatus, fetchingCampaignStatus]);

  if (fetchingCampaignStatus || loadingCampaignData)
    return (
      <ActivityIndicator
        size="large"
        color={palette.primary}
        style={styles.activityIndicator}
      />
    );

  const onPressHandler = () => {
    setBusinessModelVisible(true);
  };

  return (
    <View style={styles.business_information_container}>
      <BusinessInformationHeader
        hasFlicentCampaign={hasFlicentCampaign}
        campaignStatus={campaignStatus}
        brandStatus={brandStatus}
      />
      <ScrollView
        contentContainerStyle={[
          styles.business_information_scrollview,
          hasFlicentCampaign && { width: "auto" },
        ]}
      >
        {campaignStatus === CAMPAIGN_STATUS_NOT_SUBMITTED ? (
          <NewCampaignButton onModalOpen={onPressHandler} />
        ) : hasFlicentCampaign ? (
          <ResubmitCampaignScreen onModalOpen={onPressHandler} />
        ) : (
          <Form />
        )}
      </ScrollView>
    </View>
  );
}

const Form = () => {
  return (
    <>
      <BusinessInformationForm editing={false} />
      <BusinessAddressForm editing={false} />
      <BusinessRepresentativeForm editing={false} />
    </>
  );
};

const NewCampaignButton = ({ onModalOpen }) => {
  return (
    <View>
      <Text style={styles.business_information_message}>
        {Strings.A2P_10DLC_SUBMISSION_MESSAGE}
      </Text>
      <Row>
        <View>
          <SolidButton
            label="Fill Details"
            color={palette.info}
            onPress={onModalOpen}
          />
        </View>
      </Row>
    </View>
  );
};

const BusinessInformationHeader = ({
  hasFlicentCampaign,
  campaignStatus,
  brandStatus,
}) => {
  if (hasFlicentCampaign) {
    return null;
  }
  const status = Business.getStatusLabel(campaignStatus, brandStatus);
  return (
    <View style={styles.business_information_header_container}>
      <Row leftCenter style={styles.row_gap}>
        <Heading size={3}>10DLC Campaign</Heading>
        <InfoIconButton link={Strings.A2P_10DLC_DOCS_URL} ml={0} mr={4} />
        <View>
          <SolidButton
            label={status?.label}
            color={status?.color}
            onPress={null}
          />
        </View>
      </Row>
      <Row mt={4}>
        <Text style={styles.campaign_status}>{status?.message}</Text>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  activityIndicator: {
    margin: 24,
  },
  business_information_container: {
    flex: 1,
  },
  business_information_scrollview: {
    paddingHorizontal: 24,
    width: "65%",
  },
  business_information_message: {
    fontFamily: "OpenSans_600SemiBold",
    marginBottom: 12,
  },
  business_information_header_container: {
    borderBottomColor: palette.light_grey,
    borderBottomWidth: StyleSheet.hairlineWidth,
    paddingVertical: 8,
    marginBottom: 16,
    paddingHorizontal: 24,
  },
  row_gap: {
    gap: 8,
    alignItems: "center",
  },
  campaign_status: { color: palette.label_grey },
});
