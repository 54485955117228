import React from "react";
import { TouchableOpacity, Text, View } from "react-native";

import commonStyles from "../../styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import palette from "../../styles/palette";

export default function CheckBox({
  checked,
  label,
  onChange,
  labelStyle = {},
  color = palette.label_grey,
}) {
  return (
    <TouchableOpacity
      style={[commonStyles.row, commonStyles.alignCenter]}
      onPress={onChange}
    >
      <View
        style={[
          commonStyles.row,
          commonStyles.alignCenter,
          { cursor: "pointer" },
        ]}
      >
        <FontAwesomeIcon
          icon={checked ? faSquareCheck : faSquare}
          color={color}
        />

        {label && (
          <Text style={[commonStyles.ml4, { color }, labelStyle]}>{label}</Text>
        )}
      </View>
    </TouchableOpacity>
  );
}
