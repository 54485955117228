import React from "react";
import { StyleSheet, View } from "react-native";
import palette from "~/styles/palette";

export default function Card({ children, style }) {
  return <View style={[styles.card__container, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  card__container: {
    backgroundColor: palette.white,
    borderRadius: 16,
    padding: 8,
    width: "100%",
    overflow: "hidden",
    // display:'relative'
  },
});
