import React, {
  useMemo,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import { StyleSheet, Text, View }                from "react-native";

import Row                                       from "~/layouts/Row";
import Col                                       from "~/layouts/Col";
import AdjustableScreen                          from "~/layouts/AdjustableScreen/index.web";
import UserContext                               from "~/contexts/UserContext";
import { UsersTable }                            from "~/components/Admin/Users/UsersTable/index.web";
import { AccountsTable }                         from "../../components/Admin/Accounts/AccountsTable/index.web";
import { Link, Switch, Route }                   from "react-router-dom";

const Routes = [
  {
    label: "Accounts",
    path: "/admin/accounts",
  },
  {
    label: "Users",
    path: "/admin/users",
  },
  // {
  //   label: "Reports",
  //   path: "/admin/reports",
  // },
];

export default function AdminScreen({ history }) {
  const { user, loggedIn } = useContext(UserContext);

  useEffect(() => {
    if (loggedIn) {
      if (!user.super_user) {
        history.push("/login");
      }
    }
  }, [user]);

  return (
    <AdjustableScreen>
      <Row flex>
        <Col style={{ maxWidth: 200 }} pl={16} pr={16} pt={16}>
          <View>
            <ul>
              {Routes.map((route, x) => {
                return (
                  <li key={x}>
                    <Link to={route.path}>
                      <Text>{route.label}</Text>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </View>
        </Col>
        <Col pl={16} pr={16} pt={16}>
          <Switch>
            <Route path="/admin/users" component={UsersTable} />
            <Route path="/admin/accounts" component={AccountsTable} />
          </Switch>
        </Col>
      </Row>
    </AdjustableScreen>
  );
}

const styles = StyleSheet.create({});
