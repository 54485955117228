export const sortByCreatedAt = (data) => {
  return data.sort((a, b) =>
     new Date(b.created_at) - new Date(a.created_at)
  );
};

export const sortByTimestamp = (data) => {
  return data.sort((a, b) =>
     new Date(b.timestamp) - new Date(a.timestamp)
  );
};