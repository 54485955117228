import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useParticipant from "~/hooks/useParticipant";
import { safeParseNumber } from "~/helpers/phoneNumbers";
import { getCustomerName } from "../helpers/customers";
import ConversationContext from "./ConversationContext";
const ParticipantContext = createContext({});

export function ParticipantProvider({ value: initialValue, children }) {
  const [value, setValue] = useState(initialValue);

  const {
    participant,
    customer,
    getCustomer,
    getParticipant,
    loading,
    loadingCustomer,
  } = useParticipant(value);

  const { name, country_code, phone_number } = participant || {};
  const { clearConversationTimeStamp } = useContext(ConversationContext);

  const displayName = useMemo(() => {
    if (name) {
      return name;
    } else if (customer) {
      getCustomerName(customer);
    }
    return "";
  }, [name, customer]);

  const phoneLabel = useMemo(() => {
    if (country_code && phone_number) {
      return safeParseNumber(phone_number, country_code).formatNational();
    } else if (customer?.phone && customer?.country_code) {
      return safeParseNumber(
        customer.phone,
        customer.country_code,
      ).formatNational();
    }
    return "";
  }, [phone_number, country_code, customer]);

  useEffect(() => {
    setValue({});
  }, [clearConversationTimeStamp]);

  return (
    <ParticipantContext.Provider
      value={{
        loading,
        loadingCustomer,
        participant,
        customer,
        getCustomer,
        getParticipant,
        displayName,
        phoneLabel,
      }}
    >
      {children}
    </ParticipantContext.Provider>
  );
}

export default ParticipantContext;
