import {
  daylightTimeZones,
  standardTimeZones,
  timezones,
} from "../constants/timezones";
import DayJs from "../helpers/day";

export const isDaylightSavings = () => {
  const date = DayJs();

  let daylightStartDate = DayJs();
  daylightStartDate = daylightStartDate
    .set("date", 10)
    .set("month", 2)
    .set("hour", 2)
    .set("minute", 0);

  let daylightEndDate = DayJs();
  daylightEndDate = daylightEndDate
    .set("date", 3)
    .set("month", 10)
    .set("hour", 2)
    .set("minute", 0);

  if (date.isAfter(daylightStartDate) && date.isBefore(daylightEndDate)) {
    return true;
  }

  return false;
};

export const getTimezones = () => {
  let res = [];
  try {
    const isDaylightSavingsTime = isDaylightSavings();
    const correctTimeZone = isDaylightSavingsTime
      ? daylightTimeZones
      : standardTimeZones;

    timezones.map((tz) => {
      if (correctTimeZone[tz.name]) {
        res.push({ ...tz, name: correctTimeZone[tz.name] });
        return;
      }
      res.push(tz);
    });
  } catch {
    res = timezones;
  }

  return res;
};
