import { Platform }       from "react-native";
import * as Notifications from "expo-notifications";
import Constants          from "expo-constants";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

// Notifications.addNotificationReceivedListener(() => {
  
// });

// Notifications.addNotificationResponseReceivedListener(() => {
  
// });

export const registerForPushNotificationsAsync = async () => {
  let token;
  if (Constants.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      console.error("Failed to get push token for push notification!");
      return;
    }
    token = (
      await Notifications.getExpoPushTokenAsync({
        experienceId: "@fieldpulse/field-pulse-engage",
        applicationId: "com.fieldpulse.engage",
      })
    ).data;
    console.log("[registerForPushNotificationsAsync:token]", token);
  } else {
    console.error(
      "[registerForPushNotificationsAsync]",
      "Must use physical device for Push Notifications"
    );
  }
  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("engage", {
      name: "engage",
      importance: Notifications.AndroidImportance.MAX,
      enableVibrate: true,
      showBadge: true,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }
  return token;
};
