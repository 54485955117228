import {
  Modal,
  View,
  Text,
  StyleSheet,
  Button,
  TouchableOpacity,
} from "react-native";
import palette from "~/styles/palette";
import Row from "../../layouts/Row";

const CommanModal = (props) => {
  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        visible={props?.isVisisble}
        onRequestClose={props?.onClose}
      >
        <View
          style={[
            style.container,
            {
              backgroundColor: props?.transparent
                ? palette.transparent_grey_light
                : palette.transparent_grey_dark,
            },
          ]}
        >
          <View style={style.main}>
            <Text style={style.message}>{props?.message}</Text>
            <Row style={{ gap: 8 }}>
              {props?.buttonAction ? (
                <TouchableOpacity
                  onPress={props?.buttonAction}
                  style={style.action_button}
                >
                  <Text style={style.action_button_text}>
                    {props?.buttonTitle}
                  </Text>
                </TouchableOpacity>
              ) : null}
              {props?.closeable && (
                <TouchableOpacity
                  onPress={props?.onClose}
                  style={style.action_button}
                >
                  <Text style={style.action_button_text}>
                    {!!props?.closeableLabel ? props?.closeableLabel : "Close"}
                  </Text>
                </TouchableOpacity>
              )}
            </Row>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default CommanModal;

CommanModal.defaultProps = {
  message: "",
  onClose: () => null,
  isVisisble: false,
  buttonTitle: false,
};

const style = StyleSheet.create({
  container: {
    height: "100vh",
    width: "100vw",
    backgroundColor: palette.transparent_grey_dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    backgroundColor: palette.white,
    padding: 15,
    width: "90%",
    borderRadius: 10,
    justifyContent: "space-between",
    minHeight: 0,
    maxWidth: 500,
  },
  message: {
    color: palette.black,
    fontSize: 16,
  },
  action_button: {
    borderWidth: 1,
    borderColor: palette.border_black,
    padding: 8,
    textAlign: "center",
    borderRadius: 8,
    marginTop: 30,
  },
  action_button_text: {
    color: palette.blue_turquoise,
    fontWeight: "bold",
  },
});
