import React from "react";
import { StyleSheet, Text, View } from "react-native";
import palette from "~/styles/palette";

export default function BlockedBadge({ visible, style }) {
  if (visible) {
    return (
      <View style={[styles.blocked_badge__container, style]}>
        <Text style={styles.blocked_badge__text}>BLOCKED</Text>
      </View>
    );
  }
  return false;
}

const styles = StyleSheet.create({
  blocked_badge__container: {
    backgroundColor: palette.blocked,
    borderRadius: 4,
    paddingHorizontal: 6,
    paddingVertical: 3,
  },
  blocked_badge__text: {
    color: palette.white,
    fontFamily: "OpenSans_700Bold",
    fontSize: 12,
  },
});
