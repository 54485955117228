import React, { useContext } from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import StepWizard from "react-step-wizard";
import { Redirect } from "react-router-dom";

import palette from "../../styles/palette";
import Row from "../../layouts/Row";
import Col from "../../layouts/Col";
import Card from "../../components/Card";

import AcquirePhoneNumberStep from "./AcquirePhoneNumberStep/index.web";
import GetStartedStep from "./GetStartedStep/index.web";
import SelectAreaCodeStep from "./SelectAreaCodeStep/index.web";
import OnboardingContext, {
  OnboardingProvider,
} from "../../contexts/OnboardingContext";
import UserContext from "../../contexts/UserContext";
import SelectCountryStep from "./SelectCountryStep/index.web";

export default function OnboardingScreen() {
  const { user } = useContext(UserContext);

  if (user?.skip_onboarding) {
    return <Redirect to="/messages" />;
  }

  return (
    <OnboardingProvider>
      <View
        style={[
          styles.onboarding_screen__container,
          { height: Dimensions.get("window").height },
        ]}
      >
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            height: "100%",
          }}
        >
          <Col center size={6} style={{ minWidth: 420 }} maxWidth={420}>
            <Card>
              <Steps />
            </Card>
          </Col>
        </Row>
      </View>
    </OnboardingProvider>
  );
}

const Steps = () => {
  const { user } = useContext(UserContext);
  const country = user?.account?.country;
  return (
    <StepWizard>
      <SelectCountryStep />
      {country !== "AU" && <SelectAreaCodeStep />}
      <AcquirePhoneNumberStep />
      <GetStartedStep />
    </StepWizard>
  );
};

const styles = StyleSheet.create({
  onboarding_screen__container: {
    backgroundColor: palette.light_grey,
    flex: 1,
  },
});
