import React from "react";
import { Platform, StyleSheet, Text } from "react-native";
import palette from "~/styles/palette";

export default function Paragraph({
  children,
  center,
  style,
  mb = 15,
  mt = 15,
}) {
  return (
    <Text
      style={[
        styles.paragraph,
        { textAlign: center ? "center" : "left" },
        { marginBottom: mb, marginTop: mt },
        style,
        {
          ...(Platform.OS === "web"
            ? { fontFamily: "acumin-pro, sans-serif" }
            : {}),
        },
      ]}
    >
      {children}
    </Text>
  );
}

const styles = StyleSheet.create({
  paragraph: {
    fontSize: 16,
    color: palette.grey,
    fontWeight: "400",
    // letterSpacing: "0.25px"
  },
});
