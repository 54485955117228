import React from "react";
import { StyleSheet, Text, View } from "react-native";
import palette from "~/styles/palette";

const SIZES = {
  1: 30,
  2: 26,
  3: 22,
  4: 20,
  5: 16,
};

const WEIGHTS = {
  1: "700",
  2: "700",
  3: "700",
  4: "700",
  5: "600",
};

export default function Heading({
  children,
  size = 1,
  center = false,
  color = palette.black,
  numberOfLines = 1,
  ml = 0,
  mr = 0,
  mt = 5,
  mb = 5,
  pl = 0,
  pr = 0,
  pt = 0,
  pb = 0,
  textStyle,
}) {
  return (
    <View
      style={{
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
      }}
    >
      <Text
        numberOfLines={numberOfLines}
        style={[
          styles.heading,
          {
            textAlign: center ? "center" : "left",
            fontWeight: WEIGHTS[size],
            fontSize: SIZES[size],
            color,
          },
          textStyle,
        ]}
      >
        {children}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontSize: 30,
    fontWeight: "700",
    color: palette.black,
  },
});
