import FieldPulse from "../services/fieldpulse";
import { List } from "./base";
import { Contact } from "./Contact";

export default class Contacts extends List {
  static async getContactsForCustomer(params) {
    const res = await FieldPulse.getContactsForCustomer(params);
    return res;
  }

  getContactsForDropdown() {
    return this.models.map((contact) => contact.getDropdownParams());
  }

  get model() {
    return Contact;
  }
}
