import { useCallback, useContext } from "react";
import { Alert } from "react-native";
import MultipleImagePicker from "@baronha/react-native-multiple-image-picker";
import { MediaContext } from "../contexts/MediaContext";

export default function useMediaLibrary() {
  const { assets, setAssets } = useContext(MediaContext);

  const selectAssets = useCallback(async () => {
    try {
      const response = await MultipleImagePicker.openPicker({
        mediaType: "image",
        selectedAssets: assets,
        maxSelectedAssets: 1,
      });

      if (response[0].size > 25000000) {
        Alert.alert(
          "Your image is too large. Image sizes are limited to 25 MB."
        );
      } else {
        setAssets(response);
      }
    } catch (e) {
      console.log("[useMediaLibrary:selectAssets - Error] = ", e);
    }
  }, [assets, setAssets]);

  return {
    assets,
    selectAssets,
  };
}
