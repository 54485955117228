import React, { useEffect, useMemo, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import Fullscreen from "yet-another-react-lightbox/dist/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";

export default function LightboxImage({
  styles,
  currentMessage,
  imageStyle,
  imageProps,
  lightboxProps,
  index = 0,
  isOpen = false,
  onChange = null,
}) {
  const [lightboxOpen, setLightboxOpen] = useState(isOpen);
  const [currentIndex, setCurrentIndex] = useState(index);
  const imagesLength = currentMessage?.images?.length;
  let thumbnail;

  if (currentMessage?.images?.length) {
    if (currentMessage.images[currentIndex]?.thumb_url) {
      thumbnail = currentMessage.images[currentIndex].thumb_url;
    } else if (typeof currentMessage.images[currentIndex]?.url !== "string") {
      thumbnail = URL.createObjectURL(currentMessage.images[currentIndex].url);
    } else {
      thumbnail = currentMessage.images[currentIndex]?.url;
    }
  } else {
    if (currentMessage.thumbnail) {
      thumbnail = currentMessage.thumbnail;
    } else if (typeof currentMessage.image !== "string") {
      thumbnail = URL.createObjectURL(currentMessage.image);
    } else {
      thumbnail = currentMessage.image;
    }
  }

  let preview = currentMessage?.images?.length
    ? typeof currentMessage.images[currentIndex]?.url !== "string"
      ? URL.createObjectURL(currentMessage?.images[currentIndex]?.url)
      : currentMessage.images[currentIndex]?.url
    : typeof currentMessage.image !== "string"
    ? URL.createObjectURL(currentMessage.image)
    : currentMessage.image;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === imagesLength ? 0 : prevIndex + 1,
    );
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? imagesLength - 1 : prevIndex - 1,
    );
  };

  const previousImage = useMemo(() => {
    let previousIndex =
      currentIndex - 1 < 0 ? imagesLength - 1 : currentIndex - 1;

    return currentMessage?.images?.length > 1
      ? typeof currentMessage.images[previousIndex]?.url !== "string"
        ? URL.createObjectURL(currentMessage.images[previousIndex].url)
        : currentMessage.images[previousIndex].url
      : null;
  }, [currentMessage?.images, currentIndex]);

  const nextImage = useMemo(() => {
    let nextIndex = currentIndex + 1 === imagesLength ? 0 : currentIndex + 1;
    return currentMessage?.images?.length > 1
      ? typeof currentMessage.images[nextIndex]?.url !== "string"
        ? URL.createObjectURL(currentMessage.images[nextIndex].url)
        : currentMessage.images[nextIndex].url
      : null;
  }, [currentMessage?.images, currentIndex, imagesLength]);

  useEffect(() => {
    setLightboxOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (lightboxOpen) {
      setCurrentIndex(index);
    }
  }, [lightboxOpen, index]);

  return (
    <>
      <TouchableOpacity onPress={() => setLightboxOpen(true)}>
        <Image
          {...imageProps}
          style={[styles.image, imageStyle]}
          source={{
            uri: thumbnail,
          }}
        />
      </TouchableOpacity>

      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        index={currentIndex}
        slides={currentMessage?.images?.map((image) => ({
          src: image?.url,
        }))}
        plugins={[Fullscreen, Thumbnails, Zoom]}
        noScroll
        controller={{
          closeOnPullDown: true,
          closeOnBackdropClick: true,
        }}
      />
    </>
  );
}
