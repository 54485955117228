import { useContext, useEffect, useState } from "react";
import { Text, TouchableWithoutFeedback, View } from "react-native-web";
import StepWizard from "react-step-wizard";
import IconButton from "../../components/Buttons/IconButton";
import SolidButton from "../../components/Buttons/SolidButton";
import { Field } from "../../components/PhoneTree/PhoneTreeForm/index.web";
import StyledInput from "../../components/StyledInput";
import { templates } from "../../constants/phoneTreeTypes";
import { PhoneTreeContext } from "../../contexts/PhoneTreeContext";
import useToggle from "../../hooks/useToggle";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import commonStyles from "../../styles/common";
import palette from "../../styles/palette";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function PhoneTreeModal() {
  const { nodes } = useContext(PhoneTreeContext);
  const [show, setShow] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (nodes.length === 0) {
      setShow(true);
      setIsEmpty(true);
    } else if (nodes.length > 0 && nodes[0].text?.length < 1) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [JSON.stringify(nodes)]);

  if (!show) return null;

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.24)",
        position: "absolute",
        zIndex: 20000,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: 500,
          backgroundColor: palette.bg_light_grey,
          borderRadius: 6,
          border: `2px solid ${palette.light_grey}`,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          padding: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Steps isEmpty={isEmpty} />
      </View>
    </View>
  );
}

const Steps = ({ isEmpty }) => {
  const [selectedChoice, toggleChoice] = useState(0);
  const [stepCount, setStepCount] = useState(1);
  if (isEmpty) {
    switch (stepCount) {
      case 1:
        return (
          <ChoiceStep
            toggleChoice={toggleChoice}
            setStepCount={() => setStepCount(2)}
          />
        );
      case 2:
        if (selectedChoice === 0) {
          return (
            <MainGreetingStep
              isEmpty={isEmpty}
              setStepCount={() => {
                setStepCount(1);
              }}
            />
          );
        } else
          return (
            <TemplatesStep
              setStepCount={() => {
                setStepCount(1);
              }}
            />
          );

      default:
        return null;
    }
  }
  return <MainGreetingStep />;
};

const ChoiceStep = ({ toggleChoice, setStepCount }) => {
  return (
    <Row center>
      <Col style={{ marginRight: 10 }}>
        <SolidButton
          style={{
            height: 100,
            border: `2px solid ${palette.light_grey}`,
          }}
          label="Start from Scratch"
          color={palette.background_color}
          labelColor={palette.black}
          onPress={() => {
            toggleChoice(0);
            setStepCount();
          }}
        />
      </Col>
      <Col>
        <SolidButton
          style={{ height: 100 }}
          label="Choose from Templates"
          onPress={() => {
            toggleChoice(1);
            setStepCount();
          }}
        />
      </Col>
    </Row>
  );
};

const MainGreetingStep = ({ isEmpty, setStepCount }) => {
  const [label, setLabel] = useState("");
  const { editData, addMainNode } = useContext(PhoneTreeContext);

  const handleKeyPress = ({ nativeEvent: { key: keyValue } }) => {
    if (keyValue === "Enter") {
      isEmpty ? addMainNode(label) : editData(0, { text: label });
    }
  };
  return (
    <Row>
      <Col>
        {isEmpty && (
          <Row>
            <IconButton
              faPro
              faProIcon={faChevronLeft}
              style={{ marginLeft: 0 }}
              onPress={setStepCount}
            />
          </Row>
        )}
        <View style={{ paddingHorizontal: 10 }}>
          <Field
            label="Main Greeting"
            required
            showLength
            maxLength={200}
            length={label?.length}
          >
            <StyledInput
              style={{ backgroundColor: palette.white, marginBottom: 10 }}
              placeholder="Welcome to Fieldpulse!"
              onChangeText={(value) => setLabel(value)}
              value={label}
              onKeyPress={handleKeyPress}
              maxLength={200}
              nativeID="phone_tree_greeting"
            />
          </Field>
          <Row size={4} right>
            <SolidButton
              label={isEmpty ? "Start Building Tree" : "Continue"}
              onPress={() => {
                isEmpty ? addMainNode(label) : editData(0, { text: label });
              }}
              disabled={label.length === 0}
            />
          </Row>
        </View>
      </Col>
    </Row>
  );
};

const TemplatesStep = ({ setStepCount }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { useTemplate } = useContext(PhoneTreeContext);
  return (
    <Row>
      <Col>
        <Row>
          <IconButton
            faPro
            faProIcon={faChevronLeft}
            style={{ marginLeft: 0 }}
            onPress={setStepCount}
          />
        </Row>
        <View style={{ paddingHorizontal: 10 }}>
          <Text style={{ textAlign: "center", fontSize: 16 }}>
            Select from Templates
          </Text>
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            {templates.map((template, i) => (
              <TouchableWithoutFeedback
                onPress={() => {
                  setSelectedTemplate(i);
                }}
              >
                <Col
                  style={{
                    backgroundColor: palette.background_color,
                    border:
                      selectedTemplate === i
                        ? `2px solid ${palette.primary}`
                        : `2px solid ${palette.light_grey}`,
                    padding: 20,
                    borderRadius: 6,
                    marginRight: i === templates.length - 1 ? 0 : 10,
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text>{template.name}</Text>
                </Col>
              </TouchableWithoutFeedback>
            ))}
          </Row>
          <Row size={4} right>
            <SolidButton
              label="Use Template"
              disabled={selectedTemplate === null}
              onPress={() => useTemplate(templates[selectedTemplate])}
            />
          </Row>
        </View>
      </Col>
    </Row>
  );
};
