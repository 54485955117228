import { GREETING_TYPES, WORKING_HOURS_GREETING_TYPES } from "./mediaTypes";

export const USER_ROLE_TEAM_MANAGER_ADMIN = 1;
export const USER_ROLE_TEAM_MANAGER = 2;
export const USER_ROLE_SERVICE_AGENT = 4;
export const USER_ROLE_LIMITED_AGENT = 7;
export const USER_ROLE_ASSIGNMENT = 6;

export const USER_LOGOUT_DUE_TO_EMAIL_MISMATCH_ERROR =
  "Error: Account Mismatch.\nYou have been logged out due to a mismatch between the account used to login into Engage and Fieldpulse. Please ensure you are using the correct credentials and try logging in again.";

export const DEFAULT_USER = {
  id: null,
  remote_id: null,
  first_name: "",
  last_name: "",
  account: {
    id: null,
    remote_id: null,
    owner_id: null,
    name: "",
    country: "US",
    default_voicemail_greeting: "",
    default_voicemail_greeting_url: null,
    default_voicemail_greeting_type: GREETING_TYPES.TEXT,
    flicent: false,
    working_hours: [
      {
        monday: [
          {
            start: "0000",
            finish: "2400",
          },
        ],
      },
      {
        tuesday: [
          {
            start: "0000",
            finish: "2400",
          },
        ],
      },
      {
        wednesday: [
          {
            start: "0000",
            finish: "2400",
          },
        ],
      },
      {
        thursday: [
          {
            start: "0000",
            finish: "2400",
          },
        ],
      },
      {
        friday: [
          {
            start: "0000",
            finish: "2400",
          },
        ],
      },
      {
        saturday: [
          {
            start: "0000",
            finish: "2400",
          },
        ],
      },
      {
        sunday: [
          {
            start: "0000",
            finish: "2400",
          },
        ],
      },
    ],
    timezone: "-4.0",
    redirect_to: "",
    wh_enabled: false,
    wh_audio_url: null,
    wh_greeting: "",
    wh_greeting_type: WORKING_HOURS_GREETING_TYPES.TEXT,
    out_of_work_audio_url: null,
    out_of_work_greeting: "",
  },
  assigned_number: null,
  managed_numbers: [],
  numbers: [],
  name: "",
  phone: "",
  email: "",
  avatar: null,
  skip_onboarding: true,
  badges: {
    voicemails: 0,
    call_recordings: 0,
    messages: 0,
    calls: 0,
  },
  access_level: "admin",
  settings: {
    do_not_disturb: false,
    incoming_message_tone: false,
  },
  fieldpulse_active: true,
  is_able_to_view_customer_contact_info: true,
  pid: null,
  status: "active",
};
