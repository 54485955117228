import { useCallback, useEffect, useState, useRef } from "react";
import Engage from "../services/engage";
import { SEARCH_LIMIT } from "../constants/search";

export default function useVoicemailssearch({
  search = "",
  page = 1,
  limit = 50,
  ...params
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [voicemails, setVoicemails] = useState([]);

  let timeout = useRef();
  let term = useRef();

  const searchVoicemails = useCallback(async () => {
    setVoicemails([]);
    setLoading(true);
    const res = await Engage.getVoicemails({ search, limit, page, ...params });
    if (res.error) {
      setError(res.message);
    } else {
      setVoicemails(res.response || []);
    }
    setLoading(false);
  }, [loading, search, setError, setVoicemails, setLoading]);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (search !== term?.current?.search) {
      timeout.current = setTimeout(() => {
        term.current = {
          search,
        };
        if (search.length >= SEARCH_LIMIT) searchVoicemails(search);
      }, 300);
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [search]);

  return {
    voicemails,
    error,
    loading,
    search,
    searchVoicemails,
  };
}
