import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  View,
  FlatList,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { useHistory } from "react-router-dom";

import StyledInput from "~/components/StyledInput/";
import Col from "~/layouts/Col";
import Row from "~/layouts/Row";
import palette from "~/styles/palette";
import IconButton from "~/components/Buttons/IconButton/index";
import CustomersContext from "~/contexts/CustomersContext";
import ConversationContext from "~/contexts/ConversationContext";
import SolidButton from "~/components/Buttons/SolidButton";
import AnimatedLogo from "~/components/Logo/AnimatedLogo/index";
import useCustomerSearch from "~/hooks/useCustomerSearch";
import useTwilio from "~/hooks/useTwilio";
import Heading from "~/components/Heading/index";
import FieldPulse from "../../services/fieldpulse";
import NumbersContext from "../../contexts/NumbersContext";
import UserContext from "../../contexts/UserContext";
import ParticipantContext from "~/contexts/ParticipantContext";
import DayJs from "~/helpers/day";
import FilterButton from "../Buttons/FilterButton/index.web";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faComments,
  faPhone,
  faUser,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import { parsePhoneNumber } from "libphonenumber-js";
import { getFullName, toTitleCase } from "../../helpers/text";

export default function CustomersList({ onPress, active, showContact }) {
  const history = useHistory();
  const { setCallQueue, activeCall } = useTwilio();
  const { activeNumber } = useContext(NumbersContext);

  const {
    updateFilter,
    loading,
    customers,
    nextPage,
    filter,
    deleteCustomer: onDeletePress,
  } = useContext(CustomersContext);

  const {
    participant,
    setParticipantFromCustomer,
    setParticipant,
    conversation,
    getConversationByParticipant,
    setTempHeader,
    setHasCustomer,
  } = useContext(ConversationContext);

  const { user } = useContext(UserContext);
  const [didPressText, setDidPressText] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { customers: suggestions, loading: loadingSuggestions } =
    useCustomerSearch({
      search: searchTerm,
      filter,
      user_id: user?.remote_id,
    });

  const showSearchResults = searchTerm?.length;

  const onCallPress = (item) => {
    if (!item?.phone_e164) return;
    try {
      let data = {};
      data.remote_id = item.id;
      const {
        display_name,
        first_name,
        last_name,
        account_type,
        company_name,
      } = item;

      data = {
        ...data,
        name: display_name,
        first_name,
        last_name,
        account_type,
        company_name,
      };

      setCallQueue({ to: item?.phone_e164, metaData: data });
    } catch (e) {}
  };

  const onTextPress = useCallback(
    async (customer) => {
      const title = customer?.display_name;
      setTempHeader(title);
      setHasCustomer(true);
      if (participant?.id) {
        await setParticipant(participant);
      } else {
        await setParticipantFromCustomer(customer);
      }
    },
    [setParticipant, setParticipantFromCustomer, getConversationByParticipant],
  );

  const onCustomerPress = (item) => {
    if (active === item) return;
    onTextPress(item);
    if (onPress) {
      onPress(item);
    }
  };

  return (
    <View style={[styles.customers_list__container]}>
      <CustomersListControlBar activeNumber={activeNumber} />
      <CustomerSearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      {(loadingSuggestions && searchTerm?.length > 0) || loading ? (
        <CustomersLoadingIndicator />
      ) : (
        <></>
      )}
      <FlatList
        style={{
          display: loading ? "none" : "flex",
          flex: 1,
        }}
        refreshing={loading || (loadingSuggestions && searchTerm?.length > 0)}
        onRefresh={() => updateFilter(filter)}
        data={showSearchResults ? suggestions : customers}
        ListEmptyComponent={
          <NoCustomersComponent
            loading={loading || loadingSuggestions}
            searchTerm={searchTerm}
          />
        }
        renderItem={renderCustomerItem(
          onCustomerPress,
          onCallPress,
          onTextPress,
          !!activeCall,
          onDeletePress,
          activeNumber,
          filter,
          active,
          showContact,
        )}
        keyExtractor={(item) => `${item.id}`}
      />
    </View>
  );
}

function CustomerSearchBar({ searchTerm, setSearchTerm }) {
  const { filter, updateFilter, getCustomers } = useContext(CustomersContext);
  const onIconPress = useCallback(() => {
    setSearchTerm("");
    updateFilter(filter);
    getCustomers();
  }, [filter, updateFilter]);

  return (
    <View style={[styles.customers_list__search_input_container]}>
      <StyledInput
        value={searchTerm}
        // autoFocus
        returnKeyType="done"
        placeholder="Search by name or phone"
        onChangeText={(v) => setSearchTerm(v)}
        // blurOnSubmit={true}
        style={[styles.customers_list__search_input]}
        nativeID="search_term"
      />

      <View style={[styles.customers_list__search_input__clear_button]}>
        <IconButton
          height={24}
          width={24}
          iconSize={20}
          faProIcon={faXmark}
          faPro
          // disabled={!item.phone_e164}
          onPress={onIconPress}
          iconColor={palette.light_grey}
        />
      </View>
    </View>
  );
}

const renderCustomerItem =
  (
    onCustomerPress,
    onCallPress,
    onTextPress,
    activeCall,
    onDeletePress,
    activeNumber,
    filter,
    active,
    showContact,
  ) =>
  ({ item }) => {
    const props = {
      onCustomerPress,
      onCallPress,
      onTextPress,
      activeCall,
      onDeletePress,
      activeNumber,
      filter,
      active,
      showContact,
    };

    return <CustomerItem props={props} item={item} />;
  };

const CustomerItem = ({ props, item }) => {
  const {
    onCustomerPress,
    onCallPress,
    onTextPress,
    onDeletePress,
    activeNumber,
    filter,
    active,
    showContact,
  } = props;
  const { user } = useContext(UserContext);

  const phoneNumber = useMemo(() => {
    let res = {};
    if (item?.phone_e164) {
      try {
        let parsedNumber = parsePhoneNumber(
          item?.phone_e164,
          user?.account?.country || "US",
        );
        if (parsedNumber.country === user?.account?.country || "US") {
          res.formattedNumber = parsedNumber.formatNational();
        } else res.formattedNumber = parsedNumber.number;
        res.number = parsedNumber.number;
      } catch (e) {}
    }
    return res;
  }, [item]);

  return (
    <TouchableWithoutFeedback onPress={() => onCustomerPress(item)}>
      <View
        style={[
          styles.customers_list__item_container,
          {
            backgroundColor:
              active === item?.id ? palette.grey_selection : palette.grey_bg,
          },
        ]}
      >
        <Row style={{ minHeight: 36 }}>
          <Col left maxWidth={30} style={{ justifyContent: "center" }}>
            <FontAwesomeIcon icon={faUser} size={16} color={palette.grey} />
          </Col>

          <Col>
            <Row style={{ justifyContent: "space-between", gap: 8 }}>
              <Text
                numberOfLines={1}
                style={[styles.customers_list__item_text]}
              >
                {item?.account_type === "individual"
                  ? getFullName(
                      item?.first_name,
                      item?.last_name,
                      item?.display_name,
                    )
                  : item?.company_name || item?.display_name}
              </Text>
              <Text
                numberOfLines={1}
                style={[
                  styles.customers_list__item_subtext,
                  { fontWeight: "700", fontFamily: "OpenSans_700Bold" },
                ]}
              >
                {toTitleCase(item?.status)}
              </Text>
            </Row>
            {showContact && (
              <Row
                style={{
                  justifyContent: "space-between",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <View>
                  {!!item?.email && (
                    <Text
                      numberOfLines={1}
                      style={[styles.customers_list__item_subtext]}
                    >
                      {item?.email}
                    </Text>
                  )}
                </View>
              </Row>
            )}
            <Row
              style={{
                justifyContent: "space-between",
                gap: 8,
                alignItems: "center",
              }}
            >
              {showContact && (
                <View>
                  {!!phoneNumber.formattedNumber && (
                    <Text
                      numberOfLines={1}
                      style={[styles.customers_list__item_subtext]}
                    >
                      {phoneNumber.formattedNumber}
                    </Text>
                  )}
                </View>
              )}
              <View>
                <Row>
                  <Col>
                    <Row center style={{ gap: 8 }}>
                      <IconButton
                        faPro
                        faProIcon={faPhone}
                        iconColor={
                          item.phone_e164 ? palette.success : palette.light_grey
                        }
                        onPress={() =>
                          onCallPress({
                            ...item,
                            phone_e164: phoneNumber.number,
                          })
                        }
                        iconSize={12}
                        width={12}
                        height={12}
                        ml={0}
                        mr={0}
                        disabled={!activeNumber || !item.phone_e164}
                      />
                      <IconButton
                        faPro
                        faProIcon={faComments}
                        iconColor={
                          item.phone_e164 ? palette.primary : palette.light_grey
                        }
                        disabled={!activeNumber || !item.phone_e164}
                        onPress={() => onTextPress(item)}
                        iconSize={12}
                        width={12}
                        height={12}
                        ml={0}
                        mr={0}
                      />
                    </Row>
                  </Col>
                </Row>
              </View>
            </Row>
          </Col>
        </Row>
      </View>
    </TouchableWithoutFeedback>
  );
};

const CustomersLoadingIndicator = () => {
  return (
    <View style={styles.loading_indicator}>
      <ActivityIndicator size="large" />
      <Text style={[styles.customers_list__item_text, { marginTop: 20 }]}>
        Loading Customers
      </Text>
    </View>
  );
};

const CustomerItemCheckBox = ({ checked = true }) => (
  <View>
    {checked ? (
      <IconButton iconName="square" iconColor={palette.light_grey} />
    ) : (
      <IconButton iconName="check-square" iconColor={palette.primary} />
    )}
  </View>
);

const CustomersListControlBar = () => {
  const { filter, updateFilter } = useContext(CustomersContext);

  return (
    <View style={[styles.customers_list__item_control_bar_container]}>
      <Row style={{ gap: 8 }} pl={8} pr={8}>
        <FilterButton
          label={"Recent"}
          active={filter === "recent"}
          onPress={() => updateFilter("recent")}
          labelStyle={{ fontSize: 12 }}
        />
        <FilterButton
          label={"All"}
          active={filter === "all"}
          onPress={() => updateFilter("all")}
          labelStyle={{ fontSize: 12 }}
        />
        <FilterButton
          label={"Assigned to me"}
          active={filter === "assigned"}
          onPress={() => updateFilter("assigned")}
          labelStyle={{ fontSize: 12 }}
        />
      </Row>
    </View>
  );
};

const NoCustomersComponent = ({ loading = false }) => {
  if (loading) return null;
  return (
    <View style={[styles.no_results_component]}>
      <Heading size={4} color={palette.grey}>
        No results
      </Heading>
    </View>
  );
};

// const CustomersPaginationControls = () => {
//   const { page, limit, total, nextPage, prevPage } =
//     useContext(CustomersContext);

//   const isFirstPage = page === 1;
//   const isLastPage = page * limit > total;

//   return (
//     <Row center mb={8}>
//       <Col rightCenter pl={16} pr={16}>
//         <Text
//           nunberOfLines={1}
//           style={[styles.customers_list__item_control_bar_pagination_text]}
//         >
//           {!total
//             ? ""
//             : `${1 + page * limit - limit}-${
//                 isLastPage
//                   ? (page - 1) * limit + (limit + total - page * limit)
//                   : page * limit
//               } of ${total}`}
//         </Text>
//       </Col>
//       <IconButton
//         onPress={prevPage}
//         disabled={isFirstPage}
//         style={[styles.customers_list__item_control_bar_pagination_controls]}
//         iconName="chevron-left"
//         color={palette.primary}
//         iconColor={palette.white}
//       />
//       <IconButton
//         onPress={nextPage}
//         disabled={isLastPage}
//         style={[styles.customers_list__item_control_bar_pagination_controls]}
//         iconName="chevron-right"
//         color={palette.primary}
//         iconColor={palette.white}
//       />
//     </Row>
//   );
// };

const styles = StyleSheet.create({
  customers_list__container: {
    flex: 1,
    backgroundColor: palette.grey_bg,
  },
  customers_list__loading_indicator_container: {
    flex: 1,
    // backgroundColor: "#fbfbfb",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  customers_list__item_control_bar_container: {
    paddingVertical: 12,
  },
  customers_list__item_text: {
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  customers_list__item_label_text: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 18,
    color: palette.grey,
  },
  customers_list__item_subtext: {
    fontFamily: "OpenSans_400Regular",
    fontSize: 12,
    color: palette.grey,
  },
  customers_list__item_label_chevron: {
    padding: 5,
  },
  customers_list__item_sort_bar_container: {
    backgroundColor: palette.white,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: palette.light_grey,
    height: 75,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.12,
    shadowRadius: 2.62,
    elevation: 4,
    zIndex: 10,
  },
  customers_list__item_control_bar_pagination_controls: {
    borderRadius: 4,
  },
  customers_list__item_control_bar_pagination_text: {
    fontSize: 18,
  },
  customers_list__item_container: {
    borderTopColor: palette.light_grey,
    borderTopWidth: 1,
    borderColor: palette.light_grey,
    padding: 12,
    cursor: "pointer",
  },
  customers_list__search_input_container: {
    // width: "100%",
    marginBottom: 8,
    marginHorizontal: 8,
  },
  customers_list__search_input: {
    borderWidth: 1,
    borderRadius: 32,
    backgroundColor: "white",
    paddingVertical: 12,
    paddingHorizontal: 12,
  },
  customers_list__search_input__clear_button: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    position: "absolute",
    height: "100%",
    right: 8,
    zIndex: 10,
    elevation: 10,
  },
  no_results_component: {
    flex: 1,
    paddingTop: 128,
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  loading_indicator: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    width: 200,
    height: 200,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: `1px 2px 20px 4px rgba(0,0,0,0.3)`,
  },
});
