import React, { useContext, useEffect, useMemo } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AdjustableScreen from "~/layouts/AdjustableScreen/index.web";
import SettingsScreen from "~/screens/SettingsScreen/index.web";
import InboxScreen from "~/screens/InboxScreen/index.web";
import OnboardingScreen from "~/screens/OnboardingScreen/index.web";
import CustomersScreen from "~/screens/CustomersScreen/index.web";
import CallsScreen from "~/screens/CallsScreen/index.web";
import RecordingsScreen from "~/screens/RecordingsScreen/index.web";
import VoicemailsScreen from "~/screens/VoicemailsScreen/index.web";
import FileUploadModal from "~/modals/FileUploadModal/index.web";
import CustomerCreateModal from "~/modals/CustomerCreateModal/index.web";
import IncomingCallModal from "~/modals/IncomingCallModal/index.web";
import ConfirmDeleteModal from "~/modals/ConfirmDeleteModal/index.web";
import HashRoute from "~/routers/HashRoute";

import UserContext from "~/contexts/UserContext";
import { TwilioProvider } from "~/contexts/TwilioContext/index.web";
import { ConversationProvider } from "~/contexts/ConversationContext";
import { NumbersProvider } from "~/contexts/NumbersContext";
import { CustomersProvider } from "~/contexts/CustomersContext";
import { ThreadsProvider } from "~/contexts/ThreadsContext";
import { PusherProvider } from "~/contexts/PusherContext";
import { BadgeProvider } from "~/contexts/BadgeContext";
import { TeamProvider } from "~/contexts/TeamContext";
import { MediaProvider } from "~/contexts/MediaContext";
import { View } from "react-native";
import { CallsProvider } from "../../contexts/CallsContext";
import { VoicemailsProvider } from "../../contexts/VoicemailsContext";
import { RecordingsProvider } from "../../contexts/RecordingsContext";
import AudioUploadModal from "../../modals/AudioUploadModal/index.web";
import AudioRecordingModal from "../../modals/AudioRecordingModal/index.web";
import { RecordingProvider } from "../../contexts/RecordingContext";
import PhoneNumberSelectModal from "../../modals/PhoneNumberSelectModal/index.web";
import TopBar from "../../components/TopBar/index.web";
import UserDashboardScreen from "../UserDashboardScreen/index.web";
import IncomingCallsPanel from "../../modals/IncomingCallsPanel/index.web";
import { EmailProvider } from "../../contexts/EmailContext";
import BusinessInformationModal from "../../modals/BusinessInformationModal/index.web";
import CallQualityFeedbackModal from "../../modals/CallQualityFeedbackModal/index.web";
import SignPostScreen from "../SignPostScreen/index.web";
import { BlockedContactsProvider } from "../../contexts/BlockedContactsContext";

export default function HomeScreen({ history, match }) {
  const { loggedIn, getUser } = useContext(UserContext);

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  useEffect(() => {
    getUser();
  }, [history?.location?.pathname?.split("/")?.[1]]);

  return (
    <NumbersProvider>
      <TwilioProvider>
        <PusherProvider>
          <BadgeProvider>
            <MediaProvider>
              <ConversationProvider>
                <ThreadsProvider>
                  <BlockedContactsProvider>
                    <CustomersProvider isWeb={true}>
                      <TeamProvider>
                        <CallsProvider>
                          <VoicemailsProvider>
                            <RecordingsProvider>
                              <RecordingProvider>
                                <EmailProvider>
                                  <AdjustableScreen>
                                    <View style={{ flex: 1 }}>
                                      <View style={{ flex: 1 }}>
                                        <TopBar />
                                        <Switch>
                                          <Route
                                            path="/settings"
                                            component={SettingsScreen}
                                          />
                                          <Route
                                            path="/signpost"
                                            component={SignPostScreen}
                                          />
                                          <Route
                                            component={UserDashboardScreen}
                                          ></Route>
                                        </Switch>
                                        <Switch>
                                          <HashRoute
                                            hash="#confirm-delete"
                                            path="/"
                                            component={ConfirmDeleteModal}
                                          />
                                        </Switch>
                                        <Switch>
                                          <HashRoute
                                            hash="#new-customer"
                                            path="/"
                                            component={CustomerCreateModal}
                                          />
                                        </Switch>
                                        <Switch>
                                          <HashRoute
                                            hash="#report-call"
                                            path="/"
                                            component={CallQualityFeedbackModal}
                                          />
                                        </Switch>
                                      </View>
                                    </View>
                                    <BusinessInformationModal />
                                    <PhoneNumberSelectModal />
                                    <FileUploadModal />
                                    <AudioUploadModal />
                                    <AudioRecordingModal />
                                    <IncomingCallsPanel />
                                  </AdjustableScreen>
                                </EmailProvider>
                              </RecordingProvider>
                            </RecordingsProvider>
                          </VoicemailsProvider>
                        </CallsProvider>
                      </TeamProvider>
                    </CustomersProvider>
                  </BlockedContactsProvider>
                </ThreadsProvider>
              </ConversationProvider>
            </MediaProvider>
          </BadgeProvider>
        </PusherProvider>
      </TwilioProvider>
    </NumbersProvider>
  );
}
