import React, { useState, useEffect, useMemo, useContext } from "react";
import { StyleSheet, View } from "react-native";
import Row from "~/layouts/Row";
import palette from "~/styles/palette";
import Col from "~/layouts/Col";

import useTwilio from "~/hooks/useTwilio";
import IconButton from "~/components/Buttons/IconButton";
import Heading from "../../components/Heading/index.web";
import { faPhone, faPhoneHangup } from "@fortawesome/pro-solid-svg-icons";
import ConversationContext from "../../contexts/ConversationContext";
import SolidButton from "../../components/Buttons/SolidButton";
import NumbersContext from "../../contexts/NumbersContext";
import UserContext from "../../contexts/UserContext";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

export default function IncomingCallModal({ callItem, index }) {
  const { acceptCall, rejectCallInvite } = useTwilio();

  const [calleeName, setCalleeName] = useState("");
  const [participant, setParticipant] = useState();
  const { getConversationByParticipant } = useContext(ConversationContext);
  const { numbers } = useContext(NumbersContext);
  const { user, canSeeCustomerContact } = useContext(UserContext);
  const [hidden, setHidden] = useState(false);

  const visible = useMemo(() => callItem, [callItem]);

  const headerText = useMemo(() => {
    setCalleeName("");

    if (
      callItem?.customParameters &&
      callItem?.customParameters.has("participantName")
    ) {
      setCalleeName(callItem?.customParameters?.get("participantName"));
      return `Incoming Call\n${callItem?.customParameters?.get(
        "participantName",
      )}`;
    }

    if (
      callItem?.customParameters &&
      callItem?.customParameters.has("participantName")
    ) {
      setCalleeName(callItem?.customParameters?.get("participantName"));
      return callItem?.customParameters?.get("participantName");
    }

    if (callItem) {
      setCalleeName("");
      return "Incoming Call";
    }
  }, [callItem, visible]);

  useEffect(() => {
    let participant = {
      caller_id: calleeName,
    };

    if (
      callItem?.customParameters &&
      callItem?.customParameters.has("leadSourceId")
    ) {
      participant.lead_source_id =
        callItem.customParameters?.get("leadSourceId");
    }

    if (
      callItem?.customParameters &&
      callItem?.customParameters.has("leadSourceName")
    ) {
      participant.lead_source_name =
        callItem.customParameters?.get("leadSourceName");
    }

    if (!participant?.phoneNumber && callItem?.parameters?.From) {
      participant.phone_number = callItem?.parameters?.From;
    }

    if (
      !participant.customer_id &&
      callItem?.customParameters?.has("participantRemoteId")
    ) {
      participant.customer_id = callItem?.customParameters?.get(
        "participantRemoteId",
      );
    }

    if (
      !participant?.participant_id &&
      callItem?.customParameters?.has("participantId")
    ) {
      participant.participant_id =
        callItem?.customParameters?.get("participantId");
    }

    setParticipant(participant);
  }, [calleeName, JSON.stringify(callItem), user?.account?.country]);

  const onOpenProfile = () => {
    if (!participant || !participant?.customer_id) return;
    getConversationByParticipant({
      id: participant?.participant_id,
      remote_id: participant?.customer_id,
    });
  };

  const PhoneLineInUse = useMemo(() => {
    if (callItem) {
      if (callItem?.customParameters?.has("numberId")) {
        const from = callItem?.customParameters?.get("numberId");
        const number = numbers?.find((number) => number.id === from);
        if (number) {
          return number;
        }
      }
    }
    return {};
  }, [callItem, numbers, user?.account?.country]);

  const onAcceptIncomingCall = () => {
    setHidden(true);
    acceptCall(callItem?.parameters?.CallSid);
  };

  const onRejectIncomingCall = () => {
    setHidden(true);
    rejectCallInvite(callItem?.parameters?.CallSid);
  };

  const ignoreIncomingCall = () => {
    setHidden(true);
    rejectCallInvite(callItem?.parameters?.CallSid, true);
  };

  if (!visible || hidden) return null;

  return (
    <View
      style={[
        styles.modal_container,
        {
          paddingVertical: hidden ? 0 : 12,
          paddingHorizontal: hidden ? 0 : 12,
          borderTopRightRadius: hidden ? 0 : 8,
          borderBottomRightRadius: hidden ? 0 : 8,
          marginTop: index > 0 ? 8 : 0,
        },
      ]}
    >
      <Row center style={{ justifyContent: "space-between" }}>
        <Heading
          size={6}
          textStyle={styles.modal__header_subtitle_text}
          color={palette.white}
          mt={0}
          mb={0}
        >
          Incoming Call
        </Heading>
        <IconButton
          faPro
          faProIcon={faChevronRight}
          onPress={ignoreIncomingCall}
          iconColor={palette.white}
          iconSize={18}
          height={40}
          width={40}
        />
      </Row>
      <Row center mt={8}>
        <Heading
          size={4}
          textStyle={styles.modal__header_title_text}
          color={palette.white}
          numberOfLines={1}
          mt={0}
          mb={0}
        >
          {participant?.customer_id ? participant?.caller_id : "Unknown Caller"}
        </Heading>
      </Row>
      {canSeeCustomerContact && (
        <Row center>
          <Heading
            size={6}
            textStyle={styles.modal__header_subtitle_text}
            color={palette.white}
            numberOfLines={1}
            mt={0}
            mb={0}
          >
            {participant?.phone_number}
          </Heading>
        </Row>
      )}

      <Row center mt={8}>
        <Col center pr={16}>
          <IconButton
            faPro
            faProIcon={faPhoneHangup}
            iconColor={palette.white}
            color={palette.danger}
            iconSize={18}
            height={40}
            width={40}
            onPress={onRejectIncomingCall}
          />
        </Col>
        <Col center pl={16}>
          <IconButton
            faPro
            faProIcon={faPhone}
            iconName="phone"
            iconColor={palette.white}
            color={palette.success}
            iconSize={18}
            height={40}
            width={40}
            onPress={onAcceptIncomingCall}
          />
        </Col>
      </Row>
      <Row center mt={8}>
        <Col center>
          <Heading
            size={6}
            textStyle={[styles.modal__header_subtitle_text, { fontSize: 10 }]}
            color={palette.white}
            numberOfLines={1}
            mt={0}
            mb={0}
          >
            {`${PhoneLineInUse?.friendly_name || ""}`.slice(0, 10)} -{" "}
            {PhoneLineInUse?.national_number}
          </Heading>
        </Col>
      </Row>
      {participant?.customer_id && (
        <Row center mt={16} mb={8}>
          <SolidButton
            label={"Open Customer Profile"}
            color={palette.success}
            labelStyle={{
              fontSize: 14,
            }}
            onPress={onOpenProfile}
          />
        </Row>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modal__header: {
    height: 75,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    backgroundColor: palette.primary,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    color: palette.white,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal__header_subtitle_text: {
    color: palette.light_grey,
    fontSize: 12,
    textAlign: "center",
  },
  modal__header_shadow_text: {
    color: palette.white,
    opacity: 0.6,
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.79)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
  },
  modal_container: {
    borderRadius: 8,
    justifyContent: "center",
    // position: "absolute",
    backgroundColor: palette.primary,
    shadowColor: "#000",
    shadowOffset: {
      width: -5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
  },
});
