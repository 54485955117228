import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../Buttons/IconButton";
import { faCircleQuestion } from "@fortawesome/pro-duotone-svg-icons";
import palette from "../../styles/palette";
import { Link } from "react-router-dom";
import { View } from "react-native-web";

const InfoIconButton = ({ link, ml, ...props }) => {
  const Container = !!link ? Link : View;
  const containerProps = !!link
    ? {
        to: {
          pathname: link,
        },
        target: "_blank",
      }
    : {};

  return (
    <Container {...containerProps}>
      <IconButton
        faPro
        faProIcon={faCircleQuestion}
        round
        secondaryColor={palette.light_grey}
        iconSize={24}
        ml={ml ?? 4}
        {...props}
      />
    </Container>
  );
};

export default InfoIconButton;
