import { createContext, useState } from "react";
import Engage from "../services/engage";

const CustomRecordingsContext = createContext({});

export const CustomRecordingsProvider = ({ children }) => {
  const [customRecordings, setCustomRecordings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [active, setActive] = useState(0);

  const getCustomRecordings = async () => {
    setLoading(true);
    const res = await Engage.getCustomRecordings();
    if (!res.error) {
      setCustomRecordings(res.response);
    }
    setLoading(false);
  };

  const addNewCustomRecording = async () => {
    setSaving(true);
    // const res = await Engage.addCustomRecording();
    // console.log(res);
    setSaving(false);
  };

  const updateCustomRecording = async () => {
    setSaving(true);
    // const res = await Engage.addCustomRecording();
    // console.log(res);
    setSaving(false);
  };

  const deleteCustomRecording = async (id) => {
    setSaving(true);
    await Engage.deleteCustomRecording(id);
    await getCustomRecordings();
    setSaving(false);
  };

  return (
    <CustomRecordingsContext.Provider
      value={{
        customRecordings,
        loading,
        saving,
        getCustomRecordings,
        updateCustomRecording,
        deleteCustomRecording,
        addNewCustomRecording,
        active,
        setActive,
      }}
    >
      {children}
    </CustomRecordingsContext.Provider>
  );
};

export default CustomRecordingsContext;
