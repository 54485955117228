import { createContext, useState } from "react";

export const RecordingContext = createContext({});

export const RecordingProvider = ({ children }) => {
  const [recording, setRecording] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState(null);

  return (
    <RecordingContext.Provider
      value={{ permissionStatus, setPermissionStatus, recording, setRecording }}
    >
      {children}
    </RecordingContext.Provider>
  );
};
