import { DEFAULT_USER } from "../constants/users";
import { Model } from "./base";

export default class User extends Model {
  get hasFlicentCampaign() {
    return this.account.flicent;
  }

  get isDisturbMode() {
    return this.settings.do_not_disturb;
  }

  getFlattenedWorkingHours() {
    const { working_hours: workingHours } = this?.account;
    let hours = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    workingHours?.forEach((workingDay, i) => {
      Object.keys(workingDay).forEach((day) => {
        const index = (i + 1) % 7;
        hours[index] = workingDay[day];
      });
    });
    return hours;
  }

  get defaults() {
    return DEFAULT_USER;
  }
}
