import { useEffect, useState, useCallback } from "react";
import { usePusher } from "@harelpls/use-pusher";

export default function usePusherSub({
  channel = "",
  isPrivate = true,
  event = "",
  handler,
  onMount = true,
}) {
  const { client } = usePusher();
  const name = isPrivate ? `private-${channel}` : `${channel}`;
  const [subscribeOnMount, setSubscribeOnMount] = useState(onMount);

  const subscribe = useCallback(() => {
    if (client && name) {
      client.subscribe(name);
    }
    return () => {
      if (client && name) {
        client.unsubscribe(name);
      }
    };
  }, [client, name]);

  const listen = useCallback(() => {
    let channel = null;
    if (client && name) {
      channel = client.channel(name);
      if (event) {
        channel.bind(event, handler);
      } else {
        channel.bind_global(handler);
      }
    }
    return () => {
      if (channel) {
        if (event) {
          channel.bind(event, handler);
        } else {
          channel.unbind_global(handler);
        }
      }
    };
  }, [client, name]);

  if (subscribeOnMount) {
    useEffect(subscribe, [client, name]);
    useEffect(listen, [name, handler, client, event]);
  }

  return { socket_id: client?.connection?.socket_id, listen, subscribe };
}
