import { Strings } from "./strings";

export const CALL_REPORTING_CATEGORY_NO_AUDIO_INCOMING = "is_audio_issue_cannot_hear_customer";
export const CALL_REPORTING_CATEGORY_CALL_DROPPED = "is_call_dropped";
export const CALL_REPORTING_CATEGORY_NO_AUDIO_OUTGOING = "is_audio_issue_customer_cannot_hear";
export const CALL_REPORTING_CATEGORY_ECHO_ON_LINE = "has_echo_on_line";
export const CALL_REPORTING_CATEGORY_DELAYED_AUDIO = "is_audio_delayed";
export const CALL_REPORTING_CATEGORY_POOR_AUDIO_QUALITY = "is_audio_quality_poor";
export const CALL_REPORTING_CATEGORY_MIS_ROUTED = "is_call_misdirected";
export const CALL_REPORTING_CATEGORY_INTERRUPTIONS = "has_frequent_interruptions";
export const CALL_REPORTING_CATEGORY_LOW_VOLUME = "is_volume_too_low";
export const CALL_REPORTING_CATEGORY_OTHER_ISSUE = "has_other_issue";

export const CALL_REPORTING_CATEGORY_OPTIONS = [
  {
    label: Strings.CALL_REPORTING_CATEGORY_NO_AUDIO_INCOMING,
    value: CALL_REPORTING_CATEGORY_NO_AUDIO_INCOMING,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_CALL_DROPPED,
    value: CALL_REPORTING_CATEGORY_CALL_DROPPED,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_NO_AUDIO_OUTGOING,
    value: CALL_REPORTING_CATEGORY_NO_AUDIO_OUTGOING,
  },

  {
    label: Strings.CALL_REPORTING_CATEGORY_ECHO_ON_LINE,
    value: CALL_REPORTING_CATEGORY_ECHO_ON_LINE,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_DELAYED_AUDIO,
    value: CALL_REPORTING_CATEGORY_DELAYED_AUDIO,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_POOR_AUDIO_QUALITY,
    value: CALL_REPORTING_CATEGORY_POOR_AUDIO_QUALITY,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_MIS_ROUTED,
    value: CALL_REPORTING_CATEGORY_MIS_ROUTED,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_INTERRUPTIONS,
    value: CALL_REPORTING_CATEGORY_INTERRUPTIONS,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_LOW_VOLUME,
    value: CALL_REPORTING_CATEGORY_LOW_VOLUME,
  },
  {
    label: Strings.CALL_REPORTING_CATEGORY_OTHER_ISSUE,
    value: CALL_REPORTING_CATEGORY_OTHER_ISSUE,
  },
];
