import { safeParseNumber } from "./phoneNumbers";

/**
 * getParticipantName(participant = {}, customer = {})
 *
 * Attempts to find the best way to identity a participant by name
 *
 * @param participant
 * @param customer
 *
 * @return "" String
 */
export const getParticipantName = (participant = {}, customer = {}) => {
  return (
    participant?.name ||
    `${customer?.first_name || ""} ${customer?.last_name || ""}`.trim() ||
    participant?.national_number ||
    participant?.phone_number ||
    customer?.phone_e164 ||
    customer?.phone
  );
};

/**
 *
 * getNationalNumberForParticipant(participant = {})
 *
 * Formats numbers into a pretty number to display
 *
 * @param participant
 * @return "" String
 */
export const getNationalNumberForParticipant = (participant = {}) => {
  if (!participant) {
    return "";
  }

  if (participant.national_number) {
    return participant.national_number;
  }

  const { phone, phone_number, country_code } = participant;

  const parsedNumber = (phone_number || phone, country_code)
    ? safeParseNumber(phone_number || phone, country_code)
    : null;

  return parsedNumber ? parsedNumber.formatNational() : phone_number;
};

/**
 *
 * getInternationalNumberForParticipant(participant = {})
 *
 * Formats number into e164 format to be used for calling
 *
 * @param participant
 * @return "" String
 */
export const getInternationalNumberForParticipant = (participant = {}) => {
  if (!participant) {
    return "";
  }

  if (participant.phone_e164) {
    return participant.phone_e164;
  }

  const { phone, phone_number, country_code } = participant;

  const parsedNumber = (phone_number || phone, country_code)
    ? safeParseNumber(phone_number || phone, country_code)
    : null;

  return parsedNumber ? parsedNumber.formatInternational() : null;
};

/**
 *
 * getAttributesForParticipant(participant = {}, customer = {})
 *
 * @param participant
 * @param customer
 * @return {*} Participant:Object
 */
export const getAttributesForParticipant = (
  participant = {},
  customer = {}
) => {
  return {
    ...participant,
    ...customer,
    id: participant?.id,
    remote_id: customer?.id || participant?.remote_id,
    name: getParticipantName(participant, customer),
    first_name: customer?.first_name || participant?.first_name || "",
    last_name: customer?.last_name || participant?.last_name || "",
    email: customer?.email || participant?.email,
    phone_e164: customer?.phone_e164 || participant?.phone_e164, // Engage's phone_164 is determined by TwilioLookUp API default={customer.phone_e164}
    phone: customer?.phone || participant?.phone_number, // User inputed value via CRM without formatting
    phone_number:
      participant?.national_number ||
      participant?.phone_number ||
      customer?.phone, // User inputed value via Engage with formatting default={customer.phone}
    country_code: participant?.country_code || customer?.country_code, // Engage's country code is determined by TwilioLookUp API default={customer.country_code}
  };
};
