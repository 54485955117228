import { createContext, useCallback, useEffect, useState } from "react";
import { Contact } from "../models/Contact";
import useDraft from "../hooks/useDraft";
import Engage from "../services/engage";
import { ACCOUNT_TYPES } from "../constants/accountTypes";

const AdditionalContactContext = createContext({});

const initState = {
  first_name: "",
  last_name: "",
  phone: "",
  phone_e164: "",
  email: "",
  customer_id: "",
  parent: null,
};

export function AdditionalContactProvider({
  value = {},
  children,
  contact_id,
  participant,
}) {
  const [contact, setContact] = useState({ ...initState, ...value });
  const [parentCustomer, setParentCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingParent, setLoadingParent] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const { draft, dirty, changed, updateDraft, resetDraft } = useDraft({
    ...contact,
  });

  const updateContact = useCallback(
    (data) => {
      updateDraft(data);
    },
    [contact, updateDraft],
  );

  const resetContact = () => {
    updateDraft(initState);
  };

  const getContact = async (contact_id) => {
    try {
      setLoading(true);
      setParentCustomer(null);
      const res = await Contact.getContact(contact_id);
      if (!res.error) setContact(res.response);
    } catch {
      setContact(null);
    } finally {
      setLoading(false);
    }
  };

  const saveAdditionalContact = async () => {
    setSaving(true);
    setError(null);
    const contact = new Contact({
      ...draft,
      id: participant?.id,
      remote_id: participant?.remote_id,
      parent_id: participant?.parent?.id,
    });
    let result = null;
    const res = await Contact.updateContactInFP(contact);

    if (!res.error) {
      try {
        const res2 = await Contact.updateContactInEngage(contact);
        if (!res2?.error) {
          result = res2?.response;
        }
      } catch {}
      setContact(res.response);
    } else {
      setError(res.message);
    }
    setSaving(false);
    return result;
  };

  const createAdditionalContact = useCallback(async () => {
    setSaving(true);
    setError(null);

    let result = null;
    try {
      let data = {
        ...draft,
        id: participant?.id,
        customer_id: parentCustomer?.id,
      };
      const contact = new Contact(data);

      const res = await Contact.createContactInFP(contact);

      if (!res.error) {
        try {
          const contactAfterSavingFP = new Contact({
            ...data,
            remote_id: res?.response?.id,
          });
          let engageRes;
          if (participant?.id) {
            engageRes = await Contact.updateContactInEngage(
              contactAfterSavingFP,
            );
          } else {
            engageRes = await Contact.createContactInEngage(
              contactAfterSavingFP,
            );
          }
          if (!engageRes?.error && engageRes?.response) {
            result = engageRes?.response;
          }
        } catch {}
        setContact(res.response);
        setParentCustomer(null);
      } else {
        setError(res.message);
      }
    } catch {}

    setSaving(false);
    return result;
  }, [draft, participant, parentCustomer]);

  const setContactFromValue = (value) => {
    const {
      first_name = "",
      last_name = "",
      phone = "",
      phone_e164 = "",
      email = "",
      customer_id = "",
    } = value;
    setContact({
      first_name,
      last_name,
      phone,
      phone_e164,
      email,
      customer_id,
    });
  };

  const getParentParticipant = async (customer) => {
    setLoadingParent(true);
    const parentRes = await Engage.createParticipant({
      first_name:
        (customer?.account_type === ACCOUNT_TYPES[1].value
          ? customer.company_name
          : customer.first_name) || customer?.display_name,
      last_name:
        customer?.account_type === ACCOUNT_TYPES[1].value
          ? ""
          : customer.last_name,
      phone_number: customer.phone,
      phone_e164: customer.phone_e164,
      remote_id: customer.id,
      email: customer.email,
    });

    if (!parentRes.error) {
      setContact((contact) => ({
        ...contact,
        parent_id: parentRes?.response?.id,
      }));
    } else {
      setError(parentRes.message);
    }
    setLoadingParent(false);
  };

  useEffect(() => {
    if (contact_id) getContact(contact_id);
    else {
      setLoading(false);
    }
  }, [contact_id]);

  const handleParentChange = (customer) => {
    setParentCustomer(customer);
    updateContact({
      customer_id: customer?.id,
    });
    if (customer) {
      getParentParticipant(customer);
    }
  };

  useEffect(() => {
    if (participant?.id && participant?.is_child) {
      const { first_name, last_name, phone_e164, phone_number } = participant;

      setContact({
        ...initState,
        first_name,
        last_name,
        phone: phone_number,
        phone_e164: phone_e164,
        parent: participant?.parent,
        customer_id: participant?.parent?.remote_id,
      });
    }
  }, [JSON.stringify(participant)]);

  return (
    <AdditionalContactContext.Provider
      value={{
        contact: {
          ...contact,
          ...draft,
        },
        loading,
        saving,
        updateContact,
        resetContact,
        changed,
        dirty,
        resetDraft,
        saveAdditionalContact,
        error,
        setContactFromValue,
        createAdditionalContact,
        loadingParent,
        handleParentChange,
        parentCustomer,
      }}
    >
      {children}
    </AdditionalContactContext.Provider>
  );
}

export default AdditionalContactContext;
