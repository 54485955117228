import { JOB_STATUS } from "../constants/jobs";

export const getLabelForStatus = (status, type, taskCategory) => {
  if (type === "task" && taskCategory?.color) {
    return {
      value: "",
      label: { default: "-", AU: "-", NZ: "-" },
      color: taskCategory?.color,
      colorLight: taskCategory?.color,
    };
  }

  const item = JOB_STATUS.find((i) => i.value === status);

  return (
    item || {
      value: "",
      label: { default: "-", AU: "-", NZ: "-" },
      color: "black",
      colorLight: "grey",
    }
  );
};

export const getLocationAddress = (address) => {
  const addressItems = [
    address.addressLine,
    address.city,
    address.state,
    address.postalCode,
  ].filter((e) => e != null && e !== "");

  return addressItems.join(", ");
};

export const getActualDuration = (duration) => {
  if (!isNaN(duration)) {
    const minutes = Math.floor((duration / 60) % 60);
    const hours = Math.floor(duration / (60 * 60));

    let actualDuration =
      hours > 0 ? hours + (hours !== 1 ? " hours " : " hour ") : "";
    actualDuration += minutes + (minutes !== 1 ? " minutes." : " minute.");

    return actualDuration;
  }

  return "0 minutes.";
};
