import { useEffect, useState } from "react";
import {
  Modal,
  View,
  Text,
  StyleSheet,
  Button,
  TouchableOpacity,
} from "react-native";
import palette from "~/styles/palette";
import Row from "../../layouts/Row";
import { ActivityIndicator } from "react-native-web";
const UniversalModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [actionButton, setActionButton] = useState(null);
  const [actionProcessing, setActionProcessing] = useState(false);
  const handleModalOpen = ({ detail }) => {
    setIsVisible(true);
    const { message = "No Text", actionButton } = detail;
    setActionButton(actionButton);
    setMessage(message);
  };

  useEffect(() => {
    window.addEventListener("openCustomModal", handleModalOpen);
    return () => window.removeEventListener("openCustomModal", () => {});
  }, []);

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => setIsVisible(false)}
    >
      <View style={[style.container]}>
        <View style={style.main}>
          <Text style={style.message}>{message}</Text>
          <Row style={{ gap: 8 }} mt={24}>
            {!!actionButton && (
              <TouchableOpacity
                {...actionButton}
                onPress={async () => {
                  setActionProcessing(true);
                  try {
                    await actionButton?.onPress();
                  } finally {
                    setActionProcessing(false);
                    setIsVisible(false);
                  }
                }}
                style={style.action_button}
              >
                {actionProcessing ? (
                  <ActivityIndicator />
                ) : (
                  <Text style={style.action_button_text}>
                    {actionButton?.label}
                  </Text>
                )}
              </TouchableOpacity>
            )}
            <TouchableOpacity
              onPress={() => setIsVisible(false)}
              style={style.action_button}
            >
              <Text style={style.action_button_text}>Close</Text>
            </TouchableOpacity>
          </Row>
        </View>
      </View>
    </Modal>
  );
};

export default UniversalModal;

export const universalModalOpenEvent = (detail) => {
  const event = new CustomEvent("openCustomModal", { detail });
  window.dispatchEvent(event);
};

const style = StyleSheet.create({
  container: {
    height: "100vh",
    width: "100vw",
    backgroundColor: palette.transparent_grey_dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    backgroundColor: palette.white,
    padding: 15,
    width: "90%",
    borderRadius: 10,
    justifyContent: "space-between",
    minHeight: 0,
    maxWidth: 500,
  },
  message: {
    color: palette.black,
    fontSize: 16,
  },
  action_button: {
    borderWidth: 1,
    borderColor: palette.border_black,
    padding: 8,
    borderRadius: 8,
  },
  action_button_text: {
    color: palette.blue_turquoise,
    fontWeight: "bold",
  },
});
