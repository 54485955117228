import React, { useContext, useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import ReactAudioPlayer from "react-audio-player";

import Screen from "~/layouts/Screen/";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import palette from "~/styles/palette";
import { BadgeContext } from "~/contexts/BadgeContext";

import RecordingList from "~/components/RecordingsList/index.web";
import RecordingsHeader from "~/components/Headers/RecordingsHeader/";
import UserContext from "../../contexts/UserContext";
import NumbersContext from "../../contexts/NumbersContext";
import { Link } from "react-router-dom";
import MenuBar from "../../components/MenuBar/index.web";

export default function RecordingsScreen() {
  const { user } = useContext(UserContext);
  const { call_recordings } = useContext(BadgeContext);
  const [selectedRecording, setSelectedRecording] = useState(null);

  const { activeNumber } = useContext(NumbersContext);

  return (
    <Screen safeArea>
      <Row style={[styles.container]}>
        <Col>
          <Row style={[styles.inner]}>
            <Col size={3} style={[styles.listPanel]}>
              <MenuBar />
              {activeNumber && (
                <RecordingList
                  onRecordingPress={(item) => setSelectedRecording(item)}
                />
              )}
            </Col>
            <Col style={[styles.centerPanel]}>
              <View>
                {user?.first_name ? (
                  <Text style={[styles.greeting]}>Hi, {user.first_name}!</Text>
                ) : (
                  <Text style={[styles.greeting]}>Hi!</Text>
                )}
              </View>
              {activeNumber ? (
                <Text style={[styles.text]}>
                  {" "}
                  You have{" "}
                  {!!call_recordings ? (
                    <Text>
                      {call_recordings} new recording
                      {call_recordings > 1 ? "s" : ""}
                    </Text>
                  ) : (
                    <Text>no unread recordings</Text>
                  )}
                </Text>
              ) : (
                <>
                  <Text style={[styles.text]}>
                    You don't have an Active Number.
                  </Text>
                  <Link
                    to="/settings/number"
                    style={{ textDecoration: "none" }}
                  >
                    <Text style={[styles.link]}>Manage Numbers</Text>
                  </Link>
                </>
              )}
            </Col>
            <Col maxWidth={500} style={[styles.rightPanel]}>
              {selectedRecording && (
                <ReactAudioPlayer src={selectedRecording.url} controls />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Screen>
  );
}

const styles = StyleSheet.create({
  greeting: {
    textAlign: "center",
    fontSize: 30,
  },
  text: {
    textAlign: "center",
    fontSize: 18,
    color: palette.grey,
  },
  listPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    height: "100%",
  },
  rightPanel: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  centerPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    minWidth: 380,
    height: "100%",
  },
  container: {
    flex: 1,
  },
  inner: {
    flex: 1,
  },
  link: {
    color: palette.primary_light,
    fontWeight: "600",
    fontSize: 18,
    textAlign: "center",
  },
});
