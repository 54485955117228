import { useCallback, useContext, useEffect, useRef, useState } from "react";
import EmailContext from "../../contexts/EmailContext";
import {
  ActivityIndicator,
  View,
  ScrollView,
  FlatList,
  Pressable,
  Text,
} from "react-native";
import palette from "../../styles/palette";
import Row from "../../layouts/Row";
import IconButton from "../Buttons/IconButton";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faLoader,
} from "@fortawesome/pro-regular-svg-icons";
import Heading from "../Heading/index.web";
import SolidButton from "../Buttons/SolidButton";

const EmailView = () => {
  const {
    selectedEmail,
    setSelectedEmail,
    getMessageData,
    selectedEmailData,
    emails,
    selectedEmailIndex,
    setSelectedEmailIndex,
  } = useContext(EmailContext);

  const [loading, setLoading] = useState(true);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const getEmail = async () => {
      if (selectedEmail) {
        setLoading(true);
        await getMessageData();
        setLoading(false);
      }
    };
    getEmail();
  }, [selectedEmail?.id]);

  useEffect(() => {
    return () => {
      setSelectedEmail(null);
      setSelectedEmailIndex(null);
    };
  }, []);

  useEffect(() => {
    if (
      selectedEmailIndex !== null &&
      selectedEmailIndex >= 0 &&
      selectedEmailIndex < emails.length
    ) {
      setSelectedEmail(emails[selectedEmailIndex]);
    } else {
      setSelectedEmail(null);
      setSelectedEmailIndex(null);
    }
  }, [selectedEmailIndex, emails]);

  useEffect(() => {
    if (iframeLoaded) {
      const iframe = document?.querySelector("#email-iframe");
      const contentWindow = iframe?.contentWindow,
        contentDocument = contentWindow?.document,
        contentBody = contentDocument?.body;
      if (
        contentBody &&
        selectedEmailData?.text?.html?.startsWith(
          '<div style="overflow: auto;">',
        )
      )
        contentBody.innerHTML = `<style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');* { font-family: Open Sans; }</style>${selectedEmailData?.text?.html}`;
      setFontLoaded(true);
    }
  }, [iframeLoaded, selectedEmailData?.text?.html]);

  const nextEmail = () => {
    setSelectedEmailIndex((i) => i + 1);
    setLoading(true);
    setIframeLoaded(false);
    setFontLoaded(false);
  };
  const prevEmail = () => {
    setSelectedEmailIndex((i) => i - 1);
    setLoading(true);
    setIframeLoaded(false);
    setFontLoaded(false);
  };

  if (!selectedEmail) return null;

  if (!selectedEmailData && !loading)
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Heading size={5} color={palette.label_grey}>
          Something went wrong
        </Heading>
      </View>
    );

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{
        paddingVertical: 16,
        paddingHorizontal: 32,
        flex: 1,
      }}
    >
      <Row
        rightCenter
        style={{
          padding: 8,
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <View>
          <SolidButton
            label="Back"
            color={palette.blue_turquoise}
            faIcon={faArrowLeft}
            style={{
              borderRadius: 8,
            }}
            onPress={() => {
              setSelectedEmail(null);
            }}
          />
        </View>
        <View>
          <Row style={{ gap: 8 }}>
            <SolidButton
              label="Previous Email"
              color={palette.white}
              labelColor={palette.label_grey}
              faIcon={faArrowLeft}
              style={{
                borderRadius: 8,
              }}
              disabled={selectedEmailIndex === 0}
              onPress={prevEmail}
            />
            <SolidButton
              label="Next Email"
              color={palette.white}
              labelColor={palette.label_grey}
              faIcon={faArrowRight}
              iconPosition="right"
              style={{
                borderRadius: 8,
              }}
              disabled={selectedEmailIndex === emails?.length - 1}
              onPress={nextEmail}
            />
          </Row>
        </View>
      </Row>
      {loading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <ActivityIndicator size="large" color={palette.primary} />
        </View>
      ) : (
        <>
          <View>
            <Heading size={5} numberOfLines={null}>
              {selectedEmailData?.subject}
            </Heading>
          </View>
          <Row
            leftCenter
            padding={8}
            style={{
              alignItems: "center",
            }}
          >
            <Heading size={6} style={{ marginRight: 4 }}>
              From:
            </Heading>
            <EmailAddressContainer email={selectedEmailData?.from} />
          </Row>
          <Row
            leftCenter
            padding={8}
            style={{
              alignItems: "center",
            }}
          >
            <Heading size={6} style={{ marginRight: 4 }}>
              to:
            </Heading>
            {selectedEmailData?.to?.map((email, index) => (
              <EmailAddressContainer
                email={email}
                key={email?.address}
                hasNext={index < selectedEmailData?.to?.length - 1}
              />
            ))}
          </Row>
          {selectedEmailData?.text?.plain !== "\n" && (
            <iframe
              srcDoc={selectedEmailData?.text?.html}
              style={{
                width: "100%",
                display: fontLoaded ? "flex" : "none",
                flex: 1,
                border: "none",
                fontFamily: "OpenSans_400Regular",
              }}
              id="email-iframe"
              onLoad={() => setIframeLoaded(true)}
            ></iframe>
          )}
          {selectedEmailData?.attachments &&
            selectedEmailData?.attachments?.filter((attachment) =>
              ["application/pdf", "video/quicktime"].includes(
                attachment?.contentType,
              ),
            )?.length > 0 && (
              <>
                <Row>
                  <Heading size={6}>Attachments</Heading>
                </Row>
                <Row>
                  <FlatList
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    data={selectedEmailData?.attachments}
                    keyExtractor={(item) => item.id}
                    renderItem={(props) => <RenderAttachment {...props} />}
                  />
                </Row>
              </>
            )}
        </>
      )}
    </ScrollView>
  );
};

const RenderAttachment = ({ item }) => {
  const { emailClient, account } = useContext(EmailContext);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePress = useCallback(async () => {
    try {
      setLoading(true);
      await emailClient.downloadAttachment(account?.account, item?.id);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [account?.id, emailClient, item.id]);

  if (["application/pdf", "video/quicktime"].includes(item.contentType))
    return (
      <Pressable
        onPress={handlePress}
        onHoverIn={() => setHover(true)}
        onHoverOut={() => setHover(false)}
      >
        <View
          style={{
            paddingVertical: 6,
            paddingHorizontal: 8,
            flexDirection: "row",
            gap: 6,
            backgroundColor: palette.bg_light_grey,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
            marginRight: 4,
            marginBottom: 4,
            border: `2px solid ${hover ? palette.primary : palette.light_grey}`,
          }}
        >
          <Heading size={5}>{item?.filename}</Heading>
          <IconButton
            onPress={handlePress}
            faProIcon={loading ? faLoader : faArrowDown}
            faPro
            iconSize={12}
            width={22}
            height={22}
          />
        </View>
      </Pressable>
    );

  return null;
};

const EmailAddressContainer = ({ email, hasNext }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        gap: !!email?.name ? 4 : 0,
        paddingHorizontal: 4,
        paddingVertical: 2,
        borderRadius: 4,
        marginLeft: 2,
      }}
    >
      <Text style={{ fontSize: 12 }}>{email?.name}</Text>
      <Text style={{ color: palette.label_grey, fontSize: 12 }}>
        {email?.address}
        {hasNext ? "," : ""}
      </Text>
    </View>
  );
};

export default EmailView;
