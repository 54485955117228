import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { View, StyleSheet, Text } from "react-native";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";

import StyledInput from "../StyledInput";
import Row from "../../layouts/Row";
import Col from "../../layouts/Col";
import palette from "../../styles/palette";
import COUNTRIES from "../../constants/countries";

export default function PhoneInput({
  defaultValue = "",
  onChange,
  label,
  required,
  country = "US",
  defaultCountryCode = "+1",
  lessPadding = false,
  bold = false,
  nativeID = "",
  ...props
}) {
  const [phone, setPhone] = useState(
    defaultValue === "+1" ? "" : defaultValue || "",
  );
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const displayValue = useMemo(() => {
    try {
      return new AsYouType({
        defaultCallingCode: countryCode?.replace("+", ""),
      }).input(phone);
    } catch {
      return new AsYouType({
        defaultCallingCode: "1",
      }).input(phone);
    }
  }, [countryCode, phone]);

  let pnRef = useRef();
  let ccRef = useRef();

  const onPhoneChange = () => {
    const numString = new AsYouType().input(`${countryCode}${phone}`);

    try {
      const num = parsePhoneNumber(numString);
      onChange && onChange(num.number);
    } catch (e) {
      // onChange && onChange(numString);
    }
  };

  const onBlur = useCallback(() => {
    onPhoneChange();
  }, [phone, onChange, countryCode]);

  useEffect(() => {
    onPhoneChange();
  }, [phone]);

  useEffect(() => {
    try {
      const num = parsePhoneNumber(defaultValue, "US");
      setCountryCode("+" + num.countryCallingCode);
      setPhone(num.nationalNumber);
    } catch {}
  }, [defaultValue]);

  const maxAreaCodeLength = countryCode.length === 3 ? 4 : 3;

  return (
    <View>
      <Row>
        <Col maxWidth={lessPadding ? 40 : 60}>
          <StyledInput
            innerRef={ccRef}
            placeholder={`+${COUNTRIES[country].phoneCode}`}
            center
            {...props}
            value={countryCode}
            maxLength={3}
            selectTextOnFocus
            bold
            onChangeText={(val) => {
              const value = `${val ? "+" : ""}${val.replaceAll("+", "")}`;
              setCountryCode(new AsYouType().input(value));
              onPhoneChange();
              if (value === "+1" || value.length === 3) pnRef.current.focus();
            }}
            lessPadding={lessPadding}
            nativeID={`${nativeID}_country`}
          />
        </Col>
        <Col>
          <StyledInput
            innerRef={pnRef}
            selectTextOnFocus
            placeholder="(phone number)"
            {...props}
            value={displayValue}
            bold
            onChangeText={(val) => {
              let value;
              try {
                value = new AsYouType({
                  defaultCallingCode: countryCode?.replace("+", ""),
                }).input(val);
              } catch {
                value = new AsYouType({
                  defaultCallingCode: "1",
                }).input(val);
              }
              try {
                if (phone.length > 9 && value.length < val.length) {
                  const parsedNumber = parsePhoneNumber(
                    `${countryCode}${phone}`,
                    country,
                  );
                  if (parsedNumber && parsedNumber.number.length > 9) {
                    return;
                  }
                }
              } catch {}
              if (
                val.length < value.length &&
                value[value.length - 1] === ")" &&
                val?.[0] === "("
              ) {
                val = val.slice(0, -1);
              }

              if (val?.[0] === "1" || val?.startsWith("+1")) {
                val = val.slice(val?.startsWith("+1") ? 2 : 1);
              }
              setPhone(val);
              // onChange && onChange(val);
            }}
            onBlur={onBlur}
            onKeyPress={({ nativeEvent: { key } }) => {
              if (key === "Backspace" && !phone.length) {
                ccRef.current.focus();
              }
            }}
            lessPadding={lessPadding}
            nativeID={nativeID}
          />
        </Col>
      </Row>
    </View>
  );
}

const styles = StyleSheet.create({
  field_label: {
    padding: 8,
  },
  field_label_required: {
    color: palette.grey,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  field_label_text: {
    fontSize: 14,
    color: palette.dark,
    fontFamily: "acumin-pro, san-serif",
  },
});
