import React from "react";
import { Image, TouchableOpacity } from "react-native";

export default function Logo({ height = 45, width = 45, onPress }) {
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={onPress ? 0.5 : 1}>
      <Image
        source={require("~/assets/logo-icon.png")}
        style={{ height, width }}
      />
    </TouchableOpacity>
  );
}
