import { FieldPulseApi }        from "@flicent/field-pulse-js-sdk";
import { APP_ENV }              from "~/constants/app";

const FieldPulse = new FieldPulseApi({
  mode: APP_ENV,
  token: window.localStorage ? window.localStorage.getItem("token") : null,
});

export const hasToken = () => {
  const t = FieldPulse.token();
  return !!t;
};

export default FieldPulse;