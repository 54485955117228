import { useCallback, useEffect, useState, useRef } from "react";
import Engage from "../services/engage";
import { SEARCH_LIMIT } from "../constants/search";

export default function useCallLogssearch({
  search = "",
  page = 1,
  limit = 50,
  ...params
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [callLogs, setCallLogs] = useState([]);

  let timeout = useRef();
  let term = useRef();

  const searchCallLogs = useCallback(async () => {
    setCallLogs([]);
    setLoading(true);
    const res = await Engage.getCalls({ search, limit, page, ...params });
    if (res.error) {
      setError(res.message);
    } else {
      setCallLogs(res.response || []);
    }
    setLoading(false);
  }, [loading, search, setError, setCallLogs, setLoading]);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (search !== term?.current?.search) {
      timeout.current = setTimeout(() => {
        term.current = {
          search,
        };
        if (search.length >= SEARCH_LIMIT) searchCallLogs(search);
      }, 300);
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [search]);

  return {
    callLogs,
    error,
    loading,
    search,
    searchCallLogs,
  };
}
