import React, { useContext }              from "react";
import { StyleSheet, View, Text }         from "react-native";
import Row                                from "~/layouts/Row";
import Col                                from "~/layouts/Col";
import palette                            from "~/styles/palette";
import CustomersContext                   from "~/contexts/CustomersContext";

export default function CustomersActionBar() {
  const { total } = useContext(CustomersContext);

  return (
    <View style={[styles.customers_header__container]}>
      <Row pl={16} pr={8}>
        <Col></Col>
        <Col rightCenter>
        </Col>
      </Row>
    </View>
  );
}

const styles = StyleSheet.create({
  customers_header__container: {
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
    height: 75,
  },
  context_header__count_text: {
    color: palette.grey,
    fontSize: 16,
  },
});
