import { useContext, useEffect, useMemo } from "react";
import OnboardingContext from "../../../contexts/OnboardingContext";
import Bundle from "../../../models/Bundle";
import { StyleSheet, Text, View } from "react-native";
import SolidButton from "../../../components/Buttons/SolidButton";
import Col from "../../../layouts/Col";
import Heading from "../../../components/Heading/index.web";
import Logo from "../../../components/Logo";
import palette from "../../../styles/palette";

export default function AusBundleStatusStep({ nextStep }) {
  const { bundleData, loading } = useContext(OnboardingContext);
  const bundle = new Bundle(bundleData);

  const ViewComponent = useMemo(() => {
    if (loading) return <FetchingBundleStatusMessage />;
    if (!bundle.isBundleVerified()) return <BundleNotVerifiedMessage />;
    return <BundleVerifiedMessage />;
  }, [bundleData, loading, nextStep]);

  useEffect(() => {
    let t = setTimeout(() => {
      if (!loading) {
        const bundle = new Bundle(bundleData);
        if (bundle.isBundleVerified()) nextStep();
      }
    }, 20);
    return () => clearTimeout(t);
  }, [loading, bundleData]);

  return (
    <View style={styles.container}>
      <View style={styles.logo_container}>
        <Logo height={70} width={70} />
      </View>

      <View>
        <Heading center mt={10} mb={15}>
          Purchase Number
        </Heading>
      </View>
      {ViewComponent}
      {!loading && (
        <Col mt={8}>
          <SolidButton
            label="Select a Phone Number"
            color={palette.primary}
            onPress={nextStep}
            disabled={!bundle.isBundleVerified()}
          />
        </Col>
      )}
    </View>
  );
}

const FetchingBundleStatusMessage = () => {
  return <Text style={styles.loading_text}>Fetching bundle status...</Text>;
};

const BundleNotVerifiedMessage = () => {
  return (
    <View style={[styles.message_container, styles.error_message_container]}>
      <Text style={[styles.message_text, styles.error_message_text]}>
        Prior to using Engage, we'll verify your account within two weeks. You
        can return here after that time for an update or contact our chat
        support for information on your Engage account. Once approved, you can
        purchase number immediately.
      </Text>
    </View>
  );
};

const BundleVerifiedMessage = () => {
  return (
    <View style={[styles.message_container, styles.verified_message_container]}>
      <Text style={[styles.message_text, styles.verified_message_text]}>
        Your Engage account has been verified, You can now purchase numbers.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logo_container: {
    alignItems: "center",
    marginTop: 44,
  },
  loading_text: {
    textAlign: "center",
    marginTop: 16,
  },
  message_container: {
    width: "100%",
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  error_message_container: {
    backgroundColor: palette.bg_red,
  },
  verified_message_container: {
    backgroundColor: palette.bg_light_blue,
  },
  message_text: {
    fontFamily: "OpenSans_600SemiBold",
  },
  error_message_text: {
    color: palette.label_red,
  },
  verified_message_text: {
    color: palette.info,
  },
});
