import React, { useCallback, useEffect, useMemo, useReducer } from "react";

import { View } from "react-native";

import Col from "~/layouts/Col";
import Row from "~/layouts/Row";

import SolidButton from "~/components/Buttons/SolidButton/index.native";
import IconButton from "~/components/Buttons/IconButton";
import Heading from "~/components/Heading/index.native";

import palette from "~/styles/palette";
import {
  faAsterisk,
  faChevronLeft,
  faHashtag,
  faPlus,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";

const initialState = {
  number: "",
};

const dialReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "ADD_NUM": {
      return {
        ...state,
        number: `${state.number}${payload}`,
      };
    }

    case "REMOVE_NUM": {
      return {
        ...state,
        number: state.number.slice(0, -1),
      };
    }

    case "CLEAR_NUM": {
      return {
        ...state,
        number: "",
      };
    }

    default: {
      return state;
    }
  }
};

function useDialState({ onChange, onDigitPress }) {
  const [dialState, dialDispatch] = useReducer(dialReducer, initialState);
  useEffect(() => {
    if (onChange) {
      onChange(dialState.number);
    }
  }, [onChange, dialState.number]);

  const dispatch = useCallback(
    (action) => {
      if (action.type === "ADD_NUM" && onDigitPress) {
        onDigitPress(action.payload);
      }

      dialDispatch(action);
    },
    [dialState, dialDispatch, onDigitPress],
  );

  return {
    dialState,
    dispatch,
  };
}

const ALPHABET = [
  " ",
  "ABC",
  "DEF",
  "GHI",
  "JKL",
  "MNO",
  "PQRS",
  "TUV",
  "WXYZ",
];

export default function Dialer({
  onChange,
  onDigitPress,
  symbols = false,
  buttonBgColor = palette.light_grey,
}) {
  const { dialState, dispatch } = useDialState({ onChange, onDigitPress });

  const buttonColor =
    buttonBgColor === null
      ? null
      : !buttonBgColor
      ? palette.light_grey
      : buttonBgColor;

  const numberButtons = useMemo(() => {
    const numbers = new Array(9).fill(0);

    const onPress = (number) => () => {
      dispatch({ type: "ADD_NUM", payload: number });
    };

    return numbers.map((_, i) => {
      return (
        <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80} key={i}>
          <SolidButton
            label={`${i + 1}`}
            subtext={ALPHABET[i]}
            color={buttonColor}
            onPress={onPress(i + 1)}
            width={80}
            labelStyle={{ fontSize: 32 }}
            style={{ height: 80, borderRadius: 80 }}
          />
        </Col>
      );
    });
  }, [dispatch, buttonColor]);

  const numberRows = useMemo(() => {
    return (
      <Row pl={32} pr={32} centered>
        <Col centered>
          <Row center>{numberButtons.slice(0, 3)}</Row>

          <Row center>{numberButtons.slice(3, 6)}</Row>

          <Row center>{numberButtons.slice(6, 9)}</Row>

          <Row center>
            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}>
              {symbols ? (
                <IconButton
                  faPro
                  faProIcon={faAsterisk}
                  iconSize={30}
                  color={buttonColor}
                  onPress={() => {
                    dispatch({ type: "ADD_NUM", payload: "*" });
                  }}
                  iconColor={palette.white}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              ) : (
                <IconButton
                  faPro
                  faProIcon={faXmark}
                  iconSize={32}
                  onPress={() => {
                    dispatch({ type: "CLEAR_NUM" });
                  }}
                  color={buttonColor}
                  iconColor={palette.white}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              )}
            </Col>

            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}>
              <SolidButton
                label="0"
                subtext="+"
                color={buttonColor}
                onPress={() => {
                  dispatch({ type: "ADD_NUM", payload: 0 });
                }}
                width={80}
                labelStyle={{ fontSize: 32 }}
                subtextStyle={{ fontSize: 16 }}
                style={{ height: 80, borderRadius: 80 }}
              />
            </Col>

            <Col ml={8} mr={8} mt={8} mb={8} maxWidth={80}>
              {symbols ? (
                <IconButton
                  faPro
                  faProIcon={faHashtag}
                  iconSize={30}
                  color={buttonColor}
                  onPress={() => {
                    dispatch({ type: "ADD_NUM", payload: "#" });
                  }}
                  iconColor={palette.white}
                  width={80}
                  labelStyle={{ fontSize: 32 }}
                  style={{ height: 80, borderRadius: 80 }}
                />
              ) : dialState.number.length > 0 ? (
                <IconButton
                  onPress={() => {
                    dispatch({ type: "REMOVE_NUM" });
                  }}
                  faPro
                  faProIcon={faChevronLeft}
                  iconSize={32}
                  color={buttonColor}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              ) : (
                <IconButton
                  onPress={() => {
                    dispatch({ type: "ADD_NUM", payload: "+" });
                  }}
                  faPro
                  faProIcon={faPlus}
                  iconSize={32}
                  color={buttonColor}
                  width={80}
                  mr={0}
                  ml={0}
                  style={{ height: 80 }}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }, [numberButtons, dialState, buttonColor]);

  return (
    <Row mt={64} style={{ maxHeight: "70%" }}>
      <Col centered pt={64}>
        <View style={{ alignItems: "center", height: "15%" }}>
          {dialState.number.length > 0 ? (
            <Heading size={2} color={buttonColor}>
              {dialState.number}
            </Heading>
          ) : (
            <Heading size={2} color={buttonColor}>
              Enter Keys
            </Heading>
          )}
        </View>

        <Row center mt={64}>
          <Col>{numberRows}</Col>
        </Row>
      </Col>
    </Row>
  );
}
