import { ActivityIndicator, StyleSheet, View } from "react-native";
import Screen from "~/layouts/Screen/index.web";
import palette from "../../../styles/palette";
const LoadingScreen = ({ loading }) => {
  if (!loading) return null;
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={palette.primary} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: palette.bg_light_grey,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default LoadingScreen;
