import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import React from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/Feather";
import palette from "~/styles/palette";

export default function Avatar({
  first_name = "",
  last_name = "",
  initials = "",
  border = false,
  source,
  color = palette.light_grey,
  labelColor = palette.dark,
  placeholder,
  size = 50,
  ml = 0,
  mr = 0,
  onPress,
  style = {},
}) {
  const initialsFromName =
    first_name && last_name
      ? `${first_name[0]}${last_name[0]}`
      : !last_name
      ? `${(first_name && first_name[0].toUpperCase()) || ""}${
          (first_name && first_name[1]) || ""
        }`
      : `${(last_name && last_name[0].toUpperCase()) || ""}${
          (last_name && last_name[1]) || ""
        }`;

  return (
    <WithTouchable onPress={onPress}>
      <View
        style={[
          styles.avatar__container,
          {
            height: size,
            width: size,
            backgroundColor: color,
            marginLeft: ml,
            marginRight: mr,
          },
          border && {
            borderWidth: 1,
            borderColor: palette.grey,
            borderStyle: "solid",
          },
          style,
        ]}
      >
        {source ? (
          <Image source={source} style={{ height: size, width: size }} />
        ) : placeholder || (!initials && !initialsFromName) ? (
          <FontAwesomeIcon icon={faUser} size={size / 2} color={labelColor} />
        ) : (
          <Text
            style={[
              styles.avatar__initials,
              { fontSize: size * 0.4, color: labelColor },
            ]}
          >
            {initials || initialsFromName}
          </Text>
        )}
      </View>
    </WithTouchable>
  );
}

export const WithTouchable = ({ children, onPress }) => {
  if (onPress) {
    return <TouchableOpacity onPress={onPress}>{children}</TouchableOpacity>;
  }

  return children;
};

const styles = StyleSheet.create({
  avatar__container: {
    borderRadius: 200,
    height: 50,
    width: 50,
    backgroundColor: palette.light_grey,
    overflow: "hidden",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  avatar__image: {
    borderRadius: 60,
    height: 50,
    width: 50,
    position: "absolute",
    left: 0,
    top: 0,
  },
  avatar__initials: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
});
