import { StyleSheet, View } from "react-native";
import { useCallback } from "react";
import StyledInput from "~/components/StyledInput/";
import IconButton from "~/components/Buttons/IconButton/index";
import { faSearch, faXmark } from "@fortawesome/pro-regular-svg-icons";
import palette from "../../../styles/palette";

const SearchBar = ({
  searchTerm,
  setSearchTerm,
  placeholder,
  managedNumbersLength = 0,
}) => {
  const onIconPress = useCallback(() => {
    setSearchTerm(() => "");
  });

  return (
    <View
      style={[
        styles.search_input_container,
        {
          paddingTop: managedNumbersLength > 1 ? 4 : 12,
        },
      ]}
    >
      <StyledInput
        value={searchTerm}
        returnKeyType="done"
        placeholder={placeholder}
        onChangeText={(v) => setSearchTerm(v)}
        style={[styles.search_input]}
        nativeID="search_term"
        hoverable={false}
        containerStyle={{
          backgroundColor: "transparent",
        }}
      />

      <View
        style={[
          styles.search_input__clear_button,
          {
            top: managedNumbersLength > 1 ? 12 : 18,
          },
        ]}
      >
        <IconButton
          height={24}
          width={24}
          iconSize={20}
          faProIcon={searchTerm?.length > 0 ? faXmark : faSearch}
          faPro
          round
          onPress={onIconPress}
          color={palette.white}
          iconColor={palette.light_grey}
        />
      </View>
    </View>
  );
};

export default SearchBar;

const styles = StyleSheet.create({
  search_input__clear_button: {
    position: "absolute",
    height: "100%",
    right: 8,
    zIndex: 10,
    paddingRight: 8,
  },
  search_input_container: {
    paddingBottom: 12,
    position: "relative",
  },
  search_input: {
    borderWidth: 1,
    borderRadius: 32,
    backgroundColor: "white",
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginHorizontal: 8,
  },
});
