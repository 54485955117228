import { useContext, useEffect, useMemo, useState } from "react";
import EmailContext from "../../contexts/EmailContext";
import {
  ActivityIndicator,
  FlatList,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import palette from "../../styles/palette";
import Heading from "../Heading/index.web";
import { getSmartTimestamp } from "../../helpers/day";
import Col from "../../layouts/Col";
import SolidButton from "../Buttons/SolidButton";
import ConversationContext from "../../contexts/ConversationContext";

const EmailList = () => {
  const {
    emails,
    loadingEmails,
    doesAccountExist,
    account,
    mailboxes,
    totalPages,
    loadMoreEmails,
    page,
  } = useContext(EmailContext);
  const { conversation } = useContext(ConversationContext);
  const { customer } = conversation || {};

  if (loadingEmails && page === 0)
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color={palette.primary} />
      </View>
    );

  if (!customer?.email) return <CustomerEmailNotFound />;

  if (!doesAccountExist) return <CreateAccount />;

  if (account.state === "syncing") return <AccountSyncing />;

  if (account.state !== "connected" || mailboxes?.length === 0)
    return <AccountNotConnected />;

  if (emails?.length === 0) return <NoEmailFound />;

  return (
    <FlatList
      data={emails}
      renderItem={(props) => <RenderEmailItem {...props} />}
      ListFooterComponent={() => {
        if (emails.length > 0)
          return (
            <View
              style={{
                width: "100%",
                paddingVertical: 8,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SolidButton
                label="Load More"
                onPress={loadMoreEmails}
                disabled={page === totalPages - 1}
                loading={loadingEmails}
              />
            </View>
          );
        return null;
      }}
      keyExtractor={(item) => item.id}
      ListEmptyComponent={NoEmailFound}
      style={{
        flex: 1,
      }}
    />
  );
};

const AccountSyncing = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Heading
        size={5}
        color={palette.label_grey}
        textStyle={{
          textAlign: "center",
        }}
      >
        Hold on! We're setting up connection to your account and then you'll be
        able to use Engage emails.
      </Heading>
    </View>
  );
};

const AccountNotConnected = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Heading
        size={5}
        color={palette.label_grey}
        textStyle={{
          textAlign: "center",
        }}
      >
        Problem connecting to your account.
      </Heading>
    </View>
  );
};

const CreateAccount = () => {
  const [authLink, setAuthLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const { generateAuthLink } = useContext(EmailContext);

  useEffect(() => {
    const getAuthLink = async () => {
      setLoading(true);
      const res = await generateAuthLink();
      if (res) setAuthLink(res?.url);
      setLoading(false);
    };
    getAuthLink();
  }, [generateAuthLink]);

  if (loading)
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color={palette.primary} />
      </View>
    );

  if (!authLink) return null;

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Heading size={4} color={palette.label_grey}>
        Create an account using this form{" "}
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(authLink);
          }}
        >
          <View>
            <Heading size={4} color={palette.blue_link}>
              link
            </Heading>
          </View>
        </TouchableOpacity>
        .
      </Heading>
      <Heading size={6} color={palette.label_grey}>
        Refresh this page after you sign in.
      </Heading>
    </View>
  );
};

const NoEmailFound = () => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Heading size={4} color={palette.label_grey}>
        No email history found.
      </Heading>
    </View>
  );
};

const CustomerEmailNotFound = () => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Heading size={4} color={palette.label_grey}>
        Please add email address to customer profile to view email history with
        them.
      </Heading>
    </View>
  );
};

const RenderEmailItem = ({ item, index }) => {
  const { setSelectedEmail, setSelectedEmailIndex, account } =
    useContext(EmailContext);
  const { email } = account || {};
  const { date, from, subject } = item || {};

  if (!email) return null;

  return (
    <TouchableOpacity
      onPress={() => {
        setSelectedEmailIndex(index);
        setSelectedEmail(item);
      }}
    >
      <View style={styles.container}>
        <Col size={4} pl={24}>
          <Text style={styles.subtext}>From {from?.address}</Text>
        </Col>
        <Col size={6}>
          <Text style={styles.text}>{subject}</Text>
        </Col>
        <Col pr={16} size={2}>
          <Text style={styles.subtext}>{getSmartTimestamp(date)}</Text>
        </Col>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
    paddingVertical: 6,
    gap: 4,
  },
  text: {
    fontSize: 12,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  heading: {
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  subtext: {
    fontSize: 12,
    color: palette.label_grey,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
});

export default EmailList;
