import PropTypes from "prop-types";
import React from "react";
import { Linking, StyleSheet, Text, View } from "react-native";
// @ts-ignore
import ParsedText from "react-native-parsed-text";
import Communications from "react-native-communications";
import { StylePropType } from "../helpers/utils";
import palette from "../../../styles/palette";
import dayjs from "dayjs";
import { Strings } from "../../../constants/strings";
const WWW_URL_PATTERN = /^www\./i;
const textStyle = {
  fontSize: 16,
  lineHeight: 20,
};
const containerStyle = {
  flexDirection: "row",
  flexWrap: "wrap",
};
const styles = {
  left: StyleSheet.create({
    container: containerStyle,
    text: {
      color: "black",
      ...textStyle,
    },
    link: {
      color: "black",
      textDecorationLine: "underline",
    },
  }),
  right: StyleSheet.create({
    container: containerStyle,
    text: {
      color: "black",
      ...textStyle,
    },
    link: {
      color: "black",
      textDecorationLine: "underline",
    },
  }),
};

const timeStampTextStyle = {
  fontSize: 10,
  color: palette.label_grey,
  fontFamily: "OpenSans_600SemiBold",
};

const timestampStyles = {
  left: StyleSheet.create({
    container: {
      justifyContent: "flex-end",
      flex: 1,
      alignItems: "flex-start",
    },
    text: timeStampTextStyle,
  }),
  right: StyleSheet.create({
    container: {
      justifyContent: "flex-end",
      flex: 1,
      alignItems: "flex-end",
    },
    text: timeStampTextStyle,
  }),
};

const DEFAULT_OPTION_TITLES = ["Call", "Text", "Cancel"];
export default class MessageText extends React.Component {
  constructor() {
    super(...arguments);
    this.onUrlPress = (url) => {
      // When someone sends a message that includes a website address beginning with "www." (omitting the scheme),
      // react-native-parsed-text recognizes it as a valid url, but Linking fails to open due to the missing scheme.
      if (WWW_URL_PATTERN.test(url)) {
        this.onUrlPress(`http://${url}`);
      } else {
        Linking.canOpenURL(url).then((supported) => {
          if (!supported) {
            console.error("No handler for URL:", url);
          } else {
            Linking.openURL(url);
          }
        });
      }
    };
    this.onPhonePress = (phone) => {
      const { optionTitles } = this.props;
      const options =
        optionTitles && optionTitles.length > 0
          ? optionTitles.slice(0, 3)
          : DEFAULT_OPTION_TITLES;
      const cancelButtonIndex = options.length - 1;
      this.context.actionSheet().showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              this.props.onPhonePress(phone, Strings.COMMUNICATIONS.CALL);
              break;
            case 1:
              this.props.onPhonePress(phone, Strings.COMMUNICATIONS.MESSAGE);
              break;
            default:
              break;
          }
        },
      );
    };
    this.onEmailPress = (email) =>
      Communications.email([email], null, null, null, null);

    this.shortenLink = function (link, maxLength) {
      if (link.length <= maxLength) {
        return link;
      }

      const half = Math.floor(maxLength / 2);
      const start = link.slice(0, half);
      const end = link.slice(-half);

      return `${start}...${end}`;
    };
  }
  shouldComponentUpdate(nextProps) {
    return (
      !!this.props.currentMessage &&
      !!nextProps.currentMessage &&
      this.props.currentMessage.text !== nextProps.currentMessage.text
    );
  }
  render() {
    const linkStyle = [
      styles[this.props.position].link,
      this.props.linkStyle && this.props.linkStyle[this.props.position],
    ];
    return (
      <View>
        <View
          style={[
            styles[this.props.position].container,
            this.props.containerStyle &&
              this.props.containerStyle[this.props.position],
          ]}
        >
          <ParsedText
            style={[
              styles[this.props.position].text,
              this.props.textStyle && this.props.textStyle[this.props.position],
              this.props.customTextStyle,
            ]}
            parse={[
              ...this.props.parsePatterns(linkStyle),
              {
                type: "url",
                style: linkStyle,
                onPress: this.onUrlPress,
                renderText: (url) => {
                  return <Text>{this.shortenLink(url, 80)}</Text>;
                },
              },
              { type: "phone", style: linkStyle, onPress: this.onPhonePress },
              { type: "email", style: linkStyle, onPress: this.onEmailPress },
            ]}
            childrenProps={{ ...this.props.textProps }}
          >
            {this.props.currentMessage.text}
          </ParsedText>
        </View>
        {!!this.props?.currentMessage?.created_at && (
          <View style={timestampStyles[this.props.position].container}>
            <Text
              style={timestampStyles[this.props.position].text}
              selectable={false}
            >
              {dayjs(this.props.currentMessage.created_at).format(
                this.props.timeFormat,
              )}
            </Text>
          </View>
        )}
      </View>
    );
  }
}
MessageText.contextTypes = {
  actionSheet: PropTypes.func,
};
MessageText.defaultProps = {
  position: "left",
  optionTitles: DEFAULT_OPTION_TITLES,
  currentMessage: {
    text: "",
  },
  containerStyle: {},
  textStyle: {},
  linkStyle: {},
  customTextStyle: {},
  textProps: {},
  parsePatterns: () => [],
};
MessageText.propTypes = {
  position: PropTypes.oneOf(["left", "right"]),
  optionTitles: PropTypes.arrayOf(PropTypes.string),
  currentMessage: PropTypes.object,
  containerStyle: PropTypes.shape({
    left: StylePropType,
    right: StylePropType,
  }),
  textStyle: PropTypes.shape({
    left: StylePropType,
    right: StylePropType,
  }),
  linkStyle: PropTypes.shape({
    left: StylePropType,
    right: StylePropType,
  }),
  parsePatterns: PropTypes.func,
  textProps: PropTypes.object,
  customTextStyle: StylePropType,
  onPhonePress: PropTypes.func,
};
