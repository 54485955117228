import React, { Component } from 'react';
import { StyleSheet, Text, View, } from 'react-native';

import palette from "~/styles/palette";

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    marginTop: 5,
    marginBottom: 10,
  },
  text: {
    backgroundColor: 'transparent',
    color: '#b2b2b2',
    fontSize: 14,
    fontWeight: '500',
  },
});

export const SystemText = ({ textStyle, currentMessage }) => {
  return (
    <Text style={[styles.text, textStyle]}>{currentMessage.text}</Text>
  );
};

export default class SystemMessage extends Component {
  render() {
    const { currentMessage, containerStyle, wrapperStyle, textStyle, children } = this.props;

    if (currentMessage) {
      return (
        <View style={[styles.container, containerStyle]}>
          { children }
        </View>
      );
    }
    return null;
  }
}

SystemMessage.defaultProps = {
  currentMessage: {
    system: false,
  },
  containerStyle: {},
  wrapperStyle: {},
  textStyle: {},
};
