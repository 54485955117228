export const appendInteractions = (
  currentInteractions = [],
  interactions = [],
  inverted = false
) => {
  if (!Array.isArray(interactions)) {
    interactions = [interactions];
  }
  return inverted
    ? interactions.concat(currentInteractions)
    : currentInteractions.concat(interactions);
};

export const prependInteractions = (
  currentInteractions = [],
  interactions = [],
  inverted = false
) => {
  if (!Array.isArray(interactions)) {
    interactions = [interactions];
  }
  return inverted
    ? currentInteractions.concat(interactions)
    : interactions.concat(currentInteractions);
};

export const findPendingInteraction = (
  pendingInteractions = [],
  pendingInteractionID
) => {
  const idx = pendingInteractions.findIndex(
    (i, x) => i._id == pendingInteractionID
  );
  if (idx > -1) {
    return [pendingInteractions[idx],idx];
  }
  return [null,idx];
};

export const removePendingInteraction = (
  pendingInteractions = [],
  pendingInteractionID
) => {
  const [idx,interaction] = findPendingInteraction(pendingInteractions,pendingInteractionID);
  if (idx) {
    const pendingInteractionsCopy = pendingInteractions.concat();
    pendingInteractionsCopy.splice(idx, 1);
    return [pendingInteractionsCopy,interaction,idx];
  } else {
    return [pendingInteractions, null, null];
  }
};
