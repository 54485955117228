import { useCallback, useState } from "react";
import { useContext, useEffect } from "react";
import { StyleSheet, Text, View } from "react-native-web";
import { useHistory } from "react-router-dom";
import {
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  useEdgesState,
  useReactFlow,
  useNodesState,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import { PhoneTreeContext } from "../../contexts/PhoneTreeContext";
import { checkNode, getLayoutElements } from "../../helpers/phoneTree";
import useToggle from "../../hooks/useToggle";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import IconButton from "../Buttons/IconButton";
import SolidButton from "../Buttons/SolidButton";
import ButtonEdge from "../Edges/ButtonEdge/index.web";
import EmptyNode from "../Nodes/EmptyNode";
import MainNode from "../Nodes/MainNode";
import Node from "../Nodes/Node";
import StyledInput from "../StyledInput";
import StyledPicker from "../StyledPicker";
import PhoneTreeForm from "./PhoneTreeForm/index.web";

const nodeTypes = { node: Node, main: MainNode, empty: EmptyNode };
const edgeTypes = { buttonEdge: ButtonEdge };

const MIN_DISTANCE = 250;

export default function PhoneTree({ showPopup, setShowPopup }) {
  const {
    nodes: initialNodes,
    addChildAtIndex,
    hasEmptyNode,
    activeNode,
    savePhoneTree,
    altered: isDataChanged,
    saving,
    addChildEdge,
    resetTree,
  } = useContext(PhoneTreeContext);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [dragging, setDragging] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [popupType, setPopupType] = useState("navigation");
  const history = useHistory();

  const { project, fitView, getIntersectingNodes, setCenter } = useReactFlow();
  const store = useStoreApi();
  const updateNodeInternals = useUpdateNodeInternals();
  const getClosestEdge = useCallback(
    (node) => {
      const { nodeInternals } = store.getState();
      const storeNodes = Array.from(nodeInternals.values());

      const closestNode = storeNodes.reduce(
        (res, n) => {
          if (n.id !== node.id) {
            const dx = n.positionAbsolute.x - node.positionAbsolute.x;
            const dy = n.positionAbsolute.y - node.positionAbsolute.y;
            const d = Math.sqrt(dx * dx + dy * dy);

            if (d < res.distance && d < MIN_DISTANCE && dy < 0) {
              res.distance = d;
              res.node = n;
            }
          }

          return res;
        },
        {
          distance: Number.MAX_VALUE,
          node: null,
        },
      );

      if (
        !closestNode.node ||
        node.parent !== null ||
        closestNode.node.value === 2 ||
        closestNode.node.value === 3
      ) {
        return null;
      }

      return {
        parentIndex: closestNode.node.index,
        nodeIndex: node.index,
      };
    },
    [JSON.stringify(initialNodes)],
  );

  const onNodeDragStop = useCallback(
    (_, node) => {
      setDragging(false);
      if (node.parent === null) {
        const closeEdge = getClosestEdge(node);
        if (closeEdge !== null) {
          addChildEdge(closeEdge.parentIndex, closeEdge.nodeIndex);
        }
      }
    },
    [getClosestEdge, JSON.stringify(initialNodes)],
  );

  const onNodeDrag = useCallback(
    (_, node) => {
      if (node.parent === null) {
        const closeEdge = getClosestEdge(node);

        setEdges((es) => {
          const nextEdges = es.filter((e) => e.className !== "temp");

          if (closeEdge !== null) {
            nextEdges.push({
              id: `${closeEdge.parentIndex}-${closeEdge.nodeIndex}`,
              source: `${closeEdge.parentIndex}`,
              target: `${closeEdge.nodeIndex}`,
              className: "temp",
              animated: true,
            });
          }

          return nextEdges;
        });
      }
    },
    [getClosestEdge, setEdges, JSON.stringify(initialNodes)],
  );

  useEffect(() => {
    const { nodes, edges } = getLayoutElements(initialNodes);
    let isInValid = false;
    initialNodes.forEach((node) => {
      if (checkNode(node).error) isInValid = true;
    });
    setIsInvalid(isInValid);
    setNodes(nodes);
    setEdges(edges);
    updateNodeInternals("0");
  }, [JSON.stringify(initialNodes)]);

  useEffect(() => {
    if (!dragging) {
      let t = setTimeout(() => {
        fitView({ padding: 0.2, duration: 800 });
      }, 200);
      return () => clearTimeout(t);
    }
  }, [nodes, edges, dragging]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitView
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodeDragStart={() => setDragging(true)}
      onNodeDragStop={onNodeDragStop}
      onNodeDrag={onNodeDrag}
      deleteKeyCode={null}
      fitViewOptions={{ padding: 0.2 }}
    >
      {showPopup && (
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.24)",
            position: "absolute",
            zIndex: 20000,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              maxWidth: 500,
              backgroundColor: palette.bg_light_grey,
              borderRadius: 6,
              border: `2px solid ${palette.light_grey}`,
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              padding: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                marginBottom: 10,
              }}
            >
              {popupType === "navigation"
                ? "You have unsaved changes. Going back will erase your changes. Do you want to continue?"
                : "This will erase your phone tree. Do you want to continue?"}
            </Text>
            <Row right>
              <SolidButton
                label="Yes"
                color={palette.danger}
                onPress={() => {
                  if (popupType === "navigation") {
                    history.goBack();
                  } else {
                    resetTree();
                  }
                  setShowPopup();
                  setPopupType("navigation");
                }}
                style={{ marginRight: 10 }}
              />
              <SolidButton
                label="Cancel"
                onPress={() => {
                  setShowPopup("");
                  setPopupType("navigation");
                }}
              />
            </Row>
          </View>
        </View>
      )}
      {activeNode !== null && (
        <Panel position="top-right">
          <PhoneTreeForm />
        </Panel>
      )}
      {initialNodes.length > 0 && (
        <Panel position="bottom-left">
          <Row style={{ marginLeft: 40 }}>
            <SolidButton
              label="Save Phone Tree"
              onPress={async () => {
                await savePhoneTree();
              }}
              style={{ marginRight: 10 }}
              loading={saving}
              disabled={isInvalid || !isDataChanged}
            />
            {nodes?.length !== 0 && (
              <SolidButton
                label="Reset Phone Tree"
                onPress={() => {
                  setPopupType("reset");
                  setShowPopup();
                }}
                loading={saving}
                color={palette.danger}
              />
            )}
          </Row>
        </Panel>
      )}
      <Controls />
    </ReactFlow>
  );
}
