import { View } from "react-native";
import { Strings } from "../../constants/strings";
import UserContext from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";

const SignPostScreen = () => {
  const history = useHistory();
  const { company } = useContext(UserContext);

  useEffect(() => {
    if (
      !company?.is_signpost_enabled ||
      !company?.is_engage_signpost_available
    ) {
      history.replace("/");
    }
  }, [company?.is_signpost_enabled, company?.is_engage_signpost_available]);

  return (
    <View style={{ flex: 1 }}>
      <iframe
        src={Strings.SIGNPOST_IFRAME_URL}
        style={{
          border: "none",
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </View>
  );
};

export default SignPostScreen;
