import {
  ActivityIndicator,
  ScrollView,
  Text,
  View,
  StyleSheet,
} from "react-native-web";
import Heading from "../../../components/Heading/index.web";
import InfoIconButton from "../../../components/InfoIconButton/index.web";
import { Strings } from "../../../constants/strings";
import Col from "../../../layouts/Col";
import Row from "../../../layouts/Row";
import commonStyles from "../../../styles/common";
import palette from "../../../styles/palette";
import RecipientSearchInput from "../../../components/RecipientSearchInput/index.web";
import SolidButton from "../../../components/Buttons/SolidButton";
import { getCustomerName } from "../../../helpers/customers";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BlockedContactsContext from "../../../contexts/BlockedContactsContext";
import { useContext, useEffect } from "react";
import SearchBar from "../../../components/Bars/SearchBar/index.web";
import ConversationContext from "../../../contexts/ConversationContext";
import { useState } from "react";
import LoadingIndicator from "../../../components/LoadingIndicatior/index.web";
import UserContext from "../../../contexts/UserContext";

const styles = StyleSheet.create({
  block_contacts_heading_container: {
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
  },
  blocked_contact_item_container: {
    padding: 12,
    textAlign: "center",
  },
  blocked_contact_item_heading: {
    fontFamily: "OpenSans_700Bold",
  },
  blocked_contact_item_subheading: {
    fontFamily: "OpenSans_600SemiBold",
  },
  blocked_contact_item_unblock_btn: {
    margin: 4,
    marginTop: 0,
  },
  blocked_contact_items_list: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 16,
    paddingTop: 16,
    paddingHorizontal: 16,
  },
});
function BlockedContactsScreen() {
  const [loadingConversation, setLoadingConversation] = useState(false);

  return (
    <>
      {loadingConversation && <LoadingIndicator title="Loading Conversation" />}
      <BlockedContactsScreenHeader />
      <BlockNumberForm />
      <SearchBlockedNumbers />
      <BlockedContactsList setLoadingConversation={setLoadingConversation} />
    </>
  );
}

const BlockedContactsScreenHeader = () => {
  return (
    <Row pl={16} pr={16} style={styles.block_contacts_heading_container}>
      <Col leftCenter>
        <Row leftCenter style={commonStyles.alignCenter}>
          <Heading size={5} pb={8} mb={0}>
            Blocked Numbers
          </Heading>
          <InfoIconButton link={Strings.BLOCKED_NUMBERS_DOCS_URL} />
        </Row>
      </Col>
    </Row>
  );
};

const BlockNumberForm = () => {
  const history = useHistory();
  const { canSeeCustomerContact } = useContext(UserContext);

  const onBlockContact = (customer) => {
    let title;
    if (customer?.id) {
      title = getCustomerName(customer);
    } else {
      title = customer?.phone;
    }
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will block ${title}${
          canSeeCustomerContact && customer?.id ? " " + customer?.phone : ""
        }. Do you want to continue?`,
        type: "block-customer",
        customer,
        onSuccessMessage: Strings.BLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.BLOCK_NUMBER_ERROR,
        title: Strings.BLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  return (
    <View style={{ zIndex: 100 }}>
      <Row pl={16} pr={16} pt={12}>
        <Col size={8}>
          <Heading size={4} mb={0} mt={0}>
            {Strings.ADD_BLOCK_NUMBER_HEADING}
          </Heading>
          <Text>{Strings.ADD_BLOCKED_NUMBER_SUBTEXT}</Text>
        </Col>
      </Row>
      <Row
        pl={16}
        pr={16}
        pt={16}
        style={[commonStyles.alignCenter, { zIndex: 100 }]}
      >
        <Col size={8} style={{ zIndex: 100 }}>
          <RecipientSearchInput
            onPress={onBlockContact}
            border
            addMessage="Block Number"
            showAddMessage
            showAddIcon={false}
            inputPlaceholder={Strings.BLOCK_CONTACTS_SEARCH_PLACEHOLDER}
            searchContacts
          />
        </Col>
      </Row>
    </View>
  );
};

const renderContactsList = (contacts, setLoadingConversation) => {
  const history = useHistory();
  const { getConversationByParticipant } = useContext(ConversationContext);
  const { canSeeCustomerContact } = useContext(UserContext);
  const onUnblockContact = (contact) => () => {
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will unblock ${contact?.full_name}${
          canSeeCustomerContact ? " " + contact?.national_number : ""
        }. Do you want to continue?`,
        type: "unblock-customer",
        id: contact?.id,
        onSuccessMessage: Strings.UNBLOCK_NUMBER_SUCCESS,
        onErrorMessage: Strings.UNBLOCK_NUMBER_ERROR,
        title: Strings.UNBLOCK_NUMBER_CONFIRM_HEADING,
      },
    );
  };

  const onContactPress = (participant) => async () => {
    setLoadingConversation(true);
    const conversation = await getConversationByParticipant({
      participant_id: participant.id,
      remote_id: participant.customer_id,
      phone_number: participant.national_number,
    });
    setLoadingConversation(false);
    if (conversation) {
      history.push(`/messages/${conversation.id}`);
    }
  };

  const renderItem = (item) => {
    return (
      <View style={styles.blocked_contact_item_container} key={item?.id}>
        <Text
          style={styles.blocked_contact_item_heading}
          onPress={onContactPress(item)}
        >
          {item?.full_name}
        </Text>
        {!!item?.parent && (
          <Text style={styles.blocked_contact_item_subheading}>
            {item?.parent?.full_name}
          </Text>
        )}
        <Text>{item?.national_number}</Text>
        <View style={styles.blocked_contact_item_unblock_btn}>
          <SolidButton
            label={"Unblock"}
            color={palette.white}
            style={{
              borderWidth: 0,
            }}
            onPress={onUnblockContact(item)}
          />
        </View>
      </View>
    );
  };

  if (contacts.length === 0) {
    return (
      <Text style={commonStyles.textAlignCenter}>
        No blocked contacts found
      </Text>
    );
  }

  return (
    <View style={styles.blocked_contact_items_list}>
      {contacts?.map((contact) => {
        return renderItem(contact);
      })}
    </View>
  );
};

const SearchBlockedNumbers = () => {
  const { searchBlockedContacts, search, setSearch } = useContext(
    BlockedContactsContext,
  );

  useEffect(() => {
    let t;
    if (search && search?.trim()) {
      t = setTimeout(() => {
        searchBlockedContacts();
      }, 300);
    }
    return () => {
      clearTimeout(t);
    };
  }, [search]);

  return (
    <Row pl={16} pr={16} pt={12}>
      <Col size={4}>
        <Heading size={5}>Blocked Numbers</Heading>
        <SearchBar
          searchTerm={search}
          setSearchTerm={setSearch}
          placeholder={"Search by name or phone"}
        />
      </Col>
    </Row>
  );
};

const BlockedContactsList = ({ setLoadingConversation }) => {
  const {
    getBlockedContacts,
    nextPage,
    contacts,
    loading,
    fetchedAll,
    page,
    searchedContacts,
    search,
    searching,
  } = useContext(BlockedContactsContext);

  useEffect(() => {
    getBlockedContacts();
  }, []);

  if ((loading && page === 1) || searching)
    return (
      <Row pl={16} pr={16} pt={12} center>
        <ActivityIndicator color={palette.primary} size="large" />
      </Row>
    );

  return (
    <ScrollView contentContainerStyle={{ paddingBottom: 16 }}>
      {renderContactsList(
        search ? searchedContacts : contacts,
        setLoadingConversation,
      )}
      {!search && !fetchedAll && (
        <LoadMoreButton
          onPress={nextPage}
          disabled={loading}
          loading={loading}
        />
      )}
    </ScrollView>
  );
};

const LoadMoreButton = ({ onPress, disabled, loading }) => {
  return (
    <Row center>
      <SolidButton
        label={"Load More"}
        onPress={onPress}
        disabled={disabled}
        loading={loading}
      />
    </Row>
  );
};

export default BlockedContactsScreen;
