import React, { useCallback, useContext, useEffect, useState } from "react";
import { View, Dimensions }                                    from "react-native";
import AppContext                                              from "../../contexts/AppContext";

export default function AdjustableScreen({ children, style }) {
  const [size, setSize] = useState({
    height: `${Dimensions.get("window").height}px`,
    width: `${Dimensions.get("window").width}px`,
  });

  const { setAppContext, compactMode } = useContext(AppContext);

  const onResize = useCallback(() => {
    if (Dimensions.get("window").width <= 960 && !compactMode) {
      console.log("[AdjustableScreen]","Entering Compact Mode");
      setAppContext({ compactMode: true });
    } else if (Dimensions.get("window").width > 960 && compactMode) {
      console.log("[AdjustableScreen]","Exiting Compact Mode");
      setAppContext({ compactMode: false });
    }
    setSize({
      height: `${Dimensions.get("window").height}px`,
      width: `${Dimensions.get("window").width}px`,
    });
  }, [size, setSize, setAppContext, compactMode]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [compactMode]);

  return <View style={[{ ...size, }, style]}>{children}</View>;
}
