import { createContext, useCallback, useState } from "react";
import Contacts from "../models/Contacts";
import { Contact } from "../models/Contact";
import cloneDeep from "lodash.clonedeep";
import debounce from "lodash.debounce";

const ContactsContext = createContext({});

export function ContactsProvider({ children }) {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savedContacts, setSavedContacts] = useState([]);

  const getContacts = useCallback(
    debounce(
      async (customer_id, parentParticipantId, search = "", set = true) => {
        if (set) {
          setLoading(true);
          setContacts([]);
        }

        try {
          const res = await Contacts.getContactsForCustomer({
            customer_id,
            search,
          });
          if (!res.error) {
            if (set) setContacts(res.response);
            else return res.response;
            if (parentParticipantId) {
              saveContactsToEngage(res.response, parentParticipantId);
            }
          }
        } catch (err) {
          console.error(err);
        }
        if (set) setLoading(false);
        return [];
      },
      300,
    ),
    [],
  );

  const saveContactsToEngage = (contacts, parentParticipantId) => {
    if (!contacts || contacts?.length === 0) return;
    const newContacts = cloneDeep(contacts);

    newContacts.forEach((data) => {
      if (savedContacts.includes(data.id)) return;
      const contact = Contact.createContactFromCustomer(
        data,
        parentParticipantId,
      );
      Contact.createContactInEngage(contact);
      setSavedContacts((newContacts) => [...newContacts, contact.id]);
    });
  };

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        loading,
        hasContacts: !loading && contacts.length,
        getContacts,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
}

export default ContactsContext;
