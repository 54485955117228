const PHONE_TREE_TYPES = [
  { name: "MAIN", value: 0, label: "Main Greeting" },
  { name: "OPTION", value: 1, label: "Option" },
  { name: "ASSIGN_TO", value: 2, label: "Assign to Phone Number" },
  { name: "INFO", value: 3, label: "Message" },
];

export const TWILIO_TYPES = {
  "gather-input-on-call": 1,
  "connect-call-to": 2,
  "say-play": 3,
  "split-based-on": -1,
};

export const MAX_LEVELS = 3;

export const TWILIO_LANGUAGES = [
  {
    label: "English (US)",
    code: "en-US",
    voice: "Polly.Matthew-Neural",
  },
  {
    label: "English (Australian)",
    code: "en-AU",
    voice: "Polly.Olivia-Neural",
  },
  {
    label: "English (Canada)",
    code: "en",
    voice: "Polly.Matthew-Neural",
  },
  {
    label: "English (New Zealand)",
    code: "en-NZ",
    voice: "Polly.Aria-Neural",
  },
  {
    label: "Spanish",
    code: "es-US",
    voice: "Polly.Lupe-Neural",
  },
];
export const SPANISH_LANGUAGE_CODE = "es-US";

const demo1 = [
  {
    label: "Main Greeting",
    text: "Welcome to Fieldpulse!",
    value: 0,
    type: "main",
    children: [1, 2],
    parent: null,
    toBeEdited: true,
  },
  {
    children: [3, 4],
    parent: 0,
    value: 1,
    type: "node",
    num: 1,
    text: "",
    label: "English",
    user: null,
    toBeEdited: true,
  },
  {
    children: [],
    parent: 0,
    value: 1,
    type: "node",
    num: 2,
    text: "",
    label: "Spanish",
    user: null,
    toBeEdited: true,
  },
  {
    children: [5, 6],
    parent: 1,
    value: 1,
    type: "node",
    num: 1,
    text: "",
    label: "Office 1",
    user: null,
    toBeEdited: true,
  },
  {
    children: [],
    parent: 1,
    value: 1,
    type: "node",
    num: 2,
    text: "",
    label: "Office 2",
    user: null,
    toBeEdited: true,
  },
  {
    children: [],
    parent: 3,
    value: 2,
    type: "node",
    num: 1,
    text: "",
    label: "Customer Support",
    user: {
      id: "",
      name: "",
      number: "",
    },
    toBeEdited: true,
  },
  {
    children: [],
    parent: 3,
    value: 3,
    type: "node",
    num: 2,
    text: "9:00 AM TO 5:00 PM",
    label: "Office Hours",
    user: null,
    toBeEdited: true,
  },
];

export const DEFAULT_ACTIVE_NODE = {
  label: "",
  text: "",
  language: "en-US",
  num: 1,
  user: {
    number: "",
  },
  index: 1,
  parent: 0,
  children: [],
  type: "node",
  value: 2,
  level: 1,
};

export const templates = [{ name: "Template 1", data: demo1 }];

export default PHONE_TREE_TYPES;
