import React                          from "react";
import { PusherProvider as Provider } from "@harelpls/use-pusher";
import { BASE_URI, PUSHER_KEY }       from "~/constants/app";

const authorizer = (channel, options) => {
  return {
    authorize: (socketId, callback) => {
      fetch(`${options.authEndpoint}?socket_id=${socketId}&channel_name=${channel.name}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
              "Content-Type": "application/x-www-form-urlencoded" ,
              ...(options.auth?.headers || [])
          },
        })
        .then(res => {
          if (!res.ok) {
            throw new Error(`Received ${res.statusCode} from ${options.authEndpoint}`);
          }
          return res.json();
        })
        .then(data => {
          callback(null, data);
        })
        .catch(err => {
          callback(new Error(`Error calling auth endpoint: ${err}`), {
            auth: ''
          });
        });
    }
  };
};

const config = {
  // required config props
  clientKey: PUSHER_KEY,
  cluster: "us2",
  // required for private/presence channels
  // also sends auth headers to trigger endpoint
  authEndpoint: `${BASE_URI}/pusher/auth`,
  authorizer,
  activityTimeout: 6000,
};

export const PusherProvider = ({ children }) => {
  return <Provider {...config}>{children}</Provider>;
};
