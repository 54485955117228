import { Audio } from "expo-av";
import { useContext, useEffect } from "react";
import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native-web";
import IconButton from "../../components/Buttons/IconButton";
import SolidButton from "../../components/Buttons/SolidButton";
import Heading from "../../components/Heading/index.web";
import { RecordingContext } from "../../contexts/RecordingContext";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import ReactAudioPlayer from "react-audio-player";
import { MediaContext } from "../../contexts/MediaContext";
import * as MicRecorder from "mic-recorder-to-mp3";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { getColonSeparatedHoursMinutesSeconds } from "../../helpers/text";

export default function AudioRecordingModal() {
  const { permissionStatus, setPermissionStatus, setRecording, recording } =
    useContext(RecordingContext);
  const { audioRecordVisible, setAudioRecordVisible } =
    useContext(MediaContext);
  const isFullScreen = Dimensions.get("window").width < 960;

  useEffect(() => {
    const getPermission = async () => {
      const res = await Audio.getPermissionsAsync();
      setPermissionStatus(res);

      if (res.status !== Audio.PermissionStatus.DENIED) {
        await Audio.requestPermissionsAsync();
      }
    };
    getPermission();
  }, []);

  if (!audioRecordVisible) return null;

  return (
    <TouchableWithoutFeedback onPress={() => {}}>
      <View style={[styles.modal_overlay]}>
        <View
          style={[
            styles.modal_container,
            { width: isFullScreen ? "100%" : 600 },
          ]}
        >
          <Row
            center
            flex
            style={{ position: "absolute", top: 16, left: 0, zIndex: 1000 }}
          >
            <Col size={2}></Col>
            <Col center>
              <Text style={[styles.modal__header_title_text]}>
                Record Audio Sample
              </Text>
            </Col>
            <Col rightCenter size={2} pr={16}>
              <IconButton
                faPro
                faProIcon={faXmark}
                width={30}
                height={30}
                onPress={async () => {
                  if (
                    recording &&
                    recording !== null &&
                    recording.recorder &&
                    recording.isRecording
                  )
                    recording.recorder.stop();
                  setRecording({
                    recorder: null,
                    isDoneRecording: false,
                    isRecording: false,
                    fileUrl: null,
                    file: null,
                    useAudio: false,
                  });
                  setAudioRecordVisible(false);
                }}
              />
            </Col>
          </Row>
          <Row>
            {!permissionStatus ||
            permissionStatus?.status === Audio.PermissionStatus.UNDETERMINED ||
            permissionStatus?.status === Audio.PermissionStatus.DENIED ? (
              <Row center mt={16}>
                <Heading size={5}>
                  Please allow microphone permission to be able to record audio
                  sample.
                </Heading>
              </Row>
            ) : (
              <RecordingView />
            )}
          </Row>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

const RecordingView = () => {
  const { recording: state, setRecording: setState } =
    useContext(RecordingContext);
  const { setAudioRecordVisible } = useContext(MediaContext);

  useEffect(() => {
    let int;
    if (state?.isRecording) {
      int = setInterval(() => {
        setState((data) => ({
          ...data,
          duration: data.duration + 1000 || 1000,
        }));
      }, [1000]);
    }
    return () => {
      if (int) {
        clearInterval(int);
      }
    };
  }, [state?.isRecording]);

  const onStartRecording = async () => {
    try {
      const recorder = new MicRecorder({
        bitRate: 128,
      });
      setState((data) => ({
        ...data,
        isRecording: true,
        recorder,
      }));
      recorder.start();
    } catch (e) {
      console.error(e);
    }
  };

  const onEndRecording = async () => {
    try {
      let file, fileUrl;
      state.recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          file = new File(buffer, "music.mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });
          fileUrl = URL.createObjectURL(blob);
          setState((data) => ({
            ...data,
            isRecording: false,
            isDoneRecording: true,
            fileUrl,
            file,
          }));
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onCancelRecording = async () => {
    if (state === null || !state.recorder) return;
    try {
      onEndRecording();
      setState({
        recorder: null,
        isDoneRecording: false,
        isRecording: false,
        fileUrl: null,
        file: null,
      });
    } catch (error) {
      // do nothing
    }
  };

  useEffect(() => {
    setState(null);
    return onCancelRecording;
  }, []);

  return (
    <Col mt={16}>
      <Row flex center mt={32} mb={32}>
        {state && state?.isDoneRecording ? (
          <View>
            <Text style={{ textAlign: "center", marginBottom: 16 }}>
              Recording Complete
            </Text>
            {state && state.fileUrl && (
              <ReactAudioPlayer
                controls
                controlsList="nodownload noplaybackrate"
                src={state.fileUrl}
                style={{ marginBottom: "16px" }}
              />
            )}
            <Row flex style={{ gap: 16 }}>
              <SolidButton
                label="Reset Recording"
                onPress={() => setState(null)}
                color={palette.danger}
              />
              <SolidButton
                label="Use Recording"
                onPress={() => {
                  setState((data) => ({ ...data, useAudio: true }));
                  setAudioRecordVisible(false);
                }}
              />
            </Row>
          </View>
        ) : state && state?.isRecording ? (
          <View>
            <Text
              style={{ textAlign: "center", fontSize: 48, fontWeight: "300" }}
            >
              {getColonSeparatedHoursMinutesSeconds(
                state.duration,
                "milliseconds",
              )}
            </Text>
            <SolidButton label="Stop Recording" onPress={onEndRecording} />
          </View>
        ) : (
          <SolidButton label="Start Recording" onPress={onStartRecording} />
        )}
      </Row>
    </Col>
  );
};

const styles = StyleSheet.create({
  modal__header: {
    height: 75,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    // backgroundColor: palette.primary,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    color: palette.primary,
    fontSize: 24,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal__header_subtitle_text: {
    color: palette.light_grey,
    fontSize: 20,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal__header_shadow_text: {
    color: palette.light_grey,
    opacity: 0.6,
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.65)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
    zIndex: 100,
  },
  modal_container: {
    borderRadius: 8,
    justifyContent: "center",
    backgroundColor: palette.bg_light_grey,
    padding: 16,
    position: "absolute",
    minWidth: "300px",
    maxWidth: "100%",
    minHeight: 275,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: -5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    transitionProperty: "right",
    transitionDelay: "100ms",
    transitionDuration: "200ms",
  },
});
