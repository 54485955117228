import cloneDeep from "lodash.clonedeep";
import { createContext, useContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import useToggle from "../hooks/useToggle";
import Engage from "../services/engage";
import UserContext from "./UserContext";
import { WORKING_HOURS_GREETING_TYPES } from "../constants/mediaTypes";
import {
  ALL_WORKING_HOURS,
  DAYS_ENUM,
  WORKING_HOURS,
  WORKING_HOURS_AUDIO_KEY,
  WORKING_HOURS_ENABLED_KEY,
  WORKING_HOURS_GREETING_KEY,
  WORKING_HOURS_GREETING_TYPE_KEY,
  WORKING_HOURS_KEY,
  WORKING_HOURS_REDIRECT_TO_KEY,
  WORKING_HOURS_TIMEZONE_KEY,
} from "../constants/workingHours";

const WorkingHoursContext = createContext({});

export const WorkingHoursProvider = ({ children }) => {
  const { user, getUser, loading } = useContext(UserContext);
  const account = user && user !== null ? user.account : undefined;
  const [clearHours, setClearHours] = useToggle(false);
  const [workingHours, setWorkingHours] = useState(
    account?.[WORKING_HOURS_KEY]?.length === 0
      ? ALL_WORKING_HOURS
      : account?.[WORKING_HOURS_KEY] || ALL_WORKING_HOURS,
  );
  const [timeZone, setTimeZone] = useState(
    account?.[WORKING_HOURS_TIMEZONE_KEY] === null
      ? "0"
      : account?.[WORKING_HOURS_TIMEZONE_KEY] || "0",
  );
  const [redirectTo, setRedirectTo] = useState(account?.redirect_to);
  const [activeAudio, setActiveAudio] = useState(
    account?.[WORKING_HOURS_AUDIO_KEY],
  );
  const [isWorkingHoursEnabled, setIsWorkingHoursEnabled] = useState(
    account?.[WORKING_HOURS_ENABLED_KEY],
  );

  const [audioId, setAudioId] = useState();

  const [isInvalid, setIsInvalid] = useState(false);
  const [message, setMessage] = useState("");
  const [active, setActive] = useState({});
  const [useAudio, setUseAudio] = useToggle(
    account?.[WORKING_HOURS_AUDIO_KEY] !== null,
  );
  const [startTime, setStartTime] = useState("");
  const [finishTime, setFinishTime] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [saving, setSaving] = useState(false);
  const [activeDay, setActiveDay] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [greetingType, setGreetingType] = useState(
    account?.[WORKING_HOURS_GREETING_TYPE_KEY] ||
      WORKING_HOURS_GREETING_TYPES.TEXT,
  );
  const [workingHoursGreeting, setWorkingHoursGreeting] = useState(
    account?.[WORKING_HOURS_GREETING_KEY] || "",
  );

  const [open, setOpen] = useState(
    active && active !== null && Object.keys(active).length > 0,
  );

  const addHour = () => {
    if (
      Object.keys(active).length &&
      startTime.length === 4 &&
      finishTime.length === 4
    ) {
      if (clearHours) setClearHours();
      const dayE = Object.keys(DAYS_ENUM).find(
        (key) => DAYS_ENUM[key] === active.day,
      );

      const dayIndex = DAYS_ENUM[dayE];
      let hours = cloneDeep(workingHours);
      let arr = hours[dayIndex][dayE];
      const res = isOverlapping(
        dayE,
        { start: startTime, finish: finishTime },
        active.editing,
      );
      if (!res) {
        if (active.editing) {
          let set = false;
          arr.map((a) => {
            if (a.start === active.start && a.end === active.end) {
              set = true;
              a.start = startTime;
              a.finish = finishTime;
            }
          });
          if (!set) {
            arr.push({ start: startTime, finish: finishTime });
          }
          if (active.prev || active.prev === 0) {
            const prevDay = Object.keys(DAYS_ENUM).find(
              (key) => DAYS_ENUM[key] === active.prev,
            );

            const prevDayIndex = DAYS_ENUM[prevDay];

            hours[prevDayIndex][prevDay] = hours[prevDayIndex][prevDay].filter(
              (a) => {
                a.start !== active.start && a.end !== active.end;
              },
            );
          }
        } else arr.push({ start: startTime, finish: finishTime });
        setWorkingHours(hours);
        if (Platform.OS === "web") setActive({});
      }
    }
  };

  const isOverlapping = (day, current, editing = false) => {
    let res = false;
    const dayIndex = DAYS_ENUM[day];
    let arr = cloneDeep(workingHours)[dayIndex][day];
    setMessage("");
    if (arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        let hour = arr[i];
        const startHour = parseInt(hour.start.slice(0, 2));
        const startMinute = parseInt(hour.start.slice(2, 4));
        const endHour = parseInt(hour.finish.slice(0, 2));
        const endMinute = parseInt(hour.finish.slice(2, 4));
        const currentStartHour = parseInt(current.start.slice(0, 2));
        const currentStartMinute = parseInt(current.start.slice(2, 4));
        const currentEndHour = parseInt(current.finish.slice(0, 2));
        const currentEndMinute = parseInt(current.finish.slice(2, 4));
        const start = startHour * 60 + startMinute;
        const end = endHour * 60 + endMinute;
        const currentStart = currentStartHour * 60 + currentStartMinute;
        const currentEnd = currentEndHour * 60 + currentEndMinute;
        let formattedStart = startHour;
        let formattedEnd = endHour;
        let startHalf = false;
        let endHalf = false;
        if (formattedStart >= 12 && formattedStart !== 24) {
          startHalf = true;
        }
        if (formattedEnd >= 12 && formattedEnd !== 24) {
          endHalf = true;
        }
        if (formattedStart === 0 || formattedStart === 24) {
          startHour = 12;
        }
        if (formattedEnd === 0 || formattedEnd === 24) {
          formattedEnd = 12;
        }

        formattedStart = `${
          formattedStart % 12 ? formattedStart % 12 : 12
        }:${hour.start.slice(2, 4)} ${startHalf ? "PM" : "AM"}`;
        formattedEnd = `${
          formattedEnd % 12 ? formattedEnd % 12 : 12
        }:${hour.finish.slice(2, 4)} ${endHalf ? "PM" : "AM"}`;

        if (
          active.editing &&
          active.prev === undefined &&
          hour.start === active.start &&
          hour.finish === active.finish
        ) {
          if (currentEndHour !== 24 && currentEnd <= currentStart) {
            setMessage("End Time cannot be before Start Time.");
            return "End Time cannot be before Start Time.";
          }
        } else {
          if (currentEndHour !== 24 && currentEnd <= currentStart) {
            setMessage("End Time cannot be before Start Time.");
            return "End Time cannot be before Start Time.";
          }
          if (currentStart === start && currentEnd === end) {
            setMessage("Start and End Time cannot be same as of another Hour.");
            return "Start and End Time cannot be same as of another Hour.";
          }

          if (currentStart < start && currentEnd > start && currentEnd <= end) {
            setMessage(
              `Overlapping with hour from ${formattedStart} to ${formattedEnd}`,
            );
            return `Overlapping with hour from ${formattedStart} to ${formattedEnd}`;
          }

          if (
            currentStart > start &&
            currentStart <= end &&
            currentEnd >= start &&
            currentEnd <= end
          ) {
            setMessage(
              `Overlapping with hour from ${formattedStart} to ${formattedEnd}`,
            );
            return `Overlapping with hour from ${formattedStart} to ${formattedEnd}`;
          }

          if (
            currentStart <= start &&
            currentEnd > start &&
            currentEnd <= end
          ) {
            setMessage(
              `Overlapping with hour from ${formattedStart} to ${formattedEnd}`,
            );
            return `Overlapping with hour from ${formattedStart} to ${formattedEnd}`;
          }
          if (
            currentStart >= start &&
            currentStart < end &&
            (currentEnd > end || currentEnd > end)
          ) {
            setMessage(
              `Overlapping with hour from ${formattedStart} to ${formattedEnd}`,
            );
            return `Overlapping with hour from ${formattedStart} to ${formattedEnd}`;
          }
          if (currentStart < start && currentEnd > end) {
            setMessage(
              `Overlapping with hour from ${formattedStart} to ${formattedEnd}`,
            );
            return `Overlapping with hour from ${formattedStart} to ${formattedEnd}`;
          }
        }
      }
    } else {
      const currentStartHour = parseInt(current.start.slice(0, 2));
      const currentStartMinute = parseInt(current.start.slice(2, 4));
      const currentEndHour = parseInt(current.finish.slice(0, 2));
      const currentEndMinute = parseInt(current.finish.slice(2, 4));

      const currentStart = currentStartHour * 60 + currentStartMinute;
      const currentEnd = currentEndHour * 60 + currentEndMinute;
      if (currentEndHour !== 24 && currentEnd <= currentStart) {
        setMessage("End Time cannot be before Start Time.");
        return "End Time cannot be before Start Time.";
      }
    }

    return res;
  };

  const deleteHour = (dayIndex = null, index = null) => {
    let hours = cloneDeep(workingHours);
    let arr = [];
    if (dayIndex !== null && index !== null) {
      arr = Object.values(hours[dayIndex])[0];
      arr.splice(index, 1);
    } else {
      if (active.prev || active.prev === 0) {
        arr = Object.values(hours[active.prev])[0];
      } else {
        arr = Object.values(hours[active.day])[0];
      }
      arr.splice(active.hourIndex, 1);
    }

    setWorkingHours(hours);
    if (Platform.OS === "web") setActive({});
  };

  const saveWorkingHours = async () => {
    setSaving(true);

    let data = {
      [WORKING_HOURS_KEY]: clearHours
        ? []
        : JSON.stringify(workingHours) === JSON.stringify(WORKING_HOURS)
        ? []
        : workingHours,
      [WORKING_HOURS_TIMEZONE_KEY]: timeZone,
      [WORKING_HOURS_ENABLED_KEY]: isWorkingHoursEnabled,
      [WORKING_HOURS_GREETING_KEY]: workingHoursGreeting,
      [WORKING_HOURS_GREETING_TYPE_KEY]: greetingType,
      [WORKING_HOURS_REDIRECT_TO_KEY]: redirectTo,
      voice_audio_id: audioId,
    };

    await Engage.updateWorkingHours(data);
    await getUser();
    if (clearHours) setClearHours();
    setSaving(false);
  };

  const clearWorkingHours = () => {
    if (!clearHours) setClearHours();
    setWorkingHours(WORKING_HOURS);
  };

  useEffect(() => {
    setOpen(active && active !== null && Object.keys(active).length > 0);
  }, [active]);

  useEffect(() => {
    setIsFormInvalid(false);
    setIsChanged(false);
    let res = false;

    if (
      !isWorkingHoursEnabled &&
      isWorkingHoursEnabled !== account?.[WORKING_HOURS_ENABLED_KEY]
    ) {
      setIsChanged(true);
      res = true;
    }

    if (JSON.stringify(workingHours) === JSON.stringify(WORKING_HOURS)) {
      setIsFormInvalid(true);
      return;
    }

    if (
      greetingType === WORKING_HOURS_GREETING_TYPES.AUDIO &&
      (!audioId || audioId === null)
    ) {
      setIsChanged(true);
      res = true;
      setIsFormInvalid(true);
      return;
    }

    if (
      greetingType === WORKING_HOURS_GREETING_TYPES.FORWARD_TO &&
      (!redirectTo || !redirectTo?.trim())
    ) {
      res = true;
      setIsFormInvalid(true);
      return;
    }

    if (
      greetingType === WORKING_HOURS_GREETING_TYPES.TEXT &&
      (!workingHoursGreeting || !workingHoursGreeting?.trim())
    ) {
      setIsChanged(true);
      res = true;
      setIsFormInvalid(true);
      return;
    }

    let originalData = {
      [WORKING_HOURS_KEY]: account?.working_hours,
      [WORKING_HOURS_TIMEZONE_KEY]: account?.timezone,
      voice: account?.out_of_work_audio_url,
      [WORKING_HOURS_REDIRECT_TO_KEY]: account?.redirect_to,
      [WORKING_HOURS_GREETING_TYPE_KEY]:
        account?.[WORKING_HOURS_GREETING_TYPE_KEY],
      [WORKING_HOURS_GREETING_KEY]: account?.[WORKING_HOURS_GREETING_KEY],
    };

    let currentData = {
      [WORKING_HOURS_KEY]: workingHours,
      [WORKING_HOURS_TIMEZONE_KEY]: timeZone,
      voice: activeAudio === "-1" ? null : activeAudio,
      [WORKING_HOURS_REDIRECT_TO_KEY]: redirectTo,
      [WORKING_HOURS_GREETING_TYPE_KEY]: greetingType,
      [WORKING_HOURS_GREETING_KEY]: workingHoursGreeting,
    };

    for (let key of Object.keys(originalData)) {
      if (
        JSON.stringify(originalData[key]) !==
          JSON.stringify(currentData[key]) &&
        !res
      ) {
        if (
          key === "working_hours" &&
          (!originalData[key] || originalData[key].length === 0) &&
          JSON.stringify(currentData[key]) === JSON.stringify(WORKING_HOURS)
        ) {
        } else if (
          key === "timezone" &&
          originalData[key] !== null &&
          parseFloat(originalData[key]) === parseFloat(currentData[key])
        ) {
        } else if (
          key === "voice" &&
          originalData[key] === null &&
          (currentData[key] === "-1" || !currentData[key]) &&
          !res
        ) {
          setIsChanged(true);
          res = true;
        } else {
          setIsChanged(true);
          res = true;
        }
      }
    }

    if (
      !res &&
      isWorkingHoursEnabled &&
      isWorkingHoursEnabled !== account?.[WORKING_HOURS_ENABLED_KEY]
    ) {
      setIsChanged(true);
      res = true;
    }

    setIsFormInvalid(!res);
  }, [
    isWorkingHoursEnabled,
    greetingType,
    workingHoursGreeting,
    audioId,
    timeZone,
    redirectTo,
    activeAudio,
    workingHours,
    saving,
  ]);

  useEffect(() => {
    if (workingHours.length !== 7) {
      let data = Object.keys(DAYS_ENUM);
      let correctData = [];
      for (let i = 0; i < 7; i++) {
        if (workingHours.length <= i) {
          if (workingHours[i] && data[i] === Object.keys(workingHours[i])) {
            correctData.push(workingHours[i]);
          } else {
            correctData.push({ [data[i]]: [] });
          }
        } else {
          correctData.push({ [data[i]]: [] });
        }
      }
      setWorkingHours(correctData);
    }
  }, [workingHours, user]);

  useEffect(() => {
    if (greetingType !== WORKING_HOURS_GREETING_TYPES.AUDIO) {
      setActiveAudio("-1");
      setAudioId(null);
    }
  }, [greetingType]);

  useEffect(() => {
    setWorkingHours(
      account?.working_hours?.length === 0
        ? ALL_WORKING_HOURS
        : account?.working_hours || ALL_WORKING_HOURS,
    );

    setTimeZone(account?.timezone === null ? "0" : account?.timezone || "0");
    setRedirectTo(account?.redirect_to);
    setActiveAudio(account?.out_of_work_audio_url);
  }, [user, loading]);

  const markDayAsNonWorking = (dayIndex, day) => {
    try {
      let hours = cloneDeep(workingHours);
      hours[dayIndex][day] = [];
      setWorkingHours(hours);
    } catch (e) {}
  };

  const markAllDayAsWorking = (dayIndex, day) => {
    try {
      let hours = cloneDeep(workingHours);
      hours[dayIndex][day] = [{ start: "0000", finish: "2400" }];
      setWorkingHours(hours);
    } catch (e) {}
  };

  const setAllHoursAsWorking = () => {
    setWorkingHours(ALL_WORKING_HOURS);
  };

  return (
    <WorkingHoursContext.Provider
      value={{
        workingHours,
        setWorkingHours,
        active,
        setActive,
        isInvalid,
        setIsInvalid,
        startTime,
        setStartTime,
        finishTime,
        setFinishTime,
        isOverlapping,
        addHour,
        deleteHour,
        saveWorkingHours,
        clearWorkingHours,
        timeZone,
        setTimeZone,
        redirectTo,
        setRedirectTo,
        activeAudio,
        setActiveAudio,
        audioId,
        setAudioId,
        useAudio,
        setUseAudio,
        isFormInvalid,
        saving,
        message,
        setMessage,
        clearHours,
        setClearHours,
        activeDay,
        setActiveDay,
        markDayAsNonWorking,
        markAllDayAsWorking,
        isChanged,
        open,
        setOpen,
        isWorkingHoursEnabled,
        setIsWorkingHoursEnabled,
        setAllHoursAsWorking,
        workingHoursGreeting,
        greetingType,
        setGreetingType,
        setWorkingHoursGreeting,
      }}
    >
      {children}
    </WorkingHoursContext.Provider>
  );
};

export default WorkingHoursContext;
