import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { StyleSheet, ScrollView, ActivityIndicator, View } from "react-native";
import ConversationContext from "~/contexts/ConversationContext";
import { ParticipantProvider } from "~/contexts/ParticipantContext";

import palette from "~/styles/palette";
import CustomerForm, { Field } from "../../Customer/CustomerForm";
import ContactContext, {
  ContactProvider,
} from "../../../contexts/ContactContext";
import Row from "../../../layouts/Row";
import SolidButton from "../../Buttons/SolidButton";
import {
  checkContactFormValid,
  checkCustomerFormValid,
} from "../../../helpers/customers";
import Col from "../../../layouts/Col";
import Heading from "../../Heading/index.web";
import IconButton from "../../Buttons/IconButton";
import NumbersContext from "../../../contexts/NumbersContext";
import { faSquarePhoneFlip } from "@fortawesome/pro-duotone-svg-icons";
import { faCommentLines, faWrench } from "@fortawesome/pro-solid-svg-icons";
import useTwilio from "../../../hooks/useTwilio";
import { CallsContext } from "../../../contexts/CallsContext";
import { VoicemailsContext } from "../../../contexts/VoicemailsContext";
import { matchPath, useHistory } from "react-router-dom";
import { pages, pagesObject } from "../../../constants/pages";
import ThreadsContext from "../../../contexts/ThreadsContext";
import { Option } from "../../TopBar/index.web";
import OutsidePressHandler from "react-native-outside-press";
import { OptionsList } from "../../../models/Option";
import {
  CREATE_COMMAND,
  CUSTOMER_OBJECT,
  OPEN_PAGE_PRIMARY_OPTIONS,
  OPEN_PAGE_RELATED_OPTIONS,
} from "../../../constants/webapp-integration";
import UserContext from "../../../contexts/UserContext";
import { WebAppPostMessage } from "../../../models/WebAppPostMessage";
import AdditionalContactContext, {
  AdditionalContactProvider,
} from "../../../contexts/AdditionalContactContext";
import ContactsContext, {
  ContactsProvider,
} from "../../../contexts/ContactsContext";
import { ContactForm, ContactSelect } from "./ContactSelect/index.web";
import CheckBox from "../../CheckBox";
import commonStyles from "../../../styles/common";
import CustomersContext from "../../../contexts/CustomersContext";

export default function ParticipantDetails({
  customer: customerProp,
  onUpdate,
}) {
  const { conversation } = useContext(ConversationContext);
  const { participant, customer } = conversation;

  return (
    <ParticipantProvider value={{ participant, customer }}>
      <ParticipantView customerProp={customerProp} onUpdate={onUpdate} />
    </ParticipantProvider>
  );
}

const ParticipantView = ({ onUpdate, customerProp }) => {
  const { conversation, newConversation } = useContext(ConversationContext);
  const { participant, customer } = conversation;

  const value = useMemo(() => {
    let value = {
      ...participant,
      ...customer,
      ...customerProp,
    };

    if (participant?.phone_number && !value.phone) {
      value.phone = participant.phone_number;
    }

    return value;
  }, [participant, customer]);

  return (
    <ContactProvider
      value={value}
      customer_id={
        newConversation
          ? null
          : value?.is_child
          ? value?.parent?.remote_id
          : value?.remote_id
      }
      isAdditionalContact={value?.is_child}
    >
      <ProfileDetails
        customer_id={value?.remote_id}
        onUpdate={onUpdate}
        participant={participant}
        customer={customer}
        value={value}
      />
    </ContactProvider>
  );
};

const ProfileDetails = ({
  participant,
  onUpdate,
  initialLoading,
  customer,
  customerProp,
  setParticipantFromCustomer,
  panelVisible,
  setPanelVisible,
  customer_id,
  value,
}) => {
  const { loading: loadingConversation, newConversation } =
    useContext(ConversationContext);
  const { loading, contact, contactDeleted } = useContext(ContactContext);

  if (
    loading ||
    loadingConversation ||
    (!newConversation && !contact?.id && customer_id && !contactDeleted)
  )
    return <ProfileTemplate />;

  return (
    <AdditionalContactProvider
      contact_id={newConversation || !value?.is_child ? null : value?.remote_id}
      participant={participant}
    >
      <ContactsProvider
        customer_id={
          newConversation
            ? null
            : value?.is_child
            ? value?.parent?.remote_id
            : value?.remote_id
        }
        parentParticipantId={
          newConversation
            ? null
            : participant?.is_child
            ? participant?.parent?.id
            : null
        }
      >
        <Details
          {...{
            participant,
            onUpdate,
            initialLoading,
            customer,
            customerProp: { ...customerProp, id: customer_id },
            setParticipantFromCustomer,
          }}
          panelVisible={panelVisible}
          setPanelVisible={setPanelVisible}
        />
      </ContactsProvider>
    </AdditionalContactProvider>
  );
};

const Details = ({
  participant,
  onUpdate,
  initialLoading,
  customer,
  customerProp,
  setParticipantFromCustomer,
  panelVisible,
  setPanelVisible,
}) => {
  const { activeNumber } = useContext(NumbersContext);
  const { setCallQueue } = useTwilio();
  const {
    contact,
    loading: loadingContact,
    updateContact,
    isContact,
    setIsContact,
  } = useContext(ContactContext);
  const { hasContacts } = useContext(ContactsContext);
  const { selected: selectedCall } = useContext(CallsContext);
  const { selected: selectedVoicemail } = useContext(VoicemailsContext);
  const { canSeeCustomerContact } = useContext(UserContext);
  const { contact: additionalContact, setContactFromValue } = useContext(
    AdditionalContactContext,
  );
  const { blockedContacts } = useContext(CustomersContext);
  const remoteId = participant?.parent
    ? participant?.parent?.remote_id
    : participant?.remote_id || customer?.id || customerProp?.id;
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const match = useMemo(() => {
    let res = "";
    pages.forEach((to) => {
      let isMatch = matchPath(history?.location.pathname, {
        path: to,
        exact: false,
        strict: false,
      });
      if (isMatch) {
        res = to;
        return;
      }
    });
    if (!res) return history.push("/messages");
    return res.slice(1);
  }, [history.location.pathname]);

  const onCallPress = useCallback(() => {
    if (contact && contact?.phone_e164) {
      const remote_id = participant?.parent
        ? participant?.parent?.remote_id
        : participant?.remote_id || customer?.id || customerProp?.id;

      setCallQueue({
        to: `${contact?.countryCode}${contact?.phone}`,
        metaData: {
          ...contact,
          name: remote_id ? contact?.display_name : participant?.name,
          remote_id,
        },
      });
    }
  }, [
    contact?.phone_e164,
    contact?.remote_id,
    participant?.remote_id,
    participant?.parent?.remote_id,
    customer?.id,
    customerProp?.id,
  ]);

  const isBlocked =
    participant?.is_blocked || blockedContacts?.includes(participant?.id);

  useEffect(() => {
    if (initialLoading || loadingContact)
      setTimeout(() => setLoading(() => true), 200);
    else {
      setTimeout(() => setLoading(() => false), 200);
    }
  }, [initialLoading, loadingContact]);

  return (
    <>
      <View
        style={{
          backgroundColor: "white",
          height: 75,
          borderColor: palette.light_grey,
          borderBottomWidth: 1,
        }}
      >
        <Col leftCenter pl={16}>
          <Row center>
            <Heading size={5} color={palette.label_grey} mt={0} mb={0}>
              Profile Info
            </Heading>
            <Col right>
              <Row center mr={8} style={{ justifyContent: "flex-end", gap: 6 }}>
                {panelVisible &&
                  ((selectedCall && match === pagesObject.CALLS) ||
                    (selectedVoicemail &&
                      match === pagesObject.VOICEMAILS)) && (
                    <IconButton
                      faPro
                      faProIcon={faCommentLines}
                      iconColor={palette.blue_turquoise}
                      round={false}
                      color={palette.blue_turquoise_light}
                      mb={0}
                      mr={0}
                      mt={0}
                      ml={0}
                      height={38}
                      width={38}
                      iconSize={24}
                      borderRadius={4}
                      disabled={!activeNumber || !contact?.phone_e164}
                      onPress={() => setPanelVisible(false)}
                    />
                  )}
                <IconButton
                  faPro
                  faProIcon={faSquarePhoneFlip}
                  iconColor={palette.success}
                  secondaryOpacity={0.3}
                  secondaryColor={palette.lime_green}
                  round={false}
                  mb={0}
                  mr={0}
                  ml={0}
                  mt={0}
                  iconSize={44}
                  borderRadius={4}
                  disabled={!activeNumber || !contact?.phone_e164 || isBlocked}
                  onPress={onCallPress}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </View>
      <ScrollView
        style={[styles.participant_details__container]}
        contentContainerStyle={{
          flex: 1,
          justifyContent: initialLoading ? "center" : "flex-start",
          paddingHorizontal: 16,
        }}
      >
        <ContactSelect
          selected={participant?.phone_number || participant?.phone_e164}
          parent={participant?.is_child ? participant?.parent : participant}
          participant={participant}
          parentContact={contact}
        />
        <View
          style={[
            styles.participant_details__container,
            { zIndex: 100, justifyContent: "flex-end", alignItems: "center" },
            (participant?.is_child || !!hasContacts) && {
              borderTopWidth: 1,
              borderTopColor: palette.light_grey,
              justifyContent: "space-between",
            },
            commonStyles.row_normal,
          ]}
        >
          {(participant?.is_child || !!hasContacts) && (
            <Heading size={4}>Customer</Heading>
          )}
          <CustomerProfileActions
            participantId={participant?.id}
            remoteId={remoteId}
            onUpdate={onUpdate}
            isContact={isContact}
          />
        </View>
        {!remoteId && (
          <View style={[commonStyles.row]}>
            <Field>
              <CheckBox
                label={"Link contact to existing customer"}
                checked={isContact}
                onChange={() => {
                  if (!isContact) {
                    setContactFromValue(contact);
                  } else {
                    updateContact(additionalContact);
                  }
                  setIsContact((val) => !val);
                }}
              />
            </Field>
          </View>
        )}
        {loading || (isContact && !remoteId) ? null : (
          <CustomerForm
            type="edit"
            participantId={participant?.id}
            customerId={customer?.id || customerProp?.id}
            modal={false}
            onUpdate={(value) => {
              setParticipantFromCustomer(value);
              if (typeof onUpdate === "function") onUpdate();
            }}
            showContact={canSeeCustomerContact}
          />
        )}
        {isContact && !remoteId && <ContactForm isContact={true} />}
      </ScrollView>
    </>
  );
};

const ProfileTemplate = () => {
  return (
    <>
      <View
        style={{
          backgroundColor: "white",
          height: 75,
          borderColor: palette.light_grey,
          borderBottomWidth: 1,
        }}
      >
        <Col leftCenter pl={16}>
          <Row leftCenter>
            <Heading size={5} color={palette.label_grey} mt={0} mb={0}>
              Profile Info
            </Heading>
          </Row>
        </Col>
      </View>
      <View style={[styles.participant_details__container]}></View>
      <ScrollView
        style={[styles.participant_details__container]}
        contentContainerStyle={{
          flex: 1,
          justifyContent: "center",
          paddingHorizontal: 16,
        }}
      >
        <ActivityIndicator size="large" />
      </ScrollView>
    </>
  );
};

const CustomerProfileActions = ({
  participantId,
  remoteId,
  onUpdate,
  isContact,
}) => {
  const {
    contact,
    createContact,
    saveContact,
    dirty,
    saving,
    changed,
    contactDeleted,
  } = useContext(ContactContext);

  const { fpUser, isManagerRole, company } = useContext(UserContext);
  const [actionsVisible, setActionsVisible] = useState(false);
  const {
    setParticipantFromCustomer,
    setTempHeader,
    conversation,
    setConversationById,
    getConversationByParticipant,
  } = useContext(ConversationContext);

  const { updateThread } = useContext(ThreadsContext);
  const { setSelected: setSelectedCall } = useContext(CallsContext);
  const { setSelected: setSelectedVoicemail } = useContext(VoicemailsContext);
  const {
    createAdditionalContact,
    contact: additionalContact,
    dirty: isAdditionalContactDirty,
    saving: savingContact,
  } = useContext(AdditionalContactContext);

  const [isCustomerFormValid, setIsCustomerFormValid] = useState(true);

  const onCreate = useCallback(async () => {
    const res = await createContact();
    let title = res?.display_name ? res?.display_name : res?.phone;
    setTempHeader(title ? title : "");
    if (!res?.error) {
      const participant = await setParticipantFromCustomer(res?.response);
      const conversation = await getConversationByParticipant({
        ...participant,
        participant_id: participant.id,
      });

      updateThread(conversation);
      setSelectedCall(null);
      setSelectedVoicemail(null);
    }
  }, [createContact, history]);

  const onSave = useCallback(async () => {
    const res = await saveContact(
      participantId ?? conversation?.participant?.id,
      remoteId,
    );
    let title = res?.display_name ? res?.display_name : res?.phone;
    setTempHeader(title ? title : "");
    if (!res?.error) {
      const participant = await setParticipantFromCustomer(res?.response);
      const title = participant?.name || participant?.phone_number;
      setTempHeader(title);
      setConversationById(conversation?.id, true);
      updateThread({ ...conversation, participant });
      setSelectedCall(null);
      setSelectedVoicemail(null);
    }
  }, [
    saveContact,
    history,
    participantId,
    conversation?.participant?.id,
    conversation?.id,
  ]);

  useEffect(() => {
    let isValid = false;
    if (isContact && !remoteId) {
      isValid = checkContactFormValid(additionalContact);
    } else {
      isValid = checkCustomerFormValid(contact);
    }
    setIsCustomerFormValid(isValid);
  }, [contact, additionalContact, isContact, remoteId]);

  useEffect(() => {
    setActionsVisible(false);
  }, [remoteId]);

  const createOptions = () => {
    try {
      const primaryOptionsList = new OptionsList(OPEN_PAGE_PRIMARY_OPTIONS);
      const relatedOptionsList = new OptionsList(OPEN_PAGE_RELATED_OPTIONS);
      const response = [];
      response.push(<Option label="Primary" key={"primary"} />);
      const enabledPrimaryOptions = primaryOptionsList?.getEnabledOptions({
        user: fpUser,
        isManagerRole,
        company,
      });
      const enabledRelatedOptions = relatedOptionsList?.getEnabledOptions({
        user: fpUser,
        isManagerRole,
        company,
      });

      const createPressHandler = (option) => {
        return () => {
          let webappPostMessageEvent;
          if (option.value === CUSTOMER_OBJECT) {
            webappPostMessageEvent =
              WebAppPostMessage.createCustomerOpenEvent(contact);
          } else {
            webappPostMessageEvent = WebAppPostMessage.createPageOpenEvent(
              option?.value,
              CREATE_COMMAND,
            );
            webappPostMessageEvent.attachCustomer(contact);
          }
          webappPostMessageEvent.emitEvent();
          setActionsVisible(false);
        };
      };

      enabledPrimaryOptions.forEach((option) => {
        const pressHandler = createPressHandler(option);
        response.push(
          <Option
            {...option}
            onPress={pressHandler}
            bold={false}
            paddingVertical={6}
            key={option.label}
          />,
        );
      });

      response.push(<Option label="Related" key={"related"} />);
      enabledRelatedOptions.forEach((option) => {
        const pressHandler = createPressHandler(option);
        response.push(
          <Option
            {...option}
            onPress={pressHandler}
            bold={false}
            paddingVertical={6}
            key={option.label}
          />,
        );
      });

      return response;
    } catch {
      return null;
    }
  };

  const onContactCreate = useCallback(async () => {
    const participant = await createAdditionalContact();

    if (participant) {
      const title = participant?.name || participant?.phone_number;
      setTempHeader(title);
      setConversationById(conversation?.id, true);
      updateThread({ ...conversation, participant });
      setSelectedCall(null);
      setSelectedVoicemail(null);
    }
  }, [createAdditionalContact, conversation?.id]);

  const onSubmit = useCallback(async () => {
    if (isContact && !remoteId) {
      onContactCreate();
      return;
    }
    const func = contactDeleted ? onCreate : remoteId ? onSave : onCreate;
    func();
  }, [isContact, contactDeleted, onCreate, onSave, remoteId, onContactCreate]);

  const isDisabled = useMemo(() => {
    if (isContact && !remoteId) {
      return !isAdditionalContactDirty || !isCustomerFormValid;
    } else {
      return !isCustomerFormValid || !dirty;
    }
  }, [
    isCustomerFormValid,
    dirty,
    isContact,
    isAdditionalContactDirty,
    remoteId,
  ]);

  return (
    <View>
      <Row
        center
        pt={8}
        pb={8}
        pr={8}
        style={{ justifyContent: "flex-end", gap: 8, zIndex: 100 }}
      >
        <View>
          {remoteId && (
            <SolidButton
              label="Actions"
              faIcon={faWrench}
              color={palette.success}
              onPress={() => {
                setActionsVisible((v) => !v);
              }}
            />
          )}
        </View>
        <SolidButton
          label="Update/Save"
          onPress={onSubmit}
          disabled={isDisabled}
          loading={saving || (savingContact && !remoteId)}
        />
      </Row>
      {actionsVisible && remoteId && (
        <OutsidePressHandler
          onOutsidePress={() => setActionsVisible(false)}
          style={{
            width: "100%",
          }}
        >
          <ScrollView
            style={{
              backgroundColor: palette.bg_light_grey,
              position: "absolute",
              zIndex: 100,
              top: "110%",
              marginTop: 8,
              left: 0,
              shadowOpacity: 0.2,
              shadowOffset: { width: 0, height: 2 },
              shadowRadius: 4,
              shadowColor: palette.black,
              borderRadius: 4,
            }}
            contentContainerStyle={{
              paddingBottom: 8,
            }}
          >
            {createOptions()}
          </ScrollView>
        </OutsidePressHandler>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  participant_details__container: {
    backgroundColor: palette.white,
  },
  participant_details_action_bar__label: {
    textAlign: "center",
    fontWeight: "500",
    paddingVertical: 6,
    fontSize: 12,
    color: palette.dark,
  },
  participant_details__header_email: {
    fontWeight: "500",
    color: palette.grey,
  },
});
