import React, { useContext } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native-web";
import { getBezierPath, MarkerType } from "reactflow";
import palette from "../../../styles/palette";
import IconButton from "../../Buttons/IconButton";
import SolidButton from "../../Buttons/SolidButton";
import Icon from "react-native-vector-icons/Feather";
import { PhoneTreeContext } from "../../../contexts/PhoneTreeContext";
import { faPlus, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";

const foreignObjectSize = 20;

const styles = StyleSheet.create({
  button: {
    // paddingVertical: 0,
  },
  button_container: {
    backgroundColor: "transparent",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd = MarkerType.ArrowClosed,
  source,
  target,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { addChildAtIndex, nodes, hasEmptyNode } = useContext(PhoneTreeContext);

  const onEdgeClick = () => {
    let x = nodes[parseInt(source)].children.indexOf(parseInt(target));
    addChildAtIndex(parseInt(source), x + 1, { type: "empty" });
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={MarkerType.ArrowClosed}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <View style={styles.button_container}>
          <IconButton
            faPro
            faProIcon={faPlusCircle}
            round
            iconSize={20}
            iconColor={palette.grey}
            iconStyle={{ backgroundColor: palette.white }}
            width={20}
            height={20}
            onPress={onEdgeClick}
            disabled={hasEmptyNode}
          />
        </View>
      </foreignObject>
    </>
  );
}
