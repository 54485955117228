import { parsePhoneNumber } from "libphonenumber-js";
import {
  CALLER_ID_APPROVED_STATUS,
  CALLER_ID_IN_ACTIVE_STATUS,
  CALLER_ID_IN_REVIEW_STATUS,
  CALLER_ID_REJECTED_STATUS,
} from "../constants/callerId";
import { GREETING_TYPES } from "../constants/mediaTypes";
import {
  ALLOW_CALLS_TO_MANAGERS,
  FORWARDING_ENABLED,
  FRIENDLY_NAME,
  PHONE_NUMBER_KEY,
  FORWARD_TO,
  LEAD_SOURCE_ID,
  LEAD_SOURCE_NAME,
  NATIONAL_NUMBER,
  RECORD_CALL,
  TRANSFER_ENABLED,
  TRANSFER_CALLS,
  TWILIO_FLOW_ID,
  VOICEMAIL_GREETING,
  VOICEMAIL_GREETING_TYPE,
  VOICEMAIL_GREETING_URL,
  PHONE_NUMBER,
  PHONE_TREE_ENABLED,
  CALLER_ID,
  CALLER_ID_STATUS,
  PLAY_RECORDING_MESSAGE,
} from "../constants/phoneNumber";
import { Model } from "./base";

export class PhoneNumber extends Model {
  get hasAssignees() {
    return this.assignees.length > 0;
  }
  getAssigneesName() {
    return this.assignees.map((assignee) => assignee.name).join(", ");
  }

  get callerId() {
    return this.get(CALLER_ID);
  }

  get callerIdStatus() {
    return this.get(CALLER_ID_STATUS);
  }
  get flowEnabled() {
    return this.get(PHONE_TREE_ENABLED);
  }
  get friendlyName() {
    return this.get(FRIENDLY_NAME);
  }
  get forwardTo() {
    return this.get(FORWARD_TO);
  }
  get forwardingEnabled() {
    return this.get(FORWARDING_ENABLED);
  }
  get leadSourceId() {
    return this.get(LEAD_SOURCE_ID);
  }
  get leadSourceName() {
    return this.get(LEAD_SOURCE_NAME);
  }
  get hasManagers() {
    return this.managers.length > 0;
  }

  getManagersNames() {
    return this.managers.map((manager) => manager.name).join(", ");
  }
  get number() {
    return this.get(PHONE_NUMBER_KEY);
  }
  get nationalNumber() {
    return this.get(NATIONAL_NUMBER);
  }
  get recordCall() {
    return this.get(RECORD_CALL);
  }

  get transferEnabled() {
    return this.get(TRANSFER_ENABLED);
  }
  get transferCalls() {
    return this.get(TRANSFER_CALLS);
  }
  get twilioFlowId() {
    return this.get(TWILIO_FLOW_ID);
  }
  get voicemailGreeting() {
    return this.get(VOICEMAIL_GREETING);
  }
  get voicemailGreetingType() {
    if (this.get(VOICEMAIL_GREETING_TYPE) === null)
      return GREETING_TYPES.DEFAULT;
    return this.get(VOICEMAIL_GREETING_TYPE);
  }
  get voicemailGreetingUrl() {
    return this.get(VOICEMAIL_GREETING_URL);
  }
  get allowCallsToManagers() {
    return this.get(ALLOW_CALLS_TO_MANAGERS);
  }

  get isDefaultVoicemail() {
    return this.voicemailGreetingType === GREETING_TYPES.DEFAULT;
  }

  get isTextToSpeechVoicemail() {
    return this.voicemailGreetingType === GREETING_TYPES.TEXT;
  }

  get isAudioRecordingVoicemail() {
    return this.voicemailGreetingType === GREETING_TYPES.AUDIO;
  }

  get phoneTreeEnabled() {
    return this.get(PHONE_TREE_ENABLED);
  }

  get playRecordingMessage() {
    return this.get(PLAY_RECORDING_MESSAGE);
  }

  get callRoutingRoute() {
    return this.getCallRoutingRoute();
  }

  get isCallerIdApprovedOrRejected() {
    return (
      this.callerIdStatus === CALLER_ID_APPROVED_STATUS ||
      this.callerIdStatus === CALLER_ID_REJECTED_STATUS
    );
  }

  get isCallerIdInActive() {
    return (
      this.callerIdStatus === CALLER_ID_IN_ACTIVE_STATUS ||
      this.callerIdStatus === null
    );
  }

  get isCallerIdEditable() {
    return this.callerIdStatus !== CALLER_ID_IN_REVIEW_STATUS;
  }

  serializeForList() {
    return {
      name: this.friendlyName,
      value: this.number,
    };
  }

  getCallRoutingRoute() {
    const {
      hasCallRouting,
      forwardingEnabled,
      phoneTreeEnabled,
      transferEnabled,
    } = this;

    if (!hasCallRouting) {
      return null;
    }

    if (forwardingEnabled) return FORWARDING_ENABLED;

    if (phoneTreeEnabled) return PHONE_TREE_ENABLED;

    if (transferEnabled) return TRANSFER_ENABLED;

    return null;
  }

  get hasCallRouting() {
    return (
      this.forwardingEnabled || this.phoneTreeEnabled || this.transferEnabled
    );
  }

  get hasLeadSource() {
    return this.leadSourceId !== null;
  }

  get hasTwilioFlowAttached() {
    return this.twilioFlowId !== null;
  }

  canSavePhoneNumber(dirty = false, changed = {}) {
    if (!dirty) return false;

    if (this.phoneTreeEnabled && !this.hasTwilioFlowAttached) return false;

    if (this.forwardingEnabled && (!this.forwardTo || !this.forwardTo?.trim()))
      return false;

    if (
      this.transferEnabled &&
      (!this.transferEnabled || this.transferCalls.length === 0)
    )
      return false;

    if (this.isAudioRecordingVoicemail && !this.voicemailGreetingUrl)
      return false;

    if (this.isTextToSpeechVoicemail && !this.voicemailGreeting) return false;

    if (
      !this.transferEnabled &&
      JSON.stringify(changed) ===
        JSON.stringify({
          transfer_calls: [],
        })
    )
      return false;

    if (this.transferEnabled) {
      let areNumbersValid = true;

      this.transferCalls.forEach((number) => {
        try {
          const parsedNumber = parsePhoneNumber(number, "US");
          if (parsedNumber.nationalNumber.length < 10) areNumbersValid = false;
        } catch {
          areNumbersValid = false;
          return false;
        }
      });
      if (!areNumbersValid) {
        return false;
      }
    }

    return true;
  }

  get defaults() {
    return PHONE_NUMBER;
  }
}
