import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Heading from "../../components/Heading/index.web";
import palette from "../../styles/palette";
import { useMemo, useState } from "react";
import {
  BusinessAddress,
  BusinessInformation,
  BusinessRepresentative,
} from "../../models/BusinessInformation";
import { Field } from "../../components/PhoneTree/PhoneTreeForm/index.web";
import StyledInput from "../../components/StyledInput";
import {
  BUSINESS_ADDRESS_CITY_KEY,
  BUSINESS_ADDRESS_COUNTRY_CODE_KEY,
  BUSINESS_ADDRESS_ERROR_MESSAGES,
  BUSINESS_ADDRESS_KEY,
  BUSINESS_ADDRESS_LABELS,
  BUSINESS_ADDRESS_POSTAL_CODE_KEY,
  BUSINESS_ADDRESS_REGION_KEY,
  BUSINESS_ADDRESS_STREET_KEY,
  BUSINESS_EIN_KEY,
  BUSINESS_INFORMATION_KEY,
  BUSINESS_INFORMATION_LABELS,
  BUSINESS_INFO_ERROR_MESSAGES,
  BUSINESS_REPRESENTATIVE_ERROR_MESSAGES,
  BUSINESS_REPRESENTATIVE_JOB_POSITION,
  BUSINESS_REPRESENTATIVE_KEY,
  BUSINESS_REPRESENTATIVE_LABELS,
  BUSINESS_REPRESENTATIVE_PHONE_NUMBER,
  BUSINESS_TYPES,
  BUSINESS_TYPE_KEY,
  JOB_POSITIONS,
} from "../../constants/businessInformation";
import BusinessContext, {
  BusinessProvider,
} from "../../contexts/BusinessContext";
import { useContext } from "react";
import AddressInput from "../../components/AddressInput/index.web";
import StyledPicker from "../../components/StyledPicker";
import SolidButton from "../../components/Buttons/SolidButton";
import Row from "../../layouts/Row";
import Paragraph from "../../components/Paragraph";
import AppContext from "../../contexts/AppContext";
import IconButton from "../../components/Buttons/IconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../contexts/UserContext";
import { COUNTRIES_ENABLED } from "../../constants/countries";

const BusinessInformationModal = () => {
  const { businessModelVisible, isManagerRole } = useContext(UserContext);

  if (!businessModelVisible || !isManagerRole()) return null;
  return (
    <BusinessProvider>
      <View
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10000,
          backgroundColor: palette.white,
        }}
      >
        <BusinessInformationHeader />
        <BusinessInformationFormContainer />
      </View>
    </BusinessProvider>
  );
};

const BusinessInformationHeader = () => {
  const { setBusinessModelVisible } = useContext(UserContext);

  return (
    <View style={styles.business_information_header_container}>
      <Heading>Business Information</Heading>
      <IconButton
        faPro
        faProIcon={faXmark}
        onPress={() => {
          setBusinessModelVisible(false);
        }}
      />
    </View>
  );
};

export const BusinessInformationFormContainer = () => {
  const { compactMode } = useContext(AppContext);
  const { saving } = useContext(BusinessContext);

  if (saving) return <SavingInformation />;
  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{
        width: compactMode ? "100%" : 700,
        justifyContent: "center",
        alignSelf: "center",
        margin: 8,
      }}
    >
      <Text
        style={{
          fontFamily: "OpenSans_600SemiBold",
          marginBottom: 12,
        }}
      >
        We'll verify your new number for Engage within two weeks. After
        approval, you can start sending messages. Check here or contact support
        for updates.
      </Text>
      <BusinessFormSteps />
    </ScrollView>
  );
};

const BusinessFormSteps = () => {
  const [step, setStep] = useState(1);
  const { submit, validateFields } = useContext(BusinessContext);

  const nextStep = () => {
    const errors = validateFields(
      step === 1
        ? BUSINESS_INFORMATION_KEY
        : step === 2
        ? BUSINESS_ADDRESS_KEY
        : BUSINESS_REPRESENTATIVE_KEY,
    );
    if (Object.keys(errors).length > 0) return;
    setStep((s) => (s === 3 ? 3 : s + 1));
  };

  const prevStep = () => {
    setStep((s) => (s === 1 ? 1 : s - 1));
  };

  const FormComponent = useMemo(() => {
    switch (step) {
      case 1:
        return BusinessInformationForm;
      case 2:
        return BusinessAddressForm;
      case 3:
        return BusinessRepresentativeForm;
      default:
        return null;
    }
  }, [step]);

  return (
    <View>
      <FormComponent />
      <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
        <View>
          <Text>Page: {step}/3</Text>
        </View>
        <View style={{ flexDirection: "row", gap: 8 }}>
          {step > 1 && <SolidButton label="Previous" onPress={prevStep} />}
          <SolidButton
            label={step === 3 ? "Submit" : "Next"}
            onPress={step === 3 ? submit : nextStep}
          />
        </View>
      </Row>
    </View>
  );
};

export const BusinessInformationForm = ({ editing = true }) => {
  const { businessInformation, setBusinessInformation, errors, setErrors } =
    useContext(BusinessContext);

  const businessInformationData = new BusinessInformation(businessInformation);
  const onChangeText = (key) => (val) => {
    let value = val;
    if (key === BUSINESS_EIN_KEY) {
      value = val?.replace(/[^0-9]/g, "");
    }
    setBusinessInformation((data) => ({ ...data, [key]: value }));
    if (value?.length && value?.trim()?.length) {
      setErrors((e) => ({ ...e, [key]: false }));
    }
  };

  return (
    <View style={styles.business_form_container}>
      <Heading size={2}>Business Details</Heading>
      <Form
        labels={BUSINESS_INFORMATION_LABELS}
        errorMessages={BUSINESS_INFO_ERROR_MESSAGES}
        data={businessInformationData}
        setData={setBusinessInformation}
        errors={errors}
        pickerKey={BUSINESS_TYPE_KEY}
        pickerOptions={BUSINESS_TYPES}
        onChangeText={onChangeText}
        editing={editing}
      />
    </View>
  );
};

export const BusinessAddressForm = ({ editing = true }) => {
  const { businessAddress, setBusinessAddress, errors, setErrors } =
    useContext(BusinessContext);
  const { user } = useContext(UserContext);
  const country = user?.account?.country || "US";

  const businessAddressData = new BusinessAddress(businessAddress);
  const onChangeText = (key) => (val) => {
    let value = val;
    setBusinessAddress((data) => ({ ...data, [key]: value }));
    if (value?.length && value?.trim()?.length) {
      setErrors((e) => ({ ...e, [key]: false }));
    }
  };

  return (
    <View style={[styles.business_form_container, { zIndex: 100 }]}>
      <Heading size={2}>Business Address</Heading>
      {editing && (
        <Field
          label={"Address"}
          style={[
            {
              zIndex: 100,
            },
          ]}
        >
          <AddressInput
            value={""}
            shortNameForState
            defaultCountry={country}
            extras
            onChange={(data) => {
              if (data?.suggested) {
                const { street, zip, state, city, country_code } = data;
                let address = {
                  [BUSINESS_ADDRESS_STREET_KEY]: street,
                  [BUSINESS_ADDRESS_CITY_KEY]: city,
                  [BUSINESS_ADDRESS_REGION_KEY]: state,
                  [BUSINESS_ADDRESS_POSTAL_CODE_KEY]: zip,
                  [BUSINESS_ADDRESS_COUNTRY_CODE_KEY]: country_code,
                };
                setBusinessAddress(() => address);
              } else {
                if (
                  data?.street &&
                  !businessAddress[BUSINESS_ADDRESS_STREET_KEY]
                )
                  setBusinessAddress((prev) => ({
                    ...prev,
                    [BUSINESS_ADDRESS_STREET_KEY]: data?.street || "",
                  }));
              }
            }}
            lessPadding={false}
            border
            bold
            placeholder="Start typing for suggestions"
          />
        </Field>
      )}
      <Form
        labels={BUSINESS_ADDRESS_LABELS}
        errorMessages={BUSINESS_ADDRESS_ERROR_MESSAGES}
        data={businessAddressData}
        setData={setBusinessAddress}
        errors={errors}
        pickerKey={BUSINESS_ADDRESS_COUNTRY_CODE_KEY}
        pickerOptions={COUNTRIES_ENABLED}
        onChangeText={onChangeText}
        editing={editing}
      />
    </View>
  );
};

export const BusinessRepresentativeForm = ({ editing = true }) => {
  const {
    businessRepresentative,
    setBusinessRepresentative,
    errors,
    setErrors,
  } = useContext(BusinessContext);

  const businessRepresentativeData = new BusinessRepresentative(
    businessRepresentative,
  );
  const onChangeText = (key) => (val) => {
    let value = val;
    if (key === BUSINESS_REPRESENTATIVE_PHONE_NUMBER) {
      value = val?.replace(/[^0-9,+]/g, "");
    }
    setBusinessRepresentative((data) => ({
      ...data,
      [key]: value,
    }));
    if (value?.length && value?.trim()?.length) {
      setErrors((e) => ({ ...e, [key]: false }));
    }
  };

  return (
    <View style={styles.business_form_container}>
      <Heading size={2}>Contact Information</Heading>
      <Paragraph>
        Please provide information about the person who can be contacted about
        the information provided for submission.
      </Paragraph>
      <Form
        labels={BUSINESS_REPRESENTATIVE_LABELS}
        errorMessages={BUSINESS_REPRESENTATIVE_ERROR_MESSAGES}
        data={businessRepresentativeData}
        setData={setBusinessRepresentative}
        errors={errors}
        pickerKey={BUSINESS_REPRESENTATIVE_JOB_POSITION}
        pickerOptions={JOB_POSITIONS}
        onChangeText={onChangeText}
        editing={editing}
      />
    </View>
  );
};

const Form = ({
  labels,
  errorMessages,
  data,
  setData,
  pickerKey,
  pickerOptions,
  onChangeText,
  errors,
  editing,
}) => {
  const Fields = Object.entries(labels).map((field) => {
    const key = field[0];
    const label_obj = field[1];
    let label = label_obj;
    let subtext = "";
    if (typeof label_obj === "object") {
      label = label_obj.label;
      subtext = label_obj.subtext;
    }
    return (
      <Field
        key={key}
        label={label}
        required
        errorMessage={
          errors[key] ? errorMessages[key] || `${label} is required.` : false
        }
        subtext={subtext}
      >
        {key === pickerKey ? (
          <StyledPicker
            options={pickerOptions}
            selectedValue={data.get(key)}
            onValueChange={(value) =>
              setData((data) => ({
                ...data,
                [key]: value,
              }))
            }
            border
            bold
            disabled={!editing}
          />
        ) : (
          <StyledInput
            border
            bold
            value={data.get(key)}
            onChangeText={onChangeText(key)}
            containerStyle={{
              borderColor: errors[key] ? palette.red : palette.light_grey,
            }}
            disabled={!editing}
          />
        )}
      </Field>
    );
  });

  return Fields;
};

const styles = StyleSheet.create({
  business_information_header_container: {
    backgroundColor: palette.grey_bg,
    paddingVertical: 8,
    paddingHorizontal: 12,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  business_form_container: {
    marginBottom: 24,
  },
});

const SavingInformation = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActivityIndicator
        size="large"
        color={palette.primary}
        style={{ marginBottom: 24 }}
      />
      <Text
        style={{
          fontFamily: "OpenSans_600SemiBold",
          marginBottom: 12,
        }}
      >
        Submitting your business information for Account Verification
      </Text>
    </View>
  );
};

export default BusinessInformationModal;
