import { createContext, useContext, useEffect, useMemo, useState } from "react";
import PHONE_TREE_TYPES, {
  DEFAULT_ACTIVE_NODE,
  TWILIO_LANGUAGES,
} from "../constants/phoneTreeTypes";
import { convertBackToNode, convertToTwilioFormat } from "../helpers/phoneTree";
import cloneDeep from "lodash.clonedeep";
import NumberContext from "./NumberContext";
import Engage from "../services/engage";
import NumbersContext from "./NumbersContext";
import SettingsContext from "./SettingsContext";
import UserContext from "./UserContext";
export const PhoneTreeContext = createContext({});

const init = [];

export const PhoneTreeProvider = ({ children }) => {
  const [nodes, setNodes] = useState(init);
  const isEmpty = useMemo(() => nodes.length === 0, [nodes]);
  const [activeNode, setActiveNode] = useState(null);
  const [showGreetingModal, setShowGreetingModal] = useState(isEmpty);
  const hasEmptyNode = useMemo(() => {
    let res = false;
    nodes.forEach((node) => {
      if (node.type === "empty") res = true;
    });
    return res;
  }, [JSON.stringify(nodes)]);
  const [saving, setSaving] = useState(false);
  const { setIsDataChanged } = useContext(SettingsContext);

  const { number, getNumber, updateNumber } = useContext(NumberContext);
  const [loadingPhoneTree, setLoadingPhoneTree] = useState(false);
  const [phoneTree, setPhoneTree] = useState(null);
  const [phoneTreeNodes, setPhoneTreeNodes] = useState([]);

  const [altered, setAltered] = useState(false);
  const {
    user: {
      account: { country },
    },
  } = useContext(UserContext);

  const addMainNode = (text) => {
    const arr = [];
    arr.push({
      label: "Main Greeting",
      text,
      value: PHONE_TREE_TYPES[0].value,
      type: "main",
      children: [],
      parent: null,
      language:
        country === "US"
          ? TWILIO_LANGUAGES[0].code
          : country === "AU"
          ? TWILIO_LANGUAGES[1].code
          : country === "CA"
          ? TWILIO_LANGUAGES[2].code
          : TWILIO_LANGUAGES[0].code,
    });
    setNodes(arr);
  };

  const addChild = (parentIndex, data) => {
    let arr = [...nodes];
    const parent = arr[parentIndex];
    if (parent !== null) {
      const newIndex = arr.length;
      parent.children.push(newIndex);
      let body = {
        children: [],
        parent: parentIndex,
        value: PHONE_TREE_TYPES[1].value,
        type: "node",
        activeIndex: newIndex,
        language: parent?.language,
        ...data,
      };
      arr.push(body);
      setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...body });
      setNodes(arr);
    }
  };

  const addChildAtIndex = (parentIndex, index, data) => {
    let arr = [...nodes];
    const parent = arr[parentIndex];
    if (parent !== undefined) {
      const newIndex = arr.length;
      parent.children.splice(index, 0, newIndex);

      let body = {
        children: [],
        parent: parentIndex,
        value: PHONE_TREE_TYPES[1].value,
        type: "node",
        activeIndex: newIndex,
        language: parent?.language,
        ...data,
      };

      arr.push(body);
      setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...body });
      setNodes(arr);
    }
  };

  const moveChildToIndex = (parentIndex = 0, fromIndex = 0, toIndex = 1) => {
    let arr = [...nodes];
    const parent = arr[parentIndex];
    if (parent !== null) {
      const element = parent.children[fromIndex];
      parent.children.splice(fromIndex, 1);
      parent.children.splice(toIndex, 0, element);
      setNodes(arr);
    }
  };

  const removeNode = (index) => {
    let arr = nodes;
    let node = arr[index];
    if (node !== undefined) {
      if (node?.children?.length > 0) {
        node.children.forEach((child) => (arr[child].parent = null));
      }
      arr = updateIndexes(arr, index);
      arr.splice(index, 1);
      setNodes(arr);
      setActiveNode(null);
    }
  };

  const editData = (data) => {
    let arr = [...nodes];
    if (activeNode !== null) {
      arr[activeNode.index] = { ...arr[activeNode.index], ...data };
      let newActiveNode = { ...arr[activeNode.index], ...data };
      if (arr[activeNode.index].toBeEdited) {
        delete arr[activeNode.index].toBeEdited;
      }
      setNodes(arr);
      setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...newActiveNode });
    }
  };

  const addChildEdge = (parentIndex, nodeIndex) => {
    let arr = [...nodes];
    let parent = arr[parentIndex];
    let curr = arr[nodeIndex];
    if (parent !== undefined && curr !== undefined) {
      parent.children.push(nodeIndex);
      curr.parent = parentIndex;
      setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...arr[nodeIndex] });
    }
    setNodes(arr);
  };

  const reArrangeChildren = (parentIndex, children) => {
    let arr = [...nodes];
    let curr = arr[parentIndex];
    curr.children = children;

    setNodes(arr);
    setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...arr[parentIndex] });
  };

  const savePhoneTree = async () => {
    setSaving(true);
    if (number && number.id) {
      const data = convertToTwilioFormat(nodes);
      let res = {};
      if (phoneTree !== null) {
        res = await Engage.savePhoneTree({
          id: phoneTree.id,
          nodes: data,
          twilio_number_id: number.id,
          update: true,
        });
      } else {
        res = await Engage.savePhoneTree({
          nodes: data,
          twilio_number_id: number.id,
        });
      }
      if (res?.response?.phone_tree) {
        updateNumber({ flow_enabled: true }, true);
        setPhoneTree(res?.response);
        const data = convertBackToNode(
          cloneDeep(res.response?.phone_tree?.states) || [],
        );
        setPhoneTreeNodes(cloneDeep(data));
        setNodes(cloneDeep(data));
      } else {
        updateNumber({ flow_enabled: false }, true);
      }
    }
    setSaving(false);
  };

  const updateIndexes = (nodes, index) => {
    let arr = nodes;
    arr.forEach((node) => {
      if (node.index !== index) {
        let children = [];
        for (let i = 0; i < node.children.length; i++) {
          let child = node.children[i];
          if (child !== index) children.push(child > index ? child - 1 : child);
        }
        node.children = children;
      }
    });
    return nodes;
  };

  useEffect(() => {
    let arr = cloneDeep(nodes);
    const sortedNodes = [];
    let newActiveIndex;
    for (let i = 0; i < arr.length; i++) {
      let node = arr[i];
      if (node.parent === null) {
        let newIndex = sortedNodes.length;
        node.index = newIndex;
        node.id = `${newIndex}`;
        node.level = 0;
        sortedNodes.push(node);
      }
      if (node.children && node.children.length > 0) {
        let newIndexes = [];
        for (let j = 0; j < node.children.length; j++) {
          let child = arr[node.children[j]];
          let newIndex = sortedNodes.length;
          if (child !== undefined) {
            child.index = newIndex;
            child.id = `${newIndex}`;
            child.parent = node.index;
            child.level = node.level + 1;
            child.num = j + 1;
            if (child.activeIndex !== undefined) {
              newActiveIndex = newIndex;
              delete child.activeIndex;
            }
            newIndexes.push(newIndex);
            sortedNodes.push(child);
          }
        }
        node.children = newIndexes;
      }
    }
    if (newActiveIndex !== undefined)
      setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...sortedNodes[newActiveIndex] });
    else if (
      newActiveIndex === undefined &&
      activeNode === null &&
      sortedNodes.length > 0
    ) {
      setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...sortedNodes[0] });
    }
    setNodes(sortedNodes);
    checkIfAltered();
  }, [JSON.stringify(nodes), JSON.stringify(phoneTree)]);

  const checkIfAltered = () => {
    let nodesCopy = cloneDeep(nodes);
    let phoneTreeCopy = cloneDeep(phoneTreeNodes);
    setAltered(false);
    setIsDataChanged(false);
    if (
      phoneTreeCopy &&
      nodesCopy &&
      nodesCopy.length !== phoneTreeCopy.length
    ) {
      setIsDataChanged(true);
      setAltered(true);
      return true;
    }
    for (let i = 0; i < nodesCopy.length; i++) {
      let node = nodesCopy[i];
      let phoneTreeNode = phoneTreeCopy[i];
      const keys = ["label", "language", "text", "value", "user"];
      let res = false;

      keys.forEach((key) => {
        if (
          key !== "user" &&
          node[key] !== undefined &&
          phoneTreeNode[key] !== undefined &&
          phoneTreeNode[key] !== node[key]
        ) {
          res = true;
          setIsDataChanged(true);
          setAltered(true);
          return;
        } else if (key === "user" && node.value === 2) {
          if (
            !(
              phoneTreeNode.user &&
              phoneTreeNode.user.number &&
              node.user &&
              node.user.number &&
              node.user.number === phoneTreeNode.user.number
            )
          ) {
            res = true;
            setIsDataChanged(true);
            setAltered(true);
            return;
          }
        }
      });
      if (res) {
        setAltered(true);
        return true;
      }
    }
    return false;
  };

  const useTemplate = (template) => {
    setNodes(template.data);
  };

  const getPhoneTree = async () => {
    setLoadingPhoneTree(true);
    if (number?.twilio_flow_id && number.twilio_flow_id !== null) {
      const res = await Engage.getPhoneTree(number?.twilio_flow_id);

      if (res?.response) {
        setPhoneTree(res?.response || []);
      }
    } else {
      setNodes([]);
      setPhoneTree(null);
    }
    setLoadingPhoneTree(false);
  };

  useEffect(() => {
    getPhoneTree();
  }, [number?.id, number?.twilio_flow_id, number?.flow_enabled]);

  useEffect(() => {
    if (!loadingPhoneTree) {
      setData();
    }
  }, [loadingPhoneTree, phoneTree]);

  const setData = async () => {
    if (phoneTree) {
      const data = convertBackToNode(
        cloneDeep(phoneTree?.phone_tree?.states) || [],
      );
      setPhoneTreeNodes(cloneDeep(data));
      setNodes(cloneDeep(data));
    } else {
      setNodes([]);
      setPhoneTreeNodes([]);
    }
  };

  const resetTree = async () => {
    if (number && number.id) {
      setSaving(true);
      if (phoneTree !== null) {
        await Engage.deletePhoneTree(phoneTree.id);
        await getNumber();
        await getPhoneTree();
        updateNumber({ flow_enabled: false }, true);
      } else {
        setNodes([]);
      }
      setSaving(false);
    }
  };

  return (
    <PhoneTreeContext.Provider
      value={{
        nodes: cloneDeep(nodes),
        isEmpty,
        activeNode,
        setActiveNode,
        showGreetingModal,
        setShowGreetingModal,
        addMainNode,
        addChild,
        addChildAtIndex,
        moveChildToIndex,
        removeNode,
        editData,
        hasEmptyNode,
        reArrangeChildren,
        savePhoneTree,
        useTemplate,
        saving,
        altered,
        addChildEdge,
        resetTree,
        loadingPhoneTree,
        getPhoneTree,
      }}
    >
      {children}
    </PhoneTreeContext.Provider>
  );
};
