export const addressComponentsLocalNames = {
  city: "locality",
  state: "administrative_area_level_1",
  zipCode: "postal_code",
  postalCity: "postal_town",
  subCity: "administrative_area_level_2",
};

export function getAddressComponentFromPlace(addressComponentLocalName, place) {
  let addressComponent = "";

  for (let placeAddressComponent of place.address_components) {
    if (
      placeAddressComponent.types &&
      placeAddressComponent.types.indexOf(addressComponentLocalName) !== -1
    ) {
      addressComponent = placeAddressComponent.long_name;
    }
  }

  return addressComponent;
}

export function getCityFromAddressComponents(place) {
  return (
    getAddressComponentFromPlace(addressComponentsLocalNames.city, place) ||
    getAddressComponentFromPlace(
      addressComponentsLocalNames.postalCity,
      place,
    ) ||
    getAddressComponentFromPlace(addressComponentsLocalNames.subCity, place) ||
    ""
  );
}

export function getFormattedAddress(place) {
  let streetNumber = getAddressComponentFromPlace("street_number", place) || "";
  let streetName = getAddressComponentFromPlace("route", place) || "";
  let subpremise = getAddressComponentFromPlace("subpremise", place) || "";

  if (streetNumber === "" || streetName === "") {
    return place.formatted_address;
  }

  return `${subpremise ? subpremise + "/" : ""}${streetNumber} ${streetName}`;
}
