import BUSINESS_INFORMATION_DEFAULT, {
  BRAND_STATUS_APPROVED,
  BRAND_STATUS_KEY,
  BRAND_STATUS_NOT_SUBMITTED,
  BRAND_STATUS_PENDING,
  BRAND_STATUS_REJECTED,
  BUSINESS_ADDRESS,
  BUSINESS_ADDRESS_KEY,
  BUSINESS_INFORMATION,
  BUSINESS_INFORMATION_KEY,
  BUSINESS_REPRESENTATIVE,
  BUSINESS_REPRESENTATIVE_KEY,
  CAMPAIGN_STATUS,
  CAMPAIGN_STATUS_REJECTED,
} from "../constants/businessInformation.js";
import palette from "../styles/palette.js";
import { Model } from "./base.js";

export class BusinessInformation extends Model {
  get defaults() {
    return BUSINESS_INFORMATION;
  }
}

export class BrandStatus extends Model {
  constructor(brandStatus) {
    super();
    this[BRAND_STATUS_KEY] = brandStatus;
  }

  isBrandRejected() {
    return this?.get(BRAND_STATUS_KEY) === BRAND_STATUS_REJECTED;
  }

  isBrandPending() {
    return (
      this?.get(BRAND_STATUS_KEY) === BRAND_STATUS_PENDING ||
      this?.get(BRAND_STATUS_KEY) === BRAND_STATUS_NOT_SUBMITTED
    );
  }

  isBrandVerified() {
    return this?.get(BRAND_STATUS_KEY) === BRAND_STATUS_APPROVED;
  }

  get defaults() {
    return {
      [BRAND_STATUS_KEY]: BRAND_STATUS_PENDING,
    };
  }
}

export class BusinessAddress extends Model {
  get defaults() {
    return BUSINESS_ADDRESS;
  }
}

export class BusinessRepresentative extends Model {
  get defaults() {
    return BUSINESS_REPRESENTATIVE;
  }
}

export class Business extends Model {
  constructor(
    businessInformation = new BusinessInformation(),
    businessAddress = new BusinessAddress(),
    businessRepresentative = new BusinessRepresentative(),
  ) {
    super();
    this[BUSINESS_INFORMATION_KEY] = businessInformation;
    this[BUSINESS_ADDRESS_KEY] = businessAddress;
    this[BUSINESS_REPRESENTATIVE_KEY] = businessRepresentative;
  }

  static build(data) {
    return new Business(
      new BusinessInformation(data.business_information),
      new BusinessAddress(data.business_address),
      new BusinessRepresentative(data.business_representative),
    );
  }

  static getStatusLabel(status, brand_status) {
    const brandStatus = new BrandStatus(brand_status);
    if (brandStatus.isBrandRejected()) {
      return CAMPAIGN_STATUS[CAMPAIGN_STATUS_REJECTED];
    }
    try {
      return CAMPAIGN_STATUS[status];
    } catch {
      return {
        label: "Not Submitted",
        color: palette.grey,
        message:
          "Business Information has not yet been submitted for 10DLC verification.",
      };
    }
  }

  get BusinessInformation() {
    return this[BUSINESS_INFORMATION_KEY];
  }

  get BusinessAddress() {
    return this[BUSINESS_ADDRESS_KEY];
  }

  get BusinessRepresentative() {
    return this[BUSINESS_REPRESENTATIVE_KEY];
  }

  setBusinessInformation(businessInformation) {
    this[BUSINESS_INFORMATION_KEY] = businessInformation;
  }

  setBusinessAddress(businessAddress) {
    this[BUSINESS_ADDRESS_KEY] = businessAddress;
  }

  setBusinessRepresentative(businessRepresentative) {
    this[BUSINESS_REPRESENTATIVE_KEY] = businessRepresentative;
  }

  get defaults() {
    return BUSINESS_INFORMATION_DEFAULT;
  }
}
