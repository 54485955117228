export const ACCOUNT_TYPES = [
  {
    name: "Residential",
    value: "individual",
  },
  {
    name: "Commercial",
    value: "company",
  },
];
