import { useContext, useEffect, useState } from "react";
import { Text, View, StyleSheet, Modal } from "react-native-web";
import { Handle, Position } from "reactflow";
import PHONE_TREE_TYPES, {
  DEFAULT_ACTIVE_NODE,
} from "../../constants/phoneTreeTypes";
import { PhoneTreeContext } from "../../contexts/PhoneTreeContext";
import { checkNode } from "../../helpers/phoneTree";
import useToggle from "../../hooks/useToggle";
import palette from "../../styles/palette";
import IconButton from "../Buttons/IconButton";
import Icon from "react-native-vector-icons/Feather";
import { faCircleInfo, faPlus } from "@fortawesome/pro-regular-svg-icons";

const styles = StyleSheet.create({
  node_container: {
    minHeight: 50,
    minWidth: 200,
    maxWidth: 200,
    padding: 5,
    borderRadius: 4,
    backgroundColor: palette.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
  },
  handle_container: {
    position: "absolute",
    zIndex: 1000,
    left: "50%",
    top: -12,
    transform: "translateX(-50%)",
    borderRadius: 3,
  },
  text: { fontSize: 12, textAlign: "center" },
});

export default function Node({ data }) {
  const { setActiveNode, activeNode, addChild, hasEmptyNode } =
    useContext(PhoneTreeContext);

  const isValid = checkNode(data);
  const childrenLength = data?.children?.length;

  const addNewChild = () => {
    if (childrenLength < 9) addChild(data.index, { type: "empty" });
  };

  const [popupVisible, togglePopup] = useToggle(false);

  return (
    <View>
      {(data.toBeEdited || isValid.error) && (
        <View
          style={{
            position: "absolute",
            right: -25,
            top: 0,
          }}
        >
          <IconButton
            iconColor={data.toBeEdited ? palette.info : palette.danger}
            style={{ padding: 0, marginLeft: 0, marginRight: 0 }}
            iconSize={20}
            width={20}
            height={20}
            faPro
            faProIcon={faCircleInfo}
            onPress={togglePopup}
          />
        </View>
      )}
      {(data.toBeEdited || isValid.error) && popupVisible && (
        <View
          style={{
            position: "absolute",
            right: "-8%",
            top: -5,
            zIndex: 5000,
            padding: 5,
            border: `1px solid ${palette.light_grey}`,
            borderRadius: 5,
            transform: "translate(50%,-100%)",
            backgroundColor: palette.background_color,
            maxWidth: 100,
          }}
        >
          {data.toBeEdited ? (
            <Text style={[styles.text, { fontSize: 8 }]}>
              This node was imported from a template.
            </Text>
          ) : (
            <Text style={[styles.text, { fontSize: 8 }]}>
              {isValid.message}
            </Text>
          )}
        </View>
      )}
      <View
        style={[
          styles.node_container,
          {
            border:
              activeNode?.index === data.index
                ? `2px solid ${palette.primary}`
                : data.toBeEdited
                ? `2px solid ${palette.info}`
                : isValid.error
                ? `2px solid ${palette.danger}`
                : `2px solid ${palette.light_grey}`,
          },
        ]}
        onClick={() => {
          console.log("🚀 ~ Node ~ data:", data);

          setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...data });
        }}
      >
        <View
          style={[
            styles.handle_container,
            {
              backgroundColor: data.toBeEdited
                ? palette.info
                : isValid.error
                ? palette.danger
                : palette.primary,
            },
          ]}
        >
          <View style={{ padding: 4 }}>
            <Handle type="target" position={Position.Top} />
            <Text style={[styles.text, { color: palette.white }]}>
              Press {data.num}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            paddingVertical: 10,
          }}
        >
          <Text style={styles.text}>{data.label}</Text>
          {!!data.user?.numberLabel && (
            <Text
              style={[
                styles.text,
                { color: palette.label_grey, marginVertical: 2 },
              ]}
            >
              {data.user.numberLabel}
            </Text>
          )}
          <Text
            style={[
              styles.text,
              {
                color: palette.label_grey,
              },
            ]}
          >
            {data.value === PHONE_TREE_TYPES[2].value
              ? `${data.user?.number || " "}`
              : data.text}
          </Text>
        </View>
      </View>
      <Handle
        type="source"
        id="a"
        position={Position.Bottom}
        style={{
          backgroundColor: "#fff",
          border: "1px solid black",
          width: 14,
          height: 14,
          bottom: -7,
          zIndex: 1000,
          opacity: hasEmptyNode ? 0.5 : 1,
          visibility:
            data.value !== PHONE_TREE_TYPES[1].value ? "hidden" : "visible",
        }}
        onClick={hasEmptyNode ? null : addNewChild}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            faPro
            faProIcon={faPlus}
            iconSize={12}
            width={12}
            height={12}
            style={{ marginTop: 1 }}
            onPress={addNewChild}
            disabled={
              data.value !== PHONE_TREE_TYPES[1].value ||
              hasEmptyNode ||
              childrenLength === 9
            }
          />
        </View>
      </Handle>
    </View>
  );
}

export const SimpleNode = ({ data }) => {
  return (
    <View>
      <View
        style={[
          styles.node_container,
          {
            border: `2px solid ${palette.light_grey}`,
          },
        ]}
      >
        <Handle type="target" position={Position.Top} />
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={[styles.text]}>{data.label}</Text>
        </View>
      </View>
      <Handle
        type="source"
        id="a"
        position={Position.Bottom}
        style={{
          border: `1px solid ${palette.light_grey}`,
          zIndex: 1000,
        }}
      ></Handle>
    </View>
  );
};
