import { INVOICE_STATUS } from "../constants/invoices";

export const getInvoiceStatus = (status) =>
  INVOICE_STATUS.reduce((acc, curr) => {
    if (acc) {
      return acc;
    }

    if (curr.value === status) {
      return curr;
    }

    return null;
  }, null);

export const getLabelForStatus = (status) => {
  const item = INVOICE_STATUS.find((i) => i.value === status);
  return item || { value: "", label: "-", color: "black" };
};
