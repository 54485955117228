import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { Image, StyleSheet, Text, TextInput, View } from "react-native";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import AdjustableScreen from "~/layouts/AdjustableScreen/index.web";
import palette from "~/styles/palette";
import Logo from "~/components/Logo";
import SolidButton from "~/components/Buttons/SolidButton/index";
import useEngage from "~/hooks/useEngage";
import UserContext from "~/contexts/UserContext";
import { USER_LOGOUT_DUE_TO_EMAIL_MISMATCH_ERROR } from "../../constants/users";

export default function LoginScreen({ history }) {
  const { user, loggedIn } = useContext(UserContext);

  useEffect(() => {
    if (loggedIn) {
      history.push("/messages");
    }
  }, [user]);

  return (
    <AdjustableScreen>
      <Row flex>
        <Col center pl={16} pr={16} pt={16}>
          <View style={{ height: 600 }}>
            <View style={[styles.login_screen__logo_container]}>
              <Logo height={75} width={75} />
            </View>
            <View style={{ flexGrow: 1 }}>
              <Login />
            </View>
          </View>
        </Col>
        <Col style={[styles.right_panel]}></Col>
      </Row>
    </AdjustableScreen>
  );
}

const Login = () => {
  const Engage = useEngage();
  const { updateUser, setWebappToken, logoutError, setLogoutError } =
    useContext(UserContext);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const passwordInputRef = useRef();

  const onLogin = useCallback(async () => {
    setLoading(true);
    setError(null);
    setLogoutError(false);
    const res = await Engage.login({ email, password });

    if (res.error) {
      setError(res.message || "Login attempt failed. Please try again.");
      setPassword("");
    } else {
      updateUser(res.response);
      setWebappToken(res?.apiToken);
    }
    setLoading(false);
  }, [email, password, updateUser, setError, setPassword]);

  const errorMessage = useMemo(() => {
    if (!!error) return error;
    if (logoutError) return USER_LOGOUT_DUE_TO_EMAIL_MISMATCH_ERROR;
    return null;
  }, [error, logoutError]);

  return (
    <View style={[styles.login_screen__login_container]}>
      <View style={[styles.login_screen__title_container]}>
        <Text style={[styles.login_screen__title]}>
          Sign in to your account
        </Text>
      </View>
      <View>
        <Text style={[styles.login_screen__error_message]}>{errorMessage}</Text>
      </View>
      <View style={[styles.login_screen__input_container]}>
        <View style={[styles.login_screen__input_label_container]}>
          <Text style={[styles.login_screen__input_label]}>Email</Text>
        </View>
        <TextInput
          textContentType="emailAddress"
          value={email}
          style={[styles.login_screen__input]}
          onChangeText={setEmail}
          onKeyPress={(e) =>
            e.nativeEvent.key === "Enter"
              ? passwordInputRef?.current?.focus()
              : null
          }
        />
      </View>
      <View style={[styles.login_screen__input_container]}>
        <View style={[styles.login_screen__input_label_container]}>
          <Text style={[styles.login_screen__input_label]}>Password</Text>
        </View>
        <TextInput
          name="password"
          value={password}
          secureTextEntry
          style={[styles.login_screen__input]}
          onChangeText={setPassword}
          onKeyPress={(e) => (e.nativeEvent.key === "Enter" ? onLogin() : null)}
          ref={passwordInputRef}
        />
      </View>

      <View style={[styles.login_screen__input_container]}>
        <SolidButton
          textContentType="password"
          label="Login"
          loading={loading}
          color={palette.success}
          labelStyle={{ fontSize: 18 }}
          onPress={onLogin}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  right_panel: {
    backgroundColor: palette.primary,
  },
  login_screen__logo_container: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  login_screen__logo: {
    height: 45,
    width: 45,
  },
  login_screen__login_container: {
    width: 450,
    marginTop: -50,
  },
  login_screen__title_container: {
    marginBottom: 24,
    textAlign: "center",
  },
  login_screen__title: {
    fontSize: 32,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  login_screen__input_label_container: {
    padding: 8,
  },
  login_screen__input_label: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  login_screen__input: {
    backgroundColor: palette.lightest_grey,
    borderColor: palette.light_grey,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 12,
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "OpenSans_600SemiBold",
    // outlineColor: palette.light_grey,
    // outlineOffset: 0,
    // outlineWidth: 0,
    borderRadius: 4,
  },
  login_screen__input_container: {
    padding: 8,
  },
  login_screen__error_message: {
    color: palette.danger,
    textAlign: "center",
  },
});
