import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { Platform } from "react-native";
import { Device } from "@twilio/voice-sdk";

import Engage from "~/services/engage";
import { TWILIO_VOICE_OPTIONS } from "~/constants/twilio";
import NumbersContext from "../../contexts/NumbersContext";
import UserContext from "../../contexts/UserContext";
import { WebAppPostMessage } from "../../models/WebAppPostMessage";
import {
  CALL_EVENT_TYPE,
  CALL_STATUS_COMMAND,
} from "../../constants/webapp-integration";
import { Calls } from "../../models/Calls";
import { Twilio } from "../../models/Twilio";
import { INIT_CALL_STATE } from "../../reducers/callReducer";
import { Call } from "../../models/call";

export default function useTwilioInit(identity) {
  const device = useRef(null);

  const { getUser, user } = useContext(UserContext);
  const { activeNumber } = useContext(NumbersContext);

  const [ready, setReady] = useState(false);
  const [token, setToken] = useState(null);
  const [iceServers, setIceServers] = useState([]);
  const [calls, setCalls] = useState([]);
  const [holding, setHolding] = useState(false);
  const [formattedCalls, setFormattedCalls] = useState(new Calls(calls));
  const callInvites = formattedCalls.callInvites();
  const acceptedCalls = formattedCalls.acceptedCalls();
  const ignoredCalls = formattedCalls.ignoredCalls();
  const isActiveCallOnHold = formattedCalls.isActiveCallOnHold;
  const activeCall = formattedCalls.activeCall;
  const activeCallData = formattedCalls.activeCallData;
  const callsOnHold = formattedCalls.callsOnHold();
  const callState = formattedCalls.activeCallState;
  const callStatus = formattedCalls.activeCallStatus;
  const isActiveCallMuted = formattedCalls.isActiveCallMuted;

  const getAccessToken = useCallback(async () => {
    if (!activeNumber) {
      return;
    }

    const twilio = new Twilio({
      identity,
      numberId: activeNumber.id,
      platform: Platform.OS,
    });

    const res = await twilio.fetchToken();

    if (res.error) {
      getUser();
      return null;
    }

    setToken(res?.token);
    setIceServers(res?.ice_servers || []);
    return res?.token;
  }, [setToken, identity, activeNumber, getUser]);

  const initialize = useCallback(async () => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      setReady(true);
    }
  }, [getAccessToken, setReady, activeNumber]);

  useEffect(() => {
    if (identity && activeNumber?.id && !token) {
      // Don't initialize until active number exists because we need it for the token
      initialize();
    }
  }, [identity, token, activeNumber?.id]);

  const addCall = useCallback(
    (call, state, data = {}) => {
      if (!call || !call?.parameters || !call?.parameters?.CallSid) return;
      setCalls((calls) => {
        let currCalls = calls;
        let existingCall = calls.find(
          (c) => c?.callSid === call?.parameters?.CallSid,
        );
        const callData = {
          call,
          timestamp: new Date(),
          data,
          state,
          ignored: false,
          hold: false,
          callSid: call?.parameters?.CallSid,
          callState: INIT_CALL_STATE,
          active: false,
          muted: false,
        };

        if (existingCall) {
          delete callData.active;
          delete callData.hold;
          delete callData.ignored;
          delete callData.muted;
          existingCall = { ...existingCall, ...callData };
        } else {
          currCalls.push(callData);
        }

        let formattedState = "incoming";
        if (state === "accepted") formattedState = "accepted";
        if (state === "accepted" && data?.direction)
          formattedState = "outgoing";
        if (state === "invited") formattedState = "incoming";

        emitCallEvent({
          ...call,
          event: formattedState,
        });
        const formattedCalls = new Calls(currCalls);
        setFormattedCalls(formattedCalls);
        return currCalls;
      });
    },
    [setCalls, setFormattedCalls],
  );

  const removeCall = useCallback(
    (call) => {
      if (!call || !call?.parameters || !call?.parameters?.CallSid) return;
      setCalls((calls) => {
        let currCalls = calls;
        let existingCall = currCalls.findIndex(
          (calls) => calls?.callSid === call?.parameters?.CallSid,
        );
        if (existingCall !== -1) {
          currCalls.splice(existingCall, 1);
        }
        const formattedCalls = new Calls(currCalls);
        setFormattedCalls(formattedCalls);
        return currCalls;
      });

      emitCallEvent({
        ...call,
        event: "ended",
      });
    },
    [setCalls, setFormattedCalls],
  );

  const toggleHold = useCallback(
    async (hold, call_sid) => {
      let success = false;
      if (activeCall || call_sid) {
        const callSid = call_sid || activeCall?.parameters?.CallSid;
        try {
          setHolding(true);
          const res = await Engage.putCallOnHold({
            hold,
            call_sid: callSid,
            account_id: user?.account?.id,
          });
          if (!res.error) {
            setCalls((calls) => {
              let newCalls = calls;
              const call = newCalls.find((c) => c.callSid === callSid);
              if (call) {
                call.hold = hold;
                if (calls.length > 1 && hold) call.active = false;
                else if (!hold) call.active = true;
              }
              const formattedCalls = new Calls(newCalls);
              setFormattedCalls(formattedCalls);
              return newCalls;
            });
          }
        } catch (e) {
          console.error(e);
        } finally {
          success = true;
          setHolding(false);
        }
      } else {
        console.warn("Cannot put call on hold without an active call");
      }
      return success;
    },
    [activeCall, setCalls],
  );

  const setActiveCall = useCallback(
    (activeCall) => {
      if (!activeCall || !activeCall?.parameters?.CallSid) return;
      setCalls((calls) => {
        let currCalls = calls;
        const newCalls = currCalls.map((call) => {
          if (call.callSid === activeCall.parameters.CallSid) {
            return { ...call, active: true };
          } else {
            return { ...call, active: false };
          }
        });

        setFormattedCalls(new Calls(newCalls));
        return newCalls;
      });
    },
    [setCalls, setFormattedCalls],
  );

  const activeCallCleanUp = (call) => {};

  const updateCallStatus = useCallback(
    (call, action) => {
      if (!call || !call?.parameters || !call?.parameters?.CallSid) return;
      setCalls((calls) => {
        let currCalls = calls;
        let existingCall = currCalls.find(
          (c) => c.callSid === call.parameters.CallSid,
        );
        if (existingCall) {
          const state = existingCall.callState;
          const newState = Call.getCallState(state, action);

          existingCall.callState = newState;
        } else {
        }
        setFormattedCalls(new Calls(currCalls));
        return currCalls;
      });
    },
    [setCalls, setFormattedCalls],
  );

  const startDeviceCall = useCallback(
    async ({ to, from, data }) => {
      if (activeCall && !callState.invited) {
        await holdAndAccept();
      }
      let call;
      const params = { to, To: to, from, from };
      const newDevice = new Device(token, TWILIO_VOICE_OPTIONS);

      const onAccept = (acceptedCall) => {
        console.log("[call:CallAccepted]", acceptedCall);
        addCall(acceptedCall, "accepted", { ...data, direction: "OUTGOING" });
        setActiveCall(acceptedCall);
        updateCallStatus(acceptedCall, { type: "CONNECTED" });
      };
      const onReject = (rejectedCall) => {
        console.log("[call:CallRejected]", rejectedCall);
        activeCallCleanUp(rejectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        newDevice.destroy();
      };
      const onCancel = (rejectedCall) => {
        console.log("[call:CallCancel]", rejectedCall);
        rejectedCall.disconnect();
        activeCallCleanUp(rejectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        newDevice.destroy();
      };
      const onError = (rejectedCall) => {
        console.log("[call:CallError]", rejectedCall);
        activeCallCleanUp(rejectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        newDevice.destroy();
      };
      const onDisconnect = (disconnectedCall) => {
        console.log("[call:CallDisconnected]", disconnectedCall);
        disconnectedCall.removeAllListeners();
        disconnectedCall.disconnect();
        activeCallCleanUp(disconnectedCall);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        newDevice.destroy();
      };
      const onMute = () => {
        console.log("[call:CallMuted]");
      };

      call = await Twilio.startCall(
        params,
        iceServers,
        newDevice,
        onMute,
        onAccept,
        onReject,
        onCancel,
        onError,
        onDisconnect,
      );

      addCall(call, "accepted", { direction: "OUTGOING" });
      setActiveCall(call);
      updateCallStatus(call, { type: "RING" });
    },
    [
      activeCall,
      callState,
      addCall,
      setActiveCall,
      updateCallStatus,
      token,
      iceServers,
      device,
      holdAndAccept,
      removeCall,
      activeCallCleanUp,
    ],
  );

  const endDeviceCall = useCallback(
    async (callSid) => {
      if (!device.current && !ready) {
        console.warn("Attempting to end call with no device or not ready");
      }
      if (!callSid) {
        console.warn("Attempting to end call with no callSid");
        if (callState?.invited && !callState.success) {
          activeCall?.reject();
        } else {
          activeCall?.disconnect();
        }
        return;
      }
      const existingCall = formattedCalls.findCallByCallSid(callSid);
      if (
        existingCall &&
        existingCall.callState.invited &&
        !existingCall.callState.success
      ) {
        existingCall.call.reject();
      } else {
        if (existingCall?.hold) {
          await Engage.endCallOnHold({
            call_sid: callSid,
            account_id: user?.account?.id,
          });
          existingCall.call?.disconnect();
        } else {
          existingCall.call?.disconnect();
        }
      }
    },
    [ready, formattedCalls, user?.account?.id, activeCall, callState],
  );

  const acceptCall = async (callSid) => {
    if (!device.current && !ready) {
      console.warn("Attempting to end call with no device or not ready");
    }
    if (!callSid) {
      console.warn("Attempting to accept call with no callSid ");
    }
    const existingCall = formattedCalls.findCallByCallSid(callSid);
    if (!existingCall) return;
    if (existingCall.state === "accepted") {
      return;
    }
    if (existingCall && existingCall.state === "invited") {
      if (!isActiveCallOnHold) {
        await holdAndAccept();
      }
      const device = new Device(token, TWILIO_VOICE_OPTIONS);
      const { connectToken } = existingCall.call;
      const call = await device.connect({ connectToken });
      const onAccept = (acceptedCall) => {
        setTimeout(() => {
          console.log("[incoming_call:CallAccepted]", acceptedCall);
          addCall(acceptedCall, "accepted", { direction: "INCOMING" });
          updateCallStatus(acceptedCall, { type: "CONNECTED" });
          setActiveCall(acceptedCall);
        }, 200);
      };
      const onDisconnect = (disconnectedCall) => {
        console.log("[incoming call:CallDisconnected]", disconnectedCall);
        if (disconnectedCall) {
          disconnectedCall.removeAllListeners();
          disconnectedCall.disconnect();
        }
        activeCallCleanUp(call);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        device?.destroy();
      };
      const onReject = (rejectedCall) => {
        console.log("[incoming call:CallRejected]", rejectedCall);
        activeCallCleanUp(call);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        device?.destroy();
      };
      const onCancel = (rejectedCall) => {
        console.log("[incoming call:CallCancel]", rejectedCall);
        activeCallCleanUp(call);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        device?.destroy();
      };
      const onError = (rejectedCall) => {
        console.log("[incoming call:CallError]", rejectedCall);
        activeCallCleanUp(call);
        removeCall(call);
        updateCallStatus(call, { type: "DISCONNECTED" });
        device?.destroy();
      };
      Twilio.addCallListeners(
        call,
        () => {},
        onAccept,
        onReject,
        onCancel,
        onError,
        onDisconnect,
      );
    } else {
      console.warn(
        "Attempting to accept call with no call Invite with callSid ",
        callSid,
      );
    }
  };

  const rejectCallInvite = useCallback(
    (callSid, ignore = false) => {
      if (!device.current && !ready) {
        console.warn("Attempting to end call with no device or not ready");
      }
      if (!callSid) {
        console.warn("Attempting to reject call with no callSid ");
      }
      try {
        setCalls((calls) => {
          let newCalls = calls;
          const call = calls.find((c) => c.callSid === callSid);
          if (call) {
            if (ignore) {
              call?.call?.silent();
              call.ignored = true;
            } else {
              call?.call?.reject();
            }
          } else {
            console.warn(
              "Attempting to reject call with no call Invite with callSid ",
              callSid,
            );
          }
          const formattedCalls = new Calls(newCalls);
          setFormattedCalls(formattedCalls);
          return newCalls;
        });
      } catch (e) {
        console.error(e);
      }
    },
    [ready, setCalls, setFormattedCalls],
  );

  const getCallData = useCallback(
    (callSid) => {
      return formattedCalls.getCallDataBySid(callSid);
    },
    [formattedCalls],
  );

  const emitCallEvent = useCallback(
    ({ parameters, event }) => {
      const call_sid = parameters?.CallSid;
      const webappPostMessageEvent = new WebAppPostMessage(CALL_EVENT_TYPE);
      webappPostMessageEvent.command = CALL_STATUS_COMMAND;
      if (event === "ended") {
        webappPostMessageEvent.data = { call_sid, status: event };
      } else {
        const formattedCalls = new Calls(calls);
        const callData = formattedCalls.getCallDataBySid(call_sid);
        if (!callData) return;
        else {
          webappPostMessageEvent.data = { ...callData, status: event };
        }
      }
      if (!webappPostMessageEvent.data) return;
      webappPostMessageEvent.emitEvent();
    },
    [Object.values(calls)],
  );

  const holdAndAccept = async () => {
    if (!activeCall || isActiveCallOnHold) {
      return;
    }
    const didHold = await toggleHold(true);
    if (didHold) {
      return;
    } else {
      window.alert("Failed to hold active call");
    }
  };

  const holdActiveAndUnHold = useCallback(
    async (unHoldCallSid) => {
      if (!activeCall || isActiveCallOnHold) {
        await toggleHold(false, unHoldCallSid);
        return;
      }
      const didHold = await toggleHold(true, activeCall?.parameters?.CallSid);
      if (didHold) {
        await toggleHold(false, unHoldCallSid);
      }
    },
    [activeCall, isActiveCallOnHold, toggleHold],
  );

  useEffect(() => {
    const num = `${activeNumber?.id}`;
    return () => {
      if (num === activeNumber?.id) {
        setToken(null);
        setIceServers([]);
      }
    };
  }, [activeNumber?.id, setToken, setIceServers]);

  const toggleMute = useCallback(
    (mute) => {
      if (activeCall) {
        activeCall.mute(mute);
        setCalls((calls) => {
          let currCalls = calls;
          let existingCall = currCalls.find(
            (c) => c.callSid === activeCall.parameters.CallSid,
          );
          if (existingCall) {
            existingCall.muted = mute;
          } else {
          }
          setFormattedCalls(new Calls(currCalls));
          return currCalls;
        });
      } else {
        console.error("Cannot mute call without an active call");
      }
    },
    [activeCall, setCalls, setFormattedCalls],
  );

  useEffect(() => {
    if (ready && token && !device.current) {
      const newDevice = new Device(token, TWILIO_VOICE_OPTIONS);
      device.current = newDevice;

      const onIncoming = (call) => {
        console.log("[useTwilioInit:CallInvite]", call);
        addCall(call, "invited", { direction: "INCOMING" });
        const onAccept = (acceptedCall) => {
          console.log("[incoming_call:CallAccepted]", acceptedCall);
          addCall(acceptedCall, "accepted", { direction: "INCOMING" });
          updateCallStatus(acceptedCall, { type: "CONNECTED" });
          setActiveCall(acceptedCall);
        };

        const onDisconnect = (disconnectedCall) => {
          console.log("[incoming call:CallDisconnected]", disconnectedCall);
          if (disconnectedCall) {
            disconnectedCall.removeAllListeners();
            disconnectedCall.disconnect();
          }
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        const onReject = (rejectedCall) => {
          console.log("[incoming call:CallRejected]", rejectedCall);
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        const onCancel = (rejectedCall) => {
          console.log("[incoming call:CallCancel]", rejectedCall);
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        const onError = (rejectedCall) => {
          console.log("[incoming call:CallError]", rejectedCall);
          activeCallCleanUp(call);
          removeCall(call);
          updateCallStatus(call, { type: "DISCONNECTED" });
        };

        Twilio.addCallListeners(
          call,
          () => {},
          onAccept,
          onReject,
          onCancel,
          onError,
          onDisconnect,
        );
        updateCallStatus(call, { type: "INVITED" });
      };

      const onUnregister = (e) => {
        console.log("[useTwilioInit:Unregistered]", e);
        setCalls([]);
        const formattedCalls = new Calls([]);
        setFormattedCalls(formattedCalls);
        dispatch({ type: "FAIL" });
      };

      const onError = (e, callInstance) => {
        console.error("[useTwilioInit:Error]", e, callInstance);
        if (callInstance) {
          callInstance.removeAllListeners();
          callInstance.disconnect();
        }
        activeCallCleanUp(callInstance);
        removeCall(callInstance);
      };

      Twilio.startListeningDeviceEvents({
        device: device.current,
        onIncoming,
        onError,
        onUnregister,
        getAccessToken,
      });
    }

    return () => {
      if (device.current) {
        device.current.removeAllListeners();

        device.current?.destroy();
        device.current = null;
      }
    };
  }, [ready, token]);

  return {
    ready,
    device: device.current,
    dispatch: null,
    activeCall,
    startDeviceCall,
    endDeviceCall,
    callState,
    token,
    callStatus,
    callInvites,
    acceptedCalls,
    acceptCall,
    rejectCallInvite,
    getCallData,
    ignoredCalls,
    callsOnHold,
    toggleHold,
    holding,
    isActiveCallOnHold,
    holdAndAccept,
    holdActiveAndUnHold,
    toggleMute,
    isActiveCallMuted,
    activeCallData,
  };
}
