import React, { useContext } from "react";
import { StyleSheet, View, Dimensions } from "react-native";
import StepWizard from "react-step-wizard";

import Modal from "../Modal";
import palette from "../../styles/palette";
import Row from "../../layouts/Row";
import Col from "../../layouts/Col";
import Card from "../../components/Card";

import AcquirePhoneNumberStep from "../../screens/OnboardingScreen/AcquirePhoneNumberStep/index.web";
import SelectAreaCodeStep from "../../screens/OnboardingScreen/SelectAreaCodeStep/index.web";
import OnboardingContext, {
  OnboardingProvider,
} from "../../contexts/OnboardingContext";
import SelectCountryStep from "../../screens/OnboardingScreen/SelectCountryStep/index.web";
import UserContext from "../../contexts/UserContext";

export default function NumberPurchaseModal() {
  return (
    <Modal title="Purchase Number">
      <OnboardingProvider>
        <View
          style={[
            styles.onboarding_screen__container,
            { height: Dimensions.get("window").height },
          ]}
        >
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              height: "100%",
            }}
          >
            <Col center size={6} style={{ minWidth: 420 }} maxWidth={420}>
              <Steps />
            </Col>
          </Row>
        </View>
      </OnboardingProvider>
    </Modal>
  );
}

const Steps = () => {
  const { user } = useContext(UserContext);
  const country = user?.account?.country;
  return (
    <StepWizard>
      <SelectCountryStep />
      {country !== "AU" && <SelectAreaCodeStep />}
      <AcquirePhoneNumberStep />
    </StepWizard>
  );
};

const styles = StyleSheet.create({
  onboarding_screen__container: {
    // backgroundColor: palette.light_grey,
    flex: 1,
  },
});
