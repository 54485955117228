import Engage from "../services/engage.js";
import FieldPulse from "../services/fieldpulse.js";
import { Model } from "./base.js";

export class Contact extends Model {
  id;
  firstName;
  lastName;
  phone;
  phoneE164;
  email;
  remoteId;
  parentId;
  customerId;
  constructor(attributes = {}) {
    super(attributes);
    this.id = attributes.id;
    this.firstName = attributes.first_name || "";
    this.lastName = attributes.last_name || "";
    this.phone = attributes.phone || attributes.phone_number;
    this.phoneE164 = attributes.phone_e164;
    this.email = attributes.email;
    this.remoteId = attributes.remote_id;
    this.parentId = attributes.parent_id;
    this.customerId = attributes.customer_id;
  }

  static async deleteContact(id) {
    return this.FPApiRequest("deleteContact", id);
  }

  static async updateContactInFP(contact) {
    const params = this.getParams(contact);
    const res = await FieldPulse.updateContact(params);
    return res;
  }

  static async updateContactInEngage(contact) {
    const params = this.getParams(contact, true);
    const res = await Engage.updateParticipant(contact?.id, params);
    return res;
  }

  static async getContact(id) {
    return this.FPApiRequest("getContact", id);
  }

  static async createContactInFP(contact) {
    const params = this.getParams(contact);
    const res = await FieldPulse.addContactToCustomer(params);
    return res;
  }

  static async createContactInEngage(contact) {
    const params = this.getParams(contact, true);
    if (!params?.parent_id) return;
    const res = await Engage.createParticipant(params);
    return res;
  }

  static getParams(contact, isEngage = false) {
    const contactFromParams =
      typeof contact === Contact ? contact : new Contact(contact);

    const params = {
      first_name: contactFromParams.firstName,
      last_name: contactFromParams.lastName,
      phone: contactFromParams.phone,
      phone_e164: contactFromParams.phoneE164 || contactFromParams?.phone,
      email: contactFromParams.email,
      customer_id: contactFromParams.customerId,
      id: contactFromParams.remoteId,
    };

    if (isEngage) {
      const engageParams = {
        ...params,
        id: contactFromParams.id,
        parent_id: contactFromParams.parentId,
        phone_number: contactFromParams.phoneE164 || contactFromParams?.phone,
        remote_id: contactFromParams.remoteId,
      };

      delete engageParams.customer_id;
      delete engageParams.phone;

      if (!engageParams.id) delete engageParams.id;

      return engageParams;
    }

    if (!params.id) delete params.id;

    return params;
  }

  getName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getDropdownParams() {
    return {
      label: this.getName(),
      value: this.phoneE164 || this.phone,
      phone: this.phoneE164 || this.phone,
    };
  }

  static createContactFromCustomer(customer, parent_id = null) {
    let data = customer;
    const remote_id = data.id;
    delete data.id;
    return new Contact({ ...data, remote_id, parent_id });
  }

  static createContactFromParticipant(participant) {
    let data = participant;
    if (!participant.is_child) {
      console.log("Participant is not a child");
      return null;
    }
    data.parent_id = participant.parent?.id;
    return new Contact(data);
  }

  static async getContact(id) {
    const res = await FieldPulse.getContact(id);
    return res;
  }

  static async getContactParent(id) {
    const res = await FieldPulse.getCustomer(id);
    return res;
  }
}
