export const ALL_INTERACTIONS_FILTER = "all";
export const CALLS_INTERACTIONS_FILTER = "calls";
export const MISSED_CALLS_INTERACTIONS_FILTER = "missed_calls";
export const INTERNAL_NOTE_INTERACTIONS_FILTER = "internal_note";
export const TEXT_MESSAGE_INTERACTIONS_FILTER = "text_message";
export const VOICEMAIL_INTERACTIONS_FILTER = "voicemail";
export const RECORDING_INTERACTIONS_FILTER = "recording";
export const EMAIL_FILTER = "email";

export const INTERACTIONS_FILTERS = {
  [ALL_INTERACTIONS_FILTER]: "All Communication Types",
  [TEXT_MESSAGE_INTERACTIONS_FILTER]: "Messages",
  [CALLS_INTERACTIONS_FILTER]: "Calls",
  [VOICEMAIL_INTERACTIONS_FILTER]: "Voicemails",
  [RECORDING_INTERACTIONS_FILTER]: "Recordings",
  [INTERNAL_NOTE_INTERACTIONS_FILTER]: "Internal Notes",
  [EMAIL_FILTER]: "Email History",
};

export const CONVERSATION_UPDATE_CHANNEL = "conversation-updated";

export const TWILIO_CALL_INTERACTION = "TwilioCall";
