import { useContext, useCallback, useState, useEffect } from "react";
import NumbersContext from "../contexts/NumbersContext";
import { usePusher } from "@harelpls/use-pusher";

export default function useNumbersSubscription(event, handler) {
  const { numbers } = useContext(NumbersContext);
  const { client } = usePusher();
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    const subs = [];
    numbers.forEach((num) => {
      if (!client) return;
      let channel = client.subscribe(`private-number-${num.id}`);

      subs.push(channel);
    });
    setChannels(subs);
    return () => {
      channels.forEach((c) => {
        client.unsubscribe(c.name);
      });
      setChannels([]);
    };
  }, [numbers]);

  useEffect(() => {
    channels.map((c) => {
      c.bind(event, handler);
    });
    return () => {
      channels.map((c) => {
        c.unbind(event, handler);
      });
    };
  }, [channels, event, handler]);

  useEffect(() => {
    return () => {
      try {
        if (client) client.disconnect();
      } catch {}
    };
  }, [client]);

  return [channels, client?.connection?.socket_id];
}
