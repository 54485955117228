import React, { createContext, useState, useEffect, useCallback } from "react";
import useKeyboard from "../hooks/useKeyboard";

const AppContext = createContext({
  compactMode: false,
  loggedIn: false,
});

export function AppProvider({ launchContext, children }) {
  const [context, setContext] = useState({
    compactMode: false,
    lastConversationId: null,
  });
  const [googleLibraryLoaded, setGoogleLibraryLoaded] = useState(false);

  const {
    visible: keyboardVisible,
    height: keyboardHeight,
    hideKeyboard,
  } = useKeyboard();

  const setAppContext = useCallback(
    (ctx) => setContext({ ...context, ...ctx }),
    [context, setContext],
  );

  return (
    <AppContext.Provider
      value={{
        ...context,
        ...launchContext,
        keyboardVisible,
        keyboardHeight,
        hideKeyboard,
        setAppContext,
        googleLibraryLoaded,
        setGoogleLibraryLoaded,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;
