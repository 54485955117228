import { useEffect, useState } from "react";
import FieldPulse from "../services/fieldpulse";

function getCache(key) {
  return window.localStorage.getItem(key);
}

export const useLocalStorageChange = (onChange, onLogout, loading = false) => {
  const [value, setValue] = useState(getCache("token"));
  const [userState, setUserState] = useState(
    JSON.parse(getCache("ENGAGE_USER")),
  );

  useEffect(() => {
    const handleStorageChange = () => {
      if (!loading) {
        try {
          const currentValue = getCache("token");
          const user = JSON.parse(getCache("ENGAGE_USER"));
          if (currentValue !== value || user?.id !== userState?.id) {
            setValue(() => currentValue);
            setUserState(() => user);
            const hasTokenValue = !!currentValue;
            const hadUserValue = !!userState;
            if (hasTokenValue && !!user) {
              FieldPulse.token(currentValue);
              onChange();
            } else if (!hasTokenValue && hadUserValue) {
              onLogout();
            }
          }
        } catch {}
      }
    };

    window.addEventListener("localStorageChange", handleStorageChange);
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("localStorageChange", handleStorageChange);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [value, onChange, onLogout, userState, loading]);
};
