import { forwardRef, useContext, useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { StyleSheet, ScrollView } from "react-native";
import {
  ActivityIndicator,
  Switch,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
} from "react-native-web";
import Heading from "../../components/Heading/index.web";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import StyledInput from "../../components/StyledInput";
import SolidButton from "../../components/Buttons/SolidButton";
import StyledPicker from "../../components/StyledPicker";
import { Field } from "../../components/PhoneTree/PhoneTreeForm/index.web";
import CustomRecordingsContext from "../../contexts/CustomRecordingsContext";
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import IconButton from "../../components/Buttons/IconButton";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { WORKING_HOURS_GREETING_TYPES } from "../../constants/mediaTypes";
import { getTimezones } from "../../utils/daylightSaving";
import {
  ALL_WORKING_HOURS,
  DAYS,
  DAYS_ENUM,
  HOURS,
  WORKING_HOURS,
} from "../../constants/workingHours";
import WorkingHoursContext from "../../contexts/WorkingHoursContext";
import InfoIconButton from "../../components/InfoIconButton/index.web";
import { Strings } from "../../constants/strings";
import commonStyles from "../../styles/common";

const CELL_HEIGHT = 75;
const CELL_WIDTH = 120; // 60 * 2
const LEFT_OFFSET = 120;

export default function WorkingHoursScreen() {
  const {
    saveWorkingHours,
    isFormInvalid,
    saving,
    setAudioId,
    activeAudio,
    isWorkingHoursEnabled,
    setIsWorkingHoursEnabled,
    setAllHoursAsWorking,
  } = useContext(WorkingHoursContext);

  const { customRecordings, getCustomRecordings, loading } = useContext(
    CustomRecordingsContext,
  );

  useEffect(() => {
    getCustomRecordings();
  }, []);

  useEffect(() => {
    if (
      customRecordings.length > 0 &&
      activeAudio !== null &&
      activeAudio !== "-1"
    ) {
      let record = customRecordings?.find(
        (record) => record.url === activeAudio,
      );
      if (record && record.id) setAudioId(record.id);
    }
  }, [customRecordings, activeAudio]);

  return (
    <Col>
      <Row
        pl={16}
        pr={16}
        style={{
          height: 65,
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
        }}
      >
        <Col leftCenter>
          <Row leftCenter style={commonStyles.alignCenter}>
            <Heading size={5} pb={8} mb={0}>
              Working Hours
            </Heading>
            <InfoIconButton link={Strings.WORKING_HOURS_DOCS_URL} />
            <Col leftCenter>
              <Switch
                value={isWorkingHoursEnabled}
                onValueChange={(value) => {
                  setIsWorkingHoursEnabled(value);
                  setAllHoursAsWorking();
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col rightCenter>
          <SolidButton
            label="Save"
            onPress={saveWorkingHours}
            disabled={isFormInvalid}
            loading={saving}
          />
        </Col>
      </Row>
      {isWorkingHoursEnabled && (
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{ marginBottom: 24 }}
        >
          <SettingsTab />
          <WorkingHours />
        </ScrollView>
      )}
      {(saving || loading) && (
        <View
          style={{
            zIndex: 1000,
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0,0.2)",
            top: 65,
          }}
        >
          <View
            style={{
              width: 100,
              height: 100,
              backgroundColor: palette.white,
              borderRadius: 4,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size="large" />
          </View>
        </View>
      )}
    </Col>
  );
}

function SettingsTab() {
  const timezones = getTimezones();

  const {
    timeZone,
    setTimeZone,
    activeAudio,
    setActiveAudio,
    redirectTo,
    setRedirectTo,
    greetingType,
    setGreetingType,
    workingHoursGreeting,
    setWorkingHoursGreeting,
  } = useContext(WorkingHoursContext);

  const { customRecordings, loading } = useContext(CustomRecordingsContext);
  const greetingInputRef = useRef(null);

  const handleGreetingTypeChange = (type) => () => {
    if (
      type === WORKING_HOURS_GREETING_TYPES.TEXT ||
      type === WORKING_HOURS_GREETING_TYPES.FORWARD_TO
    ) {
      greetingInputRef?.current?.focus();
    }
    setGreetingType(type);
  };

  const getButtonColor = (type) => {
    return greetingType === type ? palette.primary : palette.white;
  };

  return (
    <Row pl={16} pr={16}>
      <Col size={8}>
        <Field label="Time Zone">
          <StyledPicker
            options={timezones}
            selectedValue={parseFloat(timeZone)}
            onValueChange={setTimeZone}
            border
          />
        </Field>
        <Row mt={16}>
          <SolidButton
            label="Text to Speech"
            style={{ marginRight: 16 }}
            color={getButtonColor(WORKING_HOURS_GREETING_TYPES.TEXT)}
            onPress={handleGreetingTypeChange(
              WORKING_HOURS_GREETING_TYPES.TEXT,
            )}
          />
          <SolidButton
            label="Redirect To"
            style={{ marginRight: 16 }}
            color={getButtonColor(WORKING_HOURS_GREETING_TYPES.FORWARD_TO)}
            onPress={handleGreetingTypeChange(
              WORKING_HOURS_GREETING_TYPES.FORWARD_TO,
            )}
          />
          <SolidButton
            label="Audio Recording"
            color={getButtonColor(WORKING_HOURS_GREETING_TYPES.AUDIO)}
            onPress={handleGreetingTypeChange(
              WORKING_HOURS_GREETING_TYPES.AUDIO,
            )}
          />
        </Row>
        <Row mt={8}>
          {greetingType === WORKING_HOURS_GREETING_TYPES.AUDIO ? (
            <Field label="Audio Sample" subtext="Choose from Audio Samples">
              {loading && (
                <View
                  style={{
                    backgroundColor: palette.bg_light_grey,
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 4,
                    borderWidth: StyleSheet.hairlineWidth,
                    borderColor: palette.light_grey,
                  }}
                >
                  <Heading size={5} mb={0} numberOfLines={null}>
                    Loading Audio Samples
                  </Heading>
                </View>
              )}
              {!loading &&
                customRecordings &&
                (customRecordings.length === 0 ||
                  (customRecordings.length > 0 &&
                    customRecordings.filter((audio) => audio.url !== null)
                      .length === 0)) && (
                  <Heading size={5} mb={0} numberOfLines={null}>
                    You don't have any audio samples. You can add them{" "}
                    <Link
                      to={"/settings/voicenotes"}
                      style={{
                        color: palette.primary_light,
                        fontWeight: "600",
                        fontFamily: "OpenSans_600SemiBold",
                        textDecorationStyle: "none",
                      }}
                    >
                      <Text>here</Text>
                    </Link>
                    .
                  </Heading>
                )}
              {!loading &&
                customRecordings &&
                customRecordings.length > 0 &&
                customRecordings.filter((audio) => audio.url !== null).length >
                  0 && (
                  <Col>
                    <Row>
                      <StyledPicker
                        options={[
                          {
                            name: "Select Audio",
                            value: "-1",
                          },
                          ...customRecordings
                            ?.filter((audio) => audio.url !== null)
                            .map((recording) => ({
                              name: recording.title,
                              value: recording.url,
                            })),
                        ]}
                        selectedValue={activeAudio}
                        onValueChange={(val) => {
                          setActiveAudio(val);
                        }}
                      />
                      {activeAudio !== "-1" && activeAudio !== null && (
                        <Col ml={16}>
                          <ReactAudioPlayer src={activeAudio} controls />
                        </Col>
                      )}
                    </Row>
                  </Col>
                )}
            </Field>
          ) : (
            <Field
              label={
                greetingType === WORKING_HOURS_GREETING_TYPES.FORWARD_TO
                  ? "Redirect To"
                  : "Working Hours Greeting"
              }
              style={[
                {
                  width: "100%",
                  marginBottom: 10,
                },
              ]}
              subtext={
                greetingType === WORKING_HOURS_GREETING_TYPES.FORWARD_TO
                  ? "This should not be any of your Engage numbers."
                  : "This greeting message will be played on calls that come out of working hours."
              }
            >
              <TextInput
                value={
                  greetingType === WORKING_HOURS_GREETING_TYPES.FORWARD_TO
                    ? redirectTo || ""
                    : workingHoursGreeting || ""
                }
                placeholder={
                  greetingType === WORKING_HOURS_GREETING_TYPES.FORWARD_TO
                    ? "(phone number)"
                    : ""
                }
                ref={greetingInputRef}
                autoFocus
                style={[
                  {
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderColor: palette.light_grey,
                    borderWidth: 1,
                    paddingVertical: 12,
                    paddingHorizontal: 12,
                    fontSize: 16,
                    fontWeight: "500",
                    borderRadius: 4,
                  },
                ]}
                onChangeText={(val) => {
                  greetingType === WORKING_HOURS_GREETING_TYPES.FORWARD_TO
                    ? setRedirectTo(
                        val?.replace(
                          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/g,
                          "",
                        ),
                      )
                    : setWorkingHoursGreeting(val);
                }}
              />
            </Field>
          )}
        </Row>
      </Col>
    </Row>
  );
}

function WorkingHours() {
  const jobPaneHorizontalRef = useRef();
  const jobPaneVerticalRef = useRef();
  const timePaneRef = useRef();
  const daysPaneRef = useRef();
  const [horizontalScrollSource, setHorizontalScrollSource] = useState();
  const [verticalScrollSource, setVerticalScrollSource] = useState();

  const {
    workingHours,
    active,
    setActive,
    isInvalid,
    setIsInvalid,
    startTime,
    setStartTime,
    finishTime,
    setFinishTime,
    addHour,
    isOverlapping,
    deleteHour,
    clearWorkingHours,
    message,
    setAllHoursAsWorking,
  } = useContext(WorkingHoursContext);

  const [popupVisible, setPopupVisible] = useState(false);
  const [formattedStart, setFormattedStart] = useState({});
  const [formattedEnd, setFormattedEnd] = useState({});
  const [currentX, setCurrentX] = useState();

  const areWorkingHoursEmpty = useMemo(() => {
    return JSON.stringify(workingHours) === JSON.stringify(WORKING_HOURS);
  }, [workingHours]);

  const onHorizontalScroll = (event, source) => {
    if (horizontalScrollSource !== source) {
      return;
    }
    const { x } = event.nativeEvent.contentOffset;
    const options = { x, animated: false };
    if (source === "day") {
      jobPaneHorizontalRef.current?.scrollTo(options);
    } else if (source === "job") {
      daysPaneRef.current?.scrollTo(options);
    }
  };

  const onVerticalScroll = (event, source) => {
    if (verticalScrollSource !== source) {
      return;
    }
    const { y } = event.nativeEvent.contentOffset;
    const options = { y, animated: false };
    if (source === "time") {
      jobPaneVerticalRef.current?.scrollTo(options);
    } else if (source === "job") {
      timePaneRef.current?.scrollTo(options);
    }
  };

  const scrollToEarliest = () => {
    const earliest = getEarliestWorkingHour();
    const options = {
      x: CELL_WIDTH * earliest,
      animated: false,
    };
    setCurrentX(CELL_WIDTH * earliest);
    daysPaneRef.current?.scrollTo(options);
    jobPaneHorizontalRef.current?.scrollTo(options);
  };

  useEffect(() => {
    scrollToEarliest();
  }, []);

  const getEarliestWorkingHour = () => {
    let earliest = 24;
    let set = false;
    Object.values(workingHours).map((data) =>
      Object.values(data).map((hours) =>
        hours.map((hour) => {
          set = true;
          let e = parseInt(hour.start.slice(0, 2));
          earliest = Math.min(e, earliest);
        }),
      ),
    );

    if (!set) earliest = 8;

    return earliest;
  };

  useEffect(() => {
    if (Object.keys(active).length > 0) {
      setStartTime(active?.start || "0800");
      setFinishTime(active?.finish || "0900");
      let startHour = parseInt(active?.start.slice(0, 2));
      let endHour = parseInt(active?.finish.slice(0, 2));
      let startHalf = false;
      let endHalf = false;
      if (startHour >= 12 && startHour !== 24) {
        startHalf = true;
      }
      if (endHour >= 12 && endHour !== 24) {
        endHalf = true;
      }
      if (startHour === 0 || startHour === 24) {
        startHour = 12;
      }
      if (endHour === 0 || endHour === 24) {
        endHour = 12;
      }

      setFormattedStart({
        hour: startHour !== 12 ? startHour % 12 : startHour,
        minute: active?.start.slice(2, 4),
        am: startHalf,
      });
      setFormattedEnd({
        hour: endHour !== 12 ? endHour % 12 : endHour,
        minute: active?.finish.slice(2, 4),
        am: endHalf,
      });
    }
  }, [JSON.stringify(active)]);

  useEffect(() => {
    let startTime = "";
    if (formattedStart.am && formattedStart.hour !== 12) {
      startTime += "" + (formattedStart.hour + 12);
    } else if (!formattedStart.am && formattedStart.hour === 12) {
      startTime += "00";
    } else {
      startTime +=
        "" + formattedStart.hour > 9
          ? formattedStart.hour
          : "0" + formattedStart.hour;
    }
    startTime += "" + formattedStart.minute;
    let finishTime = "";
    if (formattedEnd.am && formattedEnd.hour !== 12) {
      finishTime += "" + (formattedEnd.hour + 12);
    } else if (!formattedEnd.am && formattedEnd.hour === 12) {
      finishTime += "24";
    } else {
      finishTime +=
        "" + formattedEnd.hour > 9
          ? formattedEnd.hour
          : "0" + formattedEnd.hour;
    }
    finishTime += "" + formattedEnd.minute;

    setStartTime(startTime);
    setFinishTime(finishTime);
  }, [JSON.stringify(formattedStart), JSON.stringify(formattedEnd)]);

  useEffect(() => {
    if (
      Object.keys(active).length &&
      startTime.length === 4 &&
      finishTime.length === 4
    ) {
      const isInvalid = isOverlapping(
        Object.keys(DAYS_ENUM).find((key) => DAYS_ENUM[key] === active.day),
        { start: startTime, finish: finishTime },
        active.editing,
      );
      setIsInvalid(isInvalid);
    }
  }, [startTime, finishTime, JSON.stringify(active), workingHours]);

  const customScroll = (direction) => {
    if (direction === "RIGHT") {
      daysPaneRef.current.scrollTo({
        x: currentX + 300 > 2400 ? 2400 : currentX + 300,
        animated: true,
      });
      jobPaneHorizontalRef.current.scrollTo({
        x: currentX + 300 > 2400 ? 2400 : currentX + 300,
        animated: true,
      });
      setCurrentX((x) => (x + 300 > 2400 ? 2400 : x + 300));
    } else {
      daysPaneRef.current.scrollTo({
        x: currentX - 300 < 0 ? 0 : currentX - 300,
        animated: true,
      });
      jobPaneHorizontalRef.current.scrollTo({
        x: currentX - 300 < 0 ? 0 : currentX - 300,
        animated: true,
      });
      setCurrentX((x) => (x - 300 < 0 ? 0 : x - 300));
    }
  };

  return (
    <>
      <Col
        style={{
          alignItems: "center",
        }}
      >
        <View
          style={{
            backgroundColor: palette.bg_light_grey,
            width: "98%",
            padding: 8,
            paddingLeft: 0,
            paddingBottom: 16,
            marginTop: 16,
            borderRadius: 4,
            overflow: "hidden",
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: areWorkingHoursEmpty
              ? palette.red
              : palette.light_grey,
          }}
        >
          {areWorkingHoursEmpty && (
            <View>
              <Heading size={6} center color={palette.red}>
                Working Hours cannot be empty, disable Working Hours if you
                don't want to set hours yet.
              </Heading>
            </View>
          )}
          <Row rightCenter style={{ gap: 8 }}>
            {JSON.stringify(workingHours) !==
              JSON.stringify(ALL_WORKING_HOURS) && (
              <SolidButton
                label="Reset to All Hours"
                color={palette.success}
                onPress={setAllHoursAsWorking}
              />
            )}
            <SolidButton
              label="Clear Hours"
              color={palette.red}
              onPress={() => {
                setPopupVisible(true);
              }}
              disabled={
                JSON.stringify(workingHours) === JSON.stringify(WORKING_HOURS)
              }
            />
          </Row>
          <HoursPane
            ref={daysPaneRef}
            onScroll={(event) => onHorizontalScroll(event, "day")}
            onScrollBeginDrag={() => setHorizontalScrollSource("day")}
            onScrollAnimationEnd={() => setHorizontalScrollSource(null)}
            onPressRight={() => customScroll("RIGHT")}
            onPressLeft={() => customScroll("LEFT")}
            currentScroll={currentX}
          />
          <Row>
            <DaysPane
              ref={timePaneRef}
              onScroll={(event) => onVerticalScroll(event, "time")}
              onScrollBeginDrag={() => setVerticalScrollSource("time")}
              onScrollAnimationEnd={() => setVerticalScrollSource(null)}
            />
            <CellsPane
              ref={{
                horizontalRef: jobPaneHorizontalRef,
                verticalRef: jobPaneVerticalRef,
              }}
              onHorizontalScroll={(event) => onHorizontalScroll(event, "job")}
              onHorizontalScrollBeginDrag={() =>
                setHorizontalScrollSource("job")
              }
              onHorizontalScrollAnimationEnd={() =>
                setHorizontalScrollSource(null)
              }
              workingHours={workingHours}
              onVerticalScroll={(event) => onVerticalScroll(event, "job")}
              onVerticalScrollBeginDrag={() => setVerticalScrollSource("job")}
              onVerticalScrollAnimationEnd={() => setVerticalScrollSource(null)}
              setActive={setActive}
              active={active}
            />
          </Row>
        </View>
        {Object.keys(active).length > 0 && (
          <View
            style={{
              zIndex: 1000,
              position: "absolute",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0,0.2)",
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                borderRadius: 4,
              }}
            >
              <Col pl={16} pr={16} pt={16} pb={16}>
                <Row center>
                  <Heading size={3}>Working Hour</Heading>
                </Row>
                <Field label="Day">
                  <StyledPicker
                    options={DAYS.map((day, i) => ({ name: day, value: i }))}
                    selectedValue={active.day}
                    onValueChange={(value) =>
                      setActive((active) => ({
                        ...active,
                        day: parseInt(value),
                        prev:
                          !active.prev && active.prev !== 0
                            ? active.day
                            : active.prev,
                      }))
                    }
                  />
                </Field>
                {isInvalid && (
                  <Row center>
                    <Heading
                      size={6}
                      color={palette.danger}
                      textStyle={{ textAlign: "center" }}
                    >
                      {message}
                    </Heading>
                  </Row>
                )}
                <Row mt={16} style={{ gap: 16 }}>
                  <Col mr={16}>
                    <Heading size={5} color={palette.label_grey}>
                      Start Time
                    </Heading>
                  </Col>
                  <Col>
                    <Heading size={5} color={palette.label_grey}>
                      End Time
                    </Heading>
                  </Col>
                </Row>
                <Row style={{ gap: 16 }}>
                  <Col center>
                    <Row center>
                      <StyledPicker
                        options={[
                          ...HOURS.slice(1, HOURS.length / 2 + 1).map(
                            (hour) => ({
                              name: hour,
                              value: parseInt(hour),
                            }),
                          ),
                        ]}
                        nativeID="working_hours_start_hour"
                        selectedValue={formattedStart?.hour}
                        onValueChange={(val) =>
                          setFormattedStart((o) => ({
                            ...o,
                            hour: parseInt(val),
                          }))
                        }
                      />
                      <Text style={{ fontSize: 24 }}> : </Text>
                      <StyledInput
                        placeholder="mm"
                        value={formattedStart?.minute}
                        onChangeText={(val) => {
                          let parsedVal;
                          try {
                            parsedVal = parseInt(val);
                          } catch (e) {}
                          if (
                            (!parsedVal || parsedVal > 59) &&
                            parsedVal !== 0
                          ) {
                            parsedVal = 0;
                          }
                          setFormattedStart((o) => ({
                            ...o,
                            minute:
                              val?.length === 2
                                ? parsedVal > 9
                                  ? parsedVal
                                  : "0" + parsedVal
                                : val,
                          }));
                        }}
                        nativeID="working_hours_start_minutes"
                        maxLength={2}
                        inputMode="numeric"
                        containerStyle={{ width: 50, marginRight: 8 }}
                        style={{ height: 50 }}
                      />
                      <StyledPicker
                        options={[
                          { name: "AM", value: 0 },
                          { name: "PM", value: 1 },
                        ]}
                        selectedValue={formattedStart?.am ? 1 : 0}
                        onValueChange={(val) =>
                          setFormattedStart((o) => ({
                            ...o,
                            am: parseInt(val),
                          }))
                        }
                        nativeID="working_hours_start_period"
                      />
                    </Row>
                  </Col>
                  <Col center>
                    <Row center>
                      <StyledPicker
                        options={[
                          ...HOURS.slice(1, HOURS.length / 2 + 1).map(
                            (hour) => ({
                              name: hour,
                              value: parseInt(hour),
                            }),
                          ),
                        ]}
                        selectedValue={formattedEnd?.hour}
                        onValueChange={(val) =>
                          setFormattedEnd((o) => ({
                            ...o,
                            hour: parseInt(val),
                          }))
                        }
                        nativeID="working_hours_end_hour"
                      />
                      <Text style={{ fontSize: 24 }}> : </Text>
                      <StyledInput
                        placeholder="mm"
                        maxLength={2}
                        inputMode="numeric"
                        style={{ height: 50 }}
                        containerStyle={{ width: 50, marginRight: 8 }}
                        value={formattedEnd?.minute}
                        onChangeText={(val) =>
                          setFormattedEnd((o) => ({
                            ...o,
                            minute: val?.replace(/[^0-9 ()+-]+$/, ""),
                          }))
                        }
                        nativeID="working_hours_end_minutes"
                      />
                      <StyledPicker
                        options={[
                          { name: "AM", value: 0 },
                          { name: "PM", value: 1 },
                        ]}
                        selectedValue={formattedEnd?.am ? 1 : 0}
                        onValueChange={(val) =>
                          setFormattedEnd((o) => ({
                            ...o,
                            am: parseInt(val),
                          }))
                        }
                        nativeID="working_hours_end_period"
                      />
                    </Row>
                  </Col>
                </Row>
                <Row mt={16}>
                  <Col>
                    <SolidButton
                      label="Cancel"
                      color={palette.grey}
                      onPress={() => setActive({})}
                    />
                  </Col>
                  {active.editing && (
                    <Col ml={8}>
                      <SolidButton
                        label="Delete"
                        color={palette.danger}
                        onPress={deleteHour}
                      />
                    </Col>
                  )}
                  <Col ml={8}>
                    <SolidButton
                      label={active.editing ? "Save" : "Submit"}
                      onPress={addHour}
                      disabled={
                        isInvalid ||
                        startTime.length !== 4 ||
                        finishTime.length !== 4
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </View>
          </View>
        )}
      </Col>
      {popupVisible && (
        <View
          style={{
            zIndex: 1000,
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0,0.2)",
          }}
        >
          <View
            style={{
              backgroundColor: palette.white,
              borderRadius: 4,
              justifyContent: "center",
              alignItems: "center",
              padding: 16,
            }}
          >
            <Heading size={4}>
              This will clear your working hours.{"\n"}Do you want to continue?
            </Heading>
            <Row style={{ gap: 16 }} mt={16}>
              <Col>
                <SolidButton
                  label="No"
                  onPress={() => setPopupVisible(false)}
                />
              </Col>
              <Col>
                <SolidButton
                  label="Yes"
                  color={palette.danger}
                  onPress={() => {
                    clearWorkingHours();
                    setPopupVisible(false);
                  }}
                />
              </Col>
            </Row>
          </View>
        </View>
      )}
    </>
  );
}

const HoursPane = forwardRef(
  (
    {
      onScroll,
      onScrollBeginDrag,
      onScrollAnimationEnd,
      onPressLeft,
      onPressRight,
      currentScroll,
    },
    ref,
  ) => {
    return (
      <View
        style={{
          marginLeft: LEFT_OFFSET,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <IconButton
          faPro
          faProIcon={faChevronLeft}
          width={30}
          height={30}
          mr={0}
          ml={0}
          onPress={onPressLeft}
          disabled={currentScroll === 0}
        />
        <ScrollView
          ref={ref}
          showsHorizontalScrollIndicator={false}
          bounces={false}
          scrollEventThrottle={1}
          horizontal
          contentContainerStyle={{
            flexDirection: "row",
            backgroundColor: palette.bg_light_grey,
          }}
          onScroll={onScroll}
          onMouseEnter={onScrollBeginDrag}
          onMouseOver={onScrollBeginDrag}
          onMouseLeave={onScrollAnimationEnd}
        >
          {HOURS.map((hour, i) => (
            <View
              style={{
                width: i === 24 ? CELL_WIDTH / 2 : CELL_WIDTH,
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: 10,
                paddingLeft: CELL_WIDTH / 4 - 12,
              }}
              key={`${hour}-${i}`}
            >
              <Heading size={5}>{hour}</Heading>
              <Text
                style={{
                  fontSize: 12,
                  lineHeight: 18,
                  alignSelf: "flex-start",
                  marginLeft: 4,
                  color: palette.label_grey,
                }}
              >
                {i < 12 || i === 24 ? "AM" : "PM"}
              </Text>
            </View>
          ))}
        </ScrollView>
        <IconButton
          faPro
          faProIcon={faChevronRight}
          width={30}
          height={30}
          mr={0}
          ml={0}
          onPress={onPressRight}
          disabled={currentScroll === 2400}
        />
      </View>
    );
  },
);

const DaysPane = forwardRef(
  ({ onScroll, onScrollBeginDrag, onScrollAnimationEnd }, ref) => {
    return (
      <ScrollView
        ref={ref}
        showsHorizontalScrollIndicator={false}
        bounces={false}
        scrollEventThrottle={1}
        style={{
          maxWidth: LEFT_OFFSET,
          borderRightWidth: 1,
          borderColor: palette.light_grey,
        }}
        contentContainerStyle={{}}
        onScroll={onScroll}
        onScrollBeginDrag={onScrollBeginDrag}
        onScrollAnimationEnd={onScrollAnimationEnd}
        onMouseEnter={onScrollBeginDrag}
        onMouseLeave={onScrollAnimationEnd}
        onMouseOver={onScrollBeginDrag}
      >
        {DAYS.map((day, i) => (
          <View
            style={{
              height: CELL_HEIGHT,
              width: LEFT_OFFSET,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: palette.bg_light_grey,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: palette.lighter_grey,
              borderTopWidth: i === 0 ? StyleSheet.hairlineWidth : 0,
            }}
            key={`${day}-${i}`}
          >
            <Heading size={5}>{day}</Heading>
          </View>
        ))}
      </ScrollView>
    );
  },
);

const CellsPane = forwardRef(
  (
    {
      onHorizontalScroll,
      onHorizontalScrollBeginDrag,
      onHorizontalScrollAnimationEnd,
      onVerticalScroll,
      onVerticalScrollBeginDrag,
      onVerticalScrollAnimationEnd,
      setActive,
      active,
      workingHours,
    },
    ref,
  ) => {
    const { horizontalRef, verticalRef } = ref;

    const convertToPixels = (workingHour) => {
      const startHour = parseInt(workingHour.start.slice(0, 2));
      const startMinute = parseInt(workingHour.start.slice(2, 4));
      const endHour = parseInt(workingHour.finish.slice(0, 2));
      const endMinute = parseInt(workingHour.finish.slice(2, 4));
      const left = startHour * CELL_WIDTH + (CELL_WIDTH / 60) * startMinute;
      const right = endHour * CELL_WIDTH + (CELL_WIDTH / 60) * endMinute;
      const width = right - left;
      return { left, width };
    };

    return (
      <ScrollView
        style={{ flex: 1, maxHeight: CELL_HEIGHT * DAYS.length }}
        contentContainerStyle={{
          backgroundColor: palette.white,
        }}
        ref={verticalRef}
        showsVerticalScrollIndicator={false}
        bounces={false}
        scrollEventThrottle={1}
        onScroll={onVerticalScroll}
        onScrollBeginDrag={() => console.log("onScrollBeginDrag")}
        onScrollAnimationEnd={onVerticalScrollAnimationEnd}
        onMouseEnter={onVerticalScrollBeginDrag}
        onMouseLeave={onVerticalScrollAnimationEnd}
        onMouseOver={onVerticalScrollBeginDrag}
      >
        <ScrollView
          style={{ flex: 1 }}
          ref={horizontalRef}
          showsHorizontalScrollIndicator={false}
          bounces={false}
          scrollEventThrottle={1}
          onScroll={onHorizontalScroll}
          onScrollBeginDrag={() => console.log("onScrollBeginDrag")}
          onScrollAnimationEnd={onHorizontalScrollAnimationEnd}
          horizontal
          onMouseEnter={onHorizontalScrollBeginDrag}
          onMouseLeave={onHorizontalScrollAnimationEnd}
          onMouseOver={onHorizontalScrollBeginDrag}
          contentContainerStyle={{
            backgroundColor: palette.white,
          }}
        >
          <View
            style={{
              height: CELL_HEIGHT * DAYS.length,
              width: CELL_WIDTH * (HOURS.length - 1),
              marginHorizontal: LEFT_OFFSET / 2,
              position: "relative",
              backgroundColor: palette.white,
            }}
          >
            {HOURS.slice(0, -1).map((_, i) =>
              DAYS.map((_, j) => (
                <EmptyCell
                  xAxisIndex={i}
                  yAxisIndex={j}
                  key={`${i}-${j}`}
                  active={active}
                  setActive={setActive}
                />
              )),
            )}
            {workingHours.map((data) =>
              Object.keys(data).map((key) =>
                data[key].map((hour, i) => {
                  const dayIndex = DAYS_ENUM[key];
                  const { left, width } = convertToPixels(hour);
                  return (
                    <WorkingHourCell
                      left={left}
                      width={width}
                      top={dayIndex * CELL_HEIGHT}
                      key={`${key}-${left}-${width}`}
                      active={active}
                      setActive={setActive}
                      original={{ ...hour, day: dayIndex, hourIndex: i }}
                    />
                  );
                }),
              ),
            )}
          </View>
        </ScrollView>
      </ScrollView>
    );
  },
);

const EmptyCell = ({ yAxisIndex, xAxisIndex, setActive }) => {
  const [hovering, setHovering] = useState(false);
  const { workingHours } = useContext(WorkingHoursContext);

  const overlapping = useMemo(() => {
    let paddingLeft = 0;
    let paddingRight = 0;
    let left = 0;
    let right = 0;

    if (hovering) {
      const day = Object.keys(DAYS_ENUM)[yAxisIndex];
      const arr = cloneDeep(workingHours[yAxisIndex][day]);
      const start = xAxisIndex * 100;
      const finish = start + 60;

      arr.map((hour) => {
        let startTime = parseInt(hour.start);
        let endTime = parseInt(hour.finish);
        if (startTime > start && startTime < finish) {
          paddingRight = 60 - startTime + start;
          right = startTime - start;
        }
        if (endTime > start && endTime < finish) {
          paddingLeft = 60 - finish + endTime;
          left = 60 - finish + endTime;
        }
      });
    }
    return {
      paddingLeft,
      paddingRight,
      left,
      right,
    };
  }, [JSON.stringify(workingHours), hovering]);

  const top = useMemo(() => {
    return yAxisIndex === 0 ? 0 : CELL_HEIGHT * yAxisIndex;
  }, [yAxisIndex]);

  const height = useMemo(() => {
    return CELL_HEIGHT;
  }, []);

  const left = useMemo(() => {
    return CELL_WIDTH * xAxisIndex;
  }, [xAxisIndex]);

  const width = useMemo(() => {
    return CELL_WIDTH;
  }, []);

  return (
    <TouchableWithoutFeedback
      onPress={() =>
        setActive({
          start:
            overlapping.left > 0
              ? xAxisIndex < 10
                ? `0${xAxisIndex}${
                    overlapping.left < 10
                      ? "0" + overlapping.left
                      : overlapping.left
                  }`
                : `${xAxisIndex}${
                    overlapping.left < 10
                      ? "0" + overlapping.left
                      : overlapping.left
                  }`
              : xAxisIndex < 10
              ? `0${xAxisIndex}00`
              : `${xAxisIndex}00`,
          finish:
            overlapping.right > 0
              ? xAxisIndex < 9
                ? `0${xAxisIndex}${
                    overlapping.right < 10
                      ? "0" + overlapping.right
                      : overlapping.right
                  }`
                : `${xAxisIndex}${
                    overlapping.right < 10
                      ? "0" + overlapping.right
                      : overlapping.right
                  }`
              : xAxisIndex < 9
              ? `0${xAxisIndex + 1}00`
              : `${xAxisIndex + 1}00`,
          day: yAxisIndex,
        })
      }
    >
      <View
        style={{
          width: CELL_WIDTH,
          height: CELL_HEIGHT,
          borderColor: palette.light_grey,
          borderLeftWidth: xAxisIndex === 0 ? StyleSheet.hairlineWidth : 0,
          borderRightWidth: StyleSheet.hairlineWidth,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: palette.lighter_grey,
          borderStyle: "dashed",
          position: "absolute",
          backgroundColor: palette.white,
          top,
          height,
          left,
          width,
          cursor: "pointer",
        }}
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
      >
        {hovering && (
          <View
            style={[
              {
                margin: 5,
                borderRadius: 4,
                flex: 1,
                borderColor: palette.success,
                justifyContent: "center",
                alignItems: "center",
                borderWidth: StyleSheet.hairlineWidth * 2,
                borderStyle: "dashed",
                marginLeft: overlapping.paddingLeft * 2,
                marginRight: overlapping.paddingRight * 2,
              },
            ]}
          >
            <Heading size={3} color={palette.success}>
              +
            </Heading>
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

const WorkingHourCell = ({ top, left, width, active, setActive, original }) => {
  return (
    <TouchableWithoutFeedback
      onPress={() =>
        setActive({
          ...original,
          editing: true,
        })
      }
    >
      <View
        style={{
          height: CELL_HEIGHT,
          width,
          position: "absolute",
          top,
          zIndex: 100,
          left,
          paddingVertical: 4,
          cursor: "pointer",
        }}
      >
        <View
          style={{
            backgroundColor: "rgba(205, 180, 219,0.5)",
            borderWidth: StyleSheet.hairlineWidth * 2,
            borderColor: "rgb(205, 180, 219)",
            flex: 1,
            borderRadius: 4,
          }}
        ></View>
      </View>
    </TouchableWithoutFeedback>
  );
};
