import { parsePhoneNumber } from "libphonenumber-js";
import { INIT_CALL_STATE } from "../reducers/callReducer";
import { List } from "./base";
import { Call } from "./call";
export class Calls extends List {
  findCallByCallSid(callSid) {
    return this.models.find((call) => {
      return call?.callSid === callSid;
    });
  }

  callInvites() {
    return this.models.filter((call) => {
      return call?.state === "invited";
    });
  }

  acceptedCalls() {
    return this.models.filter((call) => {
      return call?.state === "accepted";
    });
  }

  callsOnHold() {
    return this.models.filter((call) => {
      return call?.hold && !call?.active;
    });
  }

  ignoredCalls() {
    return this.models.filter((call) => {
      return call?.ignored;
    });
  }

  get activeCall() {
    if (!this.models) return null;

    return this.models.find((call) => {
      return call?.active;
    })?.call;
  }

  get activeCallData() {
    if (!this.models) return null;

    return this.models.find((call) => {
      return call?.active;
    });
  }

  get activeCallSid() {
    if (this.activeCallData) return this.activeCallData?.callSid;
    return null;
  }

  get activeCallState() {
    if (this.activeCallData) return this.activeCallData?.callState;
    return INIT_CALL_STATE;
  }

  get activeCallStatus() {
    if (this.activeCallData) return this.activeCallData?.callStatus;
    return "";
  }

  get calls() {
    return this.models;
  }

  get isActiveCallOnHold() {
    if (!this.activeCallData) return false;
    return !!this.activeCallData?.hold;
  }

  get isActiveCallMuted() {
    if (!this.activeCallData) return false;
    return !!this.activeCallData?.muted;
  }

  getCallDataBySid(callSid) {
    let callData = this.models.find((call) => {
      return call?.callSid === callSid;
    });

    if (!callData) return null;
    let destination = callData?.data;
    let res = {};
    if (destination?.participant?.name) {
      res.name = destination?.participant?.name;
    }
    if (!res.name && destination?.phoneNumber) {
      res.name = destination?.phoneNumber;
    }
    if (destination?.phoneNumber) {
      res.phone_number = destination?.phoneNumber;
    }
    if (destination?.phoneNumber && !!destination?.participant?.remote_id) {
      res.customer_id = destination?.participant?.remote_id;
    }
    let call = callData?.call;
    if (!res?.phone_number && call?.parameters?.From) {
      res.phone_number = call?.parameters?.From;
    }
    if (
      !res.name &&
      call?.customParameters &&
      call?.customParameters.has("participantName")
    ) {
      res.name = call?.customParameters?.get("participantName");
    }
    if (!res.name && call) {
      res.name = "Unknown Caller";
    }
    if (
      !res.customer_id &&
      call?.customParameters?.has("participantRemoteId")
    ) {
      res.customer_id = call?.customParameters?.get("participantRemoteId");
    }
    try {
      let parsedNumber = parsePhoneNumber(res?.phone_number, "US");
      res = {
        ...res,
        phone_number: parsedNumber.formatNational(),
      };
    } catch (e) {}
    res.call_sid = callSid;
    res.display_name = res.name;
    delete res.name;
    return res;
  }

  get model() {
    return Call;
  }
}
