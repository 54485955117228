import { useContext, useState } from "react";
import { createContext } from "react";
import BUSINESS_INFORMATION_DEFAULT, {
  BRAND_STATUS_KEY,
  BUSINESS_ADDRESS,
  BUSINESS_ADDRESS_COUNTRY_CODE_KEY,
  BUSINESS_ADDRESS_KEY,
  BUSINESS_ADDRESS_LABELS,
  BUSINESS_ADDRESS_REGION_KEY,
  BUSINESS_EIN_KEY,
  BUSINESS_INFORMATION,
  BUSINESS_INFORMATION_KEY,
  BUSINESS_INFORMATION_LABELS,
  BUSINESS_REPRESENTATIVE,
  BUSINESS_REPRESENTATIVE_EMAIL,
  BUSINESS_REPRESENTATIVE_JOB_POSITION,
  BUSINESS_REPRESENTATIVE_KEY,
  BUSINESS_REPRESENTATIVE_LABELS,
  BUSINESS_REPRESENTATIVE_PHONE_NUMBER,
  BUSINESS_TYPE_KEY,
  BUSINESS_WEBSITE_URL_KEY,
  CAMPAIGN_STATUS_NOT_SUBMITTED,
} from "../constants/businessInformation";
import {
  isStateAbbreviation,
  validateEmail,
  validateUrl,
} from "../helpers/text";
import { parsePhoneNumber } from "libphonenumber-js";
import Engage from "../services/engage";
import UserContext from "./UserContext";
import { useEffect } from "react";
import {
  BrandStatus,
  BusinessAddress,
  BusinessInformation,
  BusinessRepresentative,
} from "../models/BusinessInformation";

const BusinessContext = createContext({});

export const BusinessProvider = ({ children }) => {
  const [businessInformation, setBusinessInformation] =
    useState(BUSINESS_INFORMATION);
  const [businessAddress, setBusinessAddress] = useState(BUSINESS_ADDRESS);
  const [businessRepresentative, setBusinessRepresentative] = useState(
    BUSINESS_REPRESENTATIVE,
  );
  const { user } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const { getCampaignStatus, setBusinessModelVisible, campaignStatus } =
    useContext(UserContext);
  const [brandStatus, setBrandStatus] = useState(false);
  const [loadingCampaignData, setLoadingCampaignData] = useState(true);

  const validateFields = (options = "ALL") => {
    let errors = {};
    if (options === "ALL" || options === BUSINESS_ADDRESS_KEY)
      Object.keys(BUSINESS_ADDRESS_LABELS).map((key) => {
        if (!businessAddress[key] || !businessAddress[key]?.trim()) {
          errors[key] = true;
        } else {
          businessAddress[key] = businessAddress[key]?.trim();
        }
        if (key === BUSINESS_ADDRESS_REGION_KEY) {
          const isShortNameState = isStateAbbreviation(businessAddress[key]);
          if (!isShortNameState) {
            errors[key] = true;
          }
        }
      });
    if (options === "ALL" || options === BUSINESS_REPRESENTATIVE_KEY)
      Object.keys(BUSINESS_REPRESENTATIVE_LABELS).map((key) => {
        if (
          !businessRepresentative[key] ||
          !businessRepresentative[key]?.trim()
        ) {
          errors[key] = true;
        } else {
          businessRepresentative[key] = businessRepresentative[key]?.trim();
        }
        if (key === BUSINESS_REPRESENTATIVE_EMAIL) {
          const isEmailValid = validateEmail(businessRepresentative[key]);
          if (!isEmailValid) {
            errors[key] = true;
          }
        }
        if (key === BUSINESS_REPRESENTATIVE_PHONE_NUMBER) {
          try {
            parsePhoneNumber(businessRepresentative[key]);
          } catch {
            errors[key] = true;
          }
        }
      });
    if (options === "ALL" || options === BUSINESS_INFORMATION_KEY)
      Object.keys(BUSINESS_INFORMATION_LABELS).map((key) => {
        if (!businessInformation[key] || !businessInformation[key]?.trim()) {
          errors[key] = true;
        } else {
          businessInformation[key] = businessInformation[key]?.trim();
        }
        if (key === BUSINESS_WEBSITE_URL_KEY) {
          const isUrlValid = validateUrl(businessInformation[key]);
          if (!isUrlValid) {
            errors[key] = true;
          }
        }
        if (key === BUSINESS_EIN_KEY) {
          if (businessInformation[key].length < 9) {
            errors[key] = true;
          }
        }
      });
    setErrors((e) => errors);
    return errors;
  };

  const submit = async () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      return;
    }
    await submitCampaign();
  };

  const submitCampaign = async () => {
    setSaving(true);
    try {
      const res = await Engage?.submitCampaignData({
        [BUSINESS_ADDRESS_KEY]: businessAddress,
        [BUSINESS_INFORMATION_KEY]: {
          ...businessInformation,
          [BUSINESS_TYPE_KEY]: parseInt(businessInformation[BUSINESS_TYPE_KEY]),
        },
        [BUSINESS_REPRESENTATIVE_KEY]: {
          ...businessRepresentative,
          [BUSINESS_REPRESENTATIVE_JOB_POSITION]: parseInt(
            businessRepresentative[BUSINESS_REPRESENTATIVE_JOB_POSITION],
          ),
        },
      });
      await getCampaignStatus();
      if (!res.error) {
        setBusinessModelVisible(false);
      }
    } finally {
      setSaving(false);
    }
  };

  const getCampaignData = async () => {
    setLoadingCampaignData(true);
    try {
      const res = await Engage?.getCampaignData();

      if (!res.error) {
        const response = res?.response;
        const businessInformation = new BusinessInformation(
          response?.[BUSINESS_INFORMATION_KEY],
        );
        const businessAddress = new BusinessAddress({
          ...response?.[BUSINESS_ADDRESS_KEY],
          [BUSINESS_ADDRESS_COUNTRY_CODE_KEY]:
            response?.[BUSINESS_ADDRESS_KEY]?.[
              BUSINESS_ADDRESS_COUNTRY_CODE_KEY
            ] === null
              ? user?.account?.country || "US"
              : response?.[BUSINESS_ADDRESS_KEY]?.[
                  BUSINESS_ADDRESS_COUNTRY_CODE_KEY
                ],
        });
        const businessRepresentative = new BusinessRepresentative(
          response?.[BUSINESS_REPRESENTATIVE_KEY],
        );
        const brandStatus = new BrandStatus(response?.[BRAND_STATUS_KEY]);
        setBusinessInformation(businessInformation);
        setBusinessAddress(businessAddress);
        setBusinessRepresentative(businessRepresentative);
        setBrandStatus(brandStatus);
      }
    } finally {
      setLoadingCampaignData(false);
    }
  };

  useEffect(() => {
    if (campaignStatus === CAMPAIGN_STATUS_NOT_SUBMITTED) {
      setLoadingCampaignData(false);
    }
  }, [campaignStatus]);

  return (
    <BusinessContext.Provider
      value={{
        businessInformation,
        businessAddress,
        businessRepresentative,
        setBusinessAddress,
        setBusinessInformation,
        setBusinessRepresentative,
        errors,
        validateFields,
        submit,
        setErrors,
        saving,
        brandStatus,
        loadingCampaignData,
        getCampaignData,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

export default BusinessContext;
