import * as dagre from "dagre";
import PHONE_TREE_TYPES, {
  MAX_LEVELS,
  SPANISH_LANGUAGE_CODE,
  TWILIO_LANGUAGES,
  TWILIO_TYPES,
} from "../constants/phoneTreeTypes";
import cloneDeep from "lodash.clonedeep";

const nodeWidth = 200;
const nodeHeight = 200;
const OFFSET = 2.5;
const YOFFSET = 400;
const PAUSE = ". . . . . ";

const keys = [
  "label",
  "num",
  "text",
  "user",
  "index",
  "parent",
  "children",
  "type",
  "value",
  "language",
  "level",
];

export const getData = (data) => {
  let nodes = typeof data === "object" ? cloneDeep(data) : [];
  let edges = [];

  for (let i = 0; i < nodes.length; i++) {
    let node = nodes[i];
    let childrenLength = node?.children?.length;
    if (childrenLength > 0) {
      for (let j = 0; j < childrenLength; j++) {
        let child = nodes[node.children[j]];
        if (child !== undefined) {
          edges.push({
            id: `e${node.index}-${child.index}`,
            source: `${node.index}`,
            target: `${child.index}`,
            type:
              child.type === "empty" || childrenLength === 9
                ? "default"
                : "buttonEdge",
            animated: true,
          });
        }
      }
    }
  }
  return { nodes, edges };
};

export const getLayoutElements = (data) => {
  const { nodes, edges } = getData(data);
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: "TB" });
  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth,
      height: node?.text
        ? node.text?.length > 100
          ? 350
          : nodeHeight
        : nodeHeight,
    });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);
  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    node.data = {};
    keys.forEach((key) => (node.data[key] = node[key]));
    if (node.toBeEdited) node.data.toBeEdited = true;
    return node;
  });

  return { nodes: cloneDeep(nodes), edges: cloneDeep(edges) };
};

export const getPhoneTreeTypes = (type, level) => {
  if (type === "main") {
    return [PHONE_TREE_TYPES[0]];
  } else {
    if (level === MAX_LEVELS) return [...PHONE_TREE_TYPES].slice(2);

    return [...PHONE_TREE_TYPES].slice(1);
  }
};

export const checkNode = (node) => {
  let res = true;
  let message = ``;
  switch (node.value) {
    case 0:
      res = node.children && node.children.length > 0;
      if (!res) message += `Main Node should have at least one child.`;
      break;
    case 1:
      res = node.children && node.children.length > 0;
      if (!res) message += `Option Node should have at least one child.`;
      break;
    case 2:
      res = node?.user !== null && node?.user?.number;
      if (!res) message += `Assign To Phone Number Node should have a number.`;
      break;
    case 3:
      res = node.text && node.text.length > 0;
      if (!res) message += `Message Node should have a text.`;
      break;
    default:
      break;
  }

  if (res) {
    res = node.label && node.label.length > 0;
    if (!res) message += `Node should have a Label.`;
  }
  if (res && node.value !== 0) {
    res = node.parent !== null;
    if (!res) message += `Node should have a Parent.`;
  }
  return { error: !res, message: message };
};

export const convertToTwilioFormat = (data) => {
  let i = 0;
  let formattedNodes = [];

  const { nodes } = getLayoutElements(data);

  while (i < nodes.length) {
    let curr = nodes[i];
    let language =
      curr.language !== undefined || curr.language !== null
        ? TWILIO_LANGUAGES.filter((lang) => lang.code === curr.language)[0]
        : TWILIO_LANGUAGES[0];
    if (language?.code === "en") language.code = "en-US";

    switch (curr.value) {
      case 0:
        formattedNodes.push(...convertOption(nodes, curr, language));
        break;
      case 1:
        formattedNodes.push(...convertOption(nodes, curr, language));
        break;
      case 2:
        formattedNodes.push(convertCallTo(curr, language));
        break;
      case 3:
        formattedNodes.push(convertBotMessage(nodes, curr, language));
        break;
      default:
        break;
    }
    i++;
  }
  return formattedNodes;
};

const snakeCase = (string) => {
  return string.replaceAll(/\W+/g, " ").split(" ").join("_");
};

const titleCase = (s) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

const getName = (node) => {
  let name = `${snakeCase(node.label)}`;

  name += `_${node.label?.length || "0"}`;

  name += `_${node.parent}`;

  name += `_${node.text?.length || "0"}`;

  name += `_${node.index}`;

  return name;
};

const convertOption = (nodes, node, languageProp) => {
  let i = [];
  let language =
    languageProp === undefined ? TWILIO_LANGUAGES[0] : languageProp;
  const isSpanish = language?.code === SPANISH_LANGUAGE_CODE;
  const name = getName(node);

  if (node.value === 0)
    i.push({
      name: "Trigger",
      type: "trigger",
      transitions: [
        {
          event: "incomingMessage",
        },
        {
          next: `${name}`,
          event: "incomingCall",
        },
        {
          event: "incomingConversationMessage",
        },
        {
          event: "incomingRequest",
        },
        {
          event: "incomingParent",
        },
      ],
      properties: {
        offset: {
          x: 250,
          y: 50,
        },
      },
    });
  const splitName = `${name}_split`;
  let childrenString = `"${node.text}" `;
  let transitions = [];
  if (node.children.length > 0) {
    for (let i = 0; i < node.children.length; i++) {
      const currNode = nodes[node.children[i]];
      const pressKeyword = isSpanish ? "Marque" : "Press";
      const forKeyword = isSpanish ? "para" : "for";
      const currName = getName(currNode);
      childrenString += `${PAUSE} ${pressKeyword} ${
        i + 1
      } ${forKeyword} ${PAUSE} "${currNode.label?.replaceAll('"', "")}"${
        i === node.children.length - 1 ? "." : ", "
      }`;
      transitions.push({
        next: currName,
        event: "match",
        conditions: [
          {
            friendly_name: `${i + 1}`,
            arguments: [`{{widgets.${name}.Digits}}`],
            type: "equal_to",
            value: `${i + 1}`,
          },
        ],
      });
    }
  }

  if (node.parent !== null && node.parent !== undefined) {
    const parentName = getName(nodes[node.parent]);
    transitions.push({
      next: parentName,
      event: "match",
      conditions: [
        {
          friendly_name: "If value equal_to #",
          arguments: [`{{widgets.${name}.Digits}}`],
          type: "equal_to",
          value: "#",
        },
      ],
    });
  }

  return [
    ...i,
    {
      name,
      type: "gather-input-on-call",
      transitions: [
        {
          next: splitName,
          event: "keypress",
        },
        {
          next: name,
          event: "timeout",
        },
      ],
      properties: {
        voice: language.voice,
        speech_timeout: "auto",
        number_of_digits: 1,
        loop: 1,
        finish_on_key: "",
        say: childrenString,
        language: language.code,
        stop_gather: false,
        gather_language: "en",
        profanity_filter: "true",
        timeout: 5,
        offset: {
          x: node.position.x * OFFSET,
          y: node.position.y * OFFSET + YOFFSET,
        },
      },
    },
    {
      name: splitName,
      type: "split-based-on",
      transitions: [
        {
          next: name,
          event: "noMatch",
        },
        ...transitions,
      ],
      properties: {
        input: `{{widgets.${name}.Digits}}`,
        offset: {
          x: node.position.x * OFFSET,
          y: (node.position.y + 125) * OFFSET + YOFFSET,
        },
      },
    },
  ];
};

const convertBotMessage = (nodes, node, languageProp) => {
  let language =
    languageProp === undefined ? TWILIO_LANGUAGES[0] : languageProp;
  let name = getName(node);

  return {
    name,
    type: "say-play",
    transitions: [
      {
        event: "audioComplete",
        next: getName(nodes[node.parent]),
      },
    ],
    properties: {
      loop: 1,
      say: `${node.text?.replaceAll('"', "") || ""} ${PAUSE}`,
      voice: language.voice,
      language: language.code,
      offset: {
        x: node.position.x * OFFSET,
        y: node.position.y * OFFSET + YOFFSET,
      },
    },
  };
};

const convertCallTo = (node, languageProp) => {
  let language =
    languageProp === undefined ? TWILIO_LANGUAGES[0] : languageProp;
  const name = getName(node);
  return {
    name,
    type: "connect-call-to",
    transitions: [
      {
        event: "callCompleted",
      },
      {
        event: "hangup",
      },
    ],
    properties: {
      caller_id: "{{contact.channel.address}}",
      noun: "number",
      to: node.user.number,
      timeout: 30,
      voice: language.voice,
      language: language.code,
      offset: {
        x: node.position.x * OFFSET,
        y: node.position.y * OFFSET + YOFFSET,
      },
    },
  };
};

export const convertBackToNode = (data) => {
  const transitions = data;
  let nodes = [];

  for (let i = 1; i < transitions.length; i++) {
    const curr = transitions[i];
    let type = TWILIO_TYPES[curr.type];
    if (type > -1) {
      const name = curr?.name?.split("_");
      let parent = name[name.length - 3] || "null";
      let textLength = name[name.length - 2] || "0";
      let index = name[name.length - 1] || "0";

      let node = { value: nodes.length === 0 ? 0 : type };
      node.parent = parent === "null" ? null : parseInt(parent);
      node.type = parent === "null" ? "main" : "node";
      node.index = parseInt(index);
      node.children = [];
      if (parent !== "null") {
        let parentNode = nodes[parseInt(parent)];
        const childIndex = parentNode.children ? parentNode.children.length : 0;
        node.label = parentNode.childLabels[childIndex * 2 + 3];
        if (!parentNode.children) parentNode.children = [parseInt(index)];
        else parentNode.children.push(parseInt(index));
      } else {
        node.label = "Main Greeting";
      }

      if (type === 1 || type === 3) {
        let text = curr?.properties?.say || "";
        if (type === 1) {
          text = text.slice(1, parseInt(textLength) + 1);
          node.childLabels = curr?.properties?.say?.split('"');
        } else {
          text = text.slice(0, -(PAUSE.length + 1));
        }
        node.text = text || "";
        node.language = curr.properties.language || "en-US";
      }

      if (type === 2) {
        node.text = "";
        node.language = "en-US";
        node.user = { number: curr.properties?.to };
      }
      nodes.push(node);
    }
  }

  return nodes;
};
