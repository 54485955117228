import React from "react";
import Col from "~/layouts/Col";
import Row from "~/layouts/Row";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
} from "react-native";
import palette from "~/styles/palette";

const FilterButton = ({
  label,
  active,
  disabled = false,
  pl,
  pr,
  onPress,
  children,
  labelStyle = {},
}) => (
  <TouchableOpacity
    onPress={disabled ? null : onPress}
    activeOpacity={disabled ? 1 : 0.8}
    style={{ cursor: disabled ? "not-allowed" : "pointer", flex: 1 }}
  >
    <Col center pl={pl} pr={pr}>
      <View
        style={[
          styles.filter_bar__btn_container,
          active && styles.filter_bar__btn_container_active,
        ]}
      >
        <Row center style={{ justifyContent: "center" }}>
          <Text
            style={[
              styles.filter_bar__btn_label,
              active && styles.filter_bar__btn_label_active,
              labelStyle,
            ]}
          >
            {label}
          </Text>
          {children}
        </Row>
      </View>
    </Col>
  </TouchableOpacity>
);

const pointerStyles =
  Platform.OS === "web"
    ? {
        cursor: "pointer",
      }
    : {};

const styles = StyleSheet.create({
  filter_bar__btn_container: {
    borderWidth: 1,
    borderColor: palette.light_grey,
    width: "100%",
    paddingVertical: 8,
    borderRadius: 4,
    minHeight: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: palette.white,
    ...pointerStyles,
  },
  filter_bar__btn_container_active: {
    backgroundColor: palette.primary,
    borderColor: palette.primary,
  },
  filter_bar__btn_label: {
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
    color: palette.grey,
  },
  filter_bar__btn_label_active: {
    color: palette.white,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
});

export default FilterButton;
