import React, { useContext, useEffect, useMemo, useState } from "react";

import ContactContext, { ContactProvider } from "../../contexts/ContactContext";
import CustomerForm, { Field } from "../../components/Customer/CustomerForm";
import CustomersContext from "../../contexts/CustomersContext";
import ThreadsContext from "../../contexts/ThreadsContext";
import ConversationContext from "../../contexts/ConversationContext";
import AdditionalContactContext, {
  AdditionalContactProvider,
} from "../../contexts/AdditionalContactContext";
import { View } from "react-native";
import commonStyles from "../../styles/common";
import CheckBox from "../../components/CheckBox";
import {
  checkContactFormValid,
  checkCustomerFormValid,
} from "../../helpers/customers";
import palette from "../../styles/palette";
import Modal from "../Modal";
import { ContactForm } from "../../components/Participant/ParticipantDetails/ContactSelect/index.web";
import { useHistory } from "react-router-dom";

export default function CustomerCreateModal({ location }) {
  const initialValue = location?.state?.participant;
  const type = location?.state?.type;

  return (
    <ContactProvider value={initialValue}>
      <AdditionalContactProvider
        value={initialValue}
        participant={initialValue}
        contact_id={initialValue?.remote_id}
      >
        <InnerForm type={type} />
      </AdditionalContactProvider>
    </ContactProvider>
  );
}

const InnerForm = ({ type }) => {
  const {
    isContact,
    setIsContact,
    contact,
    updateContact,
    saving,
    createContact,
    dirty,
  } = useContext(ContactContext);
  const {
    setContactFromValue,
    contact: additionalContact,
    saving: savingContact,
    createAdditionalContact,
    dirty: isAdditionalContactDirty,
  } = useContext(AdditionalContactContext);
  const { refreshCustomers } = useContext(CustomersContext);
  const { refreshThreads } = useContext(ThreadsContext);
  const { getConversation, setParticipantFromCustomer } =
    useContext(ConversationContext);
  const [isCustomerFormValid, setIsCustomerFormValid] = useState(true);
  const history = useHistory();
  const onCreate = async () => {
    const func = isContact ? createAdditionalContact : createContact;
    const value = await func();
    if (type === "customer") refreshCustomers();
    else {
      setParticipantFromCustomer(value);
      getConversation();
      refreshThreads();
    }
    const path = history?.location?.pathname || "/";
    history.replace(path);
  };

  const isDisabled = useMemo(() => {
    if (isContact) {
      return !isAdditionalContactDirty || !isCustomerFormValid;
    } else {
      return !isCustomerFormValid || !dirty;
    }
  }, [isCustomerFormValid, dirty, isContact, isAdditionalContactDirty]);

  useEffect(() => {
    let isValid = false;
    if (isContact) {
      isValid = checkContactFormValid(additionalContact);
    } else {
      isValid = checkCustomerFormValid(contact);
    }
    setIsCustomerFormValid(isValid);
  }, [contact, additionalContact, isContact]);

  return (
    <Modal
      title={"Create New Customer"}
      confirmButtonProps={{
        loading: saving || savingContact,
        label: isContact ? "Create Contact" : "Create Customer",
        color: palette.success,
        onPress: onCreate,
        disabled: isDisabled,
      }}
    >
      <View style={[commonStyles.row, { paddingHorizontal: 4 }]}>
        <Field>
          <CheckBox
            label={"Link contact to existing customer"}
            checked={isContact}
            onChange={() => {
              if (!isContact) {
                setContactFromValue(contact);
              } else {
                updateContact(additionalContact);
              }
              setIsContact((val) => !val);
            }}
          />
        </Field>
      </View>
      {!!isContact ? (
        <ContactForm isContact={true} />
      ) : (
        <CustomerForm type="create" />
      )}
    </Modal>
  );
};
