import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from "react";
import { Text, TouchableWithoutFeedback } from "react-native";
import { StyleSheet, View, FlatList } from "react-native";
import { useHistory, useParams } from "react-router-dom";

import DayJs from "~/helpers/day";

import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import Avatar from "~/components/Avatar";
import SolidButton from "../Buttons/SolidButton/index";
import LineFilterBar from "../Bars/LineFilterBar";
import palette from "~/styles/palette";

import AppContext from "../../contexts/AppContext";
import UserContext from "../../contexts/UserContext";
import ThreadsContext from "../../contexts/ThreadsContext";
import ConversationContext from "../../contexts/ConversationContext";
import Engage from "../../services/engage";
import useTwilio from "~/hooks/useTwilio";
import NumbersContext from "../../contexts/NumbersContext";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faComments } from "@fortawesome/pro-regular-svg-icons";
import { getSmartTimestamp } from "../../helpers/day";
import {
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
} from "rctx-contextmenu";
import {
  faCircle,
  faPenToSquare,
  faStar,
} from "@fortawesome/pro-solid-svg-icons";
import useConversationsearch from "../../hooks/useConversationSearch";
import LoadingIndicator from "../LoadingIndicatior/index.web";
import SearchBar from "../Bars/SearchBar/index.web";
import { SEARCH_LIMIT } from "../../constants/search";
import commonStyles from "../../styles/common";
import CustomersContext from "../../contexts/CustomersContext";
import BlockedBadge from "../BlockedBadge";

export default function ConversationList() {
  const history = useHistory();
  const {
    threads,
    filter,
    setFilter,
    nextPage,
    initialLoading,
    getAllConversationDrafts,
    isConversationDraftsReady,
    conversationDrafts,
    isLineFilterCacheReady,
    setPageActive,
    loading,
    selectedLines,
  } = useContext(ThreadsContext);
  const {
    conversation,
    clearConversation,
    setConversation,
    setTempHeader,
    loading: loadingConversation,
    markAsStar,
    markAsUnread,
  } = useContext(ConversationContext);

  const { user, canSeeCustomerContact } = useContext(UserContext);
  const { setCallQueue } = useTwilio();
  const { managedNumbers } = useContext(NumbersContext);
  const { blockedContacts } = useContext(CustomersContext);
  const [activeConversation, setActiveConversation] = useState(null);
  const { compactMode } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState("");
  const showSearchResults = searchTerm?.length >= SEARCH_LIMIT;

  let params = {};

  if (filter === "all") {
    params.managed_lines = managedNumbers.map((num) => num?.id);
  } else if (filter === "assigned") {
    params.managed_lines = [user?.assigned_number?.id];
  } else {
    params.managed_lines = selectedLines || [];
  }

  const { conversations: suggestions, loading: loadingSuggestions } =
    useConversationsearch({
      search: searchTerm,
      page: 1,
      limit: 50,
      filter,
      ...params,
    });

  useEffect(() => {
    if (isConversationDraftsReady) getAllConversationDrafts();
  }, [isConversationDraftsReady, conversationDrafts]);

  useEffect(() => {
    setPageActive(true);

    return () => {
      setPageActive(false);
    };
  }, []);

  const onNewCall = () => {
    setCallQueue({
      to: conversation?.participant?.phone_number,
      metaData: conversation?.participant,
    });
  };

  const onCreateNewThread = useCallback(() => {
    clearConversation();
    history.push("/messages/new");
  }, [history, clearConversation]);

  const onPress = useCallback(
    (conversation, setUnread) => () => {
      const title =
        conversation?.participant?.name ||
        conversation?.participant?.phone_number;
      const isSaved = conversation?.participant?.remote_id;

      setTempHeader({
        main: isSaved || canSeeCustomerContact ? title : "Unknown Customer",
        sub: conversation?.participant?.parent?.name,
      });
      setActiveConversation(() => conversation?.id);
      setConversation(conversation, true);
      setUnread(() => false);
      Engage.markConversationAsRead(conversation.id);
      history.push(
        `${compactMode ? "/compact" : ""}/messages/${conversation.id}`,
      );
    },
    [
      history,
      setConversation,
      compactMode,
      setActiveConversation,
      canSeeCustomerContact,
    ],
  );

  useEffect(() => {
    if (!loadingConversation && conversation?.id !== activeConversation) {
      setActiveConversation(conversation?.id);
    }
  }, [conversation?.id, activeConversation, loadingConversation]);

  const id = useMemo(() => {
    let pathname = history?.location?.pathname;
    let params = pathname.split("/");
    if (params.length > 2) {
      return params[2];
    }
    return;
  }, [history?.location]);

  return (
    <View style={[styles.conversation_list__container]}>
      {/* <ConversationActions
        onNewThread={onCreateNewThread}
        onNewCall={onNewCall}
        hasConversation={!!conversation?.id}
      /> */}
      <View
        style={{
          paddingHorizontal: 6,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: palette.light_grey,
          zIndex: 200,
        }}
      >
        {managedNumbers?.length > 1 && isLineFilterCacheReady && filter && (
          <LineFilterBar
            activeFilter={filter}
            onFilterChange={(activeFilter) => setFilter(activeFilter)}
            loading={loading}
          />
        )}
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder={"Search by name, phone or text"}
          managedNumbersLength={managedNumbers?.length}
        />
      </View>

      {(loadingSuggestions && searchTerm?.length > 0) ||
      initialLoading ||
      !isLineFilterCacheReady ||
      !filter ? (
        <LoadingIndicator title={"Loading Conversations"} />
      ) : (
        <FlatList
          data={showSearchResults ? suggestions : threads}
          ListEmptyComponent={NoConversationsComponent}
          renderItem={({ item }) => {
            return (
              <View>
                <ConversationListItem
                  {...{
                    user,
                    onPress,
                    conversationDrafts,
                    active:
                      activeConversation === item.id ||
                      conversation?.id === item.id,
                    markAsStar,
                    markAsUnread,
                    history,
                    item,
                    showContact: canSeeCustomerContact,
                    blockedContacts,
                  }}
                />
              </View>
            );
          }}
          keyExtractor={(item) => `${item.id}`}
          onEndReachedThreshold={0.9}
          onEndReached={searchTerm?.length > 0 ? null : nextPage}
        />
      )}
    </View>
  );
}

const ConversationActions = ({ onNewThread, onNewCall, hasConversation }) => {
  const { activeNumber } = useContext(NumbersContext);
  return (
    <Row
      center
      mb={8}
      style={{
        height: 75,
        borderBottomWidth: 1,
        borderColor: palette.light_grey,
      }}
    >
      <Col pl={8} pt={16} pr={8}>
        <SolidButton
          label="Start a new conversation"
          onPress={onNewThread}
          // icon="message-circle"
          color={palette.success}
          disabled={!activeNumber}
        />
      </Col>
    </Row>
  );
};

const NoConversationsComponent = () => {
  return (
    <View style={[styles.no_conversations_component]}>
      <Text style={[styles.no_conversations_component_text]}>
        No conversations have been started
      </Text>
    </View>
  );
};

const ConversationListItem = ({
  user,
  onPress,
  conversationDrafts,
  active,
  markAsStar,
  markAsUnread,
  history,
  item,
  showContact,
  blockedContacts,
}) => {
  if (!item) return null;
  const read = item?.unread_count === 0;
  const direction = item?.preview_obj?.direction?.includes("outbound")
    ? "outbound"
    : "inbound";

  const hasDraft = conversationDrafts.find((draft) => draft === item.id)
    ? true
    : false;

  let wasStared = item?.stared_at == null ? false : true;
  const [star, setStar] = useState(wasStared);
  const [unread, setUnread] = useState(!read);

  const author = item?.preview_obj?.author;
  const hasImage = item?.preview_obj?.image;
  const isCall = item?.preview_obj?.interactable_type === "TwilioCall";
  const isMessage = item?.preview_obj?.interactable_type === "TwilioMessage";
  const isNote = item?.preview_obj?.interactable_type === "TwilioNote";
  const isMissed = item?.preview_obj?.missed;
  const text = item?.preview_obj?.text;
  const displayText = text ? `${text}`?.replaceAll("\n", " ") : "";

  const id = item?.id;
  const name = item?.participant?.name;
  const isChild = !!item?.participant?.is_child;
  const hasParent = !!item?.participant?.parent;
  const showParentName = isChild && hasParent;
  const parentName = item?.participant?.parent?.name;
  const isCustomerSaved = item.participant?.remote_id !== null;
  const isBlocked =
    item.participant?.is_blocked ||
    blockedContacts?.includes(item.participant?.id);
  const unreadCls = useMemo(() => {
    return unread ? styles.conversation_list__list_item_unread_cls : {};
  }, [unread]);

  useEffect(() => {
    setUnread(!read);
  }, [read]);

  const handleDelete = () => {
    history.push(
      { hash: "confirm-delete" },
      {
        message: `This will delete your conversation with ${name}. Do you want to continue?`,
        type: "one-conversation",
        id: id,
      },
    );
  };

  return (
    <ContextMenuTrigger id={id}>
      <ContextMenu id={id} appendTo="body" animation="zoom" hideOnLeave={true}>
        <ContextMenuItem
          disabled={unread}
          onClick={() => {
            if (markAsUnread(id)) setUnread(true);
          }}
        >
          <Text>Mark as unread</Text>
        </ContextMenuItem>

        <ContextMenuItem
          onClick={() => {
            if (markAsStar(id)) setStar(!star);
          }}
        >
          <Text>{star ? "Unstar" : "Star"} Conversation</Text>
        </ContextMenuItem>
        <ContextMenuItem onClick={handleDelete}>
          <Text>Delete Conversation</Text>
        </ContextMenuItem>
      </ContextMenu>

      <TouchableWithoutFeedback onPress={onPress(item, setUnread)}>
        <View
          style={[
            styles.conversation_list__list_item,
            {
              backgroundColor: active
                ? palette.grey_selection
                : palette.grey_bg,
            },
          ]}
        >
          <Row style={{ height: "100%" }}>
            <View
              style={[styles.conversation_list__list_item_avatar_container]}
            >
              <FontAwesomeIcon
                icon={faComments}
                size={18}
                color={palette.grey}
              />
            </View>
            <Col>
              <Row center style={{ justifyContent: "space-between" }}>
                <View style={commonStyles.row_normal}>
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={[
                      styles.conversation_list__list_item_name,
                      {
                        fontWeight: unread ? "800" : "600",
                        fontFamily: unread
                          ? "OpenSans_800ExtraBold"
                          : "OpenSans_600SemiBold",
                      },
                    ]}
                  >
                    {isCustomerSaved || showContact
                      ? item.participant.name
                      : "Unknown Customer"}
                  </Text>
                  {showParentName && (
                    <Text
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      style={[styles.conversation_list__list_item_name]}
                    >
                      {`: ${parentName}`}
                    </Text>
                  )}
                </View>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[styles.conversation_list__list_item__timestamp]}
                >
                  {getSmartTimestamp(item.timestamp)}
                </Text>
              </Row>
              {isBlocked && (
                <Row mt={4}>
                  <Col left>
                    <BlockedBadge visible />
                  </Col>
                </Row>
              )}
              <Row mt={4} style={{ justifyContent: "space-between" }}>
                <View style={{ width: "90%" }}>
                  <Text
                    style={[
                      styles.conversation_list__list_item_preview_text,
                      unreadCls,
                      { fontWeight: isMissed || unread ? "600" : "400" },
                    ]}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {`${
                      isNote
                        ? "Internal Note"
                        : direction === "inbound"
                        ? "Received"
                        : `${
                            author?.remote_id === user?.remote_id
                              ? ""
                              : isCall
                              ? ""
                              : author?.name + " "
                          }Sent`
                    }: `}
                    {`${isMessage || isNote ? displayText : ""}`}
                    <Text
                      style={[
                        styles.conversation_list__list_item_preview_text,
                        unreadCls,
                        {
                          fontWeight: isMissed ? "600" : "400",
                          color: isMissed ? palette.red : palette.grey,
                        },
                      ]}
                    >
                      {`${
                        isCall
                          ? isMissed
                            ? "Missed Call"
                            : direction === "inbound" && !isMissed
                            ? "Inbound Call"
                            : "Outbound Call"
                          : ""
                      }`}
                    </Text>
                    {`${hasImage && !text ? "Image" : ""}`}
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={[styles.conversation_list__list_item_number]}
                  >
                    {item.twilio_number?.friendly_name}
                  </Text>
                </View>
                <Col>
                  <Row center>
                    {unread && (
                      <View
                        style={{
                          marginTop: 7,
                          marginRight: hasDraft ? 4 : 0,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          size={12}
                          color={palette.info}
                        />
                      </View>
                    )}
                    {hasDraft && (
                      <View
                        style={{
                          marginTop: 7,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          size={12}
                          color={palette.info}
                        />
                      </View>
                    )}
                  </Row>
                  <Row center>
                    {star && (
                      <View
                        style={{
                          marginTop: 7,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faStar}
                          size={12}
                          color={palette.yellow}
                        />
                      </View>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </View>
      </TouchableWithoutFeedback>
    </ContextMenuTrigger>
  );
};

const styles = StyleSheet.create({
  conversation_list__container: {
    flex: 1,
    backgroundColor: palette.grey_bg,
  },
  conversation_list__list_item: {
    padding: 12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: palette.light_grey,
    cursor: "pointer",
  },
  conversation_list__list_item_avatar_container: {
    width: 30,
    marginRight: 8,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  conversation_list__list_item_read_indicator: {
    color: palette.info,
    fontSize: 24,
  },
  conversation_list__list_item_preview_text: {
    fontSize: 14,
    color: palette.grey,
    // fontFamily: "acumin-pro, san-serif",
  },
  conversation_list__list_item_name: {
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  conversation_list__list_item_number: {
    fontSize: 12,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    color: palette.label_grey,
    paddingTop: 4,
  },
  conversation_list__list_item_unread_cls: {
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  conversation_list__list_item__timestamp: {
    fontSize: 12,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    color: palette.grey,
    textAlign: "right",
  },
  conversation_list__list_item_draft_indicator: {
    position: "absolute",
    right: 0,
  },
  no_conversations_component: {
    padding: 16,
  },
  no_conversations_component_text: {
    textAlign: "center",
    fontFamily: "acumin-pro, san-serif",
    color: palette.grey,
    fontWeight: "500",
    fontFamily: "OpenSans_600SemiBold",
  },
});
