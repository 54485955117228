import { View, StyleSheet, Image } from "react-native";
import StyledInput from "../StyledInput";
import AppContext from "../../contexts/AppContext";
import { useContext, useEffect, useRef, useState } from "react";
import palette from "../../styles/palette";
import { Option } from "../TopBar/index.web";
import { ScrollView } from "react-native-web";
import {
  addressComponentsLocalNames,
  getAddressComponentFromPlace,
  getCityFromAddressComponents,
  getFormattedAddress,
} from "../../utils/address";
import OutsidePressHandler from "react-native-outside-press";
const AddressInput = ({
  value = "",
  onChange,
  shortNameForState = false,
  defaultCountry = "US",
  extras = false,
  ...props
}) => {
  const { googleLibraryLoaded } = useContext(AppContext);
  const [input, setInput] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(false);
  const autocompleteTimeoutRef = useRef();

  useEffect(() => {
    setInput(value || "");
  }, [value]);

  const handleAutoCompletePlaceSelected = (placeId) => async () => {
    let mockMapElement;

    if (document.getElementById("auto-population-mock-map")) {
      mockMapElement = document.getElementById("auto-population-mock-map");
    } else {
      mockMapElement = document.createElement("div");
      mockMapElement.id = "auto-population-mock-map";
      mockMapElement.style.display = "none";
      document.body.appendChild(mockMapElement);
    }

    let mockMap = new google.maps.Map(mockMapElement);

    const placesService = new google.maps.places.PlacesService(mockMap);

    try {
      placesService.getDetails(
        {
          placeId,
          fields: ["address_components"],
        },
        (place) => {
          if (place) {
            getAddressData(place);
            setSuggestions([]);
          }
        },
      );
    } catch (e) {
      setSuggestions([]);
      console.error(e);
    }
  };

  const getSuggestionsCallback = (predictions, status) => {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      setSuggestions(predictions);
    }
  };

  const getSuggestions = () => {
    try {
      let service = new google.maps.places.AutocompleteService();
      if (input && input.trim() && input.trim().length > 4)
        service.getQueryPredictions({ input }, getSuggestionsCallback);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    let address = {
      street: input,
      state: "",
      city: "",
      zip: "",
    };

    if (extras) {
      address.country_code = defaultCountry;
    }

    if (error) {
      onChange({ ...address, suggested: false });
      setError(false);
    }

    if (input?.length < 5) onChange({ address, suggested: false });
  }, [input, error]);

  const getAddressData = (place) => {
    let address = {
      street: "",
      state: "",
      city: "",
      zip: "",
    };
    if (extras) {
      address.country_code = defaultCountry;
    }
    try {
      address.street = getFormattedAddress(place) || "";
      address.city = getCityFromAddressComponents(place);
      address.state =
        getAddressComponentFromPlace(
          addressComponentsLocalNames.state,
          place,
        ) || "";
      address.zip =
        getAddressComponentFromPlace(
          addressComponentsLocalNames.zipCode,
          place,
        ) || "";
    } finally {
      onChange({ ...address, suggested: true });
    }
  };

  const handleTextChange = (text) => {
    if (autocompleteTimeoutRef.current) {
      clearTimeout(autocompleteTimeoutRef.current);
    }
    setInput(text);
    let address = {
      street: text,
      state: "",
      city: "",
      zip: "",
      suggested: false,
    };
    if (extras) {
      address.country_code = defaultCountry;
    }

    if (text && text.trim() && text.trim().length > 4) {
      autocompleteTimeoutRef.current = setTimeout(() => {
        getSuggestions();
      }, 200);
    } else {
      setSuggestions([]);
    }
  };

  if (!googleLibraryLoaded) return null;

  return (
    <OutsidePressHandler
      onOutsidePress={() => {
        setSuggestions([]);
      }}
    >
      <View style={{ zIndex: 50 }}>
        <StyledInput
          value={input}
          lessPadding
          bold
          autoComplete="none"
          onChangeText={handleTextChange}
          {...props}
        />
        {input.length > 4 &&
          input.trim().length > 4 &&
          suggestions?.length > 0 && (
            <ScrollView
              style={{
                zIndex: 50,
                position: "absolute",
                top: "100%",
                backgroundColor: palette.white,
                width: "100%",
                borderWidth: StyleSheet.hairlineWidth,
                borderColor: palette.light_grey,
                borderTopWidth: 0,
              }}
            >
              {suggestions.map((item) => {
                return (
                  <Option
                    label={item?.description}
                    key={item?.place_id}
                    onPress={handleAutoCompletePlaceSelected(item?.place_id)}
                  />
                );
              })}
              {suggestions?.length > 0 && (
                <View
                  style={{
                    padding: 10,
                  }}
                >
                  <Image
                    source={require("~/assets/powered-by-google.png")}
                    style={{
                      height: 11,
                      width: 82,
                      position: "absolute",
                      right: 2,
                      bottom: 2,
                    }}
                  />
                </View>
              )}
            </ScrollView>
          )}
      </View>
    </OutsidePressHandler>
  );
};

export default AddressInput;
