import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { useHistory } from "react-router-dom";

import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import Heading from "~/components/Heading/index";
import SolidButton from "~/components/Buttons/SolidButton/index";
import palette from "~/styles/palette";
palette;

export default function CustomersHeader() {
  const history = useHistory();

  const onCreateCustomer = useCallback(() => {
    history.push({ hash: "new-customer" }, { type: "customer" });
  }, [history]);

  return (
    <View style={[styles.customers_header__container]}>
      <View style={[styles.customers_header__inner]}>
        <Row pl={16} pr={16} style={{ height: "100%" }}>
          <Col leftCenter>
            <Heading size={1}>Customers</Heading>
          </Col>
          <Col rightCenter>
            <SolidButton
              icon="user-plus"
              label="New Customer"
              color={palette.success}
              onPress={onCreateCustomer}
            />
          </Col>
        </Row>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  customers_header__container: {
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
    backgroundColor: palette.primary_dark,
    marginBottom: 16,
  },
  customers_header__inner: {
    backgroundColor: palette.white,
    height: 75,
    borderTopLeftRadius: 16,
  },
  customers_header__search_input: {
    width: 380,
    backgroundColor: palette.lightest_grey,
    fontSize: 18,
    paddingHorizontal: 24,
    borderRadius: 24,
  },
  context_header__count_text: {
    color: palette.grey,
    fontSize: 16,
  },
});
