export const INCOMING_CALL_STATUS = 0;
export const OUTGOING_CALL_STATUS = 1;

export const ANSWERED_CALL_STATUS = 0;
export const UNANSWERED_CALL_STATUS = 1;
export const MISSED_CALL_STATUS = 2;

export const CALL_DIRECTION = {
  incoming: INCOMING_CALL_STATUS,
  outgoing: OUTGOING_CALL_STATUS,
};

export const CALL_OUTCOME = {
  missed: MISSED_CALL_STATUS,
  answered: ANSWERED_CALL_STATUS,
  unanswered: UNANSWERED_CALL_STATUS,
};

export const OUTBOUND_MISSED_CALL_KEY = "outbound_missed";

export const CALL_UPDATE_CHANNEL = "call-update";

export const DEFAULT_CALL_UPDATE_PAYLOAD = {
  conversation_id: "",
  twilio_number_id: "",
  id: "",
  to: "",
  from: "",
  voicemail: null,
  recording: null,
  called_by: "",
  note: "",
  created_at: "",
  missed: "false",
  outbound_missed: "false",
  direction: "incoming",
  name: "",
  twilio_number: {},
  meta: {
    duration: "0",
    call_recording: null,
    voicemail: null,
  },
  author: {
    name: "",
    remote_id: null,
  },
};

export const API_MATCHING_KEYS = [
  "conversation_id",
  "direction",
  "id",
  "to",
  "from",
  "note",
  "created_at",
  "called_by",
];

export const BOOLEAN_KEYS = ["missed", "outbound_missed"];

export const AUTHOR_KEYS = ["name", "remote_id"];
