import palette from "../styles/palette";

export const BUSINESS_NAME_KEY = "legal_name",
  BUSINESS_EIN_KEY = "tax_code",
  BUSINESS_TYPE_KEY = "business_type",
  BUSINESS_WEBSITE_URL_KEY = "website_url";

export const BUSINESS_ADDRESS_STREET_KEY = "street",
  BUSINESS_ADDRESS_CITY_KEY = "city",
  BUSINESS_ADDRESS_REGION_KEY = "region",
  BUSINESS_ADDRESS_POSTAL_CODE_KEY = "postal_code",
  BUSINESS_ADDRESS_COUNTRY_CODE_KEY = "country_code";

export const BUSINESS_REPRESENTATIVE_BUSINESS_TITLE = "business_title",
  BUSINESS_REPRESENTATIVE_JOB_POSITION = "job_position",
  BUSINESS_REPRESENTATIVE_FIRST_NAME = "first_name",
  BUSINESS_REPRESENTATIVE_LAST_NAME = "last_name",
  BUSINESS_REPRESENTATIVE_PHONE_NUMBER = "phone_number",
  BUSINESS_REPRESENTATIVE_EMAIL = "email";

export const BUSINESS_INFORMATION_KEY = "business_details",
  BUSINESS_ADDRESS_KEY = "address",
  BUSINESS_REPRESENTATIVE_KEY = "account_representative";

export const BUSINESS_INFORMATION = {
  [BUSINESS_NAME_KEY]: "",
  [BUSINESS_EIN_KEY]: "",
  [BUSINESS_TYPE_KEY]: "4",
  [BUSINESS_WEBSITE_URL_KEY]: "",
};

export const BUSINESS_ADDRESS = {
  [BUSINESS_ADDRESS_STREET_KEY]: "",
  [BUSINESS_ADDRESS_CITY_KEY]: "",
  [BUSINESS_ADDRESS_REGION_KEY]: "",
  [BUSINESS_ADDRESS_POSTAL_CODE_KEY]: "",
  [BUSINESS_ADDRESS_COUNTRY_CODE_KEY]: "US",
};

export const BUSINESS_REPRESENTATIVE = {
  [BUSINESS_REPRESENTATIVE_BUSINESS_TITLE]: "",
  [BUSINESS_REPRESENTATIVE_JOB_POSITION]: "1",
  [BUSINESS_REPRESENTATIVE_FIRST_NAME]: "",
  [BUSINESS_REPRESENTATIVE_LAST_NAME]: "",
  [BUSINESS_REPRESENTATIVE_PHONE_NUMBER]: "+1",
  [BUSINESS_REPRESENTATIVE_EMAIL]: "",
};

export const BUSINESS_INFORMATION_LABELS = {
  [BUSINESS_NAME_KEY]: {
    label: "Business Name",
    subtext: `If you're registering a US entity, please enter the exact legal business name as registered with the EIN,\nwhich can be found on the CP 575 EIN Confirmation Letter.`,
  },
  [BUSINESS_EIN_KEY]: {
    label: "EIN Number/Tax Code",
    subtext: "Enter the EIN / Tax ID as it appears in the EIN listing",
  },
  [BUSINESS_TYPE_KEY]: { label: "Business Type", subtext: "" },
  [BUSINESS_WEBSITE_URL_KEY]: {
    label: "Business Site [Website, Social Media Page or LinkedIn]",
    subtext:
      "The provided website should be reflective of the registered brand and accessible.",
  },
};

export const BUSINESS_ADDRESS_LABELS = {
  [BUSINESS_ADDRESS_STREET_KEY]: {
    label: "Street",
    subtext:
      "Enter the first line of business address as it appears in the EIN listing.",
  },
  [BUSINESS_ADDRESS_CITY_KEY]: {
    label: "City",
    subtext: "Enter the city name as it appears in the EIN listing.",
  },
  [BUSINESS_ADDRESS_REGION_KEY]: {
    label: "Region/State",
    subtext:
      "Enter the state/province/region as it appears in the EIN listing. Use Short Form only",
  },
  [BUSINESS_ADDRESS_POSTAL_CODE_KEY]: {
    label: "Postal/ Zip Code",
    subtext: "Enter the Postal/ Zip Code as it appears in the EIN listing.",
  },
  [BUSINESS_ADDRESS_COUNTRY_CODE_KEY]: {
    label: "Country",
  },
};

export const BUSINESS_REPRESENTATIVE_LABELS = {
  [BUSINESS_REPRESENTATIVE_BUSINESS_TITLE]: "Business Title",
  [BUSINESS_REPRESENTATIVE_JOB_POSITION]: "Job Position",
  [BUSINESS_REPRESENTATIVE_FIRST_NAME]: "First Name",
  [BUSINESS_REPRESENTATIVE_LAST_NAME]: "Last Name",
  [BUSINESS_REPRESENTATIVE_PHONE_NUMBER]: {
    label: "Contact Phone Number",
    subtext: "Enter phone number with Country code (+1 for US)",
  },
  [BUSINESS_REPRESENTATIVE_EMAIL]: "Contact Email Address",
};

const BUSINESS_INFORMATION_DEFAULT = {
  [BUSINESS_ADDRESS_KEY]: BUSINESS_ADDRESS,
  [BUSINESS_INFORMATION_KEY]: BUSINESS_INFORMATION,
  [BUSINESS_REPRESENTATIVE_KEY]: BUSINESS_REPRESENTATIVE,
};

export const BUSINESS_TYPE_PARTNERSHIP = 0,
  BUSINESS_TYPE_LLC = 1,
  BUSINESS_TYPE_COOP = 2,
  BUSINESS_TYPE_NP = 3,
  BUSINESS_TYPE_CORP = 4;

export const BUSINESS_TYPE_LABELS = {
  [BUSINESS_TYPE_PARTNERSHIP]: "Partnership",
  [BUSINESS_TYPE_LLC]: "Limited Liability Corporation",
  [BUSINESS_TYPE_COOP]: "Co-operative",
  [BUSINESS_TYPE_NP]: "Non-profit Corporation",
  [BUSINESS_TYPE_CORP]: "Corporation",
};

export const JOB_POSITION_OTHER = 0,
  JOB_POSITION_DIRECTOR = 1,
  JOB_POSITION_GM = 2,
  JOB_POSITION_VP = 3,
  JOB_POSITION_CEO = 4,
  JOB_POSITION_CFO = 4,
  JOB_POSITION_GC = 5;

export const JOB_POSITION_LABELS = {
  [JOB_POSITION_OTHER]: "Other",
  [JOB_POSITION_DIRECTOR]: "Director",
  [JOB_POSITION_GM]: "GM",
  [JOB_POSITION_VP]: "VP",
  [JOB_POSITION_CEO]: "CEO",
  [JOB_POSITION_CFO]: "CFO",
  [JOB_POSITION_GC]: "General Counsel",
};

export const BUSINESS_INFO_ERROR_MESSAGES = {
  [BUSINESS_EIN_KEY]: "Invalid EIN (EIN is 9 digit number)",
  [BUSINESS_WEBSITE_URL_KEY]: `Invalid Website Url (Website URL should start with "https://")`,
};

export const BUSINESS_ADDRESS_ERROR_MESSAGES = {
  [BUSINESS_ADDRESS_REGION_KEY]:
    "State/Region should be in short code format (for eg. TX)",
};

export const BUSINESS_REPRESENTATIVE_ERROR_MESSAGES = {
  [BUSINESS_REPRESENTATIVE_PHONE_NUMBER]: "Invalid Phone Number",
  [BUSINESS_REPRESENTATIVE_EMAIL]: "Invalid Email Address",
};

export const BUSINESS_TYPES = Object.entries(BUSINESS_TYPE_LABELS).map(
  (option) => ({ name: option[1], value: option[0] }),
);

export const JOB_POSITIONS = Object.entries(JOB_POSITION_LABELS).map(
  (option) => ({
    name: option[1],
    value: option[0],
  }),
);

export const CAMPAIGN_STATUS_KEY = "campaign_status";
export const CAMPAIGN_STATUS_NOT_SUBMITTED = null;
export const CAMPAIGN_STATUS_DRAFT = "DRAFT";
export const CAMPAIGN_STATUS_PENDING = "PENDING";
export const CAMPAIGN_STATUS_VERIFIED = "VERIFIED";
export const CAMPAIGN_STATUS_REJECTED = "REJECTED";

export const CAMPAIGN_STATUS = {
  [CAMPAIGN_STATUS_NOT_SUBMITTED]: {
    label: "Not Submitted",
    color: palette.grey,
    message:
      "Your business information for 10DLC verification has not been submitted.",
  },
  [CAMPAIGN_STATUS_DRAFT]: {
    label: "In Progress",
    color: palette.primary,
    message:
      "Your business information for the 10DLC campaign is currently undergoing the verification process.",
  },
  [CAMPAIGN_STATUS_PENDING]: {
    label: "In Progress",
    color: palette.primary,
    message:
      "Your business information for the 10DLC campaign is currently undergoing the verification process.",
  },
  [CAMPAIGN_STATUS_VERIFIED]: {
    label: "Verified",
    color: palette.green,
    message:
      "The 10DLC campaign has successfully passed verification, you now have SMS functionality with FieldPulse Engage. ",
  },
  [CAMPAIGN_STATUS_REJECTED]: {
    label: "Rejected",
    color: palette.red,
    message:
      "Your business information for the 10DLC campaign did not pass verification. Please reach out to Fieldpulse Customer Support for guidance on the next steps.",
  },
};

export const BRAND_STATUS_KEY = "brand_status";
export const BRAND_STATUS_NOT_SUBMITTED = null;
export const BRAND_STATUS_PENDING = "PENDING";
export const BRAND_STATUS_APPROVED = "APPROVED";
export const BRAND_STATUS_REJECTED = "REJECTED";

export default BUSINESS_INFORMATION_DEFAULT;
