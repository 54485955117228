export const WORKING_HOURS_KEY = "working_hours";
export const WORKING_HOURS_TIMEZONE_KEY = "timezone";
export const WORKING_HOURS_ENABLED_KEY = "wh_enabled";
export const WORKING_HOURS_GREETING_KEY = "wh_greeting";
export const WORKING_HOURS_GREETING_TYPE_KEY = "wh_greeting_type";
export const WORKING_HOURS_AUDIO_KEY = "wh_audio_url";
export const WORKING_HOURS_REDIRECT_TO_KEY = "redirect_to";

export const ALL_WORKING_HOURS = [
  {
    monday: [{ start: "0000", finish: "2400" }],
  },
  {
    tuesday: [{ start: "0000", finish: "2400" }],
  },
  {
    wednesday: [{ start: "0000", finish: "2400" }],
  },
  {
    thursday: [{ start: "0000", finish: "2400" }],
  },
  {
    friday: [{ start: "0000", finish: "2400" }],
  },
  { saturday: [{ start: "0000", finish: "2400" }] },
  { sunday: [{ start: "0000", finish: "2400" }] },
];

export const DAYS_ENUM = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

export const WORKING_HOURS = [
  {
    monday: [],
  },
  {
    tuesday: [],
  },
  {
    wednesday: [],
  },
  {
    thursday: [],
  },
  {
    friday: [],
  },
  { saturday: [] },
  { sunday: [] },
];

export const HOURS = [
  "12",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
