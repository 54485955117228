import React, {
  useContext,
  useCallback,
  useMemo,
  createContext,
  useState,
  useEffect,
} from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import Icon from "react-native-vector-icons/Feather";

import { RecordingsContext } from "~/contexts/RecordingsContext";

import IconButton from "~/components/Buttons/IconButton/index";
import Heading from "~/components/Heading/index";
import palette from "~/styles/palette";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import { getSmartTimestamp } from "~/helpers/day";
import dayjs from "~/helpers/day";
import useTwilio from "~/hooks/useTwilio";
import Engage from "../../services/engage";
import { useHistory } from "react-router-dom";

const AudioExpandedContext = createContext({
  currentlyExpanded: null,
  onExpand: () => {},
});

function AudioExpandedProvider({ children }) {
  const [current, setCurrent] = useState(null);

  return (
    <AudioExpandedContext.Provider
      value={{
        currentlyExpanded: current,
        onExpand: setCurrent,
      }}
    >
      {children}
    </AudioExpandedContext.Provider>
  );
}

function useAudioExpanded(self) {
  const { currentlyExpanded, onExpand } = useContext(AudioExpandedContext);

  const selfIsExpanded = currentlyExpanded && currentlyExpanded.id === self.id;

  const expand = () => onExpand(self);

  return {
    selfIsExpanded,
    expand,
  };
}
const RecordingList = ({ onRecordingPress, style = {} }) => {
  const {
    loading,
    initialLoading,
    recordings,
    getRecordings,
    refreshRecordings,
    nextPage,
  } = useContext(RecordingsContext);

  useEffect(() => {
    refreshRecordings();
  }, []);

  return (
    <View style={[styles.container, style]}>
      <AudioExpandedProvider>
        {initialLoading ? (
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <ActivityIndicator size="large" />
          </View>
        ) : (
          <FlatList
            data={recordings}
            refreshing={loading}
            onRefresh={refreshRecordings}
            renderItem={renderRecordingListItem(onRecordingPress)}
            showsVerticalScrollIndicator={true}
            keyExtractor={(item) => `recording-list-item-${item.id}`}
            onEndReached={nextPage}
            ListEmptyComponent={NoRecordingsComponent}
          />
        )}
      </AudioExpandedProvider>
    </View>
  );
};

const renderRecordingListItem = (onPress) => (props) => {
  return <RecordingListItem {...props} onPress={onPress} />;
};

const RecordingListItem = ({ item, onPress }) => {
  const { selfIsExpanded, expand } = useAudioExpanded(item);
  const [isRead, setIsRead] = useState(item.read);
  const history = useHistory();
  const onRecordingPress = () => {
    expand(item);
    Engage.markRecordingAsRead(item.id).then(() => {
      setIsRead(true);
    });
    onPress(item);
  };

  return (
    <TouchableWithoutFeedback onPress={onRecordingPress}>
      <View
        style={[
          styles.recording_list__item,
          {
            backgroundColor: selfIsExpanded
              ? palette.lighter_grey
              : palette.white,
            cursor: "pointer",
          },
        ]}
      >
        <Row style={{ height: "100%" }}>
          <Col leftCenter pr={8}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={[styles.recording_list__item_name]}
            >
              {item.participant.name}
            </Text>
            <Text
              numberOfLines={1}
              style={[styles.recordings_list__item_subtext]}
            >
              {dayjs(item.created_at).format("h:mm A - ddd, MMM Do")}
            </Text>
          </Col>

          <Col>
            <Row style={{ height: "100%" }}>
              <Col leftCenter minWidth={100}>
                <Text
                  numberOfLines={1}
                  style={[styles.recording_list__item_name]}
                >
                  {item.author?.name}
                </Text>
              </Col>

              <Col rightCenter>
                <View style={styles.recording_list__button_container}>
                  {!isRead && (
                    <Text style={[styles.recording__list_item_read_indicator]}>
                      &#8226;
                    </Text>
                  )}
                  <IconButton
                    height={20}
                    width={20}
                    iconName="info"
                    iconColor={item.url ? palette.primary : palette.light_grey}
                    onPress={onRecordingPress}
                  />
                  <IconButton
                    height={20}
                    width={20}
                    iconName="trash"
                    onPress={() => {
                      history.push(
                        { hash: "confirm-delete" },
                        {
                          message: `This will delete recording with ${item.participant.name}. Do you want to continue?`,
                          type: "recording",
                          id: item.id,
                        },
                      );
                    }}
                    iconColor={palette.danger}
                  />
                </View>
              </Col>
            </Row>
          </Col>
        </Row>
      </View>
    </TouchableWithoutFeedback>
  );
};

const NoRecordingsComponent = () => {
  return (
    <View style={[styles.no_conversations_component]}>
      <Heading center size={4} color={palette.grey}>
        No recordings
      </Heading>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  no_conversations_component: {
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 64,
  },
  recording_list__item: {
    height: 65,
    paddingHorizontal: 16,
    paddingRight: 8,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: palette.light_grey,
  },
  recording_list__item_name: {
    fontWeight: "600",
    fontSize: 16,
  },
  recordings_list__item_subtext: {
    fontSize: 14,
    color: palette.grey,
  },
  recording_list__item_duration: {
    fontSize: 12,
  },
  recording_list__item_missed: {
    color: palette.danger,
  },
  recording__list_item_read_indicator: {
    color: palette.info,
    fontSize: 48,
    position: "absolute",
    left: -10,
    top: "50%",
    transform: "translate(-50%, -55%)",
  },
  recording_list__button_container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
});

export default RecordingList;
