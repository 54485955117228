import { useCallback, useEffect, useState } from "react";
import { getDataAsync, storeDataAsync } from "../utils/cache";

const useCache = (key, value = null) => {
  const [cache, setCacheState] = useState(value);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      const cache = await getDataAsync(key);
      if (cache) {
        setCacheState(cache);
      } else {
        setCache(value);
      }
      setReady(true);
    })();
  }, []);

  const setCache = useCallback(async (cache) => {
    await storeDataAsync(key, cache);
    setCacheState(cache);
  }, []);

  return [cache, setCache, ready];
};

export default useCache;
