import { useContext } from "react";
import Icon from "react-native-vector-icons/Feather";
import { StyleSheet, Text, View } from "react-native-web";
import { Handle, Position } from "reactflow";
import { PhoneTreeContext } from "../../contexts/PhoneTreeContext";
import palette from "../../styles/palette";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { DEFAULT_ACTIVE_NODE } from "../../constants/phoneTreeTypes";

const styles = StyleSheet.create({
  node_container: {
    minHeight: 50,
    maxHeight: 200,
    minWidth: 200,
    maxWidth: 200,
    backgroundColor: palette.white,
    padding: 5,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    fontSize: 24,
  },
});

export default function EmptyNode({ data }) {
  const { activeNode, setActiveNode } = useContext(PhoneTreeContext);
  return (
    <View
      style={[
        styles.node_container,
        {
          border:
            activeNode?.index === data.index
              ? `2px dashed ${palette.primary}`
              : `2px dashed ${palette.grey}`,
        },
      ]}
      onClick={() => {
        setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...data });
      }}
    >
      <Handle type="target" position={Position.Top} />
      <FontAwesomeIcon icon={faPlus} color={palette.grey} size={20} />
      <Handle type="source" position={Position.Bottom} />
    </View>
  );
}
