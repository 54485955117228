import { parsePhoneNumber } from "libphonenumber-js";
import { Text } from "react-native";

export const getFullName = (firstName, lastName, displayName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  } else if (displayName) {
    return displayName;
  } else {
    return "";
  }
};

export const toTitleCase = (str) => {
  if (typeof str === "string") {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1);
    });
  }
  return null;
};

export const setCustomText = (customProps) => {
  const TextRender = Text.render;
  const initialDefaultProps = Text.defaultProps;
  Text.defaultProps = {
    ...initialDefaultProps,
    ...customProps,
  };
  Text.render = function render(props) {
    let oldProps = props;
    props = {
      ...props,
      style: [customProps.style, props.style],
    };
    try {
      return TextRender.apply(this, arguments);
    } finally {
      props = oldProps;
    }
  };
};

export function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value,
  );
}

export function validateTaxId(value) {
  return /^\d{2}\-?\d{7}$/.test(value);
}

export function validateEmail(value) {
  return /^\S+@\S+\.\S+$/.test(value);
}

let states = [
  "AA",
  "AB",
  "AE",
  "AK",
  "AL",
  "AP",
  "AR",
  "AS",
  "AZ",
  "BC",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "FM",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MB",
  "MD",
  "ME",
  "MH",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NB",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NL",
  "NM",
  "NS",
  "NT",
  "NU",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "ON",
  "PA",
  "PEI",
  "PR",
  "PW",
  "QC",
  "RI",
  "SC",
  "SD",
  "SK",
  "TN",
  "TX",
  "UM",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
  "YT",
];

export function isStateAbbreviation(state) {
  return state?.length >= 2 && states?.includes(state);
}

export function getMultilineNumberOfLines(str = "") {
  if (!str?.trim() || str?.length < 60) return 1;
  if (str?.length > 120) return 4;
  return 3;
}

export const getUrlParams = (url, decodeUri = false) => {
  let regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match;
  try {
    while ((match = regex.exec(url))) {
      let value = match[2];
      if (decodeUri) {
        value = decodeURIComponent(value);
      }
      params[match[1]] = value;
    }
  } catch (e) {}
  return params;
};

export const createNestedObject = function (base, names, value) {
  // If a value is given, remove the last name and keep it for later:
  let lastName = arguments.length === 3 ? names.pop() : false;

  // Walk the hierarchy, creating new objects where needed.
  // If the lastName was removed, then the last object is not set yet:
  for (let i = 0; i < names.length; i++) {
    base = base[names[i]] = base[names[i]] || {};
  }

  // If a value was given, set it to the last name:
  if (lastName) base = base[lastName] = value;

  // Return the last object in the hierarchy:
  return base;
};

export const getColonSeparatedHoursMinutesSeconds = (
  time,
  unit,
  alwaysShowHours,
) => {
  let seconds = time || 0;
  if (unit?.includes("milli")) {
    seconds /= 1000;
  }

  const hours = Math.floor(seconds / 3600) % 24;
  const mins = Math.floor(seconds / 60) % 60;
  const secs = Math.floor(seconds) % 60;

  const hoursText =
    hours !== 0 || alwaysShowHours ? `0${hours}:`.slice(-3) : "";
  const minutesText = `0${mins}`.slice(-2);
  const secondsText = `0${secs}`.slice(-2);

  return `${hoursText}${minutesText}:${secondsText}`;
};

export const getCommaSeparatedHoursMinutesSeconds = (time, unit, compact) => {
  let seconds = time || 0;
  if (unit?.includes("milli")) {
    seconds /= 1000;
  }

  const hours = Math.floor(seconds / 3600) % 24;
  const mins = Math.floor(seconds / 60) % 60;
  const secs = Math.floor(seconds) % 60;

  const hourLabel = compact ? "hr" : "hour";
  const minuteLabel = compact ? "min" : "minute";
  const secondLabel = compact ? "sec" : "second";

  const hoursText =
    hours !== 0 ? `${hours} ${hourLabel}${hours !== 1 ? "s" : ""}, ` : "";
  const minutesText =
    mins !== 0 ? `${mins} ${minuteLabel}${mins !== 1 ? "s" : ""}, ` : "";
  const secondsText = `${secs} ${secondLabel}${secs !== 1 ? "s" : ""}`;

  return `${hoursText}${minutesText}${secondsText}`;
};

export const fieldRequired = (attr, val) =>
  !val || val.length <= 0 ? `${attr} is required.` : false;

export const minLen = (attr, val, len) =>
  !val || val.length <= len
    ? `${attr} should be at least ${len} characters.`
    : false;

export const validEmail = (attr, val) =>
  val && val.length > 0 && !/^\S+@\S+\.\S+$/.test(val)
    ? `${attr} is not valid email.`
    : false;

export const validPhone = (attr, val) => {
  try {
    const num = parsePhoneNumber(val, "US");
    if (num.nationalNumber.length < 10) {
      return `${attr} should be at least 10 characters.`;
    }
  } catch (e) {
    return `${attr} is invalid phone number.`;
  }
  return false;
};
