import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { parsePhoneNumber } from "libphonenumber-js";
import { useHistory } from "react-router-dom";

import AvailableNumbersContext, {
  AvailableNumbersProvider,
} from "../../../contexts/AvailableNumbersContext";
import NumbersContext from "~/contexts/NumbersContext";

import Row from "../../../layouts/Row";
import Col from "../../../layouts/Col";
import IconButton from "../../../components/Buttons/IconButton/index";
import SolidButton from "../../../components/Buttons/SolidButton/index";

import Heading from "../../../components/Heading/index";
import Paragraph from "../../../components/Paragraph/index";
import Logo from "../../../components/Logo/index";
import palette from "../../../styles/palette";
import OnboardingContext from "../../../contexts/OnboardingContext";
import Engage from "../../../services/engage";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import Bundle from "../../../models/Bundle";

export default function AcquirePhoneNumberStep({
  previousStep,
  nextStep,
  totalSteps,
  currentStep,
}) {
  const history = useHistory();
  const {
    areaCode = "",
    countryCode,
    bundleData,
  } = useContext(OnboardingContext);
  const numCtx = useContext(NumbersContext);
  const [active, setActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onPress = useCallback(
    (item) => () => {
      setActive(item);
    },
    [setActive],
  );

  const onNext = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      const bundle = new Bundle(bundleData);
      const res = await Engage.reservePhoneNumber(
        active,
        bundle.getBundleData(),
      );
      if (res.error) {
        setError(res.message);
      } else {
        if (totalSteps === currentStep) {
          const { pathname } = history.location;

          if (numCtx) {
            const { getNumbers } = numCtx;
            getNumbers().then(() => {
              history.replace(pathname);
            });
          } else {
            history.replace(pathname);
          }
        } else {
          nextStep();
        }
      }
      setLoading(false);
    }
  }, [
    active,
    loading,
    nextStep,
    setLoading,
    setError,
    totalSteps,
    currentStep,
    history,
    numCtx,
    bundleData,
  ]);

  return (
    <AvailableNumbersProvider>
      <View
        style={{
          height: 500,
          padding: 24,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <View>
          <IconButton
            faPro
            faProIcon={faChevronLeft}
            ml={0}
            mb={0}
            onPress={previousStep}
          />
        </View>

        <View style={{ alignItems: "center" }}>
          <Logo height={70} width={70} />
        </View>
        <View>
          <Heading center mt={10} mb={25}>
            Select Engage number
          </Heading>
        </View>

        {error && (
          <View style={{ justifyContent: "center", flex: 1 }}>
            <Paragraph center mb={30} style={{ color: palette.danger }}>
              {error}
            </Paragraph>
          </View>
        )}

        <View>
          <AvailablePhoneNumbers
            onPress={onPress}
            goBack={previousStep}
            areaCode={areaCode}
            countryCode={countryCode}
            active={active}
            currentStep={currentStep}
            totalSteps={totalSteps}
          />
        </View>
        <View>
          <SolidButton
            loading={loading}
            label="Reserve Number"
            disabled={!active}
            color={active ? palette.primary : palette.light_grey}
            onPress={onNext}
          />
        </View>
      </View>
    </AvailableNumbersProvider>
  );
}

const AvailablePhoneNumbers = ({
  onPress,
  goBack,
  active,
  areaCode,
  countryCode,
  currentStep,
  totalSteps,
}) => {
  const {
    availableNumbers = [],
    loading,
    error,
    getAvailableNumbers,
  } = useContext(AvailableNumbersContext);
  const history = useHistory();

  useEffect(() => {
    if (
      (history.location.pathname === "/onboard" &&
        currentStep === totalSteps - 1) ||
      (history.location.pathname !== "/onboard" && currentStep === totalSteps)
    )
      getAvailableNumbers(areaCode, countryCode);
  }, [areaCode, countryCode, totalSteps, currentStep]);

  if (loading) {
    return (
      <View style={[styles.available_phone_number__container]}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (!loading && error) {
    return (
      <View style={[styles.available_phone_number__container]}>
        <Paragraph center style={[styles.available_phone_number__error_text]}>
          {error}
        </Paragraph>
        <TouchableOpacity onPress={goBack}>
          <Paragraph
            center
            style={[styles.available_phone_number__change_code_text]}
          >
            Try a different area code
          </Paragraph>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={[styles.available_phone_number__container]}>
      {availableNumbers.map((number, x) => (
        <PhoneNumber
          item={number}
          key={x}
          onPress={onPress(number)}
          active={active?.phone_number === number?.phone_number}
        />
      ))}
    </View>
  );
};

const PhoneNumber = ({ item, active, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={[
          styles.phone_number__container,
          active && styles.phone_number__container_active,
        ]}
      >
        <Row>
          <Col>
            <Text
              style={[
                styles.phone_number__text,
                active && styles.phone_number__text_active,
              ]}
            >
              {parsePhoneNumber(item.phone_number).formatInternational()}
            </Text>
          </Col>
          <Col right>
            <Text
              style={[
                styles.locality__text,
                active && styles.locality__text_active,
              ]}
            >
              {item.locality}
            </Text>
          </Col>
        </Row>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  available_phone_number__container: {},
  available_phone_number__change_code_text: {
    color: palette.primary_light,
  },
  phone_number__container: {
    cursor: "pointer",
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.light_grey,
    borderRadius: 4,
    padding: 12,
    height: 50,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginBottom: 8,
  },
  phone_number__container_active: {
    borderColor: palette.info,
    borderWidth: 2,
  },
  phone_number__text: {
    color: palette.grey,
    fontFamily: "acumin-pro, sans-serif",
    fontSize: 16,
  },
  phone_number__text_active: {
    color: palette.primary_light,
    fontWeight: "600",
  },
  locality__text: {
    color: palette.grey,
    fontFamily: "acumin-pro, sans-serif",
    fontSize: 14,
  },
  locality__text_active: {
    color: palette.primary_light,
    fontWeight: "600",
  },
});
