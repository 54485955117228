import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AdjustableScreen from "~/layouts/AdjustableScreen/index.web";
import InboxScreen from "~/screens/InboxScreen/index.web";
// import SettingsScreen              from "~/screens/SettingsScreen/index.web";
import AppContext from "~/contexts/AppContext";
import { RecordingProvider } from "../../contexts/RecordingContext";
import { RecordingsProvider } from "../../contexts/RecordingsContext";
import { VoicemailsProvider } from "../../contexts/VoicemailsContext";
import { CallsProvider } from "../../contexts/CallsContext";
import { TeamProvider } from "../../contexts/TeamContext";
import { CustomersProvider } from "../../contexts/CustomersContext";
import { ThreadsProvider } from "../../contexts/ThreadsContext";
import { MediaProvider } from "../../contexts/MediaContext";
import { ConversationProvider } from "~/contexts/ConversationContext";
import { PusherProvider } from "~/contexts/PusherContext";
import { NumbersProvider } from "../../contexts/NumbersContext";
import { TwilioProvider } from "../../contexts/TwilioContext/index.web";

export default function HomeCompactScreen() {
  const { lastConversationId, compactMode } = useContext(AppContext);

  console.log("Compact Mode");

  if (!compactMode) return <Redirect to="/" />;

  return (
    <NumbersProvider>
      <TwilioProvider>
        <PusherProvider>
          <MediaProvider>
            <ConversationProvider>
              <ThreadsProvider>
                <CustomersProvider isWeb={true}>
                  <TeamProvider>
                    <CallsProvider>
                      <VoicemailsProvider>
                        <RecordingsProvider>
                          <RecordingProvider>
                            <AdjustableScreen>
                              <Switch>
                                {/* <Route path="/compact/settings">
          <SettingsScreen />
        </Route> */}
                                <Route path="/compact/messages">
                                  <InboxScreen />
                                </Route>
                                <Route>
                                  {lastConversationId ? (
                                    <Redirect
                                      to={`/compact/messages/${lastConversationId}`}
                                    />
                                  ) : (
                                    <Redirect to={`/compact/messages`} />
                                  )}
                                </Route>
                              </Switch>
                            </AdjustableScreen>
                          </RecordingProvider>
                        </RecordingsProvider>
                      </VoicemailsProvider>
                    </CallsProvider>
                  </TeamProvider>
                </CustomersProvider>
              </ThreadsProvider>
            </ConversationProvider>
          </MediaProvider>
        </PusherProvider>
      </TwilioProvider>
    </NumbersProvider>
  );
}
