import React, { useCallback } from "react";
import { View } from "react-native";

import Row from "~/layouts/Row";
import SolidButton from "~/components/Buttons/SolidButton";
import palette from "~/styles/palette";
import useTwilio from "~/hooks/useTwilio";
import Dialer from "../../../components/Dialer/index.web";

export default function KeypadPanel({
  onClose = null,
  send = true,
  float = false,
}) {
  const { sendDigits } = useTwilio();

  const onDigitPress = useCallback(
    (digit) => {
      if (send) {
        sendDigits(digit);
      }
    },
    [send],
  );

  return (
    <View
      style={{
        backgroundColor: float ? palette.bg_light_grey : palette.navy,
        flex: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        flexDirection: "column",
        paddingVertical: float ? 8 : 0,
      }}
    >
      <Dialer
        buttonBgColor={float ? palette.grey : null}
        symbols
        onDigitPress={onDigitPress}
      />
      {!float && (
        <Row center mt={64}>
          <SolidButton onPress={onClose} label="Close" icon="x" />
        </Row>
      )}
    </View>
  );
}
