import { StyleSheet, View, Text } from "react-native";
import palette from "../../../../styles/palette";
import Heading from "../../../../components/Heading/index.web";
import { Strings } from "../../../../constants/strings";
import SolidButton from "../../../../components/Buttons/SolidButton";

export default function ResubmitCampaignScreen({ onModalOpen }) {
  return (
    <View style={styles.resubmit_campaign_container}>
      <Heading color={palette.primary} size={2} mt={0} mb={24}>
        10DLC Campaign
      </Heading>
      <Text style={styles.flicent_campaign_warning_message}>
        {Strings.FLICENT_CAMPAIGN_WARNING_MESSAGE}
      </Text>
      <SolidButton
        color={palette.success}
        label={Strings.RESUBMIT_CAMPAIGN_LABEL}
        onPress={onModalOpen}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  resubmit_campaign_container: {
    marginTop: 32,
    marginHorizontal: 16,
    padding: 32,
    paddingHorizontal: 32,
    backgroundColor: palette.grey_bg,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
  },
  flicent_campaign_warning_message: {
    marginBottom: 24,
    textAlign: "center",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
    color: palette.grey,
  },
});
