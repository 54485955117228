import React, { useCallback, useContext, useState, useEffect } from "react";
import { StyleSheet, Text, TextInput, Switch, View } from "react-native";

import {
  Link,
  Route,
  Switch as RouterSwitch,
  useLocation,
} from "react-router-dom";

import { useHistory } from "react-router-dom";

import Heading from "~/components/Heading/";
import SolidButton from "~/components/Buttons/SolidButton/";
import Screen from "~/layouts/Screen/index.web";
import NumbersList from "~/components/NumbersList/";
import AssignmentModal from "~/modals/AssignmentModal/";
import useDraft from "~/hooks/useDraft";
import Engage from "~/services/engage";

import NumberPurchaseModal from "~/modals/NumberPurchaseModal/index.web";
import HashRoute from "~/routers/HashRoute";
import UserContext from "~/contexts/UserContext";
import { NumberProvider } from "~/contexts/NumberContext";

import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import palette from "~/styles/palette";
import { NumberManagementModal } from "~/modals/NumberManagementModal/index.web";
import UserAvatar from "~/components/Avatar/UserAvatar/index.web";
import PhoneTreeScreen from "../PhoneTreeScreen/index.web";
import SettingsContext, {
  SettingsProvider,
} from "../../contexts/SettingsContext";
import { ActivityIndicator, TouchableWithoutFeedback } from "react-native-web";
import { PhoneTreeProvider } from "../../contexts/PhoneTreeContext";
import CustomRecordingsContext, {
  CustomRecordingsProvider,
} from "../../contexts/CustomRecordingsContext";
import { MediaContext } from "../../contexts/MediaContext";
import ReactAudioPlayer from "react-audio-player";
import StyledPicker from "../../components/StyledPicker";
import { GREETING_TYPES } from "../../constants/mediaTypes";
import WorkingHoursScreen from "../WorkingHoursScreen/index.web";
import { WorkingHoursProvider } from "../../contexts/WorkingHoursContext";
import IconButton from "../../components/Buttons/IconButton";
import { faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import ConversationContext from "../../contexts/ConversationContext";
import NotificationSettingsScreen from "../NotificationSettingsScreen/index.web";
import AudioDeletionModal from "../../modals/AudioDeletionModal/index.web";
import NumbersContext from "../../contexts/NumbersContext";
import ReportingScreen from "./ReportingScreen/index.web";
import { CallsReportingProvider } from "../../contexts/CallsReportingContext";
import { USER_ROLE_TEAM_MANAGER_ADMIN } from "../../constants/users";
import { BusinessProvider } from "../../contexts/BusinessContext";
import BusinessInformationScreen from "./BusinessInformationScreen/index.web";
import NumberEditSettings from "./NumberEditSettings/index.web";
import InfoIconButton from "../../components/InfoIconButton/index.web";
import { Strings } from "../../constants/strings";
import commonStyles from "../../styles/common";
import BlockedContactsScreen from "./BlockedContactsScreen";
import { BlockedContactsProvider } from "../../contexts/BlockedContactsContext";

export default function SettingsScreen() {
  const { updateSettings, isDisturbMode, user, loading, saving, isAdmin } =
    useContext(UserContext);

  const handleUpdateDoNotDisturb = (v) => {
    if (loading) return;

    updateSettings({
      do_not_disturb: v,
    });
  };

  return (
    <Screen safeArea>
      <SettingsProvider>
        <CustomRecordingsProvider>
          <WorkingHoursProvider>
            <BusinessProvider>
              <SettingsHeader />
              <Row style={[styles.container]}>
                <Col
                  maxWidth={300}
                  style={{
                    borderRightWidth: 1,
                    borderRightColor: palette.light_grey,
                  }}
                >
                  <Row
                    pl={32}
                    pr={16}
                    pt={16}
                    pb={16}
                    style={[styles.bottomBorder]}
                  >
                    <Col size={10}>
                      <Heading size={5}>Do Not Disturb</Heading>
                    </Col>
                    <Col size={2} pt={4}>
                      <Switch
                        value={isDisturbMode}
                        onValueChange={handleUpdateDoNotDisturb}
                        disabled={loading || saving}
                      />
                    </Col>
                  </Row>
                  <SettingItem label="Phone Numbers" to="/settings/numbers" />
                  {isAdmin && (
                    <>
                      <SettingItem
                        label="Voicemail Settings"
                        to="/settings/voicemails"
                      />
                      <SettingItem
                        label="Audio Samples"
                        to="/settings/voicenotes"
                      />
                      <SettingItem
                        label="Working Hours"
                        to="/settings/working-hours"
                      />
                      <SettingItem
                        label="Notifications"
                        to="/settings/notifications"
                      />
                      <SettingItem label="Reporting" to="/settings/reporting" />
                    </>
                  )}
                  {isAdmin && user?.account?.country !== "AU" && (
                    <SettingItem
                      label="10DLC Information"
                      to="/settings/10dlc"
                    />
                  )}
                  {isAdmin && (
                    <SettingItem
                      label="Blocked Contacts"
                      to="/settings/blocked-contacts"
                    />
                  )}
                </Col>
                <Col>
                  <RouterSwitch>
                    <Route
                      path="/settings/numbers"
                      component={NumberSettingsContent}
                    />
                    {isAdmin && (
                      <>
                        <Route
                          path="/settings/voicemails"
                          component={VoicemailSettingsContent}
                        />
                        <Route
                          path="/settings/voicenotes"
                          component={VoiceNotesContent}
                        />
                        <Route
                          path="/settings/working-hours"
                          component={WorkingHoursScreen}
                        />
                        <Route
                          path="/settings/notifications"
                          component={NotificationSettingsScreen}
                        />
                        <CallsReportingProvider>
                          <Route
                            path="/settings/reporting"
                            component={ReportingScreen}
                          />
                        </CallsReportingProvider>
                        <Route
                          path="/settings/blocked-contacts"
                          component={BlockedContactsScreen}
                        />
                        {user?.account?.country !== "AU" && (
                          <Route
                            path="/settings/10dlc"
                            component={BusinessInformationScreen}
                          />
                        )}
                      </>
                    )}
                    <Route component={SettingsPlaceHolder} />
                  </RouterSwitch>
                </Col>
              </Row>
              <RouterSwitch>
                <HashRoute
                  hash="#purchase-number"
                  path="/settings/numbers"
                  component={NumberPurchaseModal}
                />
              </RouterSwitch>
              <RouterSwitch>
                <HashRoute
                  hash="#delete-audio"
                  path="/settings/voicenotes"
                  component={AudioDeletionModal}
                />
              </RouterSwitch>
              <AlertPopup />
            </BusinessProvider>
          </WorkingHoursProvider>
        </CustomRecordingsProvider>
      </SettingsProvider>
    </Screen>
  );
}

const AlertPopup = () => {
  const {
    setIsDataChanged,
    alertVisible,
    toggleAlertPopup,
    location,
    setLocation,
  } = useContext(SettingsContext);

  if (!alertVisible) return null;
  const history = useHistory();

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.24)",
        position: "absolute",
        zIndex: 25000,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          maxWidth: 500,
          backgroundColor: palette.bg_light_grey,
          borderRadius: 6,
          border: `2px solid ${palette.light_grey}`,
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          padding: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            marginBottom: 10,
          }}
        >
          This will erase your progress. Do you want to continue?
        </Text>
        <Row right>
          <SolidButton
            label="Yes"
            color={palette.danger}
            onPress={() => {
              if (location) {
                history.replace(location);
                setLocation("");
              } else {
                history.goBack();
              }
              setIsDataChanged(false);
              toggleAlertPopup();
            }}
            style={{ marginRight: 10 }}
          />
          <SolidButton
            label="Cancel"
            onPress={() => {
              setLocation("");
              toggleAlertPopup();
            }}
          />
        </Row>
      </View>
    </View>
  );
};

const SettingsHeader = () => {
  const history = useHistory();
  const { isDataChanged, alertVisible, toggleAlertPopup, setLocation } =
    useContext(SettingsContext);
  const { setNewConversation } = useContext(ConversationContext);
  const { getNumbers } = useContext(NumbersContext);
  const { setIsWorkingHoursAlertVisible } = useContext(UserContext);
  return (
    <Row
      pt={8}
      pb={8}
      pl={16}
      pr={16}
      style={{
        borderBottomWidth: 1,
        borderBottomColor: palette.light_grey,
        height: 75,
      }}
    >
      <Col leftCenter maxWidth={50}>
        <IconButton
          faPro
          faProIcon={faXmark}
          iconColor={palette.black}
          iconSize={24}
          width={26}
          height={26}
          onPress={() => {
            setNewConversation(false);
            setIsWorkingHoursAlertVisible(true);
            if (isDataChanged && !alertVisible) {
              setLocation("/messages");
              toggleAlertPopup();
            } else {
              getNumbers();
              history.replace("/messages");
            }
          }}
        />
      </Col>
      <Col leftCenter>
        <Heading size={2}>Settings</Heading>
      </Col>
      <Col rightCenter maxWidth={60}>
        <UserAvatar size={40} />
      </Col>
    </Row>
  );
};

const SettingItem = ({ to, label }) => {
  const history = useHistory();
  const { isDataChanged, alertVisible, toggleAlertPopup, setLocation } =
    useContext(SettingsContext);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (history?.location?.pathname === to) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [history?.location?.pathname]);
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (isDataChanged && !alertVisible) {
          setLocation(to);
          toggleAlertPopup();
        } else {
          history.replace(to);
        }
      }}
      style={{
        position: "relative",
        color: palette.black,
      }}
    >
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
          paddingLeft: 32,
          paddingRight: 8,
          height: 60,
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          alignItems: "center",
          width: "100%",
          cursor: "pointer",
          backgroundColor: active ? palette.lighter_grey : palette.white,
        }}
      >
        <Heading size={5}>{label}</Heading>
      </View>
    </TouchableWithoutFeedback>
  );
};

const NumberSettingsContent = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const onPress = (number) => {
    history.push("/settings/numbers", {
      number,
    });
  };

  const onPurchaseNumberPress = useCallback(() => {
    history.push({ hash: "purchase-number" });
  }, [history]);

  if (location?.state?.number) {
    return (
      <Col>
        <NumberProvider value={location.state.number}>
          <NumberEditSettings />
          <RouterSwitch>
            <HashRoute path="/" hash="#assignees" component={AssignmentModal} />
            <HashRoute
              path="/"
              hash="#managers"
              component={NumberManagementModal}
            />
          </RouterSwitch>
          <RouterSwitch>
            <HashRoute
              path="/"
              hash="#managers"
              component={NumberManagementModal}
            />
          </RouterSwitch>
          <RouterSwitch>
            <PhoneTreeProvider>
              <HashRoute
                path="/"
                hash="#phone_tree"
                component={PhoneTreeScreen}
              />
            </PhoneTreeProvider>
          </RouterSwitch>
        </NumberProvider>
      </Col>
    );
  }

  return (
    <View>
      <Row
        style={{
          height: 74,
          borderBottomColor: palette.light_grey,
          borderBottomWidth: 1,
        }}
      >
        <Col leftCenter pl={16} pr={16} style={{}}>
          <Heading size={5}>Purchased Numbers</Heading>
        </Col>
        <Col rightCenter>
          {user.access_level === "admin" && (
            <SolidButton
              mr={32}
              faIcon={faPlus}
              label="Purchase Number"
              color={palette.primary}
              onPress={onPurchaseNumberPress}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col pl={16}>
          <NumbersList onNumberPress={onPress} />
        </Col>
      </Row>
    </View>
  );
};

const SettingsPlaceHolder = () => {
  return (
    <Row center fill>
      <Col center>
        <Heading size={4}>Update your account and number settings</Heading>
      </Col>
    </Row>
  );
};

const VoicemailSettingsContent = () => {
  const { user, getUser } = useContext(UserContext);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const greeting = user.account.default_voicemail_greeting;
  const greeting_type = user.account.default_voicemail_greeting_type;
  const greeting_url = user.account.default_voicemail_greeting_url;
  const [useAudio, setUseAudio] = useState(
    greeting_type === GREETING_TYPES.AUDIO,
  );

  const [active, setActive] = useState(
    greeting_type === GREETING_TYPES.AUDIO ? greeting_url : "-1",
  );

  const { customRecordings, getCustomRecordings, loading } = useContext(
    CustomRecordingsContext,
  );

  const { draft, dirty, updateDraft, resetDraft } = useDraft({
    default_voicemail_greeting: greeting,
    default_voicemail_greeting_type: greeting_type,
    default_voicemail_greeting_url: greeting_url,
  });

  const onSavePress = () => {
    if (saving) return;

    setSaving(true);
    Engage.updateVoicemailSettings(draft).then((res) => {
      if (res?.status !== 202) {
        setError(res);
      } else {
        getUser().then(() => {
          setSuccess(true);
          resetDraft();
          setSaving(false);
        });
      }
    });
  };

  useEffect(() => {
    if (useAudio) getCustomRecordings();
  }, [useAudio]);

  useEffect(() => {
    if (
      !loading &&
      (!active || active === "-1") &&
      Array.isArray(customRecordings) &&
      customRecordings.length > 0
    ) {
      setActive(customRecordings[0].url);
      updateDraft({
        default_voicemail_greeting_url: customRecordings[0].url,
      });
    }
  }, [loading, active, customRecordings]);

  return (
    <Col>
      <Row
        pl={16}
        pr={16}
        style={{
          height: 75,
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
        }}
      >
        <Col leftCenter>
          <View style={commonStyles.row_center}>
            <Heading size={5} pb={8} mb={0}>
              Voicemail Settings
            </Heading>
            <InfoIconButton link={Strings.VOICEMAIL_DOCS_URL} />
          </View>
        </Col>
      </Row>

      <Row pt={8}>
        <Col pl={16} pr={16} pt={8}>
          <Heading size={5}>Default Voicemail Greeting</Heading>
          <Heading numberOfLines={3} size={6} mb={10} color={palette.grey}>
            This greeting message will be used when you don't have any voicemail
            set for a particular number. You can specify voicemail greetings for
            specific numbers{" "}
            <Link
              to={"/settings/numbers"}
              style={{
                color: palette.primary_light,
                fontWeight: "600",
                fontFamily: "OpenSans_600SemiBold",
                fontSize: 14,
                textDecorationStyle: "none",
              }}
            >
              <Text>here</Text>
            </Link>
            .
          </Heading>
          <Row mt={16} mb={16}>
            <SolidButton
              label="Text to Speech"
              color={useAudio ? palette.white : palette.primary}
              onPress={() => {
                setUseAudio(false);
                updateDraft({
                  default_voicemail_greeting_type: GREETING_TYPES.TEXT,
                });
              }}
            />
            <SolidButton
              label="Audio Recording"
              color={useAudio ? palette.primary : palette.white}
              style={{ marginLeft: 8 }}
              onPress={() => {
                setUseAudio(true);
                updateDraft({
                  default_voicemail_greeting_type: GREETING_TYPES.AUDIO,
                });
              }}
            />
          </Row>

          <Col>
            {useAudio ? (
              <Row>
                {loading && (
                  <View
                    style={{
                      backgroundColor: palette.bg_light_grey,
                      paddingHorizontal: 20,
                      paddingVertical: 10,
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 4,
                      borderWidth: StyleSheet.hairlineWidth,
                      borderColor: palette.light_grey,
                    }}
                  >
                    <Heading size={5} mb={0} numberOfLines={null}>
                      Loading Audio Samples
                    </Heading>
                  </View>
                )}
                {!loading &&
                  customRecordings &&
                  (customRecordings.length === 0 ||
                    (customRecordings.length > 0 &&
                      customRecordings.filter((audio) => audio.url !== null)
                        .length === 0)) && (
                    <Heading size={5} mb={0} numberOfLines={null}>
                      You don't have any audio samples. You can add them{" "}
                      <Link
                        to={"/settings/voicenotes"}
                        style={{
                          color: palette.primary_light,
                          fontWeight: "600",
                          fontFamily: "OpenSans_600SemiBold",
                          textDecorationStyle: "none",
                        }}
                      >
                        <Text>here</Text>
                      </Link>
                      .
                    </Heading>
                  )}
                {!loading &&
                  customRecordings &&
                  customRecordings.length > 0 &&
                  customRecordings.filter((audio) => audio.url !== null)
                    .length > 0 && (
                    <Col>
                      <Row>
                        <StyledPicker
                          options={[
                            ...customRecordings
                              ?.filter((audio) => audio.url !== null)
                              .map((recording) => ({
                                name: recording.title,
                                value: recording.url,
                              })),
                          ]}
                          selectedValue={active}
                          onValueChange={(val) => {
                            setActive(val);
                            updateDraft({
                              default_voicemail_greeting_url: val,
                            });
                          }}
                        />
                      </Row>
                      {active !== "-1" && active && (
                        <Row mt={16}>
                          <ReactAudioPlayer src={active} controls />
                        </Row>
                      )}
                    </Col>
                  )}
              </Row>
            ) : (
              <TextInput
                numberOfLines={5}
                multiline
                textContentType="none"
                value={draft.default_voicemail_greeting}
                selectTextOnFocus
                style={[
                  {
                    // backgroundColor: palette.lightest_grey,
                    // borderTopWidth: 0,
                    // borderLeftWidth: 0,
                    // borderRightWidth: 0,
                    borderColor: palette.light_grey,
                    borderWidth: 1,
                    paddingVertical: 12,
                    paddingHorizontal: 12,
                    fontSize: 16,
                    fontWeight: "500",
                    width: "80%",
                    // outlineColor: palette.light_grey,
                    // outlineOffset: 0,
                    // outlineWidth: 0,
                    borderRadius: 4,
                  },
                ]}
                onChangeText={(value) =>
                  updateDraft({ default_voicemail_greeting: value })
                }
              />
            )}
            <Row mt={16}>
              <SolidButton
                onPress={onSavePress}
                loading={saving}
                color={palette.primary}
                disabled={
                  !dirty ||
                  (useAudio &&
                    (draft.default_voicemail_greeting_url === null ||
                      draft.default_voicemail_greeting_url === "-1")) ||
                  !draft?.default_voicemail_greeting ||
                  !draft?.default_voicemail_greeting?.trim()
                }
                label="Save"
              />
            </Row>
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

const VoiceNotesContent = () => {
  const {
    customRecordings,
    loading,
    saving,
    getCustomRecordings,
    active,
    setActive,
  } = useContext(CustomRecordingsContext);

  const { audioUploadVisible, setAudioUploadVisible, setActiveAudio } =
    useContext(MediaContext);

  const history = useHistory();

  useEffect(() => {
    if (!audioUploadVisible) getCustomRecordings();
  }, [audioUploadVisible]);

  const onDeleteAudioPress = useCallback(() => {
    history.push(
      { hash: "delete-audio" },
      {
        audioSample: customRecordings[active],
      },
    );
  }, [history, customRecordings, loading, active]);

  return (
    <Col>
      <Row
        pl={16}
        pr={16}
        style={{
          height: 75,
          borderBottomWidth: 1,
          borderBottomColor: palette.light_grey,
        }}
      >
        <Col leftCenter>
          <Heading size={5} pb={8} mb={0}>
            Audio Samples
          </Heading>
        </Col>
        <Col rightCenter>
          {!loading && !saving && customRecordings.length > 0 && (
            <SolidButton
              label="Add New Voice Note"
              onPress={() => {
                setAudioUploadVisible(true);
              }}
            />
          )}
        </Col>
      </Row>
      <Row pt={8} style={{ flex: "1 1 auto" }}>
        <Col pl={16} pr={16} pt={8} style={{ height: "100%" }}>
          <Heading
            size={5}
            mb={0}
            color={palette.grey}
            numberOfLines={null}
            style={{ flex: "0 1 auto" }}
          >
            Audio Samples are custom audio messages that can be used for
            voicemail greetings.
          </Heading>
          {!loading && customRecordings.length > 0 && (
            <View style={{ flex: "1 1 auto", height: "100%", marginTop: 16 }}>
              <Heading
                size={5}
                numberOfLines={null}
                style={{ flex: "0 1 auto" }}
              >
                Your Audio Samples
              </Heading>
              <Row style={{ alignItems: "center" }}>
                <Text style={{ marginRight: 16 }}>
                  Choose Voice Note to manage its content.
                </Text>
                <StyledPicker
                  options={customRecordings.map((recording, index) => ({
                    name: recording.title,
                    value: index,
                  }))}
                  selectedValue={active}
                  onValueChange={(val) => {
                    setActive(val);
                  }}
                />
              </Row>
              {active !== null && (
                <View
                  style={{
                    padding: 20,
                    backgroundColor: palette.bg_light_grey,
                    marginTop: 16,
                    borderRadius: 4,
                    borderWidth: StyleSheet.hairlineWidth,
                    borderColor: palette.light_grey,
                  }}
                >
                  <Row style={{ alignItems: "center" }}>
                    <Col size={2}>
                      <Heading
                        size={5}
                        mb={0}
                        color={palette.grey}
                        numberOfLines={null}
                      >
                        Title
                      </Heading>
                    </Col>
                    <Col>
                      <Heading size={6} mb={0} numberOfLines={null}>
                        {customRecordings[active]?.title || " "}
                      </Heading>
                    </Col>
                  </Row>
                  <Row mt={16} style={{ alignItems: "center" }}>
                    <Col size={2}>
                      <Heading
                        size={5}
                        mb={0}
                        color={palette.grey}
                        numberOfLines={null}
                      >
                        Description
                      </Heading>
                    </Col>
                    <Col>
                      <Heading size={6} mb={0} numberOfLines={null}>
                        {customRecordings[active]?.description}
                      </Heading>
                    </Col>
                  </Row>
                  <Row mt={16}>
                    <ReactAudioPlayer
                      src={customRecordings[active]?.url}
                      controls
                    />
                  </Row>
                  <Row mt={16}>
                    <Col size={1}>
                      <SolidButton
                        label="Edit"
                        onPress={() => {
                          setActiveAudio(customRecordings[active]);
                          setAudioUploadVisible(true);
                        }}
                      />
                    </Col>
                    <Col size={1} pl={8}>
                      <SolidButton
                        label="Delete"
                        loading={saving}
                        color={palette.danger}
                        onPress={onDeleteAudioPress}
                      />
                    </Col>
                  </Row>
                </View>
              )}
            </View>
          )}
        </Col>
      </Row>
      {(loading || saving) && (
        <View
          style={{
            position: "absolute",
            backgroundColor: palette.bg_light_grey,
            minWidth: 100,
            minHeight: 100,
            zIndex: 500,
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: palette.light_grey,
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
      {!loading && !saving && customRecordings.length === 0 && (
        <View
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading size={5} mb={0} color={palette.grey} numberOfLines={null}>
            No Voice Note found.
          </Heading>
          <Row mt={8}>
            <SolidButton
              label="Add New Voice Note"
              onPress={() => {
                setAudioUploadVisible(true);
              }}
            />
          </Row>
        </View>
      )}
    </Col>
  );
};

const styles = StyleSheet.create({
  greeting: {
    textAlign: "center",
    fontSize: 30,
  },
  text: {
    textAlign: "center",
    fontSize: 18,
    color: palette.grey,
  },
  listPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    height: "100%",
  },
  rightPanel: {
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
  },
  centerPanel: {
    borderRightWidth: 1,
    borderRightColor: palette.light_grey,
    // justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    minWidth: 372,
    height: "100%",
  },
  container: {
    flex: 1,
  },
  inner: {
    height: "100%",
  },
  list_item: {
    height: 60,
    paddingHorizontal: 16,
    paddingRight: 8,
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
  },
  list_item__name: {
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 16,
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
  },
  link: {
    color: palette.primary_light,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 18,
  },
});
