export const ESTIMATES_STATUS_ESTIMATE = 0;
export const ESTIMATES_STATUS_DRAFT = 5;
export const ESTIMATES_STATUS_SENT = 1;
export const ESTIMATES_STATUS_ACCEPTED = 2;
export const ESTIMATES_STATUS_REJECTED = 3;
export const ESTIMATES_STATUS_SCHEDULED = 6;
export const ESTIMATES_STATUS_READY = 7;
export const ESTIMATES_STATUS_LOST = 4;

export const ESTIMATES_STATUS = [
  {
    value: ESTIMATES_STATUS_ESTIMATE,
    label: "Estimate",
    color: "#747d8c",
  },
  {
    value: ESTIMATES_STATUS_DRAFT,
    label: "Draft",
    color: "#858585",
  },
  {
    value: ESTIMATES_STATUS_SENT,
    label: "Sent",
    color: "#54B7EC",
  },
  {
    value: ESTIMATES_STATUS_ACCEPTED,
    label: "Accepted",
    color: "#70D34B",
  },
  {
    value: ESTIMATES_STATUS_REJECTED,
    label: "Rejected",
    color: "#FF0100",
  },
  {
    value: ESTIMATES_STATUS_SCHEDULED,
    label: "Scheduled",
    color: "#00205B",
  },
  {
    value: ESTIMATES_STATUS_READY,
    label: "Ready",
    color: "#8811FF",
  },
  {
    value: ESTIMATES_STATUS_LOST,
    label: "Lost",
    color: "#333333",
  },
];
