import React, { useCallback, useContext } from "react";
import { StyleSheet, View, Text } from "react-native";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import Heading from "~/components/Heading/index";
import palette from "~/styles/palette";
import UserAvatar from "~/components/Avatar/UserAvatar/index.web";

import NumbersContext from "~/contexts/NumbersContext";
import SolidButton from "~/components/Buttons/SolidButton/";
import { useHistory } from "react-router-dom";

export default function CallsHeader() {
  const history = useHistory();
  const { activeNumber } = useContext(NumbersContext);

  return (
    <View style={[styles.customers_header__container]}>
      <View style={[styles.customers_header__inner]}>
        <Row pl={16} pr={16} style={{ height: "100%" }}>
          <Col leftCenter>
            <Row>
              <Heading size={1}>Calls</Heading>
              {activeNumber && (
                <SolidButton
                  label="Delete Call History"
                  color={palette.white}
                  labelColor={palette.danger}
                  style={{ marginLeft: 20, borderColor: palette.danger }}
                  onPress={() => {
                    history.push(
                      { hash: "confirm-delete" },
                      {
                        message:
                          "This will delete the call history. Do you want to continue?",
                        type: "all-calls",
                      },
                    );
                  }}
                />
              )}
            </Row>
          </Col>
          <Col rightCenter>
            {activeNumber ? (
              <Text style={[styles.conversation_header__active_number_text]}>
                Active Number: {activeNumber?.national_number}
              </Text>
            ) : (
              <Text style={[styles.conversation_header__active_number_text]}>
                No Active Number
              </Text>
            )}
          </Col>
          <Col rightCenter maxWidth={60}>
            <UserAvatar size={40} />
          </Col>
        </Row>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  customers_header__container: {
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
    backgroundColor: palette.primary_dark,
  },
  customers_header__inner: {
    backgroundColor: palette.white,
    height: 75,
    borderTopLeftRadius: 16,
  },
  customers_header__search_input: {
    width: 380,
    backgroundColor: palette.lightest_grey,
    fontSize: 18,
    paddingHorizontal: 24,
    borderRadius: 24,
  },
  context_header__count_text: {
    color: palette.grey,
    fontSize: 16,
  },
});
