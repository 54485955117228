import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import UserContext from "./UserContext";
import { EmailClientApi } from "@flicent/field-pulse-js-sdk";
import { APP_ENV } from "~/constants/app";
import { EMAIL_ENGINE_API_TOKEN } from "../constants/app/index.web";

const EmailContext = createContext({});

export const EmailProvider = ({ children }) => {
  const { fpUser, user, loggedIn, updateSettings, getUser } =
    useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState({});
  const [doesAccountExist, setDoesAccountExist] = useState(false);
  const [accessToken, setAccessToken] = useState(
    user?.settings?.email_engine_token,
  );
  const [accountState, setAccountState] = useState(null);
  const [emailClient, setEmailClient] = useState(null);
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [emails, setEmails] = useState([]);
  const [thread, setThread] = useState(null);
  const [loadingThread, setLoadingThread] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(null);
  const [loadingSelectedEmail, setLoadingSelectedEmail] = useState(false);
  const [selectedEmailData, setSelectedEmailData] = useState(null);
  const [loadingMailBoxes, setLoadingMailBoxes] = useState(null);
  const [mailBoxes, setMailBoxes] = useState([]);
  const [selectedMailBox, setSelectedMailBox] = useState(null);
  const [selectedDirection, setSelectedDirection] = useState("from");
  const [totalPages, setTotalPages] = useState(0);
  const [emailClientError, setEmailClientError] = useState(false);

  const ALL_ACCESS_TOKEN = EMAIL_ENGINE_API_TOKEN;

  const [page, setPage] = useState(0);

  const isGoogleAccount = useMemo(() => {
    if (!account) return false;
    const imap = account?.imap?.host;
    if (imap === "imap.gmail.com") {
      setSelectedDirection("both");
      return true;
    }
    return false;
  }, [account?.imap?.host]);

  const getAccessToken = useCallback(async () => {
    if (!emailClient) return;

    try {
      const res = await emailClient.createToken(`${fpUser?.id}`);
      if (res?.token) {
        setAccessToken(res?.token);
        const emailClient = new EmailClientApi({
          mode: APP_ENV,
          token: res?.token,
        });

        setEmailClient(() => emailClient);
        return res?.token;
      }
    } catch {}
    return null;
  }, [emailClient, fpUser?.id]);

  const getMailBoxes = useCallback(async () => {
    if (!emailClient || !doesAccountExist || !accessToken) return;
    setLoadingMailBoxes(true);
    try {
      const res = await emailClient.getMailboxes(fpUser?.id);
      if (!res?.error) {
        setMailBoxes(() => res?.mailboxes);
        setSelectedMailBox(() => res?.mailboxes[0]?.path);
      }
    } catch {}
    setLoadingMailBoxes(false);
  }, [emailClient, fpUser?.id, doesAccountExist]);

  const generateAuthLink = useCallback(async () => {
    if (!emailClient) return;
    try {
      const res = await emailClient.generateAuthLink({
        account: `${fpUser?.id}`,
        redirectUrl:
          document?.location?.href || "https://staging-engage.fieldpulse.com",
      });
      return res;
    } catch {}
  }, [emailClient, fpUser?.id]);

  const getEmailsByAddress = useCallback(
    async (email_address, options = {}) => {
      if (!emailClient || !selectedMailBox) return;
      try {
        setLoadingEmails(true);
        const searchParams = {};
        if (isGoogleAccount && selectedDirection === "both") {
          searchParams.gmailRaw = email_address;
        } else {
          if (selectedDirection === "to") {
            searchParams.to = email_address;
          } else {
            searchParams.from = email_address;
          }
        }
        const res = await emailClient.searchMessages(
          `${fpUser?.id}`,
          {
            page: options?.page ?? page,
            pageSize: 20,
            path: selectedMailBox,
          },
          searchParams,
        );
        if (!res.error) {
          setEmails((emails) =>
            page > 0 ? [...emails, ...res?.messages] : [...res?.messages],
          );
          setTotalPages(res?.pages);
        } else {
          setEmails([]);
        }
      } catch (e) {
        console.error(e);
      }
      setLoadingEmails(false);
    },
    [
      emailClient,
      fpUser?.id,
      page,
      selectedMailBox,
      selectedDirection,
      account?.email,
      isGoogleAccount,
    ],
  );

  const getAccount = useCallback(async () => {
    if (!emailClient) return;
    setLoading(true);
    try {
      const res = await emailClient.getAccountById(`${fpUser?.id}`);
      if (res.message === "Failed to fetch") {
        setEmailClientError(true);
        return;
      }
      if (res.statusCode === 404) {
        setDoesAccountExist(false);
      } else {
        setDoesAccountExist(true);
        setAccount(res);
        setAccountState(res?.state);
        if (!accessToken) await getAccessToken();
        await getMailBoxes();
      }
    } catch {}
    setLoading(false);
  }, [emailClient, fpUser?.id, getMailBoxes, getAccessToken, accessToken]);

  useEffect(() => {
    if (loggedIn) {
      if (!emailClient && !accessToken) {
        const emailClient = new EmailClientApi({
          mode: APP_ENV,
          token: ALL_ACCESS_TOKEN,
        });

        setEmailClient(() => emailClient);
      }
      getAccount();
    }
  }, [loggedIn, getAccount, accessToken]);

  const getMessageData = useCallback(async () => {
    if (!emailClient || !selectedEmail) return;
    setLoadingSelectedEmail(true);
    await emailClient
      .getMessage(`${fpUser?.id}`, selectedEmail?.id, {
        webSafeHtml: true,
        embedAttachedImages: true,
        preProcessHtml: false,
        documentStore: false,
        markAsSeen: true,
      })
      .then((res) => {
        if (!res?.error) {
          setSelectedEmailData(res);
        }
      })
      .finally(() => {
        setLoadingSelectedEmail(false);
      });
  }, [emailClient, selectedEmail?.id, fpUser?.id]);

  const getThread = useCallback(async () => {
    if (!emailClient || !selectedMailBox) return;
    try {
      setLoadingThread(true);
      const res = await emailClient.searchMessages(
        `${fpUser?.id}`,
        {
          page: page,
          pageSize: 20,
          path: selectedMailBox,
        },
        {
          threadId: selectedEmail?.threadId,
        },
      );
      if (!res.error) {
        setThread(res.messages);
      }
    } catch (e) {
      console.error(e);
    }
    setLoadingThread(false);
  }, [emailClient, fpUser?.id, selectedEmail, selectedMailBox]);

  const loadMoreEmails = () => {
    setPage((page) => page + 1);
  };

  useEffect(() => {
    if (selectedEmail) {
      setSelectedEmailIndex(null);
    }
    setPage(0);
  }, [selectedDirection]);

  useEffect(() => {
    if (accessToken && !user?.settings?.email_engine_token) {
      const updateUser = async () => {
        await updateSettings({
          ...user?.settings,
          email_engine_token:
            typeof accessToken === "string" ? accessToken : null,
        });
      };
      updateUser();
    }
  }, [accessToken]);

  useEffect(() => {
    if (typeof user?.settings?.email_engine_token === "string") {
      const emailClient = new EmailClientApi({
        mode: APP_ENV,
        token: user?.settings?.email_engine_token,
      });

      setEmailClient(() => emailClient);
    }
  }, [user?.settings?.email_engine_token]);

  return (
    <EmailContext.Provider
      value={{
        doesAccountExist,
        loading,
        account,
        accessToken,
        accountState,
        selectedEmail,
        setSelectedEmail,
        thread,
        emails,
        loadingEmails,
        loadingThread,
        loadingSelectedEmail,
        selectedEmailData,
        generateAuthLink,
        selectedMailBox,
        setSelectedMailBox,
        mailBoxes,
        loadingMailBoxes,
        selectedDirection,
        setSelectedDirection,
        getMessageData,
        getThread,
        emailClient,
        getEmailsByAddress,
        emailClientError,
        selectedEmailIndex,
        setSelectedEmailIndex,
        totalPages,
        loadMoreEmails,
        page,
        isGoogleAccount,
      }}
    >
      {children}
    </EmailContext.Provider>
  );
};

export default EmailContext;
