import React, { useContext, useEffect, useMemo } from "react";
import { View, StyleSheet } from "react-native";

import SolidButton from "../../../components/Buttons/SolidButton/index";

import Heading from "../../../components/Heading/index";
import Paragraph from "../../../components/Paragraph/index";
import Logo from "../../../components/Logo/index";
import palette from "../../../styles/palette";
import OnboardingContext from "../../../contexts/OnboardingContext";
import StyledPicker from "../../../components/StyledPicker";
import { AUS_REGIONS, COUNTRIES_ENABLED } from "../../../constants/countries";
import Col from "../../../layouts/Col";
import useDraft from "../../../hooks/useDraft";
import UserContext from "../../../contexts/UserContext";
import AusBundleStatusStep from "../AusBundleStatusStep/index.web";

export default function SelectCountryStep({ nextStep }) {
  const { countryCode, setCountryCode } = useContext(OnboardingContext);
  const { user } = useContext(UserContext);
  const userCountryCode = user?.account?.country || "US";

  if (userCountryCode === "AU") {
    return <AusBundleStatusStep nextStep={nextStep} />;
  }

  return (
    <View
      style={{
        flex: 1,
        padding: 24,
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <View style={{ alignItems: "center", marginTop: 44 }}>
        <Logo height={70} width={70} />
      </View>

      <View>
        <Heading center mt={10} mb={15}>
          Preferred Country code
        </Heading>
      </View>

      <View style={{ justifyContent: "center", flex: 1 }}>
        <Paragraph center mb={30}>
          Select the preferred country of your new Engage number.
        </Paragraph>
      </View>

      <Col>
        <StyledPicker
          selectedValue={countryCode}
          options={COUNTRIES_ENABLED}
          onValueChange={setCountryCode}
          style={{ marginBottom: 8 }}
        />
        <SolidButton
          label={"Select Area Code"}
          color={palette.primary}
          onPress={nextStep}
        />
      </Col>
    </View>
  );
}

const styles = StyleSheet.create({
  area_code__input: {
    textAlign: "center",
  },
});
