import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Engage from "~/services/engage";
import { sortByCreatedAt } from "~/helpers/sort";
import useTwilio from "../hooks/useTwilio";

export const RecordingsContext = createContext({});

export const RecordingsProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [recordings, setRecordings] = useState([]);
  const { activeCall } = useTwilio();

  const getRecordings = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    const res = await Engage.getRecordings({ page, limit: 30 });

    if (res.response && !res.error) {
      if (res.response.length < 30) {
        setAllLoaded(true);
      } else {
        setAllLoaded(false);
      }

      let data = sortByCreatedAt(res.response);

      setRecordings(page === 1 ? data : [...recordings, ...data]);
    } else {
      setError(res);
    }
    setLoading(false);
  }, [setLoading, setRecordings, page, activeCall]);

  const refreshRecordings = async () => {
    if (page === 1) {
      await getRecordings();
    } else {
      setPage(1);
    }
  };

  const prevPage = useCallback(() => {
    if (page === 1 || loading) return;
    setPage(page - 1);
  }, [loading]);

  const nextPage = useCallback(() => {
    if (loading || allLoaded) return;
    setPage(page + 1);
  }, [loading, allLoaded]);

  useEffect(() => {
    // getRecordings();
  }, [page, activeCall]);

  const deleteRecording = async (id) => {
    if (!id) return;
    await Engage.deleteRecording(id);
  };

  const initialLoading = useMemo(() => loading && page === 1);

  return (
    <RecordingsContext.Provider
      value={{
        error,
        loading,
        recordings,
        getRecordings,
        refreshRecordings,
        nextPage,
        prevPage,
        deleteRecording,
        initialLoading,
      }}
    >
      {children}
    </RecordingsContext.Provider>
  );
};
