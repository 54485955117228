import React                      from "react";
import { StyleSheet, Text, View } from "react-native";

import Logo                       from "~/components/Logo";
import palette                    from "~/styles/palette";
import AdjustableScreen           from "~/layouts/AdjustableScreen/index.web";

import "./splash_screen.css";

export default function SplashScreen({ visible }) {
  if (!visible) return null;
  return (
    <AdjustableScreen>
      <View
        dataSet={{
          cls: "splash-screen",
        }}
        style={[styles.splash_screen__container]}
      >
        <View dataSet={{ cls: "logo-loader" }}>
          <Logo height={75} width={75} />
        </View>
      </View>
    </AdjustableScreen>
  );
}

const styles = StyleSheet.create({
  splash_screen__container: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1040,
    backgroundColor: palette.white,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
});
