import React, { useEffect, useContext } from "react";
import AppContext from "../../contexts/AppContext";
import UserContext from "../../contexts/UserContext";
import { Redirect } from "react-router-dom";
import { View } from "react-native";
import BrowserContext from "../../contexts/BrowserContext";

export default function LogoutScreen({ history }) {
  const { logout, loggedIn } = useContext(UserContext);
  const { logoutDevice } = useContext(BrowserContext);
  const { showSplash } = useContext(AppContext);

  useEffect(() => {
    logoutDevice();
    logout();
  }, [false]);

  if (!loggedIn) return <Redirect to="/login" />;

  return <View />;
}
