import React, {
  createContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import FieldPulse from "~/services/fieldpulse";
import Engage from "~/services/engage";

const TeamContext = createContext({});
const USERS_LIMIT = 15;

export function TeamProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [members, setMembers] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);

  const [disabled, setDisableLoadMoreButton] = useState(false);
  const [loadingMoreUsers, setLoadingMoreUsers] = useState(false);
  const page = useRef(1);

  const getMembers = useCallback(async () => {
    setLoading(true);
    setError(null);
    const res = await FieldPulse.getTeamMembers();
    if (res.error) setError(res.response);
    else {
      setMembers(res.response);
    }
    setLoading(false);
  }, [members, setMembers, setLoading, setError]);

  const getUsers = useCallback(
    async (page = 1, limit = USERS_LIMIT) => {
      page === 1 ? setLoading(true) : setLoadingMoreUsers(true);
      setError(null);
      setDisableLoadMoreButton(() => false);
      try {
        const res = await Engage.getUsers(limit, page);

        if (res.error) {
          setError(res.response);
        } else {
          setUsers((users) =>
            page === 1 ? res?.response : [...users, ...res?.response],
          );

          if (res?.response?.length < limit) {
            setDisableLoadMoreButton(() => true);
          }
        }
      } catch (e) {
        console.error(e);
      }
      page === 1 ? setLoading(false) : setLoadingMoreUsers(false);
    },
    [
      setUsers,
      setLoading,
      setError,
      setLoadingMoreUsers,
      setDisableLoadMoreButton,
    ],
  );

  const getTeams = useCallback(async () => {
    setLoading(true);
    setError(null);
    const res = await FieldPulse.getTeams();
    if (res.error) setError(res.response);
    else {
      setTeams(res.response);
    }
    setLoading(false);
  }, [teams, setTeams, setLoading, setError]);

  const loadMoreUsers = useCallback(async () => {
    page.current += 1;
    await getUsers(page.current);
  }, [page.current, getUsers]);

  const refresh = useCallback(async () => {
    page.current = 1;
    await getUsers();
  }, [page.current, getUsers]);

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <TeamContext.Provider
      value={{
        loading,
        error,
        members,
        users,
        teams,
        getMembers,
        getTeams,
        getUsers,
        disabled,
        loadMoreUsers,
        loadingMoreUsers,
        refresh,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}

export default TeamContext;
