import { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import { StyleSheet, Text, View } from "react-native-web";

const style = StyleSheet.create({
  card: {
    border: "1px dashed gray",
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
    backgroundColor: "white",
    cursor: "move",
  },
});

export const DraggableCard = ({ id, text, moveCard, findCard }) => {
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "card",
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard],
  );
  const [, drop] = useDrop(
    () => ({
      accept: "card",
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard],
  );
  const opacity = isDragging ? 0 : 1;
  return (
    <View ref={(node) => drag(drop(node))} style={[style.card, { opacity }]}>
      <Text>{text}</Text>
    </View>
  );
};
