import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TouchableWithoutFeedback,
  Text,
  View,
  StyleSheet,
  FlatList,
  Pressable,
} from "react-native";

import FilterButton from "~/components/Buttons/FilterButton";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import palette from "~/styles/palette";
import NumbersContext from "../../../contexts/NumbersContext";
import useToggle from "../../../hooks/useToggle";
import Heading from "../../Heading/index.web";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import OutsidePressHandler from "react-native-outside-press";
import ThreadsContext from "../../../contexts/ThreadsContext";

export default function LineFilterBar({ loading = false }) {
  const {
    filter: activeFilter,
    setFilter,
    selectedLines,
    setSelectedLines,
    lineSelectVisible,
    toggleLineSelectVisible,
  } = useContext(ThreadsContext);

  const { managedNumbers, loading: loadingNumbers } =
    useContext(NumbersContext);

  const closePopup = useCallback(() => {
    if (lineSelectVisible) toggleLineSelectVisible();
  }, [lineSelectVisible]);

  const onFilterChange = useCallback(
    (val) => {
      if (val !== "managed" && lineSelectVisible) closePopup();
      if (val === "managed" && !lineSelectVisible) toggleLineSelectVisible();
      setFilter(val);
    },
    [lineSelectVisible, closePopup, toggleLineSelectVisible],
  );

  if (loadingNumbers) return null;

  return (
    <View style={[styles.line_filter_bar__container]}>
      <Row pl={8} pr={8} style={[styles.line_filter_bar__inner]}>
        <FilterButton
          label="All"
          active={activeFilter === "all"}
          onPress={() => onFilterChange("all")}
          disabled={loading}
          labelStyle={{ fontSize: 12 }}
        />
        <FilterButton
          label="Main"
          active={activeFilter === "assigned"}
          onPress={() => onFilterChange("assigned")}
          disabled={loading}
          labelStyle={{ fontSize: 12 }}
        />
        {managedNumbers?.length > 1 && (
          <FilterButton
            label="Selected"
            active={activeFilter === "managed"}
            onPress={() => {
              onFilterChange("managed");
            }}
            disabled={loading}
            labelStyle={{ fontSize: 12 }}
          >
            <FontAwesomeIcon
              icon={lineSelectVisible ? faChevronUp : faChevronDown}
              color={activeFilter === "managed" ? palette.white : palette.grey}
              size={14}
              style={{ marginLeft: 4 }}
            />
          </FilterButton>
        )}
        <OutsidePressHandler
          onOutsidePress={closePopup}
          style={{
            width: "100%",
          }}
        >
          <LineSelectPopup />
        </OutsidePressHandler>
      </Row>
    </View>
  );
}

const LineSelectPopup = () => {
  const { selectedLines, setSelectedLines, lineSelectVisible } =
    useContext(ThreadsContext);
  const { managedNumbers } = useContext(NumbersContext);
  const [reachedEnd, setReachedEnd] = useState(false);

  const addLine = (line) => {
    setSelectedLines((lines) => {
      if (lines.indexOf(line) === -1) return [...lines, line];
      return lines;
    });
  };

  const selectOnlyOneLine = (line) => {
    setSelectedLines(() => [line]);
  };

  const selectAll = () => {
    setSelectedLines(() => managedNumbers.map((line) => line?.id));
  };

  const removeAll = () => {
    setSelectedLines(() => []);
  };

  const areAllLinesSelected = useMemo(() => {
    if (selectedLines < 1) return false;
    if (selectedLines?.length === managedNumbers?.length) return true;
    return false;
  }, [managedNumbers, selectedLines]);

  const removeSelectedLine = (lineToRemove) => {
    setSelectedLines((lines) => {
      return lines.filter((line) => line !== lineToRemove);
    });
  };

  const renderPhoneLineItem = useCallback(
    (props) => {
      const isSelected = selectedLines?.find((line) => line === props.item.id);
      return (
        <PhoneLine
          {...props}
          isSelected={isSelected}
          onAdd={addLine}
          onSelectOnly={selectOnlyOneLine}
          onRemove={removeSelectedLine}
        />
      );
    },
    [selectedLines, managedNumbers],
  );

  if (!lineSelectVisible) return null;

  return (
    <View
      style={{
        backgroundColor: palette.white,
        zIndex: 200,
        position: "absolute",
        borderRadius: 4,
        borderWidth: 1,
        borderColor: palette.light_grey,
        right: 0,
        maxHeight: 600,
        minWidth: "80%",
        maxWidth: "95%",
        paddingHorizontal: 12,
        paddingVertical: 8,
        overflow: "hidden",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "baseline",
          marginBottom: 8,
        }}
      >
        <View>
          <Heading size={5} mb={0} mt={0}>
            Select Phone Line
          </Heading>
        </View>
        <TouchableWithoutFeedback
          onPress={areAllLinesSelected ? removeAll : selectAll}
        >
          <View style={{ cursor: "pointer" }}>
            <Heading
              size={6}
              color={areAllLinesSelected ? palette.danger : palette.dark_blue}
              textStyle={{ fontWeight: 500 }}
              mb={0}
              mt={0}
              selectable={false}
            >
              {areAllLinesSelected ? "Deselect All" : "Select All"}
            </Heading>
          </View>
        </TouchableWithoutFeedback>
      </View>
      <FlatList
        data={[...managedNumbers]}
        renderItem={renderPhoneLineItem}
        keyExtractor={(item) => item?.id}
      />
    </View>
  );
};

const PhoneLine = ({ item, isSelected, onAdd, onSelectOnly, onRemove }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Pressable
      onPress={() => {
        isSelected ? onRemove(item.id) : onAdd(item.id);
      }}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: 40,
          cursor: "pointer",
        }}
      >
        <Col size={9} leftCenter>
          <Heading size={6} mb={0} mt={0} numberOfLines={1}>
            {item?.friendly_name}
          </Heading>
        </Col>
        <Col size={2} center>
          {hovered && (
            <TouchableWithoutFeedback onPress={() => onSelectOnly(item.id)}>
              <View
                style={{
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={{ color: palette.dark_blue }}>Only</Text>
              </View>
            </TouchableWithoutFeedback>
          )}
        </Col>
        <Col size={1} center>
          {isSelected && (
            <FontAwesomeIcon icon={faCheck} color={palette.dark_blue} />
          )}
        </Col>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  line_filter_bar__container: {
    zIndex: 100,
    backgroundColor: palette.grey_bg,
    paddingTop: 12,
  },
  line_filter_bar__inner: {
    gap: 8,
  },
});
