import React, { useContext, useEffect } from "react";
import { View } from "react-native";
import ConversationThread from "../../components/ConversationThread/index.web";
import ConversationContext from "../../contexts/ConversationContext";

export default function ComposeScreen({ history, match }) {
  const { conversation } = useContext(ConversationContext);

  const { id } = match?.params || {};

  useEffect(() => {
    if (conversation?.id && id) {
      history.push(`/messages/${conversation.id}`);
    }
  }, [conversation?.id]);

  return (
    <View style={{ flex: 1 }}>
      <ConversationThread />
    </View>
  );
}
