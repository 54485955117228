import React from "react";
import { View } from "react-native";

import { RowCls, Layouts } from "./style.js";

const Row = ({
  children,
  style,
  padding, // Style shortcut for universal padding
  margin, // Style shortcut for universal margin
  center, // Centers children in parent flex direction
  left, // Aligns left all children in parent
  right, // Aligns right all children in parent
  leftCenter, // Aligns left and centers vertically all children in parent
  rightCenter, // Aligns right and centers vertically all children in parent
  showBox,
  flex,
  fill,
  ...rest
}) => {
  const { mb, mt, ml, mr, pb, pr, pl, pt, ...rst } = rest;
  const additionalStyles = {
    padding: padding || null,
    margin: margin || null,
    paddingBottom: pb || 0,
    paddingTop: pt || 0,
    paddingLeft: pl || 0,
    paddingRight: pr || 0,
    marginBottom: mb || 0,
    marginTop: mt || 0,
    marginLeft: ml || 0,
    marginRight: mr || 0,
  };

  return (
    <View
      style={[
        RowCls,
        center ? Layouts.alignCenter : {},
        left ? Layouts.alignLeft : {},
        right ? Layouts.alignRight : {},
        flex ? { flex: 1 } : {},
        leftCenter && Layouts.alignLeft,
        leftCenter && Layouts.verticalCenterRow,
        rightCenter && Layouts.alignRight,
        rightCenter && Layouts.verticalCenterRow,
        fill && { height: "100%" },
        showBox
          ? {
              backgroundColor: typeof showBox === "string" ? showBox : "purple",
            }
          : {},
        additionalStyles,
        style,
      ]}
      {...rst}
    >
      {children}
    </View>
  );
};

export default Row;
