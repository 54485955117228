import { useEffect, useState } from "react";
import { Keyboard }            from 'react-native';

export default function useKeyboard() {

  const [state, setState] = useState({
    visible: false,
    height: 0
  });

  useEffect(() => {
    
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      (e) => {
        setState({
          visible: true,
          height: e.endCoordinates.height
        })
      }
    );

    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      (e) => {
        setState({
          visible: false,
          height: e.endCoordinates.height
        })
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };

  }, [false]);

  return {
    ...state,
    hideKeyboard: Keyboard.dismiss
  }
}
