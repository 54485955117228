import PropTypes from "prop-types";
import React, { Component } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { StylePropType } from "../helpers/utils";
import LightboxImage from "./LightboxImage";
import palette from "../../../styles/palette";

const styles = StyleSheet.create({
  container: {},
  image_container: {
    flexDirection: "row",
    maxWidth: 320,
    flexWrap: "wrap",
  },
  image: {
    width: 150,
    height: 100,
    borderRadius: 12,
    margin: 4,
    resizeMode: "cover",
  },
  imageActive: {
    flex: 1,
    resizeMode: "contain",
  },
  more_images_container: {
    borderRadius: 8,
    margin: 3,
    backgroundColor: palette.white,
    padding: 4,
    paddingVertical: 8,
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 320,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: palette.primary,
  },
});

const IMAGES_DISPLAY_LIMIT = 4;
export default class MessageImage extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      isImageOpen: false,
    };
  }
  render() {
    const {
      containerStyle,
      lightboxProps,
      imageProps,
      imageStyle,
      currentMessage,
    } = this.props;

    if (!!currentMessage) {
      return (
        <View style={[styles.container, containerStyle]}>
          <View style={styles.image_container}>
            {currentMessage?.images
              ?.slice(0, IMAGES_DISPLAY_LIMIT)
              ?.map((_, i) => (
                <LightboxImage
                  styles={styles}
                  imageStyle={imageStyle}
                  currentMessage={currentMessage}
                  index={i}
                  imageProps={imageProps}
                  lightboxProps={lightboxProps}
                  isOpen={this.state.isImageOpen}
                  onChange={() => this.setState({ isImageOpen: false })}
                />
              ))}
          </View>
          {currentMessage?.images?.length > IMAGES_DISPLAY_LIMIT && (
            <View style={styles.more_images_container}>
              <Text
                style={{
                  color: palette.primary,
                  fontFamily: "OpenSans_600SemiBold",
                  fontWeight: 600,
                }}
              >
                {currentMessage?.images?.length - IMAGES_DISPLAY_LIMIT} more
                attachment
                {currentMessage?.images?.length - IMAGES_DISPLAY_LIMIT > 1
                  ? "s"
                  : ""}
              </Text>
            </View>
          )}
        </View>
      );
    }
    return null;
  }
}
MessageImage.defaultProps = {
  currentMessage: {
    image: null,
  },
  containerStyle: {},
  imageStyle: {},
  imageProps: {},
  lightboxProps: {},
};
MessageImage.propTypes = {
  currentMessage: PropTypes.object,
  containerStyle: StylePropType,
  imageStyle: StylePropType,
  imageProps: PropTypes.object,
  lightboxProps: PropTypes.object,
};
