import { useState, useRef, useEffect } from "react";
import { getColonSeparatedHoursMinutesSeconds } from "../helpers/text";
import { convertMillisToSeconds } from "../helpers/day";

export const useCallDuration = (callState, timestamp = Date.now()) => {
  const [duration, setDuration] = useState(0);
  const timeoutRef = useRef(null);
  useEffect(() => {
    const updateDuration = () => {
      const difference = Date.now() - timestamp;
      const secs = convertMillisToSeconds(difference);
      setDuration(secs);
      const nextTick = 1000 - (difference % 1000);
      timeoutRef.current = setTimeout(updateDuration, nextTick);
    };
    if (callState.success) {
      updateDuration();
    } else if (duration > 0) {
      setDuration(0);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [callState.success, timestamp, duration]);

  return {
    duration,
    callDuration: getColonSeparatedHoursMinutesSeconds(duration),
  };
};

export default useCallDuration;
