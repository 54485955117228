import { Platform, StyleSheet } from "react-native";

export const Layouts = StyleSheet.create({
  verticalCenter: {
    flexDirection: "column",
    justifyContent: "center",
  },
  verticalCenterRow: {
    flexDirection: "row",
    alignContent: "center",
  },
  alignCenter: {
    alignItems: "center",
    justifyContent: "space-around",
    alignContent: "center",
  },
  alignRight: {
    alignContent: "flex-end",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  alignLeft: {
    alignContent: "center",
    alignItems: "flex-start",
  },
  positionAbs: {
    position: "absolute",
  },
  textCenter: {
    textAlign: "center",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexCol: {
    flexDirection: "column",
  },
});

export const RowCls = {
  flexWrap: "wrap",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  alignContent: "flex-start",
  width: "100%",
};

export const ColCls = {
  flex: 1,
  flexDirection: "column",
  height: "100%",
};

export const Col1 = {
  maxWidth: "8.333333333%",
};
export const Col2 = {
  maxWidth: "16.66666666667%",
};
export const Col3 = {
  maxWidth: "25%",
};
export const Col4 = {
  maxWidth: "33.333333333%",
};
export const Col5 = {
  maxWidth: "41.666666666667%",
};
export const Col6 = {
  maxWidth: "50%",
};
export const Col7 = {
  maxWidth: "58.3333333333%",
};
export const Col8 = {
  maxWidth: "66.66666666667%",
};
export const Col9 = {
  maxWidth: "75%",
};
export const Col10 = {
  maxWidth: "83.333333333%",
};
export const Col11 = {
  maxWidth: "91.6666666667%",
};
export const Col12 = {
  width: "100%",
  maxWidth: "100%",
};
