import React, { useState } from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { Picker } from "@react-native-picker/picker";
import palette from "../../styles/palette";

export default function StyledPicker({
  style,
  icon,
  center,
  innerRef,
  options = [],
  lessPadding = false,
  bold = false,
  border = false,
  ...props
}) {
  const [state, setState] = useState(null);
  return (
    <Pressable
      onHoverIn={() =>
        setState((state) => (state === "focused" ? "focused" : "hovered"))
      }
      onHoverOut={() =>
        setState((state) => (state === "focused" ? "focused" : null))
      }
    >
      <Picker
        ref={innerRef}
        style={[
          styles.styled_picker,
          {
            paddingVertical: lessPadding ? 4 : 12,
            paddingHorizontal: lessPadding ? 4 : 12,
            fontSize: lessPadding ? 14 : 16,
          },
          style,
          {
            backgroundColor:
              state === "hovered" ? palette.lighter_grey : palette.white,
            borderRadius: 4,
          },
          bold && {
            fontWeight: "600",
            fontFamily: "OpenSans_600SemiBold",
          },
          border && {
            borderWidth: 1,
            borderColor: palette.light_grey,
          },
        ]}
        {...props}
      >
        {options.map(({ value, name, ...props }) => (
          <Picker.Item
            key={`${value}-${name}`}
            label={name}
            value={value}
            {...props}
          />
        ))}
      </Picker>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  styled_picker: {
    borderColor: palette.transparent,
    paddingVertical: 4,
    paddingHorizontal: 8,
    fontWeight: "400",
    fontFamily: "OpenSans_400Regular",
    outlineColor: palette.transparent,
    outlineOffset: 3,
    outlineWidth: 0,
    borderRadius: 2,
  },
});
