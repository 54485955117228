import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import AvailableNumbersContext, {
  AvailableNumbersProvider,
} from "../../../contexts/AvailableNumbersContext";
import Row from "../../../layouts/Row";
import Col from "../../../layouts/Col";
import SolidButton from "../../../components/Buttons/SolidButton/index";

import Heading from "../../../components/Heading/index";
import Paragraph from "../../../components/Paragraph/index";
import Logo from "../../../components/Logo/index";
import palette from "../../../styles/palette";
import OnboardingContext from "../../../contexts/OnboardingContext";
import StyledInput from "../../../components/StyledInput";
import IconButton from "../../../components/Buttons/IconButton";
import StyledPicker from "../../../components/StyledPicker";
import { AUS_REGION_CODES } from "../../../constants/countries";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

export default function SelectAreaCodeStep({ nextStep, previousStep }) {
  const {
    areaCode = "",
    setAreaCode,
    countryCode,
  } = useContext(OnboardingContext);

  const [regionCode, setRegionCode] = useState(AUS_REGION_CODES[0].value);

  useEffect(() => {
    if (countryCode === "AU") setAreaCode(regionCode);
  }, [regionCode, countryCode]);

  return (
    <View
      style={{
        height: 500,
        padding: 24,
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <View>
        <IconButton
          faPro
          faProIcon={faChevronLeft}
          ml={0}
          mb={0}
          onPress={previousStep}
        />
      </View>
      <View style={{ alignItems: "center", marginTop: 44 }}>
        <Logo height={70} width={70} />
      </View>

      <View>
        <Heading center mt={10} mb={15}>
          Preferred {countryCode === "AU" ? "Region" : "Area"} code
        </Heading>
      </View>

      <View style={{ justifyContent: "center", flex: 1 }}>
        <Paragraph center mb={30}>
          Enter the preferred {countryCode === "AU" ? "region" : "area"} code of
          your new Engage number.
        </Paragraph>
      </View>

      <View style={{ flex: 1 }}>
        {countryCode === "AU" ? (
          <StyledPicker
            selectedValue={regionCode}
            options={AUS_REGION_CODES}
            onValueChange={setRegionCode}
          />
        ) : (
          <StyledInput
            maxLength={3}
            placeholder="Preferred area code"
            value={areaCode}
            onChangeText={setAreaCode}
            style={[styles.area_code__input]}
            nativeID="number_area_code"
          />
        )}
      </View>
      <View>
        <SolidButton
          label="Select a Phone Number"
          disabled={!(areaCode && areaCode.length > 2)}
          color={
            areaCode && areaCode.length > 2
              ? palette.primary
              : palette.light_grey
          }
          onPress={nextStep}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  area_code__input: {
    textAlign: "center",
  },
});
