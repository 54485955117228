import "expo-asset";
import "react-native-gesture-handler";
import { AppRegistry, Platform } from "react-native";
import { registerRootComponent } from "expo";
import { RNTwilioPhone } from "react-native-twilio-phone";
import RNCallKeep from "react-native-callkeep";
import { TwilioPhone } from "react-native-twilio-phone";

import * as Notifications from "expo-notifications";
import messaging from "@react-native-firebase/messaging";
import { CALL_KEEP_OPTIONS } from "./src/constants/twilio";

if (Platform.OS === "android") {
  RNTwilioPhone.handleBackgroundState(CALL_KEEP_OPTIONS);

  messaging().setBackgroundMessageHandler(async (remoteMessage) => {
    console.log("bg notification", remoteMessage);

    if (!remoteMessage.data) {
      return;
    }

    const { data } = remoteMessage;

    if (data.body) {
      const body = JSON.parse(data.body);

      if (data.channelId === "engage" && body.event !== "badge-updated") {
        Notifications.scheduleNotificationAsync({
          content: {
            title: data.title,
            body: data.message,
          },
          trigger: {
            seconds: 1,
          },
        });
      }
    } else {
      RNCallKeep.registerPhoneAccount(CALL_KEEP_OPTIONS);
      RNCallKeep.registerAndroidEvents();
      RNCallKeep.setAvailable(true);

      RNTwilioPhone.listenTwilioPhone();
      RNTwilioPhone.listenCallKeep();

      TwilioPhone.handleMessage(data);
    }
  });
}

import App from "./App";

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);

if (Platform.OS === "android") {
  // NOTE: for android TODO
  // To be able to wake up your killed application when making an outgoing call form the native Phone application
  AppRegistry.registerHeadlessTask(
    "RNCallKeepBackgroundMessage",
    () =>
      ({ name, callUUID, handle, ...rest }) => {
        // Make your call here

        return Promise.resolve();
      },
  );
}
