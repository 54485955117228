import DayJs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import isYesterday from "dayjs/plugin/isYesterday";
import isToday from "dayjs/plugin/isToday";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import weekDay from "dayjs/plugin/weekday";

DayJs.extend(weekDay); // use plugin
DayJs.extend(advancedFormat); // use plugin
DayJs.extend(relativeTime); // use plugin
DayJs.extend(isYesterday); // use plugin
DayJs.extend(isToday); // use plugin
DayJs.extend(isSameOrBefore); // use plugin
DayJs.extend(utc); // use plugin
DayJs.extend(duration); // use plugin

export const getSmartTimestamp = (timestamp) => {
  const cTimestamp = DayJs(timestamp);
  return `${cTimestamp.format("M/D/YY")} at ${cTimestamp.format("h:mm A")}`;
};

export function convertMillisToSeconds(milliseconds) {
  if (!milliseconds) return 0;
  if (milliseconds < 1000) {
    return 1;
  }
  return Math.round(milliseconds / 1000);
}
export default DayJs;
