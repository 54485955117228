import Constants from "expo-constants";

import {
  NODE_ENV as nodeEnv,
  APP_ENV as appEnv,
  BASE_URI as baseUri,
  PUSHER_APP_ID as appId,
  PUSHER_KEY as pusherKey,
  TEST_EMAIL as testEmail,
  TEST_PASS as textPass,
  GOOGLE_MAPS_API_KEY as GoogleMapsAPIkey,
  EMAIL_ENGINE_API_TOKEN as EmailEngineApiToken,
} from "@env";

export const isDevelopment = appEnv === "development" || appEnv === "staging";

// TODO:  Find a new way to generate unique device IDs
export const DEVICE_UUID = Constants.installationId;

export const ENV = {
  APP_ENV: appEnv,
  NODE_ENV: nodeEnv,
  BASE_URI: baseUri,
  PUSHER_APP_ID: appId,
  PUSHER_KEY: pusherKey,
  GOOGLE_MAPS_API_KEY: GoogleMapsAPIkey,
  EMAIL_ENGINE_API_TOKEN: EmailEngineApiToken,
  HOST_URI:
    appEnv === "production"
      ? "https://engage.fieldpulse.com"
      : appEnv === "staging"
      ? "https://staging-engage.fieldpulse.com"
      : "http://localhost:19006",
};

export const NODE_ENV = ENV.NODE_ENV;
export const APP_ENV = ENV.APP_ENV;
export const BASE_URI = ENV.BASE_URI;
export const PUSHER_APP_ID = ENV.PUSHER_APP_ID;
export const PUSHER_KEY = ENV.PUSHER_KEY;
export const GOOGLE_MAPS_API_KEY = ENV.GOOGLE_MAPS_API_KEY;
export const HOST_URI = "*";
export const EMAIL_ENGINE_API_TOKEN = ENV.EMAIL_ENGINE_API_TOKEN;

export const DEFAULT_EMAIL = isDevelopment ? testEmail : "";
export const DEFAULT_PASS = isDevelopment ? textPass : "";
export const PRODUCTION_BASE_URI = `https://apibe.fieldpulse.com/v2.5`;
export const STAGING_BASE_URI = `https://developer.fieldpulse.com/v2.5`;
export const DEV_BASE_URI = `https://developer.fieldpulse.com/v2.5`;

export const MAIN_API_BASE_URLS = {
  production: PRODUCTION_BASE_URI,
  staging: DEV_BASE_URI,
  development: DEV_BASE_URI,
};

export const MAIN_BACKEND_BASE_URI = MAIN_API_BASE_URLS[APP_ENV];

export const MAIN_WEBAPP_URLS = [
  "https://dev-webapp.fieldpulse.com",
  "https://webapp.fieldpulse.com",
  "https://simplysend.fieldpulse.com",
  "https://morsco.fieldpulse.com",
  "https://devwebapp.fieldpulse.com:5577"
];

if (APP_ENV == "staging" || NODE_ENV == "development") {
  console.log("************** ENV **************");
  console.log(ENV);
  console.log("*********************************");
}
