import React, { useEffect, useState, useContext, createContext } from "react";
import Engage from "../services/engage";
import UserContext from "./UserContext";
import { BUNDLE_STATUS } from "../constants/bundle";

const OnboardingContext = createContext({});

export const OnboardingProvider = ({ children }) => {
  const [areaCode, setAreaCode] = useState("");
  const [verificationPhone, setVerificationPhone] = useState("");
  const { user } = useContext(UserContext);

  const [countryCode, setCountryCode] = useState(
    user !== null ? user?.account?.country : "US",
  );

  const [bundleData, setBundleData] = useState(BUNDLE_STATUS);
  const [loading, setLoading] = useState(false);

  const getBundleStatus = async () => {
    setLoading(true);
    const res = await Engage.getBundleStatus();
    if (!res.error) {
      setBundleData(res.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (countryCode === "AU") getBundleStatus();
  }, [countryCode]);

  return (
    <OnboardingContext.Provider
      value={{
        areaCode,
        setAreaCode,
        verificationPhone,
        setVerificationPhone,
        countryCode,
        setCountryCode,
        bundleData,
        loading,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContext;
