import { isDevelopment } from "./app";

export const CALL_KEEP_OPTIONS = {
  ios: {
    appName: "Engage by FieldPulse ",
    supportsVideo: false,
  },
  android: {
    alertTitle: "Permissions required",
    alertDescription:
      "Engage needs access to your phone settings in order to receive incoming calls.",
    cancelButton: "Cancel",
    okButton: "OK",
    additionalPermissions: [],
    // Required to get audio in background when using Android 11
    foregroundService: {
      channelId: "com.fieldpulse.engage",
      channelName: "Engage by FieldPulse",
      notificationTitle: "Engage by FieldPulse is running in the background",
    },
  },
};

export const TWILIO_VOICE_OPTIONS = {
  closeProtection: true,
  allowIncomingWhileBusy: true,
  edge: "roaming",
  logLevel: 0,
  maxAverageBitrate: 0,
  maxCallSignalingTimeoutMs: 30000,
  tokenRefreshMs: 1000 * 60 * 5,
  codecPreferences: ["opus", "pcmu"],
};
