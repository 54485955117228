import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native-web";
import PHONE_TREE_TYPES, {
  TWILIO_LANGUAGES,
} from "../../../constants/phoneTreeTypes";
import { PhoneTreeContext } from "../../../contexts/PhoneTreeContext";
import TeamContext from "../../../contexts/TeamContext";
import { getPhoneTreeTypes } from "../../../helpers/phoneTree";
import useToggle from "../../../hooks/useToggle";
import Col from "../../../layouts/Col";
import Row from "../../../layouts/Row";
import commonStyles from "../../../styles/common";
import palette from "../../../styles/palette";
import IconButton from "../../Buttons/IconButton";
import SolidButton from "../../Buttons/SolidButton";
import StyledInput from "../../StyledInput";
import StyledSelect from "../../StyledSelect";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableCard } from "../../../DraggableCard/index.web";
import update from "immutability-helper";
import { useUpdateNodeInternals } from "reactflow";
import UserContext from "../../../contexts/UserContext";
import { getFullName } from "../../../helpers/text";
import NumbersContext from "../../../contexts/NumbersContext";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/pro-solid-svg-icons";
import NumberContext from "../../../contexts/NumberContext";

const defaultValues = (country) => ({
  label: "",
  text: "",
  user: "",
  value: 1,
  type: "node",
  language:
    country === "US"
      ? TWILIO_LANGUAGES[0].code
      : country === "AU"
      ? TWILIO_LANGUAGES[1].code
      : country === "CA"
      ? TWILIO_LANGUAGES[2].code
      : TWILIO_LANGUAGES[0].code,
});

const TABS = [
  {
    name: "Information",
    value: 0,
  },
  {
    name: "Children",
    value: 1,
  },
];

const isChanged = (activeNode, data) => {
  let check = defaultValues;
  if (activeNode !== null) {
    console.warn("using activeNode");
    check = activeNode;
  }
  Object.keys(data).map((e) => {
    console.log(data[e], check[e]);
    if (data[e] !== check[e]) return true;
  });
  return false;
};

export default function PhoneTreeForm() {
  const [visible, toggleVisible] = useToggle(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const { activeNode } = useContext(PhoneTreeContext);
  const { getNumbers } = useContext(NumbersContext);
  const updateNodeInternals = useUpdateNodeInternals();

  const updateNodes = () => {
    updateNodeInternals("0");
  };
  useEffect(() => {
    getNumbers();
  }, []);

  useEffect(() => {
    setSelectedTab(0);
  }, [JSON.stringify(activeNode?.index)]);

  return (
    <>
      <IconButton
        faPro
        faProIcon={visible ? faChevronCircleRight : faChevronCircleLeft}
        iconColor={palette.primary}
        width={30}
        height={30}
        onPress={toggleVisible}
        style={{
          position: "absolute",
          right: -20,
          top: 20,
          backgroundColor: palette.background_color,
        }}
      />
      <View
        style={{
          margin: 20,
          backgroundColor: palette.bg_light_grey,
          border: visible ? `1px solid ${palette.light_grey}` : null,
          borderRadius: 4,
        }}
      >
        <View
          style={{
            minWidth: visible ? 250 : 0,
            maxWidth: 300,
            width: visible ? "auto" : 0,
            height: visible ? "auto" : 0,
            overflow: "hidden",
            padding: visible ? 10 : 0,
          }}
        >
          {activeNode && (activeNode.value === 0 || activeNode.value === 1) && (
            <Row style={{ marginTop: 16, justifyContent: "space-evenly" }}>
              {TABS.map((tab) => (
                <Tab
                  name={tab.name}
                  key={`node-tab-${tab.value}`}
                  onPress={() => {
                    if (tab.value !== selectedTab) setSelectedTab(tab.value);
                  }}
                  active={tab.value === selectedTab}
                />
              ))}
            </Row>
          )}
          {selectedTab === 0 && <InformationTab updateNodes={updateNodes} />}
          {selectedTab === 1 && (
            <DndProvider backend={HTML5Backend}>
              <ChildrenTab updateNodes={updateNodes} />
            </DndProvider>
          )}
        </View>
      </View>
    </>
  );
}

const Tab = ({ name, onPress, active }) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View
        style={{
          paddingHorizontal: 12,
          paddingVertical: 6,
          backgroundColor: active ? palette.primary : palette.background_color,
          borderRadius: 4,
          cursor: "pointer",
        }}
      >
        <Text
          style={{
            fontSize: 16,
            color: active ? palette.white : palette.black,
          }}
        >
          {name}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

const InformationTab = ({ updateNodes }) => {
  const [label, setLabel] = useState("");
  const [text, setText] = useState("");
  const [value, setValue] = useState(1);
  const [useDifferentUser, setUseDifferentUser] = useToggle(false);
  const [userNumber, setUserNumber] = useState("");
  const [userNumberLabel, setUserNumberLabel] = useState("");
  const {
    user: {
      account: { country },
    },
  } = useContext(UserContext);

  const [lang, setLang] = useState(defaultValues(country).language);

  const { activeNode, removeNode, editData, nodes } =
    useContext(PhoneTreeContext);
  const { number } = useContext(NumberContext);
  const { numbers, loading } = useContext(NumbersContext);

  const availableNumbers = useMemo(() => {
    return numbers?.filter((n) => n?.id !== number?.id);
  }, [JSON.stringify(numbers), number?.id]);

  useEffect(() => {
    let defaultVals = defaultValues(country);
    let data = defaultValues;
    if (activeNode !== null) {
      data = { ...defaultValues, ...nodes[activeNode.index] };
    }
    setLabel(data.label || defaultVals.label);
    setText(data.text || defaultVals.text);
    setValue(
      activeNode.level === 3 && data.value === 1
        ? 2
        : data?.value ?? defaultVals?.value,
    );
    if (!loading && numbers.length > 0 && data.value === 2) {
      let isNumberIn = false;
      numbers.forEach((number) => {
        if (number.phone_number === data.user.number) {
          isNumberIn = true;
        }
      });
      setUserNumber(data.user.number);
      setUserNumberLabel(data.user.numberLabel);
      if (!isNumberIn && !useDifferentUser) setUseDifferentUser();
      else if (isNumberIn && useDifferentUser) setUseDifferentUser();
    }

    setLang(data?.language || defaultVals.language);
  }, [
    JSON.stringify(activeNode),
    JSON.stringify(nodes),
    JSON.stringify(numbers),
    loading,
  ]);

  const onSubmit = () => {
    editData({
      type: activeNode.type === "empty" ? "node" : activeNode.type,
      value: activeNode.value === 0 ? 0 : parseInt(value),
      text,
      label,
      language: lang,
      user:
        parseInt(value) === 2
          ? {
              number: userNumber,
              numberLabel: userNumberLabel,
            }
          : null,
    });
  };

  const isDataChanged = useMemo(() => {
    const keys = ["label", "language", "text", "value", "user"];
    const editedData = {
      value: activeNode.value === 0 ? 0 : parseInt(value),
      text,
      label,
      language: lang,
      user:
        parseInt(value) === 2
          ? {
              number: userNumber,
              numberLabel: userNumberLabel,
            }
          : null,
    };
    let res = false;
    keys.forEach((key) => {
      if (key !== "user" && activeNode[key] !== editedData[key]) {
        if (!res) res = true;
      } else {
        if (!res && parseInt(value) === 2)
          res = editedData.user?.number !== (activeNode.user?.number || null);
      }
    });
    return res;
  }, [
    JSON.stringify(activeNode),
    text,
    label,
    value,
    lang,
    useDifferentUser,
    userNumber,
    userNumberLabel,
  ]);

  const isFormValid = useMemo(() => {
    if (label.length === 0) return false;
    if (parseInt(value) === 3 && text.length === 0) return false;

    if (
      parseInt(value) === 2 &&
      (userNumber === undefined ||
        userNumber === null ||
        userNumber === "none" ||
        userNumber === "")
    )
      return false;

    return true;
  }, [
    JSON.stringify(activeNode),
    useDifferentUser,
    label,
    text,
    value,
    userNumber,
  ]);

  useEffect(() => {
    if (
      !loading &&
      parseInt(value) === 2 &&
      availableNumbers?.length === 0 &&
      !useDifferentUser
    ) {
      setUserNumber("");
      setUserNumberLabel("");
      setUseDifferentUser();
    }
  }, [availableNumbers?.length, loading, value, useDifferentUser]);

  return (
    <Col>
      <Field label="Type" style={[commonStyles.mr4]} required>
        <StyledSelect
          selectedValue={value}
          onValueChange={setValue}
          options={getPhoneTreeTypes(activeNode.type, activeNode.level).map(
            (type) => ({
              label: type.label,
              value: type.value,
            }),
          )}
          disabled={activeNode.type === "main"}
        />
      </Field>
      <Field
        label="Label"
        style={[commonStyles.mr4]}
        required
        showLength
        length={label?.length}
        maxLength={72}
      >
        <StyledInput
          style={{ backgroundColor: palette.white }}
          onChangeText={(value) => setLabel(value)}
          value={label}
          disabled={activeNode.type === "main"}
          maxLength={72}
          nativeID="phone_tree_label"
        />
      </Field>
      {value == "2" ? (
        !loading ? (
          <View>
            <Field label="Select Number" style={[commonStyles.mr4]} required>
              <StyledSelect
                selectedValue={useDifferentUser ? "alternate" : userNumber}
                onValueChange={(value) => {
                  if (value === "alternate") {
                    setUserNumber("");
                    setUserNumberLabel("");
                    setUseDifferentUser();
                  } else {
                    if (useDifferentUser) setUseDifferentUser();
                    setUserNumber(value);
                    setUserNumberLabel(
                      availableNumbers.find((n) => n.phone_number === value)
                        ?.friendly_name || "",
                    );
                  }
                }}
                options={[
                  { value: "none", label: "None" },
                  ...availableNumbers.map((number) => ({
                    value: number.phone_number,
                    label: number.friendly_name,
                    subLabel: number.national_number,
                  })),
                  { value: "alternate", label: "Alternate Number" },
                ]}
              />
            </Field>
            {useDifferentUser && (
              <Field label="Phone Number" required>
                <StyledInput
                  style={{ backgroundColor: palette.white }}
                  onChangeText={(value) => {
                    const clearValue = value?.replaceAll(/[^+0-9]/g, "");
                    setUserNumber(clearValue);
                    setUserNumberLabel("");
                  }}
                  value={userNumber}
                  nativeID="phone_tree_number"
                />
              </Field>
            )}
          </View>
        ) : (
          <Text style={[commonStyles.mt4, { color: palette.danger }]}>
            Loading Engage Numbers
          </Text>
        )
      ) : (
        <>
          <Field
            label="Message"
            style={[commonStyles.mr4]}
            required={parseInt(value) === 3}
            showLength
            length={text?.length}
            maxLength={200}
          >
            <StyledInput
              style={{ backgroundColor: palette.white }}
              onChangeText={(value) => setText(value)}
              value={text}
              maxLength={200}
              nativeID="phone_tree_message"
            />
          </Field>
          <Field label="Language" style={[commonStyles.mr4]}>
            <StyledSelect
              selectedValue={lang}
              onValueChange={setLang}
              options={TWILIO_LANGUAGES.map((lang) => ({
                label: lang.label,
                value: lang.code,
              }))}
            />
          </Field>
        </>
      )}

      {activeNode && activeNode.index !== null && (
        <Row
          style={{
            marginTop: 12,
            justifyContent: "space-between",
          }}
        >
          <SolidButton
            label={activeNode.type === "empty" ? "Add" : "Save"}
            onPress={() => {
              onSubmit();
              updateNodes();
            }}
            disabled={!isFormValid || !isDataChanged}
          />
          {activeNode.type !== "main" && (
            <SolidButton
              color={palette.danger}
              label="Delete"
              onPress={() => {
                removeNode(activeNode.index);
                updateNodes();
              }}
            />
          )}
        </Row>
      )}
    </Col>
  );
};

const ChildrenTab = ({ updateNodes }) => {
  const [cards, setCards] = useState([]);
  const { activeNode, nodes, reArrangeChildren } = useContext(PhoneTreeContext);
  const [containsEmptyNode, setContainsEmptyNode] = useState(false);

  useEffect(() => {
    let arr = [];
    let hasEmptyNode = false;
    const node = nodes[activeNode.index];
    setCards([]);
    if (node !== undefined) {
      for (let i = 0; i < node.children.length; i++) {
        if (nodes[node.children[i]].type !== "empty")
          arr.push(nodes[node.children[i]]);
        else hasEmptyNode = true;
      }

      setContainsEmptyNode(hasEmptyNode);
      setCards(arr);
    }
  }, [JSON.stringify(nodes), JSON.stringify(activeNode)]);

  const findCard = useCallback(
    (id) => {
      const card = cards.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards],
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id);
      setCards(
        update(cards, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      );
    },
    [findCard, setCards],
  );

  const [, drop] = useDrop(() => ({ accept: "card" }));

  const onSave = () => {
    reArrangeChildren(
      activeNode.index,
      cards.map((card) => card.index),
    );
    updateNodes();
  };

  if (activeNode?.children?.length === 0) {
    return (
      <Col style={{ marginTop: 10 }}>
        <Text>This Node has No Children</Text>
      </Col>
    );
  }

  return (
    <Col ref={drop} style={{ marginTop: 10 }}>
      {containsEmptyNode ? (
        <Text>
          Please Add details / Remove Empty node to change order here.
        </Text>
      ) : (
        <>
          {cards.map((card, i) => (
            <DraggableCard
              key={card.id}
              id={card.id}
              text={`Press ${i + 1}: ${card.label}`}
              moveCard={moveCard}
              findCard={findCard}
            />
          ))}
          <SolidButton label="Save Order" onPress={onSave} />
        </>
      )}
    </Col>
  );
};

export const Field = ({
  children,
  label,
  style = [],
  isExpanded: expanded = true,
  expandable = false,
  required,
  onExpand,
  showLength = false,
  length = 0,
  maxLength = 10,
  labelStyle = {},
  subtext = false,
  subtextStyle = {},
  errorMessage = false,
  errorMessageStyle = {},
}) => {
  const [isExpanded, setIsExpanded] = useToggle(expanded);

  return (
    <View style={[styles.field, ...style]}>
      <View style={[styles.field_label]}>
        <Row>
          <Col>
            {expandable ? (
              <TouchableOpacity
                onPress={
                  typeof onExpand === "function" ? onExpand : setIsExpanded
                }
              >
                <Text style={[styles.field_label_text, labelStyle]}>
                  {expandable && (isExpanded ? "-" : "+")} {label}
                  {required && "*"}
                </Text>
              </TouchableOpacity>
            ) : typeof onExpand === "function" ? (
              <TouchableOpacity onPress={onExpand}>
                <Text style={[styles.field_label_text, labelStyle]}>
                  {label}
                  {required && "*"}
                </Text>
              </TouchableOpacity>
            ) : (
              <Text style={[styles.field_label_text, labelStyle]}>
                {label}
                {required && "*"}
              </Text>
            )}
          </Col>
          {showLength && (
            <Col right>
              <Row right>
                <Text
                  style={{
                    color: length === maxLength ? palette.black : palette.grey,
                  }}
                >
                  {length}
                </Text>
                <Text> / {maxLength}</Text>
              </Row>
            </Col>
          )}
        </Row>
        {subtext !== false && (
          <Row>
            <Text style={[styles.field_subtext, subtextStyle]}>{subtext}</Text>
          </Row>
        )}
      </View>
      {(!expandable || isExpanded) && children}
      {errorMessage !== false && (
        <Row>
          <Text style={[styles.field_error_message, errorMessageStyle]}>
            {errorMessage}
          </Text>
        </Row>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  topSaveBtn: {
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 8,
    padding: 8,
  },
  customer_create_modal_container: {
    paddingVertical: 15,
    display: "flex",
    flex: 1,
    height: "100%",
    flexDirection: "column",
  },
  field: {
    marginBottom: 4,
  },
  field_label: {
    padding: 8,
  },
  field_label_required: {
    color: palette.grey,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  field_label_text: {
    fontSize: 14,
    fontWeight: "500",
    color: palette.dark,
    fontFamily: "OpenSans_600SemiBold",
  },
  field_subtext: {
    fontSize: 12,
    color: palette.label_grey,
  },
  modal_footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  error_message_container: {
    backgroundColor: "#f8d7da",
    padding: 12,
    borderRadius: 4,
    marginBottom: 8,
  },
  error_message_text: {
    color: palette.danger,
    textAlign: "center",
    fontFamily: "OpenSans_600SemiBold",
    fontWeight: "600",
  },
  field_error_message: {
    color: palette.danger,
    fontFamily: "OpenSans_600SemiBold",
    fontWeight: "600",
  },
  sectionContent: {
    paddingHorizontal: 16,
  },
});
