import { StyleSheet, Text, View } from "react-native";
import palette from "../../styles/palette";
import Row from "../../layouts/Row";
import Col from "../../layouts/Col";
import IconButton from "../../components/Buttons/IconButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import NumbersContext from "../../contexts/NumbersContext";
import UserContext from "../../contexts/UserContext";
import { GREETING_TYPES } from "../../constants/mediaTypes";
import Heading from "../../components/Heading/index.web";
import { Link } from "react-router-dom";
import { ActivityIndicator, ScrollView } from "react-native-web";
import SolidButton from "../../components/Buttons/SolidButton";
import CustomRecordingsContext from "../../contexts/CustomRecordingsContext";

const AudioDeletionModal = ({ location, history }) => {
  const message = "You're going to delete this Audio Sample";

  const {
    numbers,
    getNumbers,
    loading: loadingNumbers,
  } = useContext(NumbersContext);
  const { user, getUser, loading: loadingUser } = useContext(UserContext);
  const { deleteCustomRecording } = useContext(CustomRecordingsContext);
  const { setActive } = useContext(CustomRecordingsContext);

  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);

  const audioSample = useMemo(() => {
    return location?.state?.audioSample;
  }, [location?.state]);

  const defaultingNumbers = useMemo(() => {
    if (loadingNumbers) return [];
    else
      return numbers?.filter((number) => {
        return (
          number?.voicemail_greeting_type === GREETING_TYPES.AUDIO &&
          number?.voicemail_greeting_url === audioSample?.url
        );
      });
  }, [loadingNumbers, numbers, audioSample?.url]);

  const defaultingUserSettings = useMemo(() => {
    let res = {
      working_hours: false,
      default_voicemail: false,
    };
    if (loadingUser) return res;
    else {
      if (
        user?.account?.working_hours?.length > 0 &&
        user?.account?.out_of_work_audio_url === audioSample?.url
      ) {
        res.working_hours = true;
      }

      if (
        user?.account?.default_voicemail_greeting_type ===
          GREETING_TYPES.AUDIO &&
        user?.account?.default_voicemail_greeting_url === audioSample?.url
      ) {
        res.default_voicemail = true;
      }

      return res;
    }
  }, [
    user?.account?.default_voicemail_greeting_type,
    user?.account?.default_voicemail_greeting_url,
    user?.account?.default_voicemail_greeting_url,
    user?.account?.out_of_work_audio_url,
    user?.account?.working_hours,
    loadingUser,
    audioSample?.url,
  ]);

  const onDeleteAudioPress = useCallback(async () => {
    setDeleting(true);
    try {
      await deleteCustomRecording(audioSample?.id);
    } catch {}
    setActive(0);
    history.goBack();
    setDeleting(false);
  }, [audioSample?.id]);

  useEffect(() => {
    const fetchData = async () => {
      if (!location?.state?.audioSample) {
        history.goBack();
      } else {
        setLoading(true);
        await getNumbers();
        await getUser();
        setLoading(false);
      }
    };
    fetchData();
  }, [location?.state?.audioSample]);

  return (
    <View style={[styles.modal_overlay]} onPress={() => history.goBack()}>
      <View style={styles.modal_container}>
        <ModalHeader
          onClose={() => {
            history.goBack();
          }}
        />
        <View style={styles.modal__body}>
          {loading ? (
            <Row mt={8} mb={8} center>
              <ActivityIndicator size="large" />
            </Row>
          ) : (
            <>
              <Text style={styles.body__message}>{message}</Text>
              {(defaultingUserSettings?.default_voicemail ||
                defaultingUserSettings?.working_hours ||
                defaultingNumbers?.length > 0) && (
                <Row>
                  <Col>
                    <Text>
                      Please remove this audio sample from these settings before
                      deleting this audio sample.
                    </Text>
                    <ScrollView>
                      {defaultingUserSettings?.default_voicemail && (
                        <Item
                          title="Default Voicemail"
                          subtitle={`Your default voicemail settings use this audio sample as response greeting\nfor missed incoming calls.`}
                          to="/settings/voicemails"
                        />
                      )}
                      {defaultingUserSettings?.working_hours && (
                        <Item
                          title="Working Hours"
                          subtitle={`Your working hours settings use this audio sample as response greeting\nfor calls coming in outside working hours.`}
                          to="/settings/working-hours"
                        />
                      )}
                      {defaultingNumbers?.length > 0 && (
                        <Item
                          title="Phone Numbers"
                          to="/settings/numbers"
                          subtitle={`These phone numbers use this audio sample as voicemail greeting:\n${defaultingNumbers
                            ?.map(
                              (number) =>
                                `${number?.friendly_name} - ${number?.phone_number}`,
                            )
                            .join(", ")}.`}
                        />
                      )}
                    </ScrollView>
                  </Col>
                </Row>
              )}
              <Row>
                <Text
                  style={[
                    styles.body__message,
                    { marginBottom: 10, marginTop: 0 },
                  ]}
                >
                  Do you want to continue?
                </Text>
              </Row>
              <Row left style={{ gap: 8 }}>
                <View>
                  <SolidButton
                    label={"Yes"}
                    color={palette.danger}
                    loading={deleting}
                    disabled={
                      defaultingUserSettings?.default_voicemail ||
                      defaultingUserSettings?.working_hours ||
                      defaultingNumbers?.length > 0
                    }
                    onPress={onDeleteAudioPress}
                  />
                </View>
                <View>
                  <SolidButton
                    label={"Cancel"}
                    onPress={() => history.goBack()}
                  />
                </View>
              </Row>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

const Item = ({ title, to, subtitle }) => {
  return (
    <Row
      mt={8}
      pl={16}
      pt={8}
      pb={12}
      pr={16}
      center
      style={{
        backgroundColor: palette.bg_light_grey,
        borderRadius: 4,
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: palette.light_grey,
      }}
    >
      <Col>
        <Row center style={{ justifyContent: "space-between" }}>
          <Heading size={5}>{title}</Heading>
          {!!to && (
            <Link
              to={to}
              style={{
                color: palette.primary_light,
                fontWeight: "600",
                textDecoration: "none",
              }}
            >
              <Text>Open Settings</Text>
            </Link>
          )}
        </Row>
        <Row>
          <Text style={{ color: palette.label_grey }}>{subtitle}</Text>
        </Row>
      </Col>
    </Row>
  );
};

const ModalHeader = ({ onClose }) => {
  return (
    <View style={[styles.modal__header]}>
      <Row pl={32} pr={8}>
        <Col leftCenter>
          <Text style={[styles.modal__header_title_text]}>
            Confirm Deletion
          </Text>
        </Col>
        <Col rightCenter>
          <IconButton
            onPress={onClose}
            faPro
            faProIcon={faXmark}
            iconSize={28}
            color={palette.primary}
            iconColor={palette.white}
          />
        </Col>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  modal__header: {
    height: 60,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    backgroundColor: palette.primary,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    color: palette.white,
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.79)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
  },
  modal_container: {
    paddingBottom: 8,
    position: "absolute",
    minWidth: "600px",
    maxWidth: "800px",
    maxHeight: "90%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: palette.white,
    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: 5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    borderRadius: 3,
  },
  body__message: {
    color: palette.black,
    fontSize: 16,
    fontFamily: "acumin-pro, san-serif",
    marginTop: 10,
    marginBottom: 6,
  },
});

export default AudioDeletionModal;
