import { useContext } from "react";
import { StyleSheet, Text, View } from "react-native-web";
import { Handle, Position } from "reactflow";
import { PhoneTreeContext } from "../../contexts/PhoneTreeContext";
import { checkNode } from "../../helpers/phoneTree";
import palette from "../../styles/palette";
import Icon from "react-native-vector-icons/Feather";
import IconButton from "../Buttons/IconButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { DEFAULT_ACTIVE_NODE } from "../../constants/phoneTreeTypes";

const styles = StyleSheet.create({
  node_container: {
    minHeight: 50,
    minWidth: 200,
    maxWidth: 200,
    padding: 5,
    borderRadius: 4,
    backgroundColor: palette.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
  },
  text: { fontSize: 12, textAlign: "center" },
});

const MainNode = ({ data }) => {
  const {
    setShowGreetingModal,
    setActiveNode,
    activeNode,
    addChild,
    hasEmptyNode,
  } = useContext(PhoneTreeContext);

  const isValid = checkNode(data);
  const childrenLength = data?.children?.length;

  const addNewChild = () => {
    if (childrenLength < 9) addChild(data.index, { type: "empty" });
  };

  return (
    <View>
      <View
        style={[
          styles.node_container,
          {
            border:
              activeNode?.index === data.index
                ? `2px solid ${palette.primary}`
                : isValid.error
                ? `2px solid ${palette.danger}`
                : `2px solid ${palette.light_grey}`,
          },
        ]}
        onClick={() => {
          data.text
            ? setActiveNode({ ...DEFAULT_ACTIVE_NODE, ...data })
            : setShowGreetingModal(true);
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            paddingVertical: 10,
          }}
        >
          <Text style={[styles.text]}>{data.label}</Text>
          <Text
            style={[
              styles.text,
              {
                color: palette.label_grey,
              },
            ]}
          >
            {data.text}
          </Text>
        </View>
      </View>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          backgroundColor: "#fff",
          border: "1px solid black",
          width: 14,
          height: 14,
          bottom: -7,
          zIndex: 1000,
          opacity: hasEmptyNode ? 0.5 : 1,
        }}
        onClick={hasEmptyNode ? null : addNewChild}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            faPro
            faProIcon={faPlus}
            iconSize={12}
            width={12}
            height={12}
            style={{ marginTop: 1 }}
            onPress={addNewChild}
            disabled={hasEmptyNode || childrenLength === 9}
          />
        </View>
      </Handle>
    </View>
  );
};

export default MainNode;
