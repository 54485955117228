import React, { useContext, useEffect } from "react";
import { View } from "react-native";
import ConversationThread from "~/components/ConversationThread/index.web";
import ConversationContext from "~/contexts/ConversationContext";

export default function ConversationScreen({ history, match }) {
  const { conversation } = useContext(ConversationContext);

  const { id } = match?.params || {};

  useEffect(() => {
    if (!conversation?.id && !id) {
      history.push("/messages");
    }
  }, [id]);

  return (
    <View style={{ flex: 1 }}>
      <ConversationThread />
    </View>
  );
}
