import React from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import Icon from "react-native-vector-icons/Feather";
import palette from "~/styles/palette";
import Row from "../../../layouts/Row";

export default function SolidButton({
  label,
  subtext = null,
  loading,
  color,
  labelColor,
  labelStyle,
  subtextStyle,
  style,
  round = false,
  width,
  onPress,
  disabled = false,
  icon,
  mr = 0,
  ml = 0,
}) {
  return (
    <TouchableOpacity
      activeOpacity={disabled ? 1 : 0.8}
      onPress={disabled || loading ? null : onPress}
      dtActionName={label ? label : "Button"}
    >
      <View
        style={[
          styles.solid_button__container,
          color && { backgroundColor: color },
          round && { borderRadius: 40 },
          width && { width },
          disabled && { opacity: 0.5 },
          { marginRight: mr, marginLeft: ml },
          color === palette.white && {
            borderColor: palette.light_grey,
            borderWidth: 1,
          },
          style,
        ]}
      >
        <Row center>
          {icon && !loading && (
            <Icon
              name={icon}
              size={18}
              color={
                labelColor || color === palette.white
                  ? palette.primary
                  : palette.white
              }
              style={{ marginRight: 8 }}
            />
          )}
          {loading ? (
            <ActivityIndicator
              size="small"
              color={
                labelColor || color === palette.white
                  ? palette.primary
                  : palette.white
              }
            />
          ) : (
            <View>
              <Text
                numberOfLines={1}
                style={[
                  styles.solid_button__label,
                  color === palette.white && { color: palette.primary },
                  labelColor && { color: labelColor },
                  ,
                  labelStyle,
                ]}
              >
                {label}
              </Text>

              {subtext && (
                <Text
                  numberOfLines={1}
                  style={[
                    styles.solid_button__label__subtext,
                    color === palette.white && { color: palette.primary },
                    labelColor && { color: labelColor },
                    subtextStyle,
                  ]}
                >
                  {subtext}
                </Text>
              )}
            </View>
          )}
        </Row>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  solid_button__container: {
    borderRadius: 4,
    backgroundColor: palette.primary,
    minHeight: 44,
    paddingHorizontal: 16,
    paddingVertical: 8,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    // cursor: "pointer",
  },
  solid_button__label: {
    fontSize: 15,
    fontWeight: "600",
    color: "white",
    textAlign: "center",
    marginVertical: 0,
    paddingVertical: 0,
  },
  solid_button__label__subtext: {
    fontSize: 11,
    fontWeight: "400",
    color: "white",
    textAlign: "center",
    marginVertical: 0,
    paddingVertical: 0,
  },
});
