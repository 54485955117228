import {
  BUNDLE_ADDRESS_SID_KEY,
  BUNDLE_SID_KEY,
  BUNDLE_STATUS,
  BUNDLE_STATUS_KEY,
} from "../constants/bundle.js";
import { Model } from "./base.js";

export default class Bundle extends Model {
  isBundleVerified() {
    return this[BUNDLE_STATUS_KEY];
  }

  getBundleSid() {
    return this[BUNDLE_SID_KEY];
  }

  getAddressSid() {
    return this[BUNDLE_ADDRESS_SID_KEY];
  }

  getBundleData() {
    return {
      [BUNDLE_SID_KEY]: this.getBundleSid(),
      [BUNDLE_ADDRESS_SID_KEY]: this.getAddressSid(),
    };
  }

  get defaults() {
    return BUNDLE_STATUS;
  }
}
