import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
} from "react";
import Engage from "../services/engage";
import UserContext from "./UserContext";

const NumbersContext = createContext({});

export const NumbersProvider = ({ children }) => {
  const { user, loggedIn } = useContext(UserContext);
  const [activeNumber, setActiveNumber] = useState(user?.assigned_number);

  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const managedNumbers = useMemo(() => {
    let res = [];
    if (user) {
      let user_id = user?.id;
      numbers?.map((number) => {
        const managers = number?.managers || [];
        managers?.map((manager) => {
          if (manager.id === user_id) {
            res.push(number);
          }
        });
      });
    }
    return res;
  }, [JSON.stringify(numbers), user?.id]);

  const getNumbers = useCallback(async () => {
    if (!loggedIn || loading) return numbers;
    setLoading(true);
    if (!initialLoading) setInitialLoading(true);
    const res = await Engage.getNumbers();
    if (res?.error) {
      setError(error);
    } else if (Array.isArray(res?.response)) {
      setNumbers(res?.response || []);
      if (res?.assigned_number) {
        setActiveNumber(res.assigned_number);
      } else if (res?.response?.length) {
        setActiveNumber(res.response[0]);
      }
    }
    setLoading(false);
  }, [
    setLoading,
    setError,
    setNumbers,
    setActiveNumber,
    loading,
    loggedIn,
    initialLoading,
  ]);

  useEffect(() => {
    if (loggedIn) {
      getNumbers().then(() => {});
    }
  }, [loggedIn]);

  // NOTE: assignees and managers are added
  const assignedNumber = user?.assigned_number;

  return (
    <NumbersContext.Provider
      value={{
        loading,
        numbers,
        activeNumber,
        assignedNumber,
        setActiveNumber,
        getNumbers,
        managedNumbers,
        initialLoading,
      }}
    >
      {children}
    </NumbersContext.Provider>
  );
};

export default NumbersContext;
