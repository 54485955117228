import { createContext, useState } from "react";
import Engage from "../services/engage";

const BlockedContactsContext = createContext({});

export function BlockedContactsProvider({ children }) {
  const [contacts, setContacts] = useState([]);
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchedAll, setFetchedAll] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const getBlockedContacts = async () => {
    setLoading(true);
    setSearch("");
    if (page === 1) {
      setFetchedAll(false);
    }
    try {
      const res = await Engage.getBlockedNumbers({
        page: page,
      });
      if (!res.error) {
        setContacts(
          page === 1 ? res?.response : [...contacts, ...res?.response],
        );

        if (page === res.totalPages) {
          setFetchedAll(true);
        }
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const searchBlockedContacts = async () => {
    setSearching(true);

    try {
      const res = await Engage.getBlockedNumbers({
        page: 1,
        search,
      });
      if (!res.error) {
        setSearchedContacts(res?.response);
      }
    } catch (err) {
      console.error(err);
    }
    setSearching(false);
  };

  const nextPage = () => {
    setPage((p) => p + 1);
    getBlockedContacts();
  };

  const refresh = () => {
    setPage(1);
    setContacts([]);
    getBlockedContacts();
  };

  const addBlockedContact = (contact) => {
    setContacts((contacts) => {
      if (contacts.length === 0) {
        return [contact];
      }
      if (contacts?.map((contact) => contact?.id)?.includes(contact?.id))
        return contacts;
      return [...contacts, contact];
    });
  };

  const removeBlockedContact = (id) => {
    setContacts((contacts) => {
      return contacts.filter((c) => c.id !== id);
    });
  };

  return (
    <BlockedContactsContext.Provider
      value={{
        getBlockedContacts,
        contacts,
        loading,
        refresh,
        nextPage,
        fetchedAll,
        page,
        search,
        setSearch,
        searchBlockedContacts,
        searching,
        searchedContacts,
        addBlockedContact,
        removeBlockedContact,
      }}
    >
      {children}
    </BlockedContactsContext.Provider>
  );
}

export default BlockedContactsContext;
