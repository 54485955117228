import Row from "../../layouts/Row";
import Col from "../../layouts/Col";
import TopBar from "../TopBar/index.web";
import palette from "../../styles/palette";
import { StyleSheet, View, Image, Text } from "react-native";
import Icon from "react-native-vector-icons/Feather";
import { NavLink } from "react-router-dom";
import { matchPath, withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { useContext, useMemo } from "react";
import FontAwesome5Pro from "../../assets/icons/FontAwesome5Pro";
import { BadgeContext } from "../../contexts/BadgeContext";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faAddressBook,
  faComments,
  faPhone,
  faVoicemail,
} from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../contexts/UserContext";

export default function MenuBar() {
  const { fpUser } = useContext(UserContext);
  const { calls, messages, voicemails } = useContext(BadgeContext);

  return (
    <Row
      style={{
        borderBottomWidth: 1,
        borderBottomColor: palette.light_grey,
      }}
    >
      <Col>
        <IconNavLink
          iconName={faComments}
          label="Conversations"
          to="/messages"
          badge={messages}
        />
      </Col>
      <Col>
        <IconNavLink
          iconName={faPhone}
          label="Calls"
          to="/calls"
          badge={calls}
        />
      </Col>

      <Col>
        <IconNavLink
          iconName={faVoicemail}
          label="Voicemails"
          to="/voicemails"
          badge={voicemails}
        />
      </Col>
      {fpUser?.can_view_customer_list && (
        <Col>
          <IconNavLink
            iconName={faAddressBook}
            label="Customers"
            to="/customers"
          />
        </Col>
      )}
    </Row>
  );
}

const IconNavLink = withRouter(
  ({ to, iconName = "", badge, vertical, bgOverride = null, label = null }) => {
    const history = useHistory();

    const isMatch = matchPath(history?.location.pathname, {
      path: to,
      exact: false,
      strict: false,
    });

    const backgroundColor = useMemo(() => {
      if (bgOverride) {
        return bgOverride;
      }

      return palette.grey_bg;
    }, [bgOverride]);

    return (
      <NavLink
        to={to}
        activeStyle={{
          color: palette.success,
        }}
        style={{ textDecoration: "none" }}
      >
        <View
          style={{
            width: vertical ? "100%" : "auto",
            marginRight: vertical ? 0 : 0,
            height: vertical ? 95 : 74,
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            backgroundColor,
            position: "relative",
          }}
        >
          <FontAwesomeIcon
            icon={iconName}
            size={24}
            color={isMatch || bgOverride ? palette.primary : palette.grey}
            style={[
              styles.main_icon_active,
              { bottom: isMatch ? 30 : 30, marginBottom: 4 },
            ]}
          />
          {(badge || badge == 0) && <IconBadge count={badge} />}
          {label && (
            <View style={[styles.hover_label, { bottom: isMatch ? 10 : 10 }]}>
              <Text
                style={[
                  styles.hover_label_text,
                  {
                    color:
                      isMatch || bgOverride ? palette.primary : palette.grey,
                    fontWeight: isMatch ? "600" : "400",
                    marginBottom: 2,
                    fontSize: 10,
                  },
                ]}
              >
                {label}
              </Text>
            </View>
          )}
          {isMatch && (
            <View
              style={{
                height: 5,
                position: "absolute",
                left: 0,
                bottom: -1,
                right: 0,
                backgroundColor: palette.primary,
                borderRadius: 4,
                zIndex: 50,
              }}
            />
          )}
        </View>
      </NavLink>
    );
  },
);

const IconBadge = ({ count }) => (
  <>
    {!count ? null : (
      <View style={[styles.icon_nav_link__badge_container]}>
        <Text style={[styles.icon_nav_link__badge_text]}>{count}</Text>
      </View>
    )}
  </>
);

const styles = StyleSheet.create({
  icon_nav_link__badge_container: {
    borderRadius: 20,
    maxHeight: 20,
    backgroundColor: palette.success,
    position: "absolute",
    top: 10,
    right: 25,
    minWidth: 22,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    paddingVertical: 6,
  },
  icon_nav_link__badge_text: {
    color: palette.white,
    fontSize: 12,
    fontWeight: "700",
  },
  hover_label: {
    position: "absolute",
    width: "100%",
    transition: "bottom 0.3s",
  },
  hover_label_text: {
    fontSize: 12,
    textAlign: "center",
  },
  main_icon_active: {
    position: "absolute",
    width: "100%",
    transition: "bottom 0.3s",
    textAlign: "center",
  },
});
