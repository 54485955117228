import palette from "../styles/palette";

export const CALLER_ID_APPROVED_STATUS = 2;
export const CALLER_ID_REJECTED_STATUS = 3;
export const CALLER_ID_IN_REVIEW_STATUS = 1;
export const CALLER_ID_IN_ACTIVE_STATUS = 0;
export const CALLER_ID_KEY = "caller_id";
export const CALLER_ID_STATUS_KEY = "caller_id_status";

export const CALLER_ID_STATUSES = {
  [CALLER_ID_APPROVED_STATUS]: {
    label: "Approved",
    color: palette.green,
  },
  [CALLER_ID_REJECTED_STATUS]: {
    label: "Rejected",
    color: palette.red,
  },
  [CALLER_ID_IN_REVIEW_STATUS]: {
    label: "In Review",
    color: palette.yellow,
  },
  [CALLER_ID_IN_ACTIVE_STATUS]: {
    label: "Inactive",
    color: palette.grey,
  },
};
