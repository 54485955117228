import React, { useContext, useEffect, useState } from "react";
import {
  Dimensions,
  StyleSheet,
  View,
  Text,
  TouchableWithoutFeedback,
  Image,
} from "react-native";
import { useDropzone } from "react-dropzone";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import palette from "~/styles/palette";
import useMedia from "../../hooks/useMedia";

import { MediaContext } from "../../contexts/MediaContext";
import SolidButton from "~/components/Buttons/SolidButton";
import { faXmark, faCheck } from "@fortawesome/pro-regular-svg-icons";

const MAX_DROP_LIMIT = 10;
export default function FileUploadModal() {
  const { mediaUploadVisible, setMediaUploadVisible } =
    useContext(MediaContext);
  const isFullScreen = Dimensions.get("window").width < 960;
  const { assets, setAssets } = useMedia();

  const [tempAssets, setTempAssets] = useState([]);
  const [dropError, setDropError] = useState();
  const onDrop = (files) => {
    console.log("onDrop -> ", files);
    setTempAssets(
      files.map((f) => ({
        preview: URL.createObjectURL(f),
        f,
      })),
    );
  };

  const onRemove = (file) => () => {
    setTempAssets(tempAssets.filter((f) => f.name !== file.name));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: (e) => {
      if (e.length > 10)
        setDropError(
          `Upload unsuccessful due to exceeding the maximum upload limit(${MAX_DROP_LIMIT})`,
        );
    },
    maxFiles: MAX_DROP_LIMIT - assets?.length,
    accept: "image/jpeg, image/png, image/heic",
  });

  const onCloseModal = () => {
    setMediaUploadVisible(false);
  };

  const onSubmit = () => {
    setAssets(
      tempAssets.map((f) => ({
        path: f.preview,
        mime: f.f.type,
        fileName: f.f.name,
        _f: f.f,
      })),
    );
    setTempAssets([]);
    setMediaUploadVisible(false);
  };

  if (!mediaUploadVisible) return null;

  return (
    <TouchableWithoutFeedback onPress={onCloseModal}>
      <View style={[styles.modal_overlay]}>
        <TouchableWithoutFeedback onPress={() => {}}>
          <View
            style={[
              styles.modal_container,
              { width: isFullScreen ? "100%" : 600 },
            ]}
          >
            <Text style={[styles.modal__header_title_text]}>Attach Media</Text>
            <Text style={[styles.modal__header_shadow_text]}>
              Images and videos under 4mb are supported.
            </Text>

            <Row mt={32} mb={16} center>
              <div
                {...getRootProps()}
                style={{
                  borderStyle: "dashed",
                  borderColor: palette.light_grey,
                  borderWidth: 2,
                  borderRadius: 4,
                  width: "100%",
                  height: 64,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginLeft: 32,
                  marginRight: 32,
                  // paddingHorizontal: 16,
                  // paddingVertical: 48,
                  paddingTop: 48,
                  paddingBottom: 48,
                  paddingLeft: 16,
                  paddingRight: 16,
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Text style={[styles.modal__header_subtitle_text]}>
                    Drop files to attach.
                  </Text>
                ) : (
                  <Text style={[styles.modal__header_subtitle_text]}>
                    Drag files you wish to attach here or click to select a
                    file.
                  </Text>
                )}
              </div>
            </Row>

            {!!dropError && (
              <Text style={[styles.modal__error_text]}>{dropError}</Text>
            )}

            {tempAssets && (
              <Row
                pl={32}
                pr={32}
                maxWidth="100%"
                centered
                style={{
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {tempAssets.map((f) => (
                  <TouchableWithoutFeedback onPress={onRemove(f)} key={f.name}>
                    <Col center maxWidth={128}>
                      <img style={{ width: 50 }} src={f.preview} />
                    </Col>
                  </TouchableWithoutFeedback>
                ))}
              </Row>
            )}

            <Row pl={32} pr={32} mt={16} spaceBetween>
              <View>
                <SolidButton
                  label="Cancel"
                  onPress={onCloseModal}
                  faIcon={faXmark}
                  color={palette.danger}
                />
              </View>

              <Col right>
                <SolidButton
                  label="Add to Message"
                  onPress={onSubmit}
                  faIcon={faCheck}
                  color={palette.success}
                />
              </Col>
            </Row>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  modal__header: {
    height: 75,
    borderBottomColor: palette.light_grey,
    borderBottomWidth: 1,
    backgroundColor: palette.primary,
    justifyContent: "center",
  },
  modal__body: {
    paddingHorizontal: 16,
    flex: 1,
  },
  modal__footer: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  modal__header_title_text: {
    color: palette.white,
    fontSize: 32,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal__header_subtitle_text: {
    color: palette.white,
    fontSize: 20,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal__header_shadow_text: {
    color: palette.white,
    opacity: 0.6,
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
  modal_overlay: {
    backgroundColor: "rgba(45, 62, 80, 0.65)",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
    transitionProperty: "none",
    transitionDelay: "0ms",
    transitionDuration: "100ms",
    zIndex: 100,
  },
  modal_container: {
    borderRadius: 8,
    justifyContent: "center",
    backgroundColor: palette.primary,
    paddingVertical: 16,
    position: "absolute",
    minWidth: "300px",
    maxWidth: "100%",

    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    zIndex: 1040,
    shadowColor: "#000",
    shadowOffset: {
      width: -5,
      height: 0,
    },
    shadowOpacity: 0.12,
    shadowRadius: 9,
    elevation: 4,
    transitionProperty: "right",
    transitionDelay: "100ms",
    transitionDuration: "200ms",
  },
  modal__error_text: {
    color: palette.danger,
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    textAlign: "center",
  },
});
