import React, { useContext } from "react";
import Avatar                from "~/components/Avatar";
import UserContext           from "~/contexts/UserContext";

export default function UserAvatar({ ...props }) {
  const { user } = useContext(UserContext);

  return (
    <Avatar
      first_name={user?.first_name || ""}
      last_name={user?.last_name || ""}
      initials={user?.initials || ""}
      {...props}
    />
  );
}
