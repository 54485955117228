import { DEVELOPMENT_ENV, PRODUCTION_ENV, STAGING_ENV } from "./envs";

export const REPORTING_V2_DEV_URL = "http://localhost:3001";
export const REPORTING_V2_STAGING_URL =
  "https://dev-reporting-v2.fieldpulse.com/";
export const REPORTING_V2_PROD_URL = "https://reporting-v2.fieldpulse.com/";

export const REPORTING_URL = {
  [DEVELOPMENT_ENV]: REPORTING_V2_DEV_URL,
  [STAGING_ENV]: REPORTING_V2_STAGING_URL,
  [PRODUCTION_ENV]: REPORTING_V2_PROD_URL,
};
