import { parsePhoneNumber } from 'libphonenumber-js';

/**
 *
 * safeParseNumber(num="", country=")
 * 
 * Safely parses a number using libphonenumber-js
 *
 * @param number
 * @param country
 * @return {*} PhoneNumber:Object
 */
export const safeParseNumber = (num="", country="") => {
  try {
    return parsePhoneNumber(num, country);
  } catch (e) {
    return null
  }
};
