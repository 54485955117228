import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { IssueReport } from "../models/IssueReport";
import Engage from "../services/engage";
import { CALL_REPORTING_CATEGORY_OTHER_ISSUE } from "../constants/issueReport";
import DeviceContext from "./DeviceContext";
import BrowserContext from "./BrowserContext";
import { Platform } from "react-native";

const IssueReportContext = createContext({});

export const useIssueReport = () => {
  return useContext(IssueReportContext);
};

export const IssueReportProvider = ({ id, children }) => {
  const [report, setReport] = useState(IssueReport.create(id));
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const { device } = useContext(DeviceContext);
  const { browserDevice } = useContext(BrowserContext);

  const setRating = (rating) => {
    setReport({ ...report, rating });
  };

  const addCategory = (category) => {
    setReport((report) => {
      const oldCategories = report.categories || [];
      const newCategories = [...oldCategories, category];

      return {
        ...report,
        categories: newCategories.filter(
          (c) => c !== CALL_REPORTING_CATEGORY_OTHER_ISSUE,
        ),
      };
    });
  };

  const removeCategory = (category) => {
    setReport((report) => {
      const oldCategories = report.categories || [];
      const newCategories = oldCategories.filter((c) => c !== category);
      return { ...report, categories: newCategories };
    });
  };

  const setCategories = (categories) => {
    setReport((report) => ({ ...report, categories }));
  };

  const setDescription = (description) => {
    setReport({ ...report, description });
  };

  const submitFeedback = async () => {
    setSaving(true);
    setError(false);
    let res = {};
    const data = new IssueReport({ ...report });
    data.addDeviceToken(
      Platform.OS === "web" ? browserDevice?.push_token : device?.push_token,
    );
    let draft = { ...data };
    try {
      res = await Engage.reportIssue(draft);
      if (res.errors) {
        setError(res.errors || "Something went wrong. Please try again later.");
      }
    } catch {
      setError("Something went wrong. Please try again later.");
    } finally {
      setSaving(false);
    }
    return res;
  };

  useEffect(() => {
    if (
      report.rating === 5 &&
      (report.categories.length > 0 || report.description)
    ) {
      setReport({ ...report, categories: [], description: "" });
    }
  }, [report.rating, report.categories, report.description]);

  return (
    <IssueReportContext.Provider
      value={{
        report,
        setRating,
        addCategory,
        removeCategory,
        setDescription,
        submitFeedback,
        saving,
        error,
        setCategories,
      }}
    >
      {children}
    </IssueReportContext.Provider>
  );
};

export default IssueReportContext;
