import { createFA5iconSet } from "@expo/vector-icons/build/createIconSetFromFontAwesome5";
import glyphMap from "./FontAwesome5Pro.json";
import metadata from "./FontAwesome5Pro_meta.json";

const fontMap = {
  Regular: require("../fonts/FontAwesome5_Pro_Regular.ttf"),
  Light: require("../fonts/FontAwesome5_Pro_Light.ttf"),
  Solid: require("../fonts/FontAwesome5_Pro_Solid.ttf"),
  Brand: require("../fonts/FontAwesome5_Pro_Brands.ttf"),
};

const FontAwesome5Pro = createFA5iconSet(glyphMap, metadata, fontMap, true);
export default FontAwesome5Pro;
