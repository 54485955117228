import palette from "../styles/palette";

// Jobs header
export const JOBS_TYPES = {
  Job: ["Team Jobs", "My Jobs"],
  Service: ["Team Services", "My Services"],
  "Work Order": ["Team Work Orders", "My Work Orders"],
  Project: ["Team Projects", "My Projects"],
};

export const JOB_STATUS_NEW = 1;
export const JOB_STATUS_ON_THE_WAY = 6;
export const JOB_STATUS_IN_PROGRESS = 2;
export const JOB_STATUS_PENDING = 3;
export const JOB_STATUS_COMPLETED = 4;
export const JOB_STATUS_CANCELED = 5;

export const JOB_STATUS = [
  {
    value: JOB_STATUS_NEW,
    label: "New",
    color: palette.blue,
    colorLight: palette.light_blue,
  },
  {
    value: JOB_STATUS_ON_THE_WAY,
    label: "On the Way",
    color: palette.purple_job,
    colorLight: palette.light_purple,
    filter: ({ company }) => company.is_on_the_way_status_enabled,
  },
  {
    value: JOB_STATUS_IN_PROGRESS,
    label: "In Progress",
    color: palette.green,
    colorLight: palette.light_green,
  },
  {
    value: JOB_STATUS_PENDING,
    label: "Pending",
    color: palette.yellow,
    colorLight: palette.light_yellow,
  },
  {
    value: JOB_STATUS_COMPLETED,
    label: "Completed",
    color: palette.dark_grey,
    colorLight: palette.lightest_grey,
  },
  {
    value: JOB_STATUS_CANCELED,
    label: {
      default: "Canceled",
      AU: "Cancelled",
      NZ: "Cancelled",
    },
    color: palette.dark_red,
    colorLight: palette.light_red,
  },
];

export const TABS = ["All", "Open", "Closed", "Unassigned", "By Tags", "Tasks"];
export const NON_MANAGER_TABS = ["All", "Open", "Closed", "By Tags", "Tasks"];
