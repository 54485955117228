import { TouchableOpacity, Text, StyleSheet } from "react-native";
import { Strings } from "../../constants/strings";
import palette from "../../styles/palette";

const styles = StyleSheet.create({
  input_toolbar__banner_container: {
    width: "100%",
    backgroundColor: palette.bg_red,
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  input_toolbar__banner_text: {
    color: palette.label_red,
    fontFamily: "OpenSans_600SemiBold",
    fontSize: 12,
  },
});

export const BlockedBanner = ({
  isBlocked,
  onUnBlockPress,
  isAdmin,
  style,
}) => {
  if (!isBlocked) {
    return null;
  }

  return (
    <TouchableOpacity
      activeOpacity={0.95}
      style={[styles.input_toolbar__banner_container, style]}
      onPress={isAdmin ? onUnBlockPress : null}
    >
      <Text style={styles.input_toolbar__banner_text}>{`${
        Strings.BLOCKED_BANNER_MESSAGE
      }${isAdmin ? "\n" + Strings.BLOCKED_BANNER_UNBLOCK_MESSAGE : ""}`}</Text>
    </TouchableOpacity>
  );
};
