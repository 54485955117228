import React, {
  useContext,
  useMemo,
  createContext,
  useState,
  useEffect,
} from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  TouchableWithoutFeedback,
} from "react-native";

import { VoicemailsContext } from "~/contexts/VoicemailsContext";

import IconButton from "~/components/Buttons/IconButton/index";
import Heading from "~/components/Heading/index";
import palette from "~/styles/palette";
import Row from "~/layouts/Row";
import Col from "~/layouts/Col";
import { getSmartTimestamp } from "~/helpers/day";
import useTwilio from "~/hooks/useTwilio";
import Engage from "../../services/engage";
import {
  faComments,
  faPhone,
  faVoicemail,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import ConversationContext from "../../contexts/ConversationContext";
import UserContext from "../../contexts/UserContext";
import ThreadsContext from "../../contexts/ThreadsContext";
import LineFilterBar from "../Bars/LineFilterBar/index.web";
import NumbersContext from "../../contexts/NumbersContext";
import LoadingIndicator from "../LoadingIndicatior/index.web";
import useVoicemailssearch from "../../hooks/useVoicemailSearch";
import SearchBar from "../Bars/SearchBar/index.web";
import { SEARCH_LIMIT } from "../../constants/search";
import { getCommaSeparatedHoursMinutesSeconds } from "../../helpers/text";
import commonStyles from "../../styles/common";
import CustomersContext from "../../contexts/CustomersContext";
import BlockedBadge from "../BlockedBadge";

const AudioExpandedContext = createContext({
  currentlyExpanded: null,
  onExpand: () => {},
});

function AudioExpandedProvider({ children }) {
  const [current, setCurrent] = useState(null);

  return (
    <AudioExpandedContext.Provider
      value={{
        currentlyExpanded: current,
        onExpand: setCurrent,
      }}
    >
      {children}
    </AudioExpandedContext.Provider>
  );
}

function useAudioExpanded(self) {
  const { currentlyExpanded, onExpand } = useContext(AudioExpandedContext);

  const selfIsExpanded = currentlyExpanded && currentlyExpanded.id === self.id;

  const expand = () => onExpand(self);

  return {
    selfIsExpanded,
    expand,
  };
}

const VoicemailList = ({ style = {} }) => {
  const {
    loading,
    initialLoading,
    voicemails,
    refreshVoicemails,
    nextPage,
    setSelected,
    setPageActive,
  } = useContext(VoicemailsContext);

  const [searchTerm, setSearchTerm] = useState("");
  const showSearchResults = searchTerm.length >= SEARCH_LIMIT;
  const { managedNumbers } = useContext(NumbersContext);
  const { user } = useContext(UserContext);
  const { filter, selectedLines } = useContext(ThreadsContext);

  let params = {};

  if (filter === "all") {
    params.managed_lines = managedNumbers.map((num) => num?.id);
  } else if (filter === "assigned") {
    params.managed_lines = [user?.assigned_number?.id];
  } else {
    params.managed_lines = selectedLines || [];
  }

  const { voicemails: suggestions, loading: loadingSuggestions } =
    useVoicemailssearch({
      search: searchTerm,
      page: 1,
      limit: 50,
      filter,
      ...params,
    });

  useEffect(() => {
    setPageActive(true);

    return () => {
      setPageActive(false);
    };
  }, []);

  const onVoicemailPress = (data) => {
    setSelected(data);
  };

  return (
    <View style={[styles.container, style]}>
      <AudioExpandedProvider>
        <View
          style={{
            paddingHorizontal: 6,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: palette.light_grey,
            zIndex: 200,
          }}
        >
          <VoicemailFilter />
          <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            placeholder={"Search by name or phone"}
            managedNumbersLength={managedNumbers?.length}
          />
        </View>
        {(loadingSuggestions && searchTerm?.length > 0) || initialLoading ? (
          <LoadingIndicator title={"Loading Voicemails"} />
        ) : (
          <FlatList
            data={showSearchResults ? suggestions : voicemails}
            refreshing={loading}
            onRefresh={refreshVoicemails}
            renderItem={renderVoicemailListItem(onVoicemailPress, setSelected)}
            showsVerticalScrollIndicator={true}
            keyExtractor={(item) => `voicemail-list-item-${item.id}`}
            onEndReached={nextPage}
            onEndReachedThreshold={0.9}
            ListEmptyComponent={NoVoicemailsComponent}
          />
        )}
      </AudioExpandedProvider>
    </View>
  );
};

const VoicemailFilter = () => {
  const { filter, setFilter } = useContext(ThreadsContext);
  const { managedNumbers } = useContext(NumbersContext);
  const { loading } = useContext(VoicemailsContext);
  return (
    <>
      {managedNumbers?.length > 1 && (
        <LineFilterBar
          activeFilter={filter}
          onFilterChange={(activeFilter) => setFilter(activeFilter)}
          loading={loading}
        />
      )}
    </>
  );
};

const renderVoicemailListItem = (onPress, setSelected) => (props) => {
  return (
    <VoicemailListItem {...props} onPress={onPress} setSelected={setSelected} />
  );
};

const VoicemailListItem = ({ item, onPress, setSelected }) => {
  const { expand } = useAudioExpanded(item);
  const { conversation, setConversationById, setTempHeader } =
    useContext(ConversationContext);
  const { blockedContacts } = useContext(CustomersContext);
  const { setCallQueue } = useTwilio();
  const { selected } = useContext(VoicemailsContext);
  const { user, canSeeCustomerContact } = useContext(UserContext);

  const [isRead, setIsRead] = useState(item.read);
  const isCustomerSaved = item?.participant?.remote_id !== null;
  const hasParent = !!item?.participant?.parent;
  const parentName = item?.participant?.parent?.name;
  const isBlocked =
    item?.participant?.is_blocked ||
    blockedContacts?.includes(item?.participant?.id);
  const phoneNumber = useMemo(() => {
    if (item !== null) {
      const { to, from, direction } = item?.call_data;
      let phone = to;

      if (direction === "inbound") {
        phone = from;
      }

      return phone;
    }
    return "";
  }, [item, user?.account?.country]);

  const onCallPress = () => {
    setCallQueue({ to: phoneNumber, metaData: item?.participant });
  };

  const onVoicemailPress = () => {
    expand(item);
    onPress(item);
    Engage.markVoicemailAsRead(item.id).then(() => {
      setIsRead(true);
    });
    if (conversation?.id !== item?.call_data?.conversation_id) {
      const title = item?.participant?.name || phoneNumber;
      setTempHeader({
        main:
          isCustomerSaved || canSeeCustomerContact ? title : "Unknown Customer",
        sub: item?.participant?.parent?.name,
      });
      setConversationById(item?.call_data?.conversation_id, true);
    }
  };
  return (
    <TouchableWithoutFeedback onPress={onVoicemailPress}>
      <View
        style={[
          styles.voicemail_list__item,
          {
            backgroundColor:
              selected?.id === item?.id
                ? palette.grey_selection
                : palette.grey_bg,
            cursor: "pointer",
          },
        ]}
      >
        <Row style={{ height: "100%" }}>
          <View style={[styles.voicemail_list__list_item_avatar_container]}>
            <FontAwesomeIcon
              icon={faVoicemail}
              size={18}
              color={palette.grey}
            />
          </View>
          <View style={{ flex: 1 }}>
            <Row center style={{ justifyContent: "space-between" }}>
              <View style={commonStyles.row_normal}>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={[styles.voicemail_list__item_name]}
                >
                  {isCustomerSaved || canSeeCustomerContact
                    ? item.participant.name
                    : "Unknown Customer"}
                </Text>
                {!!hasParent && (
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={[styles.voicemail_list__item_name]}
                  >
                    {`: ${parentName}`}
                  </Text>
                )}
              </View>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.voicemails_list__item_timestamp]}
              >
                {getSmartTimestamp(item.created_at)}
              </Text>
            </Row>
            {isBlocked && (
              <Row mt={4}>
                <Col left>
                  <BlockedBadge visible />
                </Col>
              </Row>
            )}
            <Row
              style={{
                justifyContent: "space-between",
                gap: 8,
                alignItems: "center",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: 8,
                }}
              >
                {canSeeCustomerContact && (
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={[styles.voicemails_list__item_subtext]}
                  >
                    {phoneNumber}
                  </Text>
                )}
                <View>
                  <Col>
                    <Row
                      mt={8}
                      style={{
                        alignItems: "center",
                        gap: 8,
                        justifyContent: "flex-start",
                      }}
                    >
                      <IconButton
                        faPro
                        faProIcon={faPhone}
                        iconColor={palette.success}
                        onPress={onCallPress}
                        iconSize={12}
                        width={12}
                        height={12}
                        mr={0}
                        ml={0}
                        disabled={isBlocked}
                      />
                      <IconButton
                        faPro
                        faProIcon={faComments}
                        iconColor={palette.primary}
                        width={12}
                        height={12}
                        mr={0}
                        ml={0}
                        onPress={async () => {
                          setSelected(null);
                          if (
                            conversation?.id !==
                            item?.call_data?.conversation_id
                          ) {
                            const title =
                              item?.participant?.name || phoneNumber;
                            setTempHeader({
                              main:
                                isCustomerSaved || canSeeCustomerContact
                                  ? title
                                  : "Unknown Customer",
                              sub: item?.participant?.parent?.name,
                            });
                            await setConversationById(
                              item?.call_data?.conversation_id,
                              true,
                            );
                          }
                        }}
                        iconSize={12}
                      />
                    </Row>
                  </Col>
                </View>
              </View>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.voicemails_list__item_timestamp]}
              >
                {getCommaSeparatedHoursMinutesSeconds(
                  item.duration,
                  "seconds",
                  true,
                )}
              </Text>
            </Row>
            <Row center style={{ justifyContent: "space-between" }}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.voicemails_list__item_subtext]}
              >
                {item?.call_data?.twilio_number?.friendly_name}
              </Text>
            </Row>
          </View>
          <View
            style={[
              styles.voicemail_list__list_item_avatar_container,
              { marginRight: 0, width: 14, alignItems: "flex-end" },
            ]}
          >
            {!isRead && (
              <FontAwesomeIcon icon={faCircle} size={6} color={palette.info} />
            )}
          </View>
        </Row>
      </View>
    </TouchableWithoutFeedback>
  );
};

const NoVoicemailsComponent = () => {
  return (
    <View style={[styles.no_conversations_component]}>
      <Heading center size={4} color={palette.grey}>
        No voicemails
      </Heading>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: palette.grey_bg,
  },
  no_conversations_component: {
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 64,
  },
  voicemail_list__item: {
    minHeight: 65,
    padding: 12,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: palette.light_grey,
  },
  voicemail_list__item_name: {
    fontSize: 14,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
  },
  voicemails_list__item_subtext: {
    fontSize: 12,
    color: palette.grey,
  },
  voicemails_list__item_timestamp: {
    fontSize: 12,
    fontWeight: "600",
    fontFamily: "OpenSans_600SemiBold",
    color: palette.grey,
    textAlign: "right",
  },
  voicemail_list__item_duration: {
    fontSize: 12,
  },
  voicemail_list__item_missed: {
    color: palette.danger,
  },
  voicemail_list__list_item_read_indicator: {
    color: palette.info,
    fontSize: 48,
    position: "absolute",
    left: -10,
    top: "50%",
    transform: "translate(-50%, -55%)",
  },
  voicemail_list__button_container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  voicemail_list__list_item_avatar_container: {
    width: 30,
    marginRight: 8,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default VoicemailList;
