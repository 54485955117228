import React from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import palette from "../../styles/palette";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";

const styles = {
  searchable_input: {
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: palette.lightest_grey,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  option_container: {
    padding: 8,
    width: 18,
    height: 18,
  },
  label_text: {
    fontFamily: "OpenSans_600SemiBold",
  },
  sub_label_text: { fontFamily: "OpenSans_400Regular", fontSize: 14 },
};

export default function StyledSelect({
  options = [],
  selectedValue,
  onValueChange,
  disabled,
  searchable = false,
  onSearch,
  isLoading = false,
  noBorder = false,
}) {
  const onChange = (option) => {
    onValueChange?.(option.value);
  };

  const loadOptions = async (inputValue, callback) => {
    const res = await onSearch(inputValue);
    callback(res);
  };

  if (searchable) {
    return (
      <AsyncSelect
        loadOptions={loadOptions}
        defaultOptions={options}
        onChange={onChange}
        value={options.find((option) => option.value === selectedValue)}
        menuPortalTarget={document.body}
        isDisabled={disabled}
        isLoading={isLoading}
        components={{
          Option,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base) => ({
            ...base,
            ...styles.searchable_input,
            fontFamily: "OpenSans_400Regular",
            border: noBorder ? "none" : "1px solid " + palette.light_grey,
          }),
          menuPortal: (base) => ({
            ...base,
            fontFamily: "OpenSans_400Regular",
          }),
        }}
        isSearchable
      />
    );
  }

  return (
    <Select
      options={options}
      value={options.find((option) => option.value === selectedValue)}
      onChange={onChange}
      menuPortalTarget={document.body}
      isDisabled={disabled}
      components={{
        Option,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base) => ({
          ...base,
          fontFamily: "OpenSans_400Regular",
          border: noBorder ? "none" : "1px solid " + palette.light_grey,
        }),
        menuPortal: (base) => ({
          ...base,
          fontFamily: "OpenSans_400Regular",
        }),
      }}
    />
  );
}

const Option = (props) => {
  if (props.data.show_unread) {
    return (
      <components.Option {...props}>
        <div style={styles.flex}>
          <div style={styles.option_container}>
            {props.data.unread && (
              <FontAwesomeIcon icon={faCircle} size={12} color={palette.info} />
            )}
          </div>
          <div>
            <div style={styles.label_text}>{props.data.label}</div>
            <div style={styles.sub_label_text}>{props.data.subLabel}</div>
          </div>
        </div>
      </components.Option>
    );
  } else if (props.data.subLabel) {
    return (
      <components.Option {...props}>
        <div style={styles.label_text}>{props.data.label}</div>
        <div style={styles.sub_label_text}>{props.data.subLabel}</div>
      </components.Option>
    );
  } else {
    return components.Option(props);
  }
};
