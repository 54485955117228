import React from "react";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";
import Icon from "react-native-vector-icons/Feather";
import FAIcon from "react-native-vector-icons/FontAwesome";
import FA5Icon from "react-native-vector-icons/FontAwesome5";
import IonicIcons from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcon from "react-native-vector-icons/MaterialCommunityIcons";
import palette from "~/styles/palette";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export default function IconButton({
  iconName,
  iconSize = 22,
  iconColor,
  fa = false,
  fa5 = false,
  faPro = false,
  material = false,
  ionic = false,
  color,
  round = true,
  onPress,
  disabled = false,
  border = false,
  mr = 8,
  ml = 8,
  mt = 0,
  mb = 0,
  width = 45,
  height = 45,
  style,
  iconStyle = null,
  labelStyle = {},
  label = null,
  borderColor = palette.light_grey,
  faProIcon = null,
  secondaryColor = null,
  secondaryOpacity = 1,
  borderRadius,
}) {
  return (
    <TouchableOpacity
      activeOpacity={disabled ? 1 : 0.5}
      onPress={!disabled ? onPress : null}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <View
        style={[
          styles.solid_button__container,
          border && { borderWidth: 1, borderColor },
          round && { borderRadius: 45 },
          disabled && { opacity: 0.5 },
          { backgroundColor: color },
          { width, height },
          { marginBottom: mb, marginTop: mt, marginLeft: ml, marginRight: mr },
          borderRadius && { borderRadius: borderRadius },
          style,
        ]}
      >
        {ionic && (
          <IonicIcons
            name={iconName}
            size={iconSize}
            color={
              !!iconColor
                ? iconColor
                : color && color !== palette.white
                ? palette.white
                : palette.black
            }
            style={iconStyle}
          />
        )}

        {fa && (
          <FAIcon
            name={iconName}
            size={iconSize}
            color={
              !!iconColor
                ? iconColor
                : color && color !== palette.white
                ? palette.white
                : palette.black
            }
            style={iconStyle}
          />
        )}

        {fa5 && (
          <FA5Icon
            name={iconName}
            size={iconSize}
            color={
              !!iconColor
                ? iconColor
                : color && color !== palette.white
                ? palette.white
                : palette.black
            }
            style={iconStyle}
          />
        )}

        {material && (
          <MaterialCommunityIcon
            name={iconName}
            size={iconSize}
            color={
              !!iconColor
                ? iconColor
                : color && color !== palette.white
                ? palette.white
                : palette.black
            }
            style={iconStyle}
          />
        )}

        {faPro && (
          <FontAwesomeIcon
            icon={faProIcon}
            size={iconSize}
            secondaryColor={secondaryColor}
            secondaryOpacity={secondaryOpacity}
            style={[
              { ...iconStyle },
              {
                color: !!iconColor
                  ? iconColor
                  : color && color !== palette.white
                  ? palette.white
                  : palette.black,
              },
            ]}
          />
        )}

        {!fa && !fa5 && !ionic && !material && !faPro && (
          <Icon
            name={iconName}
            size={iconSize}
            color={
              !!iconColor
                ? iconColor
                : color && color !== palette.white
                ? palette.white
                : palette.black
            }
            style={iconStyle}
          />
        )}

        {label && (
          <Text
            style={[
              color && {
                color:
                  color && color !== palette.white ? palette.white : iconColor,
              },
              { marginLeft: 8, textAlign: "center", fontWeight: "600" },
              labelStyle,
            ]}
          >
            {label}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  solid_button__container: {
    borderRadius: 8,
    backgroundColor: palette.lighter_grey,
    height: 45,
    width: 45,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    marginRight: 8,
    flexDirection: "row",
    // cursor: "pointer",
  },
});
