import React, { useContext } from 'react'
import { View } from 'react-native'

export default function Screen({children}) {
  return (
    <View style={{flex:1}}>
      {children}
    </View>
  )
}
