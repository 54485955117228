import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  createContext,
} from "react";

import Constants from "expo-constants";
import * as Device from "expo-device";

import { persistDeviceAsync, loadDeviceAsync } from "~/helpers/cache.native";
import { registerForPushNotificationsAsync } from "~/helpers/notifications";
import Engage from "~/services/engage";
import UserContext from "./UserContext";

const DEFAULT_DEVICE = {
  uuid: Constants.installationId,
  expo_version: Constants.expoVersion,
  app_version: Constants.manifest?.version,
  platform: Device.osName,
  model_type: Device.modelName,
  os_version: Device.osVersion,
};

const DeviceContext = createContext({});

export const DeviceProvider = ({ children }) => {
  const [ctx, setContext] = useState({ ...DEFAULT_DEVICE });

  const { loggedIn } = useContext(UserContext);
  const [ready, setReady] = useState(false);

  const setDeviceContext = useCallback(
    async (context) => {
      const updatedCtx = { ...ctx, ...context };
      await persistDeviceAsync(updatedCtx);
      setContext(updatedCtx);
      console.log("[DeviceContext:device]", updatedCtx);
    },
    [ctx, setContext]
  );

  useEffect(() => {
    (async () => {
      console.log("[DeviceContext:loggedIn]", loggedIn);

      if (loggedIn) {
        // Load previous stored app context
        // const device = await loadDeviceAsync();

        const push_token = await registerForPushNotificationsAsync();
        const notifications_enabled = !!push_token;
        const updatedDeviceCtx = {
          // ...device,
          ...DEFAULT_DEVICE,
          push_token,
          notifications_enabled,
          // address_book_enabled,
        };

        await Engage.addDevice(updatedDeviceCtx);
        await setDeviceContext(updatedDeviceCtx);
        setReady(true);
      }
    })();
  }, [loggedIn]);

  return (
    <DeviceContext.Provider
      value={{
        device: ctx,
        deviceReady: ready,
        setDeviceContext,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceContext;
