import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import usePusherSub from "~/hooks/usePusherSub";
import UserContext from "~/contexts/UserContext";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import { WebAppPostMessage } from "../models/WebAppPostMessage";

export const BadgeContext = createContext({});

export const BadgeProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const [badges, setBadges] = useState({
    calls: 0,
    voicemails: 0,
    messages: 0,
    ...user?.badges,
  });

  const onBadgeEvent = useCallback(
    (badgePayload) => setBadges(badgePayload),
    [setBadges],
  );

  usePusherSub({
    channel: `user-${user?.id}`,
    event: "badge-updated",
    handler: onBadgeEvent,
  });

  useEffect(() => {
    const setBadgeCountAsync = async () => {
      const originalBadgeCount = await Notifications.getBadgeCountAsync();
      await Notifications.setBadgeCountAsync(0);
      const badges = user?.badges || {};
      if (badges) {
        let badgeCount = 0;
        Object.keys(badges).map((badge) => (badgeCount += badges[badge]));
        console.log("badges", badges, badgeCount);
        if (originalBadgeCount !== badgeCount) {
          await Notifications.setBadgeCountAsync(badgeCount);
        }
      }
    };
    if (Platform.OS !== "web") setBadgeCountAsync();
    else {
      const webappPostMessageEvent =
        WebAppPostMessage.createEngageNotificationEvent(badges);
      webappPostMessageEvent.emitEvent();
    }
  }, [JSON.stringify(badges)]);

  return (
    <BadgeContext.Provider
      value={{
        ...badges,
        badges,
      }}
    >
      {children}
    </BadgeContext.Provider>
  );
};
