import dayjs from "dayjs";
import { createElement, useEffect, useRef } from "react";
import palette from "../../styles/palette";

const DatePicker = ({ value = new Date(), onChange, min, ...props }) => {
  return createElement("input", {
    type: "date",
    value: dayjs(value).format("YYYY-MM-DD"),
    onChange: (e) => onChange(e?.target?.value),
    style: {
      height: 30,
      padding: 5,
      border: `1px solid ${palette.light_grey}`,
      borderRadius: 4,
      width: 250,
    },
    max: dayjs().format("YYYY-MM-DD"),
    min: min,
    ...props,
  });
};

export default DatePicker;
