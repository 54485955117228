import { View, StyleSheet, Modal, ActivityIndicator } from "react-native-web";
import palette from "../../styles/palette";
import "reactflow/dist/style.css";
import PhoneTree from "../../components/PhoneTree/index.web";
import {
  PhoneTreeContext,
  PhoneTreeProvider,
} from "../../contexts/PhoneTreeContext";
import { ReactFlowProvider } from "reactflow";
import { useContext, useEffect } from "react";
import PhoneTreeModal from "../../modals/PhoneTreeModal/index.web";
import IconButton from "../../components/Buttons/IconButton";
import useToggle from "../../hooks/useToggle";
import { useHistory } from "react-router-dom";
import NumberContext from "../../contexts/NumberContext";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

const styles = StyleSheet.create({
  phone_tree_container: {
    backgroundColor: palette.white,
    height: "100%",
    width: "100%",
    zIndex: 10000,
    position: "absolute",
    top: 0,
    left: 0,
  },
  button_container: {
    backgroundColor: "transparent",
    width: 30,
    height: 30,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 25000,
  },
});

export default function PhoneTreeScreen() {
  const {
    loadingPhoneTree,
    getPhoneTree,
    altered: isDataChanged,
  } = useContext(PhoneTreeContext);
  const { loading: loadingNumber } = useContext(NumberContext);
  const [showPopup, setShowPopup] = useToggle();
  const history = useHistory();

  useEffect(() => {
    getPhoneTree();
  }, []);

  return (
    <ReactFlowProvider>
      {loadingPhoneTree || loadingNumber ? (
        <View
          style={[
            styles.phone_tree_container,
            { justifyContent: "center", alignItems: "center" },
          ]}
        >
          <ActivityIndicator />
        </View>
      ) : (
        <View style={[styles.phone_tree_container]}>
          <View style={styles.button_container}>
            <IconButton
              faPro
              faProIcon={faXmark}
              round={true}
              iconSize={24}
              iconColor={palette.grey}
              style={{ borderRadius: "50%", backgroundColor: palette.white }}
              width={30}
              height={30}
              onPress={() =>
                isDataChanged ? setShowPopup() : history.goBack()
              }
            />
          </View>
          <PhoneTreeModal />
          <PhoneTree showPopup={showPopup} setShowPopup={setShowPopup} />
        </View>
      )}
    </ReactFlowProvider>
  );
}
