import { useContext } from "react";
import { MediaContext } from "../contexts/MediaContext";

export default function useMedia() {
  const { assets, setAssets } = useContext(MediaContext);

  return {
    assets,
    setAssets,
  };
}
