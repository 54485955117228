export const MEDIA_TYPES = {
  image: "image/jpeg, image/png, image/heic",
  audio: "audio/mpeg",
};

export const GREETING_TYPES = {
  AUDIO: "audio-recording",
  TEXT: "text-to-speech",
  FORWARD_TO: "forward-to",
  DEFAULT: "default",
};

export const WORKING_HOURS_GREETING_TYPES = {
  TEXT: GREETING_TYPES.TEXT,
  AUDIO: GREETING_TYPES.AUDIO,
  FORWARD_TO: GREETING_TYPES.FORWARD_TO,
};
