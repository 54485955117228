import { useCallback, useEffect, useState, useRef } from "react";
import Engage from "../services/engage";
import { SEARCH_LIMIT } from "../constants/search";

export default function useConversationsearch({
  search = "",
  page = 1,
  limit = 50,
  ...params
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);

  let timeout = useRef();
  let term = useRef();

  const searchConversations = useCallback(async () => {
    setConversations([]);
    setLoading(true);
    const res = await Engage.getConversations({
      search,
      limit,
      page,
      ...params,
    });
    if (res.error) {
      setError(res.message);
    } else {
      setConversations(res.response || []);
    }
    setLoading(false);
  }, [loading, search, setError, setConversations, setLoading]);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    if (search !== term?.current?.search) {
      timeout.current = setTimeout(() => {
        term.current = {
          search,
        };
        if (search.length >= SEARCH_LIMIT) searchConversations(search);
      }, 300);
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [search]);

  return {
    conversations,
    error,
    loading,
    search,
    searchConversations,
  };
}
