import React, { useState, useContext, useCallback, useEffect } from "react";

import {
  Dimensions,
  ActivityIndicator,
  View,
  Text,
  ScrollView,
  TouchableWithoutFeedback,
  FlatList,
} from "react-native";

import Icon from "react-native-vector-icons/Feather";
import palette from "~/styles/palette";
import Avatar from "~/components/Avatar/index";
import AnimatedLogo from "~/components/Logo/AnimatedLogo";

import Col from "~/layouts/Col";
import Row from "~/layouts/Row";

import Heading from "~/components/Heading/";
import TeamContext from "~/contexts/TeamContext";
import NumberContext from "~/contexts/NumberContext";
import SolidButton from "~/components/Buttons/SolidButton/";

import Engage from "~/services/engage";
import Modal from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

export default function AssignmentModal({ onBackPress }) {
  const { saving, loading, number, dirty, error, getNumber } =
    useContext(NumberContext);

  const onAssignmentHandler = useCallback(
    (user) => async () => {
      let copy = number.assignees.concat();
      // const removed = copy.filter((a) => user.id !== a.user_id);

      if (!copy.find((u) => u.id === user.id)) {
        copy = copy.concat({
          user,
          user_id: user.id,
        });

        await Engage.assignNumber({ id: number.id, user_id: user.id });
      } else {
        copy = copy.filter((a) => user.id !== a.user_id);

        await Engage.revokeNumberAccess({
          id: number?.id,
          user_id: user?.id,
        });
      }
      await getNumber();
    },
    [number, number?.id],
  );

  return (
    <Modal title="Assign Number">
      <Col>
        <Row pt={8} left>
          <TeamList assigned={number.assignees} onPress={onAssignmentHandler} />
        </Row>
      </Col>
    </Modal>
  );
}

const TeamList = ({ assigned, onPress }) => {
  const { refresh, loading } = useContext(TeamContext);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    let t;
    t = setTimeout(() => {
      if (!loading) {
        setLoadingPage(false);
      }
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [loading]);

  if (loadingPage)
    return (
      <View
        style={{
          flex: 1,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color={palette.primary} />
      </View>
    );

  return (
    <FlatList
      ListHeaderComponent={
        <AssignedUsers assigned={assigned} onPress={onPress} />
      }
      ListFooterComponent={<OtherUsers assigned={assigned} onPress={onPress} />}
    />
  );
};

export const RenderUserList = ({
  data,
  active,
  canLoadMore,
  onLoadMore,
  onPress,
  loadingMoreUsers,
}) => {
  return (
    <FlatList
      data={data}
      renderItem={({ item }) => {
        const isActive = active?.find((active) => active.id == item.id);
        return (
          <View>
            <UserItem user={item} active={isActive} onPress={onPress(item)} />
          </View>
        );
      }}
      keyExtractor={(item) => item?.id}
      ListEmptyComponent={
        <Text style={{ textAlign: "center" }}>No users found</Text>
      }
      ListFooterComponent={
        canLoadMore
          ? () => (
              <SolidButton
                label="Load More"
                loading={loadingMoreUsers}
                onPress={onLoadMore}
                style={{ borderWidth: 0 }}
                color={palette.white}
              />
            )
          : null
      }
    />
  );
};

const AssignedUsers = ({ assigned, onPress }) => {
  return (
    <Row>
      <Col>
        <Row>
          <TeamLabel title="Assigned" />
        </Row>
        <RenderUserList
          data={assigned}
          active={assigned}
          canLoadMore={false}
          onLoadMore={null}
          onPress={onPress}
          loadingMoreUsers={false}
        />
      </Col>
    </Row>
  );
};

const OtherUsers = ({ assigned, onPress }) => {
  const { users, loadMoreUsers, loadingMoreUsers, disabled } =
    useContext(TeamContext);

  return (
    <Row>
      <Col>
        <Row>
          <TeamLabel title="Other Users" />
        </Row>
        <RenderUserList
          data={users?.filter((u) => !assigned?.find((a) => a?.id === u?.id))}
          active={assigned}
          canLoadMore={!disabled}
          onLoadMore={loadMoreUsers}
          onPress={onPress}
          loadingMoreUsers={loadingMoreUsers}
        />
      </Col>
    </Row>
  );
};

export const TeamLabel = ({ title }) => {
  return (
    <View
      style={{
        paddingHorizontal: 15,
        paddingVertical: 10,
        backgroundColor: palette.lightest_grey,
        width: "100%",
      }}
    >
      <Text style={{ fontWeight: "500" }}>{title}</Text>
    </View>
  );
};

const UserItem = ({ user, active = false, onPress }) => {
  const { first_name, last_name, name } = user;
  const [loading, setLoading] = useState(false);
  const [isActive, setActive] = useState(active);

  const onHandlePress = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    await onPress();
    setLoading(false);
    setActive((active) => !active);
  }, [loading, onPress]);

  return (
    <TouchableWithoutFeedback onPress={onHandlePress}>
      <View
        style={{
          width: "100%",
          paddingHorizontal: 10,
          borderBottomWidth: 0.5,
          cursor: "pointer",
          borderBottomColor: palette.light_grey,
        }}
      >
        <Row style={{ height: 60 }}>
          <Col leftCenter maxWidth={30}>
            {loading && <ActivityIndicator size="small" />}
            {isActive && !loading && (
              <FontAwesomeIcon icon={faCheck} size={16} color={palette.dark} />
            )}
          </Col>
          <Col leftCenter maxWidth={50}>
            <Avatar
              first_name={first_name}
              last_name={last_name}
              size={35}
              color={palette.success}
            />
          </Col>
          <Col leftCenter>
            <Text
              style={{
                fontSize: 16,
                color: palette.dark,
                fontWeight: "500",
              }}
            >
              {first_name ? `${first_name} ${last_name}` : name}
            </Text>
          </Col>
        </Row>
      </View>
    </TouchableWithoutFeedback>
  );
};
