import { Platform } from "react-native";
import { TWILIO_VOICE_OPTIONS } from "../constants/twilio";
import { Model } from "./base";
import { Device } from "@twilio/voice-sdk";
import Engage from "../services/engage";

export class Twilio extends Model {
  static identity = null;
  static numberId = null;
  static token = null;
  static iceServers = [];
  static platform = Platform.OS;
  device = null;

  constructor({ identity, numberId, ...data }) {
    super(data);
    this.identity = identity;
    this.numberId = numberId;
  }

  async fetchToken() {
    try {
      if (!this.identity || !this.numberId) {
        return;
      }
      const res = await Engage.getTwilioAccessToken(
        this.identity,
        this.numberId,
        this.platform,
      );

      if (!res.error) {
        const { response } = res || {};
        this.token = response?.token;
        this.iceServers = response?.ice_servers || [];
      }
      return res;
    } catch {
      return { error: true };
    }
  }

  static createNewDevice(token) {
    if (!this.token && !token) return null;
    if (token) this.token = token;

    this.device = new Device(this.token || token, TWILIO_VOICE_OPTIONS);
    return this.device;
  }

  static startListeningDeviceEvents({
    onIncoming,
    onError,
    getAccessToken,
    onUnregister,
    device: currentDevice,
  }) {
    const device = currentDevice || this.createNewDevice();
    device.addListener("registering", (e) => {
      console.log("[TWILIO:Registering]", e);
    });

    device.addListener("registered", (e) => {
      console.log("[TWILIO:RegistrationSuccess]", e);
    });

    device.addListener("unregistered", (e) => {
      console.log("[TWILIO:Unregistered]", e);
      onUnregister();
    });

    device.addListener("incoming", (call) => {
      console.log("[TWILIO:CallInvite]", call);
      onIncoming(call);
    });

    device.on("error", (e, callInstance) => {
      console.error("[useTwilioInit:Error]", e, callInstance);
      onError(callInstance);
    });

    device.on("tokenWillExpire", async () => {
      const token = await getAccessToken();
      device.updateToken(token);
    });

    device.register();
  }

  static async startCall(
    params,
    iceServers,
    currentDevice,
    onMute,
    onAccept,
    onReject,
    onCancel,
    onError,
    onDisconnect,
  ) {
    if (!currentDevice && !this.device) return;
    const device = currentDevice || this.device;
    const call = await device.connect({
      params: params,
      rtcConfiguration: {
        iceServers: iceServers || this.iceServers,
      },
    });
    this.addCallListeners(
      call,
      onMute,
      onAccept,
      onReject,
      onCancel,
      onError,
      onDisconnect,
    );
    return call;
  }

  static addCallListeners(
    call,
    onMute,
    onAccept,
    onReject,
    onCancel,
    onError,
    onDisconnect,
  ) {
    if (!call) return;
    call.on("mute", onMute);
    call.on("accept", onAccept);
    call.on("reject", onReject);
    call.on("cancel", onCancel);
    call.on("error", onError);
    call.on("disconnect", onDisconnect);
  }

  static async acceptIncomingCall(call) {}

  static destroyDevice() {
    if (!this.device) {
      console.log("[TWILIO:DestroyDevice]", "no device to destroy");
      return;
    }
    this.device.removeAllListeners();
    console.log("[TWILIO:DestroyDevice]", "removed all device listeners");
    this.device.destroy();
    console.log("[TWILIO:DestroyDevice]", "destroyed device");
    this.device = null;
  }
}
