export const DEFAULT_OPTION = {
  label: "",
  value: "",
  disabled: false,
  filtered: false,
  filter: () => {
    return true;
  },
  data: {},
};
