import AsyncStorage from "@react-native-async-storage/async-storage";
import { Asset } from "expo-asset";
import * as FileSystem from "expo-file-system";
import { Image } from "react-native";

// Caches a local resource into application memory using a file path
export const cacheLocalResourceAsync = async (path) => {
  if (path) {
    return Asset.fromModule(path).downloadAsync();
  }
};

// Caches an image into application memory using a remote url
export const cacheRemoteImageAsync = async (path) => {
  if (typeof path === "string") {
    return Image.prefetch(path);
  }
};

export const downloadFileAsync = async (remoteUri, localUri, options) => {
  const lastSlashIndex = localUri.lastIndexOf("/");
  const dir = localUri.slice(0, lastSlashIndex);
  const dirInfo = await FileSystem.getInfoAsync(dir);
  if (!dirInfo.exists) {
    await FileSystem.makeDirectoryAsync(dir, { intermediates: true });
  }
  return await FileSystem.downloadAsync(remoteUri, localUri, options);
};

export const downloadFileToCacheAsync = async (remoteUri, options) => {
  const dir = FileSystem.cacheDirectory + "files/";
  const fileName = remoteUri.slice(
    remoteUri.lastIndexOf("/") + 1,
    remoteUri.length,
  );
  const localUri = dir + fileName;
  await downloadFileAsync(remoteUri, localUri, options);
  return localUri;
};

export const setAsync = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    // saving error
    console.error(e);
  }
};

export const getAsync = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      // value previously stored
      return value;
    }
  } catch (e) {
    // error reading value
    console.error(e);
  }
};

export const removeAsync = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (e) {
    // remove error
    console.error(e);
  }
  console.log(`Cleared ${key}.`);
};

export const resetAsync = async (key) => {
  try {
    await AsyncStorage.clear();
  } catch (e) {
    // remove error
    console.error(e);
  }
  console.log("Reset Storage.");
};

export const storeDataAsync = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    // saving error
    console.error(e);
  }
};

export const getDataAsync = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
    console.error(e);
  }
  return null;
};
