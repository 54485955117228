export const Strings = {
  CALL_FORWARDING_LABEL: "Call Forwarding",
  PHONE_TREE_LABEL: "Phone Tree",
  SEQUENTIAL_CALL_LABEL: "Sequential Call Ring",
  SEQUENTIAL_CALL_MISCONFIGURED_NUMBER:
    "This phone line is not available for Sequential Call Ring, please remove this.",
  A2P_10DLC_SUBMISSION_MESSAGE: `We'll verify your new number for Engage within two weeks. After approval, you can start sending messages.\nCheck here or contact support for updates.`,
  FLICENT_CAMPAIGN_WARNING_MESSAGE:
    "To unlock the full potential of Engage and gain access to all features, please resubmit your 10DLC campaign with your business details. Your initial submission included FieldPulse business details, which needs to be updated.",
  RESUBMIT_CAMPAIGN_LABEL: "Resubmit Campaign",
  FLICENT_CAMPAIGN_CALLER_ID_MESSAGE:
    "Invalid 10 DLC information. Please update before gaining access to the Caller ID feature. ",
  CALLER_ID_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9390426-caller-id",
  WORKING_HOURS_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077040-engage-working-hours",
  CALL_ROUTING_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077045-call-routing",
  VOICEMAIL_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077046-engage-voicemails",
  A2P_10DLC_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9077053-10dlc-information",
  REPORTING_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/9829548-engage-reporting",
  BLOCKED_NUMBERS_DOCS_URL:
    "https://help.fieldpulse.com/en/articles/10098709-blocking-numbers-in-engage",
  COMMUNICATIONS: {
    CALL: "CALL",
    MESSAGE: "MESSAGE",
  },
  CALL_REPORTING_CATEGORY_NO_AUDIO_INCOMING:
    "No Audio (I could not hear the customer)",
  CALL_REPORTING_CATEGORY_NO_AUDIO_OUTGOING:
    "No Audio (Customer could not hear me)",
  CALL_REPORTING_CATEGORY_CALL_DROPPED: "Call Dropped",
  CALL_REPORTING_CATEGORY_ECHO_ON_LINE: "Echo on Line",
  CALL_REPORTING_CATEGORY_DELAYED_AUDIO: "Delayed Audio",
  CALL_REPORTING_CATEGORY_POOR_AUDIO_QUALITY: "Poor Audio Quality",
  CALL_REPORTING_CATEGORY_MIS_ROUTED: "Misrouted Calls",
  CALL_REPORTING_CATEGORY_INTERRUPTIONS: "Frequent Interruptions",
  CALL_REPORTING_CATEGORY_LOW_VOLUME: "Volume Too Low",
  CALL_REPORTING_CATEGORY_OTHER_ISSUE: "Other Issue",
  CALL_REPORTING_ISSUE_FACED_MESSAGE:
    "Sorry you're experiencing issues with the call.",
  CALL_REPORTING_ISSUE_FACED_MESSAGE_SUB_TEXT:
    "Thank you for bringing this to our attention. We're sorry to hear about the issues you're experiencing with the call. Our team will investigate the reported problem. If we identify a defect, we will take steps to resolve it. Should we need more information to proceed, we'll reach out to you directly. We appreciate your patience as we work to improve your experience.",
  CALL_REPORTING_ISSUE_NOT_FACED_MESSAGE: "Thank you for your feedback!",
  CALL_REPORTING_ISSUE_NOT_FACED_MESSAGE_SUB_TEXT:
    "We're glad to hear that the call quality was great. If you have any more feedback or need further assistance, feel free to let us know!",
  CALL_REPORTING_GENERAL_MESSAGE:
    "Enjoying the call experience? Let us know how the quality was - your feedback makes a difference!",
  CALL_REPORTING_HEADING: "We Value Your Feedback!",
  CALL_REPORTING_DESCRIPTION_REQUIRED:
    "Please provide a description of your issue. We'll use this to help our team investigate.",
  CALL_REPORTING_CATEGORY_REQUIRED: "Please select a category for your issue.",
  FIELDPULSE_SUPPORT_EMAIL: "support@fieldpulse.com",
  SIGNPOST_IFRAME_URL: "https://app.signpost.com/iframe-login",
  BLOCKED_BANNER_MESSAGE:
    "This phone number has been blocked. You are unable to send any outgoing SMS or make any outgoing calls.",
  BLOCKED_BANNER_UNBLOCK_MESSAGE: "Click here to unblock.",
  ADD_BLOCKED_NUMBER_SUBTEXT:
    "Activating number blocking will prevent any further incoming and outgoing communications, including calls and messages, from this number.",
  ADD_BLOCK_NUMBER_HEADING: "Add Number to Your Block List",
  BLOCK_NUMBER_SUCCESS: "Contact Successfully Blocked",
  BLOCK_NUMBER_ERROR: "Contact could not be blocked",
  UNBLOCK_NUMBER_SUCCESS: "Contact Successfully Unblocked",
  UNBLOCK_NUMBER_ERROR: "Contact could not be Unblocked",
  BLOCK_CONTACTS_SEARCH_PLACEHOLDER:
    "Search for a customer or contact to block, or type an unknown number",
  PLAY_RECORDING_MESSAGE_DISABLE_WARNING: {
    MESSAGE:
      "Please be aware that you are choosing not to notify your customers that their calls are being recorded.\nMany U.S. states legally require that all parties be informed when a call is being recorded.\nAre you sure you want to proceed without notification?",
    CANCEL_BTN: "No, I'll Notify",
    PROCEED_BTN: "Yes, Proceed Without Notifying",
  },
  BLOCK_NUMBER_CONFIRM_HEADING: "Confirm Number Block",
  UNBLOCK_NUMBER_CONFIRM_HEADING: "Confirm Number Unblock",
};
