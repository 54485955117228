import { createContext, useState } from "react";
import useToggle from "../hooks/useToggle";

const SettingsContext = createContext({});

export function SettingsProvider({ children }) {
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [alertVisible, toggleAlertPopup] = useToggle(false);
  const [location, setLocation] = useState("");

  return (
    <SettingsContext.Provider
      value={{
        isDataChanged,
        setIsDataChanged,
        alertVisible,
        toggleAlertPopup,
        location,
        setLocation,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsContext;
