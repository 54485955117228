import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, View, Keyboard, Text } from "react-native";
import Composer from "../helpers/Composer";
import Send from "../helpers/Send";
import Actions from "../helpers/Actions";
import Color from "../helpers/Color";
import { StylePropType } from "../helpers/utils";
import Row from "../../../layouts/Row";
import palette from "../../../styles/palette";
import { MediaConsumer } from "../../../contexts/MediaContext";

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    left: 0,
    right: 0,
  },
  primary: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  accessory: {
    height: 44,
  },
});
export default class InputToolbar extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      position: "absolute",
    };
    this.keyboardWillShowListener = undefined;
    this.keyboardWillHideListener = undefined;
    this.keyboardWillShow = () => {
      if (this.state.position !== "relative") {
        this.setState({
          position: "relative",
        });
      }
    };
    this.keyboardWillHide = () => {
      if (this.state.position !== "absolute") {
        this.setState({
          position: "absolute",
        });
      }
    };
  }
  componentDidMount() {
    this.keyboardWillShowListener = Keyboard.addListener(
      "keyboardWillShow",
      this.keyboardWillShow,
    );
    this.keyboardWillHideListener = Keyboard.addListener(
      "keyboardWillHide",
      this.keyboardWillHide,
    );
  }
  componentWillUnmount() {
    if (this.keyboardWillShowListener) {
      this.keyboardWillShowListener.remove();
    }
    if (this.keyboardWillHideListener) {
      this.keyboardWillHideListener.remove();
    }
  }
  renderActions() {
    const { containerStyle, ...props } = this.props;
    if (this.props.renderActions) {
      return this.props.renderActions(props);
    } else if (this.props.onPressActionButton) {
      return <Actions {...props} />;
    }
    return null;
  }

  renderComposer() {
    if (this.props.renderComposer) {
      return this.props.renderComposer(this.props);
    }
    return (
      <Composer
        {...this.props}
        textInputStyle={{
          ...this.props.textInputStyle,
          backgroundColor:
            this.props.activeTab === 1 ? palette.sticky_yellow : Color.white,
        }}
        placeholder={
          this.props.activeTab === 1
            ? "Enter an internal note"
            : this.props.placeholder
        }
      />
    );
  }
  renderAccessory() {
    if (this.props.renderAccessory) {
      return (
        <View style={[styles.accessory, this.props.accessoryStyle]}>
          {this.props.renderAccessory(this.props)}
        </View>
      );
    }
    return null;
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          { position: this.state.position },
          this.props.containerStyle,
        ]}
      >
        <View
          style={[
            styles.primary,
            {
              backgroundColor:
                this.props.activeTab === 1
                  ? palette.sticky_yellow
                  : Color.white,
            },
            this.props.primaryStyle,
          ]}
        >
          <MediaConsumer>
            {/* {this.renderActions()} */}
            {this.renderComposer()}
            {/* {this.renderAccessory()} */}
          </MediaConsumer>
        </View>
        <Row
          right
          pl={16}
          pr={16}
          pt={4}
          pb={4}
          style={{
            height: 20,
            backgroundColor:
              this.props.activeTab === 1 ? palette.sticky_yellow : Color.white,
          }}
        >
          {!!this.props.text && !!this.props.text.trim() && (
            <>
              <Text style={{ color: palette.grey, fontSize: 12 }}>
                Press <Text style={{ fontWeight: "600" }}>Enter</Text> to send
              </Text>
              <Text style={{ color: palette.grey, fontSize: 12 }}>
                {"  "}|{"  "}
                <Text style={{ fontWeight: "600" }}>Shift + Enter</Text> to add
                a new line
              </Text>
            </>
          )}
        </Row>
      </View>
    );
  }
}
InputToolbar.defaultProps = {
  renderAccessory: null,
  renderActions: null,
  renderSend: null,
  renderComposer: null,
  containerStyle: {},
  primaryStyle: {},
  accessoryStyle: {},
  onPressActionButton: () => {},
};
InputToolbar.propTypes = {
  renderAccessory: PropTypes.func,
  renderActions: PropTypes.func,
  renderSend: PropTypes.func,
  renderComposer: PropTypes.func,
  onPressActionButton: PropTypes.func,
  containerStyle: StylePropType,
  primaryStyle: StylePropType,
  accessoryStyle: StylePropType,
};
