import React from "react";
import { StyleSheet, Text, View } from "react-native";
import palette from "~/styles/palette";

const SIZES = {
  1: 30,
  2: 24,
  3: 22,
  4: 20,
  5: 16,
};

const WEIGHTS = {
  1: "700",
  2: "600",
  3: "600",
  4: "600",
  5: "600",
};

const FONT_FAMILY = {
  1: "OpenSans_700Bold",
  2: "OpenSans_600SemiBold",
  3: "OpenSans_600SemiBold",
  4: "OpenSans_600SemiBold",
  5: "OpenSans_600SemiBold",
};

export default function Heading({
  children,
  size = 1,
  numberOfLines = null,
  center = false,
  color = palette.black,
  ml = 0,
  mr = 0,
  mt = 5,
  mb = 5,
  pl = 0,
  pr = 0,
  pt = 0,
  pb = 0,
  style,
  textStyle,
}) {
  return (
    <View
      style={{
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        ...style,
      }}
    >
      <Text
        numberOfLines={numberOfLines}
        style={[
          styles.heading,
          {
            textAlign: center ? "center" : "left",
            fontWeight: WEIGHTS[size],
            fontSize: SIZES[size],
            fontFamily: FONT_FAMILY[size] || "OpenSans_400Regular",
            color,
          },
          textStyle,
        ]}
      >
        {children}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontSize: 30,
    fontWeight: "700",
    color: palette.black,
  },
});
