import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import FieldPulse from "~/services/fieldpulse";
import Engage from "~/services/engage";
import NumbersContext from "~/contexts/NumbersContext";
import useDraft from "~/hooks/useDraft";
import { filterCustomerWithRelations } from "../helpers/customers";
import { PHONE_NUMBER_KEYS } from "../constants/phoneNumber";

const NumberContext = createContext({
  loading: false,
  saving: false,
  error: false,
  number: null,
});

export function NumberProvider({ value = {}, children }) {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [fullNumber, setFullNumber] = useState(null);
  const [fullNumberError, setFullNumberError] = useState(null);
  const [number, setNumber] = useState(value);

  const { draft, dirty, updateDraft, resetDraft, changed } = useDraft(number);

  const { getNumbers } = useContext(NumbersContext);

  const getNumber = async () => {
    if (loading) return { response: number };
    setLoading(true);
    const res = await Engage.getNumber(number?.id);
    if (!res.error) {
      console.log("assignee res", res.response.assignees);
      setNumber(res.response);
    } else {
      setError(res.message);
    }
    setLoading(false);
    return res;
  };

  const updateNumber = async (data, save = false) => {
    updateDraft(data);
    if (save) {
      if (saving) return;
      setSaving(true);
      const payload = {
        ...draft,
        ...data,
      };
      delete payload.caller_id;
      delete payload.caller_id_status;
      const res = await Engage.updateNumber(payload);
      if (!res.error) {
        await getNumber();
        resetDraft();
      } else {
        setError(res.message);
      }
      setSaving(false);
    }
  };

  const saveNumber = useCallback(async () => {
    if (saving) return;
    setSaving(true);
    let data = { ...changed, id: draft.id };

    if (data.voicemail_greeting_type === null) {
      data.voicemail_greeting_url = null;
      data.voicemail_greeting = null;
    }

    if (
      !!data[PHONE_NUMBER_KEYS.TRANSFER_CALLS] &&
      data[PHONE_NUMBER_KEYS.TRANSFER_CALLS]?.length > 0
    ) {
      data[PHONE_NUMBER_KEYS.TRANSFER_ENABLED] = true;
    }

    const res = await Engage.updateNumber(data);
    if (!res.error) {
      await getNumber();
      resetDraft();
    } else {
      setError(res.message);
    }
    await getNumbers();
    setSaving(false);
    return res;
  }, [draft, saving, setSaving, setError]);

  const deleteNumber = useCallback(async () => {
    if (saving) return;
    setSaving(true);
    const res = await Engage.deleteNumber(number?.id);
    if (res.error) {
      setError(res.message);
    }
    setSaving(false);
    await getNumbers();
    return res;
  }, [number, saving, setSaving, setError, setNumber]);

  // useEffect(() => {
  //   if (!fullNumber && !fullNumberError && number) {
  //     Engage.getNumber(number.id)
  //       .then((res) => {
  //         if (res.response) {
  //           setFullNumber(res.response);
  //         }
  //       })
  //       .catch((err) => setFullNumberError(err.message));
  //   }
  // }, [fullNumber, number, draft]);

  // useEffect(() => {
  //   if (fullNumber && !fullNumberError) {
  //     setNumber(fullNumber);
  //     resetDraft();
  //   }
  // }, [fullNumber]);

  // useEffect(() => {
  //   setNumber(value);
  //   setFullNumber(false);
  // }, [value]);

  return (
    <NumberContext.Provider
      value={{
        number: {
          ...number,
          ...draft,
          // NOTE: Caused inconsistencies when using draft --> so manually adding this
          assignees: number?.assignees,
        },
        originalNumber: value,
        // fullNumber,
        dirty,
        loading,
        saving,
        error,
        getNumber,
        setNumber,
        updateNumber,
        saveNumber,
        deleteNumber,
        changed,
      }}
    >
      {children}
    </NumberContext.Provider>
  );
}

export default NumberContext;
