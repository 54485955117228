import { useReducer } from "react";
import { Model } from "./base";
import { callReducer, INIT_CALL_STATE } from "~/reducers/callReducer";

export class Call extends Model {
  call;
  timestamp = new Date();
  data;
  state;
  ignored = false;
  hold = false;
  active = false;
  callState = INIT_CALL_STATE;
  muted = false;
  callSid;

  constructor(data) {
    super(data);
    this.call = data?.call;
    this.timestamp = data?.timestamp || new Date();
    this.data = data?.data;
    this.state = data?.state;
    this.ignored = !!data?.ignored;
    this.hold = !!data?.hold;
    this.active = !!data?.active;
    this.callSid = data?.callSid;
    this.callState = data?.callState || INIT_CALL_STATE;
    this.muted = !!data?.muted;
  }

  putCallOnHold() {
    this.hold = true;
  }

  putCallOffHold() {
    this.hold = false;
  }

  toggleCallOnHold() {
    this.hold = !this.hold;
  }

  callSid() {
    return this.call?.parameters?.CallSid;
  }

  direction() {
    return this.call?.direction;
  }

  customParameters() {
    return this.call?.customParameters;
  }

  connectToken() {
    return this.call?.connectToken;
  }

  silent() {
    if (!this.call) return;
    thi.call?.silent();
    this.call.ignored = true;
  }

  reject() {
    if (!this.call) return;
    this.call?.reject();
  }

  endCall() {
    if (!this.call) return;
    this.call?.disconnect();
  }

  updateStatus(event) {
    this.dispatch(event);
  }

  get callStatus() {
    const callState = this.callState;
    if (callState.failure) {
      return "Call failed";
    }
    if (callState.disconnected) {
      return "Call ended";
    }
    if (callState.ringing) {
      return "Ringing";
    }
    return "";
  }

  static getCallState(state, action) {
    const { type, payload } = action;
    const getState = () => {
      switch (type) {
        case "FAIL": {
          return {
            ...state,
            failed: true,
            success: false,
          };
        }

        case "RING": {
          return {
            ...INIT_CALL_STATE,
            ringing: true,
          };
        }

        case "INVITED": {
          return {
            ...INIT_CALL_STATE,
            ringing: false,
            invited: true,
          };
        }

        case "CONNECTED": {
          return {
            ...state,
            failed: false,
            ringing: false,
            disconnected: false,
            success: true,
          };
        }

        case "CONNECT_FAILURE": {
          return {
            ...state,
            ringing: false,
            success: false,
            failed: payload === "error",
            disconnected: true,
          };
        }

        case "DISCONNECTED": {
          return {
            ...state,
            ringing: false,
            success: false,
            failed: payload === "error",
            disconnected: true,
          };
        }

        case "RESET": {
          return INIT_CALL_STATE;
        }

        default: {
          return state;
        }
      }
    };

    return getState();
  }
}
