export const timezones = [
  {
    name: "(UTC - 12:00)",
    value: "-12",
  },
  {
    name: "(UTC - 11:00)",
    value: "-11",
  },
  {
    name: "(UTC - 10:00)",
    value: "-10",
  },
  {
    name: "(UTC - 09:00)",
    value: "-9",
  },
  {
    name: "(UTC - 08:00)",
    value: "-8",
  },
  {
    name: "(UTC - 07:00)",
    value: "-7",
  },
  {
    name: "(UTC - 06:00)",
    value: "-6",
  },
  {
    name: "(UTC - 05:00)",
    value: "-5",
  },
  {
    name: "(UTC - 04:00)",
    value: "-4",
  },
  {
    name: "(UTC - 03:30)",
    value: "-3.5",
  },
  {
    name: "(UTC - 03:00)",
    value: "-3",
  },
  {
    name: "(UTC - 02:00)",
    value: "-2",
  },
  {
    name: "(UTC - 01:00)",
    value: "-1",
  },
  {
    name: "(UTC + 00:00)",
    value: "0",
  },
  {
    name: "(UTC + 01:00)",
    value: "1",
  },
  {
    name: "(UTC + 02:00)",
    value: "2",
  },
  {
    name: "(UTC + 03:00)",
    value: "3",
  },
  {
    name: "(UTC + 03:30)",
    value: "3.5",
  },
  {
    name: "(UTC + 04:00)",
    value: "4",
  },
  {
    name: "(UTC + 04:30)",
    value: "4.5",
  },
  {
    name: "(UTC + 05:00)",
    value: "5",
  },
  {
    name: "(UTC + 05:30)",
    value: "5.5",
  },
  {
    name: "(UTC + 06:00)",
    value: "6",
  },
  {
    name: "(UTC + 06:30)",
    value: "6.5",
  },
  {
    name: "(UTC + 07:00)",
    value: "7",
  },
  {
    name: "(UTC + 08:00)",
    value: "8",
  },
  {
    name: "(UTC + 09:00)",
    value: "9",
  },
  {
    name: "(UTC + 09:30)",
    value: "9.5",
  },
  {
    name: "(UTC + 10:00)",
    value: "10",
  },
  {
    name: "(UTC + 11:00)",
    value: "11",
  },
  {
    name: "(UTC + 12:00)",
    value: "12",
  },
  {
    name: "(UTC + 13:00)",
    value: "13",
  },
];

export const standardTimeZones = {
  "(UTC - 10:00)": "Hawaii-Aleutian Standard Time ",
  "(UTC - 09:00)": "Alaska Standard Time",
  "(UTC - 08:00)": "Pacific Standard Time",
  "(UTC - 07:00)": "Mountain Standard Time",
  "(UTC - 06:00)": "Central Standard Time",
  "(UTC - 05:00)": "Eastern Standard Time",
  "(UTC - 04:00)": "Atlantic Standard Time",
};

export const daylightTimeZones = {
  "(UTC - 09:00)": "Hawaii-Aleutian Daylight Time",
  "(UTC - 08:00)": "Alaska Daylight Time",
  "(UTC - 07:00)": "Pacific Daylight Time",
  "(UTC - 06:00)": "Mountain Daylight Time",
  "(UTC - 05:00)": "Central Daylight Time",
  "(UTC - 04:00)": "Eastern Daylight Time",
  "(UTC - 03:00)": "Atlantic Daylight Time",
};
