import React, { createContext, useState } from "react";
import { MEDIA_TYPES } from "../constants/mediaTypes";

export const MediaContext = createContext({
  assets: [],
  setAssets: () => {},
});

export function MediaProvider({ children }) {
  const [assets, setAssets] = useState([]);
  const [mediaUploadVisible, setMediaUploadVisible] = useState(false);
  const [audioUploadVisible, setAudioUploadVisible] = useState(false);
  const [audioRecordVisible, setAudioRecordVisible] = useState(false);
  const [activeAudio, setActiveAudio] = useState(null);

  return (
    <MediaContext.Provider
      value={{
        assets,
        setAssets,
        mediaUploadVisible,
        setMediaUploadVisible,
        audioUploadVisible,
        setAudioUploadVisible,
        activeAudio,
        setActiveAudio,
        audioRecordVisible,
        setAudioRecordVisible,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
}

export function MediaConsumer({ children }) {
  return (
    <MediaContext.Consumer>
      {(value) =>
        React.Children.map(children, (child) =>
          React.cloneElement(child, value),
        )
      }
    </MediaContext.Consumer>
  );
}
