export const INIT_CALL_STATE = {
  failed: false,
  ringing: false,
  invited: false,
  disconnected: false,
  success: false,
};

export const callReducer = (state = INIT_CALL_STATE, action) => {
  const { type, payload } = action;

  // console.log("reducer", type, state, action);
  // NOTE: we don't spread init state every time because we want to determine if
  // we were inbound or outbound
  switch (type) {
    case "FAIL": {
      return {
        ...state,
        failed: true,
        success: false,
      };
    }

    case "RING": {
      return {
        ...INIT_CALL_STATE,
        ringing: true,
      };
    }

    case "INVITED": {
      return {
        ...INIT_CALL_STATE,
        ringing: false,
        invited: true,
      };
    }

    case "CONNECTED": {
      return {
        ...state,
        failed: false,
        ringing: false,
        disconnected: false,
        success: true,
      };
    }

    case "CONNECT_FAILURE": {
      return {
        ...state,
        ringing: false,
        success: false,
        failed: payload === "error",
        disconnected: true,
      };
    }

    case "DISCONNECTED": {
      return {
        ...state,
        ringing: false,
        success: false,
        failed: payload === "error",
        disconnected: true,
      };
    }

    case "RESET": {
      return INIT_CALL_STATE;
    }

    default: {
      return state;
    }
  }
};