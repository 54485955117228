import { ActivityIndicator, ScrollView, StyleSheet, View } from "react-native";
import Heading from "../../components/Heading/index.web";
import Col from "../../layouts/Col";
import Row from "../../layouts/Row";
import palette from "../../styles/palette";
import { Switch } from "react-native-web";
import UserContext from "../../contexts/UserContext";
import { useCallback, useContext, useState } from "react";
import IconButton from "../../components/Buttons/IconButton";
import { faVolume } from "@fortawesome/pro-solid-svg-icons";
import { playMessageTone } from "../../helpers/playMessageTone";

export default function NotificationSettingsScreen() {
  const { updateSettings, isMessageToneEnabled, getUser } =
    useContext(UserContext);

  const [saving, setSaving] = useState(false);

  const toggleMessageTone = useCallback(
    async (v) => {
      setSaving(true);
      await updateSettings({
        incoming_message_tone: v,
      });
      await getUser();
      setSaving(false);
    },
    [getUser, updateSettings],
  );

  return (
    <Col>
      <Row pl={16} pr={16} style={[styles.title]}>
        <Col leftCenter>
          <Heading size={5} pb={8} mb={0}>
            Notifications Settings
          </Heading>
        </Col>
        <Col rightCenter></Col>
      </Row>
      <ScrollView style={{ flex: 1 }}>
        <Row pl={16} pr={16} pt={16} pb={16} style={[styles.row]}>
          <View style={{ marginRight: 12 }}>
            <Heading size={5}>Incoming Message Tone</Heading>
          </View>
          <View style={[styles.switchContainer]}>
            {saving ? (
              <ActivityIndicator />
            ) : (
              <Switch
                value={isMessageToneEnabled}
                onValueChange={toggleMessageTone}
              />
            )}
          </View>
          <IconButton
            faPro
            faProIcon={faVolume}
            iconSize={18}
            onPress={() => {
              playMessageTone(require("../../assets/sounds/new-message.mp3"));
            }}
            round
            border
            mt={0}
            mb={0}
            width={36}
            height={36}
            iconColor={palette.dark_grey}
          />
        </Row>
      </ScrollView>
    </Col>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "70%",
  },
  row: {
    justifyContent: "flex-start",
    gap: 12,
  },
  switchContainer: {
    paddingTop: 8,
  },
  title: {
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: palette.light_grey,
  },
});
