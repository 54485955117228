import { TWILIO_CALL_INTERACTION } from "../constants/conversations";
import { CALL_REPORTING_CATEGORY_OTHER_ISSUE } from "../constants/issueReport";
import { Model } from "./base";

export class IssueReport extends Model {
  rating = 0;
  issuable_type = TWILIO_CALL_INTERACTION;
  issuable_id = null;
  description = "";
  categories = [];
  device_push_token = null;

  constructor(data) {
    super(data);
    if (data?.rating) this.rating = data.rating;
    if (data?.issuable_type) this.issuable_type = data.issuable_type;
    if (data?.issuable_id) this.issuable_id = data.issuable_id;
    if (data?.description) this.description = data.description;
    if (data?.categories) this.categories = data.categories;
  }

  get isFiveStarRating() {
    return this.rating === 5;
  }

  get isLessThanFiveStarRating() {
    return this.rating < 5;
  }

  get isLessThanFiveStarRatingButNotZero() {
    return this.rating < 5 && this.rating !== 0;
  }

  get isOtherIssue() {
    return this.categories.includes(CALL_REPORTING_CATEGORY_OTHER_ISSUE);
  }

  get isValidReport() {
    if (!this.issuable_id) return false;

    if (this.isLessThanFiveStarRating) {
      if (!this.categories || this.categories.length === 0) return false;
      if (this.isOtherIssue) {
        if (!this.description || !this.description.trim()) return false;
      }
    }
    return true;
  }

  static create(id) {
    return new IssueReport({ issuable_id: id });
  }

  addDeviceToken(token) {
    this.device_push_token = token;
  }
}
