import { useContext, useState } from "react";
import TwilioContext from "../../contexts/TwilioContext/index.web";
import NumbersContext from "../../contexts/NumbersContext";
import { FlatList, Pressable, StyleSheet, View } from "react-native";
import palette from "../../styles/palette";
import Row from "../../layouts/Row";
import Heading from "../../components/Heading/index.web";

export default function PhoneNumberSelectModal() {
  const { callQueue, setCallQueue, startCall } = useContext(TwilioContext);
  const { managedNumbers } = useContext(NumbersContext);

  if (!callQueue || !managedNumbers?.length) return null;

  if (callQueue && managedNumbers?.length === 1) {
    startCall(
      callQueue.to,
      callQueue.metaData,
      managedNumbers[0]?.phone_number,
    );
    setCallQueue();
  }

  const renderPhoneLineItem = ({ item, index }) => {
    return (
      <PhoneLineItem item={item} index={index} total={managedNumbers?.length} />
    );
  };

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        zIndex: 10000,
        backgroundColor: `rgba(0,0,0,0.3)`,
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
      }}
    >
      <View
        style={{
          maxHeight: 300,
          minWidth: 400,
          maxWidth: 500,
          backgroundColor: palette.white,
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <Row
          center
          style={{
            height: 50,
            backgroundColor: palette.lighter_grey,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderColor: palette.light_grey,
          }}
          pl={16}
          pr={16}
          pb={8}
          pt={8}
        >
          <Heading size={4} mb={0} mt={0}>
            Select Phone Line
          </Heading>
        </Row>
        <FlatList data={managedNumbers} renderItem={renderPhoneLineItem} />
        <Pressable onPress={() => setCallQueue()}>
          <Row
            center
            style={{
              height: 40,
              backgroundColor: palette.danger,
            }}
            pl={16}
            pr={16}
            pb={8}
            pt={8}
          >
            <Heading size={5} mb={0} mt={0} color={palette.white}>
              Cancel
            </Heading>
          </Row>
        </Pressable>
      </View>
    </View>
  );
}

const PhoneLineItem = ({ item, index, total }) => {
  const [hovered, setHovered] = useState(false);
  const { startCall, callQueue, setCallQueue } = useContext(TwilioContext);

  const startDeviceCall = () => {
    startCall(callQueue.to, callQueue.metaData, item.phone_number);
    setCallQueue();
  };

  return (
    <Pressable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={startDeviceCall}
    >
      <Row
        style={{
          borderBottomWidth: index === total - 1 ? 0 : StyleSheet.hairlineWidth,
          borderColor: palette.light_grey,
          backgroundColor: hovered ? palette.lighter_grey : palette.white,
        }}
        center
      >
        <Heading
          size={6}
          mb={0}
          mt={0}
          pb={16}
          pt={16}
          pl={16}
          pr={16}
          textStyle={{ fontWeight: "500" }}
          numberOfLines={1}
        >
          {item?.friendly_name || item?.national_number}
        </Heading>
      </Row>
    </Pressable>
  );
};
